import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import PropTypes from 'prop-types';
import { kIntFormmater } from 'shared/utils/strUtil';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { palette } from 'shared/constants/colorsConstants';
import { CustomizedLegend } from './customizedLegend';
import { CHART_LINE_COLORS } from './chartConsts';
import { CustomizedActiveDot } from './customizedActiveDot';

const BreakEvenChart = ({ data }) => {
  const { getCurrencyNumber } = useUserSettingsContext();

  const legendWrapperStyle = {
    bottom: '0',
    left: '0',
    position: 'relative',
    width: '99%',
  };

  const dataMin = Math.min(...data.map((item) => item['On-Demand Cost']), data[0]['RI Cost']);
  const dataMax = Math.max(...data.map((item) => item['On-Demand Cost']), data[0]['RI Cost']);

  return (
    <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={284}>
      <AreaChart width={550} height={284} data={data} margin={{ top: 20, right: 20, bottom: 0, left: 20 }}>
        <XAxis
          dataKey="name"
          tickLine={false}
          tick={{ style: { fontSize: 11, color: palette.gray[400] } }}
          label={{
            value: 'Months',
            dy: 20,
          }}
        />
        <YAxis
          domain={[dataMin, dataMax]}
          label={{
            value: 'Cost',
            offset: 0,
            angle: -90,
            position: 'left',
          }}
          tickLine={false}
          tick={{ style: { fontSize: 11, color: palette.gray[400] } }}
          tickFormatter={(value) => getCurrencyNumber(kIntFormmater(value))}
        />
        <CartesianGrid strokeDasharray="3 0" vertical={false} />
        <Tooltip />
        <ReferenceLine
          y={data[0]['RI Cost']}
          label={{
            value: `RI Cost: ${getCurrencyNumber(data[0]['RI Cost'], 2)}`,
            offset: 5,
            position: 'top',
            fill: CHART_LINE_COLORS.LILAC.color,
            fontSize: 12,
          }}
          stroke={CHART_LINE_COLORS.LILAC.color}
          strokeWidth={1}
          strokeDasharray="6 6"
        />
        <defs>
          <linearGradient id="onDemandcolorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={CHART_LINE_COLORS.TEAL.color} stopOpacity={0.1} />
            <stop offset="95%" stopColor={CHART_LINE_COLORS.TEAL.color} stopOpacity={0} />
          </linearGradient>
        </defs>

        <Area
          type="monotone"
          dataKey="On-Demand Cost"
          stroke={CHART_LINE_COLORS.TEAL.color}
          dot={false}
          activeDot={<CustomizedActiveDot />}
          strokeWidth={1}
          fill="url(#onDemandcolorUv)"
        />
        <Legend content={<CustomizedLegend />} wrapperStyle={legendWrapperStyle} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

BreakEvenChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BreakEvenChart;
