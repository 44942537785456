import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import { UsersType } from 'users/constants/usersConstants';
import { cloneDeep } from 'lodash';
import classNames from 'classnames';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import styles from './preferences.module.scss';
import SinglePreferenceRow from './SinglePreferenceRow.tsx';
import { RecommendationPreference } from './types.ts';
import PreferencesFilters from './Filters.tsx';

type Props = {
  preferences: RecommendationPreference[];
  currentUserType: string;
  onPrefClicked?(id: string, expanded: boolean): void;
  onSearch?(searchStr: string): void;
  handleSave(newPreferences: RecommendationPreference[]): void;
};

enum SortingKey {
  TITLE = 'formattedTitle',
  CREATED = 'createdBy',
  UPDATED = 'updateTime',
}

const PreferencesTable: React.FC<Props> = ({
  currentUserType,
  onSearch,
  onPrefClicked,
  preferences: preferencesFromProps = [],
  handleSave,
}) => {
  const [preferences, setPreferences] = useState<RecommendationPreference[]>(cloneDeep(preferencesFromProps));
  const [rowExpanded, setRowExpanded] = useState<string | null>(null);
  const [searchVal, setSearchVal] = useState('');
  const [showActives, setShowActives] = useState(true);
  const [showInactives, setShowInactives] = useState(true);
  const [sorting, setSorting] = useState<[SortingKey, boolean] | null>(null);
  const [editingPref, setEditingPref] = useState<RecommendationPreference | null>(null);

  const handleRowExpanded = (pref: RecommendationPreference) => (isExpanded: boolean) => {
    setRowExpanded(isExpanded ? pref.uuid : null);
    setEditingPref(isExpanded ? pref : null);
    onPrefClicked?.(pref.uuid, isExpanded);
    setTimeout(
      () => document?.getElementById?.(pref.uuid)?.scrollIntoView({ behavior: 'smooth', block: 'center' }),
      500,
    );
  };

  const handleSearchValChanged = (searchVal) => {
    setSearchVal(searchVal);
    onSearch?.(searchVal);
  };

  const actionsDisabled = ![UsersType.USER, UsersType.RESELLER].includes(Number(currentUserType));

  const filteredPrefs = useMemo(
    () =>
      preferences?.filter(
        (p) =>
          (p.preferences.enabled === false ? showInactives : showActives) &&
          (!searchVal ||
            p.preferenceKey.includes(searchVal.toLowerCase()) ||
            p.formattedTitle?.toLowerCase().includes(searchVal.toLowerCase())),
      ) || [],
    [preferences, searchVal, showActives, showInactives],
  );

  const sortedAndFilteredPrefs = useMemo(() => {
    if (sorting) {
      const [sortingKey, isAsc] = sorting;
      const sortedPrefs = [...filteredPrefs];
      return sortedPrefs.sort((a, b) => {
        const v = isAsc ? 1 : -1;
        if (!a[sortingKey]) {
          return v;
        }
        if (!b[sortingKey]) {
          return -v;
        }
        const [first, second] = isAsc ? [a, b] : [b, a];
        return first[sortingKey]?.localeCompare(second[sortingKey] || '') || 1;
      });
    }
    return filteredPrefs;
  }, [filteredPrefs, sorting]);

  const onFieldChange = useCallback((key, value) => {
    setEditingPref(
      (p) =>
        p && {
          ...p,
          updated: 1,
          preferences: {
            ...p.preferences,
            [key]: value,
          },
        },
    );
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const activePrefFromUrl = searchParams.get('prefId');
    if (activePrefFromUrl) {
      setRowExpanded(activePrefFromUrl);
      setTimeout(
        () => document?.getElementById?.(activePrefFromUrl)?.scrollIntoView({ behavior: 'smooth', block: 'center' }),
        500,
      );
    }

    const searchFromUrl = searchParams.get('search');
    if (searchFromUrl) {
      setSearchVal(searchFromUrl);
    }
  }, []);

  const onSave = useCallback(() => {
    if (editingPref?.updated === 1) {
      handleSave([editingPref]);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { updated, ...savedPref } = editingPref;
      setPreferences((prefs) => prefs.map((p) => (p.uuid === editingPref.uuid ? savedPref : p)));
    }
  }, [editingPref]);

  const getArrow = (key: SortingKey) =>
    (sorting && key === sorting?.[0] && (
      <GenerateIcon iconName={sorting?.[1] ? ICONS.arrowUp.name : ICONS.arrowDown.name} />
    )) ||
    null;

  const onSort = useCallback((key: SortingKey) => setSorting((s) => [key, !s?.[1]]), []);

  return (
    <>
      <PreferencesFilters
        handleSearchValue={handleSearchValChanged}
        searchValue={searchVal}
        {...{
          showActive: showActives,
          showInactive: showInactives,
          setShowActive: setShowActives,
          setShowInactive: setShowInactives,
        }}
      />
      <div className={styles.tableHeader}>
        <AccordionSummary>
          <div className={classNames(styles.rowSummary, styles.tableHeaderSummary)}>
            <span onClick={() => onSort(SortingKey.TITLE)}>Type {getArrow(SortingKey.TITLE)}</span>
            <span onClick={() => onSort(SortingKey.CREATED)}>Created By {getArrow(SortingKey.CREATED)}</span>
            <span onClick={() => onSort(SortingKey.UPDATED)}>Update Time {getArrow(SortingKey.UPDATED)}</span>
          </div>
        </AccordionSummary>
      </div>
      {sortedAndFilteredPrefs.map((p) => (
        <div id={p.uuid} style={{ position: 'relative' }} key={p.uuid}>
          <SinglePreferenceRow
            isExpanded={p.uuid === rowExpanded}
            actionsDisabled={actionsDisabled}
            onExpand={(e, expanded) => handleRowExpanded(p)(expanded)}
            preference={p.uuid === rowExpanded && editingPref ? editingPref : p}
            onFieldChange={onFieldChange}
            onSave={onSave}
          />
        </div>
      ))}
    </>
  );
};

export default PreferencesTable;
