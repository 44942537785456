import React, { useContext, useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Button from 'shared/components/andtComponents/Button';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import Pagination from 'shared/components/andtComponents/Pagination';
import RolesContext from './contexts/rolesContext';
import { Search } from '../components/Search';
import RolesIcon from './RolesIcon';

import styles from './RolesHeader.module.scss';

const RolesHeader = ({ totalUsers }) => {
  const [searchText, setSearchText] = useState('');

  const { showFilters, setShowFilters, isAllExpanded, setIsAllExpanded } = useContext(RolesContext);

  const [isCsvDataFetching] = useState(false);

  const fetchExportData = () => {};

  const currentPage = 0;
  const totalPages = 10;
  const pageChange = () => {};

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        <Button
          automationId="expand-rows"
          text=""
          isSecondary
          icon={() => <GenerateIcon iconName={isAllExpanded ? ICONS.collapse.name : ICONS.expand.name} />}
          onClick={() => {
            setIsAllExpanded(!isAllExpanded);
          }}
        />
        <div className={styles.header}>
          <RolesIcon />
          <div className={styles.title}>
            Roles <span className={styles.count}>({totalUsers})</span>
          </div>
        </div>
      </div>
      <div className={styles.headerRight}>
        {/* <CreateRoleModal /> */}
        <Search searchText={searchText} setSearchText={setSearchText} />
        <Tooltip title="Filter columns value" arrow>
          <Button
            text="Filter Results"
            automationId="users-filter-results"
            onClick={() => {
              setShowFilters(!showFilters);
            }}
            overrideStyles={showFilters ? { background: '#DFE9FF' } : {}}
            isSecondary
            icon={() => (
              <GenerateIcon
                iconName={showFilters ? ICONS.xMark.name : ICONS.filterList.name}
                className={styles.filtersIcon}
              />
            )}
          />
          <span />
        </Tooltip>
        <Tooltip title="Export" arrow>
          <CustomCSVDownload
            fetchData={fetchExportData}
            automationId="export-recommendations"
            showDownloadIcon
            isLoading={isCsvDataFetching}
            filesNumber={1}
            isSecondary
            isLightButton
            hideText
            style={{ height: 36 }}
          />
          <span />
        </Tooltip>
        <Pagination currPageIdx={currentPage} onPageChange={pageChange} totalPages={totalPages} />
      </div>
    </div>
  );
};

export default RolesHeader;
