import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import React from 'react';

import styles from './RemoveUserRole.module.scss';

const RemoveUserRole = ({ showRemoveComponent, isMultiple, selectedCounter }) =>
  showRemoveComponent ? (
    <div className={styles.removeRolesContainer}>
      <GenerateIcon iconName={ICONS.circleMinus.name} className={styles.icon} />
      {isMultiple ? `Remove Selected (${selectedCounter})` : 'Remove'}
    </div>
  ) : (
    <div />
  );

RemoveUserRole.defaultProps = {
  showRemoveComponent: true,
  isMultiple: false,
  selectedCounter: 0,
};

export default RemoveUserRole;
