import React, { useState } from 'react';
import useTable from 'shared/hooks/customHooks/useTable';
import UserRolesTabHeader from './UserRolesTabHeader';
import UserRolesTabTable from './UserRolesTabTable';

import styles from './UserRolesTab.module.scss';

const UserRolesTab = ({ row }) => {
  const userRoles = row?.roles;

  const { NewTableWrapper } = useTable();

  const [selectedUserRoles, setSelectedUserRoles] = useState([]);
  const [searchText, setSearchText] = useState('');

  return (
    <div className={styles.userRolesTableContainer}>
      <NewTableWrapper className="sub-table" isCompact>
        <UserRolesTabHeader
          rolesCount={userRoles?.length}
          selectedUserRoles={selectedUserRoles}
          searchText={searchText}
          setSearchText={setSearchText}
        />
        <UserRolesTabTable
          userRoles={userRoles}
          selectedUserRoles={selectedUserRoles}
          setSelectedUserRoles={setSelectedUserRoles}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      </NewTableWrapper>
    </div>
  );
};

export default UserRolesTab;
