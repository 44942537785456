import React from 'react';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import classNames from 'classnames';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import styles from './recommPlanDetails.module.scss';
import PsPieChart from './SpPieChart';
import { HourlyCommitment } from './HourlyCommitment';
import { PlanPreferences } from './PlanPrefernces';
import { RECOMMENDATION_PLAN_PROPERTIES } from '../utils/consts';
import { ReactComponent as NoData } from '../img/no-calculated-data.svg';

const convertToCapital = (text) =>
  text
    .split(/(?=[A-Z])/)
    .join(' ')
    .toUpperCase();
const RecommendedPlanCube = ({
  plan,
  planKey,
  preferencePlanKey,
  shareProperties,
  selectedKey,
  setSelectedPlan,
  automationId,
}) => {
  const { getCurrencyNumber } = useUserSettingsContext();

  const getPropertyValue = (prop) => {
    const value = prop.isShareProperty ? shareProperties[prop.key] : plan[prop.key];
    return prop.isCurrency ? getCurrencyNumber(Number(value)) : convertToCapital(value);
  };

  const calculateFieldValue = (prop) => {
    const calculatedFields = prop.calculatedFields.map((field) => {
      const prop = RECOMMENDATION_PLAN_PROPERTIES.find((prop) => prop.key === field);
      return prop.isShareProperty ? shareProperties[prop.key] : plan[prop.key];
    });
    return getCurrencyNumber(calculatedFields.reduce((a, b) => Number(a) - Number(b)));
  };
  return (
    <div
      className={`${styles.planWrapper} ${selectedKey === planKey && styles.selected}`}
      onClick={() => setSelectedPlan(planKey)}
      automation-id={automationId}
    >
      <div className={styles.top}>
        <div className={styles.detailsHeader}>
          {convertToCapital(planKey)}
          {selectedKey === planKey && (
            <a
              target="_blank"
              href="https://console.aws.amazon.com/costmanagement/home?/savings-plans/purchase#/savings-plans/purchase"
              className={styles.tag}
              rel="noreferrer"
            >
              BUY
            </a>
          )}
        </div>
        {preferencePlanKey === planKey && <PlanPreferences />}
      </div>
      {!plan ? (
        <div className={styles.noRecommendedWrapper}>
          <NoData />
          <span className={styles.noRecommendedText}>This plan is not recommended</span>
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.listWrapper}>
            <ul className={styles.list}>
              {RECOMMENDATION_PLAN_PROPERTIES.map((property) => (
                <li key={property} className={`${property.isEndGroup && styles.borderBottom}`}>
                  {property.name}:
                  <span
                    className={classNames(styles.value, plan.potentialSavings < 0 && styles.noProfitable)}
                    automation-id={`${property.key}Value`}
                  >
                    {property.calculatedFields?.length ? calculateFieldValue(property) : getPropertyValue(property)}
                  </span>
                  {property.info && (
                    <div className={styles.infoIcon}>
                      <InfoPopover isSimple mode="outline">
                        {property.info}
                      </InfoPopover>
                    </div>
                  )}
                  {property.isEditable && (
                    <HourlyCommitment planKey={planKey} paymentOption={plan.paymentOption} term={plan.term} />
                  )}
                </li>
              ))}
            </ul>
          </div>
          <PsPieChart percentValue={plan?.potentialSavings} />
        </div>
      )}
    </div>
  );
};

export default RecommendedPlanCube;
