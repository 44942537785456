import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import toast from 'shared/components/andtComponents/Toast';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { anomalyFeedbackProvider } from 'usage/containers/AnomalyDetection/hooks/react-query/useAnomalyDetection';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import styles from './AnomalyFeedback.module.scss';

const propTypes = {
  row: PropTypes.object.isRequired,
};

const AnomalyFeedback = ({ row }) => {
  const [feedbacks, setFeedbacks] = useState(row.feedbacks || []);
  const { usersStore } = useRootStore();

  const anomalyFeedbackHook = anomalyFeedbackProvider(row.uuid);
  const { mutateAsync: addFeedback } = anomalyFeedbackHook.addFeedback({
    onError: () => {
      toast.error('Failed to add feedback');
    },
  });
  const { mutateAsync: deleteFeedback } = anomalyFeedbackHook.deleteFeedback({
    onError: () => {
      toast.error('Failed to delete feedback');
    },
  });

  const updateFeedback = async (feedback) => {
    const createdBy = usersStore.getCurrUserName;
    const feedbackToAdd = {
      feedback,
      createdBy,
    };

    const isRemovingFeedback = !!feedbacks.find(
      ({ feedback, createdBy }) => createdBy === feedbackToAdd.createdBy && feedback === feedbackToAdd.feedback,
    );
    if (isRemovingFeedback) {
      setFeedbacks(feedbacks.filter(({ createdBy }) => createdBy !== feedbackToAdd.createdBy));
      deleteFeedback({ createdBy });
      return;
    }

    // Remove old feedback from same user if exists
    const filteredFeedbacks = feedbacks.filter(({ createdBy }) => createdBy !== feedbackToAdd.createdBy);
    setFeedbacks([...filteredFeedbacks, feedbackToAdd]);
    addFeedback(feedbackToAdd);
  };

  const positiveFeedbacks = feedbacks.filter((feedback) => feedback.feedback);
  const negativeFeedbacks = feedbacks.filter((feedback) => !feedback.feedback);
  const getTooltipText = (feedbacks) => feedbacks.map(({ createdBy }) => createdBy).join('\n');

  return (
    <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
      <Tooltip title={getTooltipText(positiveFeedbacks)} placement="top" disabled={!positiveFeedbacks.length}>
        <div className={`${styles.feedbackContainer} ${positiveFeedbacks.length && styles.active}`}>
          <span>{!!positiveFeedbacks.length && positiveFeedbacks.length}</span>
          <GenerateIcon iconName={ICONS.thumbUp.name} onClick={() => updateFeedback(true)} />
        </div>
      </Tooltip>
      <Tooltip title={getTooltipText(negativeFeedbacks)} placement="bottom" disabled={!negativeFeedbacks.length}>
        <div className={`${styles.feedbackContainer} ${negativeFeedbacks.length && styles.active}`}>
          <GenerateIcon iconName={ICONS.thumbDown.name} onClick={() => updateFeedback(false)} />
          <span>{!!negativeFeedbacks.length && negativeFeedbacks.length}</span>
        </div>
      </Tooltip>
    </div>
  );
};

AnomalyFeedback.propTypes = propTypes;
export default AnomalyFeedback;
