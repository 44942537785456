import { IconNames } from '@pileus-cloud/anodot-frontend-common/dist/types';
import { CAU_ALERTS_STATUS, CAU_ALERTS_TYPES } from '../../constants/usageConstants';

export enum AlertTypesEnum {
  COST_CHANGES = CAU_ALERTS_TYPES.COST_CHANGES,
  RI_EXPIRATION = CAU_ALERTS_TYPES.RI_EXPIRATION,
  SP_EXPIRATION = CAU_ALERTS_TYPES.SP_EXPIRATION,
}

export enum AlertStatusEnum {
  SAVE = CAU_ALERTS_STATUS.SAVE,
  DELETE = CAU_ALERTS_STATUS.DELETE,
  EDIT = CAU_ALERTS_STATUS.EDIT,
  REMOVE = CAU_ALERTS_STATUS.REMOVE,
  ADD = CAU_ALERTS_STATUS.ADD,
}

export type CommitmentExpAlert = {
  uuid: string;
  recipientsEmails: string;
  recipients: object[];
  creationDate: string;
  daysBeforeExpiry: number;
  alertType: AlertTypesEnum;
  alertStatus: AlertStatusEnum;
};

export type AlertTypeOnUpdate = 'changeCostService' | 'commitementExpirationRi' | 'commitementExpirationSP';
export type InconsistentUpdatingParams = {
  alertStatus: 'save';
  alertType: AlertTypeOnUpdate;
};
export type UpdatedCommitmentAlert = {
  daysBeforeExpiry: CommitmentExpAlert['daysBeforeExpiry'];
  recipientsEmails: CommitmentExpAlert['recipientsEmails'];
  recipients: CommitmentExpAlert['recipients'];
  uuid?: CommitmentExpAlert['uuid'];
} & InconsistentUpdatingParams;

export type Option<T = string | number> = { label?: string; value: T };
type Filter = { operator: 'IS' | 'IS NO'; value: string | Option[] };

export enum AlertLevelsEnum {
  SERVICE = 'service',
  TOTAL = 'total',
}

export type ServiceCostAlert = {
  uuid: string;
  alertName?: string;
  alertLevel?: AlertLevelsEnum;
  alertType?: AlertTypesEnum;
  alertStatus?: AlertStatusEnum;
  recipientsEmails: string;
  recipients: object[];
  changePercent: string | number;
  accountId?: string;
  accountKey?: string | number;
  changeType: Option;
  changeValue: string;
  creationDate?: string;
  dayInWeek: Option | null;
  dbUpdateTime?: string;
  dbCreationTime?: string;
  divisionId?: string;
  filters: { [key: string]: Filter };
  granularity: Option;
  operatorType: Option;
  userKey?: string;
};

export type ServiceCostAlertUpdate = InconsistentUpdatingParams & {
  alertLevel?: AlertLevelsEnum;
  changePercent: string | number;
  changeType: string;
  changeValue: string | number;
  creationDate: string;
  dayInWeek: string | null;
  filters: { [key: string]: { operator: string; value: string | number | (string | number)[] } };
  granularity: string | number;
  operatorType: string;
  recipientsEmails: string;
  recipients: object[];
  uuid?: string;
  alertName?: string;
};

type APIBoolean = 1 | 0;

export type UsersSettings = {
  is_alert_cost_change: APIBoolean;
  is_alert_ri_expiration: APIBoolean;
  is_alert_sp_expiration: APIBoolean;
};

export type FilterOption = {
  label: string;
  id: string;
  isDisabled?: boolean;
  icon: IconNames;
};
