import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';

import ListWithHelp from 'shared/components/andtComponents/ListWithHelp/ListWithHelp';
import Input from 'shared/components/andtComponents/Input';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { GCP_ONBOARDING_FIELDS, GCP_ONBOARDING_STEPS } from '../../GcpOnboardingConstants';
import commonStyles from '../Pages.module.scss';
import K8sIntegrationPageHelp from './K8sIntegrationPageHelp';
import styles from './K8sIntegrationPage.module.scss';
import ErrorModal from '../../../SharedPages/ErrorModal/ErrorModal';
import ValidateModal from '../../../SharedPages/ValidateModal/ValidateModal';
import OnboardingLinks from '../../../utils/OnboardingLinks';
import Button from '../../../../../../shared/components/andtComponents/Button';

const K8sIntegrationPage = () => {
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  // wait 60 seconds before redirecting user to next page in case success status is
  // from prev onboarding (airflow updates it in a few sec)
  const [minWaitingTime, setMinWaitingTime] = useState(false);
  const [validationOngoing, setValidationOngoing] = useState(false);
  const { fetchOnboardingStatus, restartOnboarding, updateOnboardingParams } = useOnboarding();
  const { mutateAsync: handleUpdateOnboardingParams } = updateOnboardingParams();
  const accountId = getValues(GCP_ONBOARDING_FIELDS.ACCOUNT_ID);
  const validationStarted = getValues(GCP_ONBOARDING_FIELDS.VALIDATION_STARTED);
  const validationStartTrigger = getValues(GCP_ONBOARDING_FIELDS.VALIDATION_START_TRIGGER);
  useEffect(() => {
    if (validationStarted) {
      setMinWaitingTime(false);
      setTimeout(() => {
        setMinWaitingTime(true);
        handleUpdateOnboardingParams({ validationShouldStart: true, accountId });
      }, 60000);
    }
  }, [validationStarted]);
  useWatch(GCP_ONBOARDING_FIELDS.VALIDATION_STARTED);
  const { data: status } = fetchOnboardingStatus(accountId, {
    refetchInterval: 15 * 1000,
  });
  const handleRestart = restartOnboarding();
  const [validationFailed, setValidationFailed] = useState(false);
  const { validationStatus } = status || {};
  useEffect(() => {
    if (validationStartTrigger) {
      setValidationOngoing(true);
      setTimeout(() => {
        setValidationOngoing(false);
      }, 60 * 1000);
    }
  }, [validationStartTrigger]);
  useEffect(() => {
    const isFailed = !validationOngoing && validationStatus?.status === 'FAILED' && validationStarted;
    const isSuccess = minWaitingTime && validationStatus?.status === 'SUCCESS' && validationStarted;
    if (isSuccess) {
      setValue(GCP_ONBOARDING_FIELDS.SCREEN, GCP_ONBOARDING_STEPS.PROCESS_DATA);
      return;
    }
    if (isFailed) {
      setValidationFailed(true);
    } else {
      setValidationFailed(false);
    }
  }, [status, minWaitingTime, validationOngoing]);
  const renderInput = ({ fieldName, name, label, icon, disabled }) => (
    <div className={commonStyles.inputField}>
      <label htmlFor={name}>
        <span>
          <GenerateIcon iconName={ICONS[icon].name} />
          {label}
        </span>
      </label>
      <div className={commonStyles.inputBox}>
        <div>
          <Input
            disabled={disabled}
            value={getValues(fieldName)}
            isInvalid={!!errors[fieldName]}
            invalidComponent={errors[fieldName]?.message || errors[fieldName]}
            onChange={(e) => setValue(fieldName, e.target.value, { shouldValidate: true })}
            placeholder=""
            name={name}
            type="text"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <ListWithHelp
        styles={{
          listGap: 14,
          helpWidth: 460,
          controlStyles: {
            marginLeft: '-4px',
            borderLeft: 'none',
          },
        }}
        listDescription={
          <p className={styles.description}>
            To see metadata and labels to your K8s data create a labels dataset.
            <Button
              onClick={() => window.open(OnboardingLinks.GCP.k8sIntegration, '_blank')}
              text="Learn more"
              isTextButton
              overrideStyles={{ paddingLeft: 0, height: 20 }}
            />
          </p>
        }
        list={[
          {
            render: () =>
              renderInput({
                fieldName: GCP_ONBOARDING_FIELDS.K8S_PROJECT_ID,
                name: 'onboarding-k8s-project-id',
                label: 'Project ID',
                icon: 'cloud',
              }),
            key: 'onboarding-k8s-project-id',
          },
          {
            render: () =>
              renderInput({
                fieldName: GCP_ONBOARDING_FIELDS.K8S_DATASET_ID,
                name: 'onboarding-k8s-dataset-id',
                label: 'GKE data set name',
                icon: 'cloud',
              }),
            key: 'onboarding-k8s-dataset-id',
          },
        ]}
        help={{
          'onboarding-k8s-project-id': K8sIntegrationPageHelp.projectID,
          'onboarding-k8s-dataset-id': K8sIntegrationPageHelp.datasetID,
        }}
      />
      {validationStarted && getValues(GCP_ONBOARDING_FIELDS.VALIDATION_STARTED) && !validationFailed && (
        <ValidateModal
          noteTitle="This step can take up to 24 hours"
          text={`You can close this window, and we will send you an 
         email after we process your data and it will be visible in Anodot.`}
        />
      )}
      {validationStarted && validationFailed && (
        <ErrorModal status={status} handleRestart={handleRestart} cloudType={CLOUD_TYPE_IDS.GCP} />
      )}
    </div>
  );
};

export default K8sIntegrationPage;
