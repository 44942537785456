import React, { useState } from 'react';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import toast from 'shared/components/andtComponents/Toast';
import { USER_ACTIONS } from '../../consts';
import useUsers from '../../../../new-user-management/hooks/reactQuery/useUsers';

const ResetPasswordModal = ({ isOpen, setIsOpen, users, onSuccess }) => {
  const [saveClicked] = useState(false);

  const { resetUsersPassword } = useUsers();

  const onResetPassword = async () => {
    try {
      await resetUsersPassword.mutateAsync({ userIds: users.map((user) => user.id) });
      setIsOpen(false);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (error.response.status === 500) {
        toast.error('Error while resetting user password');
      }
    }
  };

  return (
    <CustomModal
      onClose={() => {
        setIsOpen(false);
      }}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={MODAL_MODES.SIMPLE}
      footerDisabled={saveClicked}
      onSave={onResetPassword}
      open={isOpen}
      automationId="reset-password-modal"
      saveTitle="Send"
      saveDisabled={false}
      title={USER_ACTIONS.RESET_PASSWORD.label}
    >
      <div>Send the user an email to reset the Anodot Cost password</div>
    </CustomModal>
  );
};

export default ResetPasswordModal;
