import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { DataTypeProvider, IntegratedPaging, PagingState, RowDetailState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, TableColumnResizing, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import Spinner from 'shared/components/andtComponents/Spinner';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import TableWrapper from 'shared/components/tables/TableWrapper';
import Button from 'shared/components/andtComponents/Button';
import { withRolesContextConsumer } from 'users/contexts/RolesProvider';
import { useRootStore } from 'app/contexts/RootStoreContext';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { formatDateToLabel } from 'shared/utils/dateUtil';
import Avatar from 'shared/components/andtComponents/Avatar';
import ButtonDropdown from 'shared/components/andtComponents/ButtonDropdown';
import useTable from 'shared/hooks/customHooks/useTable';
import useRoles from 'users/hooks/react-query/useRoles';
import { copyStrToClipboard } from 'shared/utils/strUtil';
import { getLinkedAccountName } from 'shared/utils/cloudUtils';
import { ROLE_TYPES, ROLES_COLUMN_EXTENSIONS } from 'users/containers/RolesAndUsers/constants/rolesAndUsersConstants';
import UsersTable from 'users/containers/RolesAndUsers/components/UsersTable';
import toast from 'shared/components/andtComponents/Toast';
import LinkedAccountsTable from 'users/containers/RolesAndUsers/components/LinkedAccountsTable/LinkedAccountsTable';
import styles from './RolesTable.module.scss';
import PermissionIcon from './PermissionIcon';

const ROLES_TABLE_COLUMNS = [
  {
    name: 'roleName',
    title: '',
    getCellValue: (row) => (
      <div className={styles.rowTypeNameCell}>
        <PermissionIcon role={row} />
        {row.roleName}
      </div>
    ),
  },
  { name: 'details', title: '' },
];

const RolesTable = ({
  editRoleHandler,
  duplicateRoleHandler,
  editedLinkedAccountsRoleHandler,
  roles,
  filters,
  addUserHandler,
  deleteRole,
  refetchRoles,
  expandedRowIds,
  setExpandedRowIds,
  renderCSVDownload,
}) => {
  const { NewTableRow, NewTableRowDetail, NewTableWrapper } = useTable();
  const [deletedRole, setDeletedRole] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(15);
  const { usersStore } = useRootStore();
  const { fetchDefaultRole } = useRoles();
  const { data: defaultRoleId } = fetchDefaultRole({
    enabled: usersStore.getCurrentUser?.selfManagedCustomerAdmin,
  });
  const handleCopyStr = (str) => {
    copyStrToClipboard(str);
    toast.success('Role ID copied to clipboard');
  };
  const usersList = usersStore.usersModel.getSubUsers;
  const [isRoleHasUsersAttached, setIsRoleHasUsersAttached] = useState(false);
  const deleteRoleButtonClicked = (deletedRole) => {
    setDeletedRole(deletedRole);
    setIsRoleHasUsersAttached(usersStore.isRoleHasUsersAttached(deletedRole.uuid));
  };
  const roleDeleteWarningHandler = async (action) => {
    if (action === 'delete') {
      setIsLoading(true);
      await deleteRole(deletedRole.uuid);
      await refetchRoles();
      setIsLoading(false);
    }
    setDeletedRole(null);
    setIsRoleHasUsersAttached(false);
  };
  const editFormatter = (data) => {
    const rowInd = roles.findIndex((r) => r.uuid === data.row.uuid);
    return (
      <ReadOnlyDisplayWrapper isHide={false} userReadOnly={usersStore.currentUserReadOnly}>
        <Tooltip title={`Add/Edit ${getLinkedAccountName(usersStore.currDispUserCloudAccountType)}s`}>
          <span className={styles.secondaryButton}>
            <Button
              disabled={data.row.roleType === ROLE_TYPES.DEFAULT_ADMIN}
              text=""
              isSecondaryWithHover={!expandedRowIds.includes(rowInd)}
              icon={() => <GenerateIcon iconName={ICONS.addLinkedAccounts.name} />}
              onClick={() => editedLinkedAccountsRoleHandler(data.row)}
            />
          </span>
        </Tooltip>
      </ReadOnlyDisplayWrapper>
    );
  };
  const addFormatter = (data) => {
    const rowInd = roles.findIndex((r) => r.uuid === data.row.uuid);
    return (
      <ReadOnlyDisplayWrapper isHide={false} userReadOnly={usersStore.currentUserReadOnly}>
        <Tooltip title="Add User">
          <span className={styles.secondaryButton}>
            <Button
              text=""
              isSecondaryWithHover={!expandedRowIds.includes(rowInd)}
              icon={() => <GenerateIcon iconName={ICONS.addUser.name} />}
              onClick={() => addUserHandler(data.row)}
            />
          </span>
        </Tooltip>
      </ReadOnlyDisplayWrapper>
    );
  };
  const actionsFormatter = (data) => (
    <ButtonDropdown
      text=""
      isTextButton
      icon={() => <GenerateIcon iconName={ICONS.verticalDots.name} className={styles.menuIcon} />}
      automationId="rolesactions"
    >
      {data.row.roleType === ROLE_TYPES.DEFAULT_ADMIN ? null : (
        <ReadOnlyDisplayWrapper isHide={false} userReadOnly={usersStore.currentUserReadOnly}>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <li onClick={() => editRoleHandler(data.row)} id="edit">
            <span>
              <GenerateIcon iconName={ICONS.edit.name} /> Edit Role
            </span>
          </li>
        </ReadOnlyDisplayWrapper>
      )}
      <ReadOnlyDisplayWrapper isHide={false} userReadOnly={usersStore.currentUserReadOnly}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <li onClick={() => duplicateRoleHandler(data.row)} id="edit">
          <span>
            <GenerateIcon iconName={ICONS.clone.name} /> Duplicate Role
          </span>
        </li>
      </ReadOnlyDisplayWrapper>
      {data.row.roleType === ROLE_TYPES.DEFAULT_ADMIN ? null : (
        <ReadOnlyDisplayWrapper isHide={false} userReadOnly={usersStore.currentUserReadOnly}>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <li onClick={() => deleteRoleButtonClicked(data.row)} id="delete">
            <span>
              <GenerateIcon iconName={ICONS.delete.name} /> Delete Role
            </span>
          </li>
        </ReadOnlyDisplayWrapper>
      )}
    </ButtonDropdown>
  );

  const detailsFormatter = (data) => (
    <div className={styles.detailsCell}>
      <div className={styles.dateCell}>Updated: {formatDateToLabel(data.row.dbUpdateTime)}</div>
      <span className={styles.roleIdCopy}>
        <span>Role ID: </span>
        {data.row.uuid}
        <span className={styles.copyIcon} onClick={() => handleCopyStr(data.row.uuid)}>
          <GenerateIcon iconName={ICONS.copy.name} />
        </span>
      </span>
      <div>
        <Avatar userName={data.row.createdBy} />
      </div>
      {addFormatter(data)}
      {editFormatter(data)}
      {renderCSVDownload(data.row)}
      {actionsFormatter(data)}
    </div>
  );

  const renderTableRow = ({ row, children, ...restProps }) => (
    <NewTableRow spacerHeight={16} expandedRowIds={expandedRowIds} {...restProps}>
      {children}
    </NewTableRow>
  );

  const renderRowDetail = (data) => (
    <NewTableRowDetail>
      <div className={styles.expandedRow}>
        <LinkedAccountsTable role={data.row} />
        <UsersTable
          roleId={data.row.uuid}
          addUserHandler={() => addUserHandler(data.row)}
          users={usersList
            .filter((user) => user.userRole?.uuid === data.row.uuid)
            .filter((user) =>
              filters && filters.userName.length ? filters.userName.some((u) => u.value === user.userName) : true,
            )}
        />
      </div>
    </NewTableRowDetail>
  );

  if (isLoading) {
    return (
      <div style={{ width: '100%', marginTop: '40px' }}>
        <Spinner />
      </div>
    );
  }
  return (
    <NewTableWrapper className={styles.container}>
      {defaultRoleId && (
        <>
          <div className={styles.defaultRoleNote}>
            <p>
              Use this default <span>Admin Role ID</span> in order to add users with full permissions
            </p>
            <span className={styles.roleIdCopy}>
              <span>Admin Role ID:</span>
              {defaultRoleId}
              <span className={styles.copyIcon} onClick={() => handleCopyStr(defaultRoleId)}>
                <GenerateIcon iconName={ICONS.copy.name} />
              </span>
            </span>
          </div>
          <div className="pt-4 pb-2" />
        </>
      )}
      <Grid rows={roles} columns={ROLES_TABLE_COLUMNS}>
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
        <PagingState pageSize={size} onPageSizeChange={setSize} currentPage={page} onCurrentPageChange={setPage} />
        <IntegratedPaging />
        <DataTypeProvider for={['details']} formatterComponent={detailsFormatter} />
        <TableWrapper rowComponent={renderTableRow} />
        <TableColumnResizing defaultColumnWidths={ROLES_COLUMN_EXTENSIONS} resizingMode="nextColumn" />
        <TableRowDetail contentComponent={renderRowDetail} />
        <PagingPanel pageSizes={[5, 10, 15]} />
      </Grid>
      <DeleteWarningModal
        deletedItemName={isRoleHasUsersAttached ? null : deletedRole && deletedRole.roleName}
        isOpen={deletedRole}
        handleDelete={roleDeleteWarningHandler}
        warningMessage={
          isRoleHasUsersAttached
            ? "This Role has users associated with it, and can't be deleted "
            : 'Be advise you are about to delete'
        }
        modalTitle="Delete User Role"
        isShowDeleteButton={!isRoleHasUsersAttached}
      />
    </NewTableWrapper>
  );
};

RolesTable.propTypes = {
  editRoleHandler: PropTypes.func.isRequired,
  editedLinkedAccountsRoleHandler: PropTypes.func.isRequired,
  duplicateRoleHandler: PropTypes.func.isRequired,
  addUserHandler: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired,
  refetchRoles: PropTypes.func.isRequired,
  renderCSVDownload: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  expandedRowIds: PropTypes.array.isRequired,
  setExpandedRowIds: PropTypes.func.isRequired,
};

export default withRolesContextConsumer(observer(RolesTable));
