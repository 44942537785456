import React from 'react';
import PropTypes from 'prop-types';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import {
  BASE_PROPERTIES,
  COST_PROPERTIES,
  EXTENDED_SUPPORT_PROPERTIES,
  OPTIONS_PROPERTIES,
} from '../../recommendationPropertyUtils';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';
import OtherResourceIdRecommendations from '../../recommendationGenericComponents/otherResourceIdRecommendations';
import RecommendationOptionsPanel from '../../recommendationGenericComponents/recommendationOptionsPanel';

const EksExtendedSupport = ({ recommendation }) => {
  const properties = {
    ...BASE_PROPERTIES,
    ...COST_PROPERTIES,
    ...EXTENDED_SUPPORT_PROPERTIES,
  };

  const description = `AWS EKS extended support for Kubernetes versions lets you use a Kubernetes minor version
                       for up to 26 months from the time the version is generally available from Amazon EKS,
                       When your Amazon EKS cluster runs a Kubernetes version in extended support,
                       you pay $0.60 per cluster per hour. We recommend you use a 
                       cluster version in standard support to get the best pricing.`;

  const insideLookComponent = <RecommendationInsideLook description={description} />;

  const otherResourceIdRecommendations = <OtherResourceIdRecommendations resourceId={recommendation?.resourceId} />;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} viewAll />
  );

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  const CURRENT_PROPERTIES = [
    properties.VERSION,
    properties.START_OF_EXTENDED_SUPPORT_DATE,
    properties.END_OF_EXTENDED_SUPPORT_DATE,
    properties.CURRENT_ANNUAL_COST,
  ];

  const ALTERNATIVE_PROPERTIES = [
    properties.RECOMMENDED_VERSION,
    properties.START_OF_EXTENDED_SUPPORT_DATE,
    properties.END_OF_EXTENDED_SUPPORT_DATE,
    properties.RECOMMENDED_ANNUAL_COST,
    OPTIONS_PROPERTIES.POTENTIAL_SAVINGS,
  ];

  const alternatives = recommendation?.recData?.alternatives;
  const optionsPanelComponent =
    Array.isArray(alternatives) && alternatives.length ? (
      <RecommendationOptionsPanel
        alternatives={alternatives}
        currentProperties={CURRENT_PROPERTIES}
        noticeComment={
          properties.START_OF_EXTENDED_SUPPORT_DATE.getterFunction(recommendation) > new Date()
            ? 'Early notice for extended support!'
            : ''
        }
        alternativeProperties={ALTERNATIVE_PROPERTIES}
        minHeight={180}
        recommendation={recommendation}
        isRecommendedOption
      />
    ) : null;

  return (
    <RecommendationDetailsLayout
      insideLook={insideLookComponent}
      otherResourceIdRecommendations={otherResourceIdRecommendations}
      details={detailsComponent}
      optionsPanel={optionsPanelComponent}
      preferences={preferencesComponent}
    />
  );
};

EksExtendedSupport.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default EksExtendedSupport;
