/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */

import moment from 'moment';
import { GRANULARITY_LEVEL_TYPES, ReportPeriodTime } from 'usage/constants/costAndUsageConstants';
import DateFilter from 'shared/modules/dateFilter';
import { isNumber } from 'lodash';
import { GranularityLevelsTypes } from '../constants/appConstants';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getMonthNameFromNumber = (monthNum) => {
  if (monthNum > 0 && monthNum < 13) {
    return months[monthNum - 1];
  }

  return 'Invalid Month';
};

export function convertDateToUTC(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
}

export const getShortMonthNameFromNumber = (monthNum) => {
  if (monthNum > 0 && monthNum < 13) {
    return shortMonths[monthNum - 1];
  }

  return 'Invalid Month';
};

export const getShortQuarterNameFromNumber = (quarterNum) => {
  if (quarterNum > 0 && quarterNum < 5) {
    return `Q${quarterNum}`;
  }

  return 'Invalid Quarter';
};

export const dateFormats = {
  hourly: 'hour',
  daily: 'day',
  monthly: 'month',
  weekly: 'week',
  quarterly: 'quarter',
};

export const isQuarterDate = (date) => date && date.match(/^\d\d\d\d-[1234]-q$/);
export const isMonthDate = (date) => date && date.match(/^\d\d\d\d-\d\d?$/);
export const isWeekDate = (date) => date && date.match(/^W-\d\d?-\d\d\d\d$/);
export const isDateOfMonth = (date) => date && date.match(/^\d\d\d\d-\d\d?-\d\d?$/);
export const isHourDate = (date) => date && date.match(/^\d\d\d\d-\d\d?-\d\d?T\d\d?(:\d\d?){0,2}(.\d\d\d)?Z?$/);

export const parseDateFormatFromDate = (date) => {
  if (!date || typeof date !== 'string') {
    return null;
  }
  if (isQuarterDate(date)) {
    return dateFormats.quarterly;
  }
  if (isWeekDate(date)) {
    return dateFormats.weekly;
  }
  if (isMonthDate(date)) {
    return dateFormats.monthly;
  }
  if (isDateOfMonth(date)) {
    return dateFormats.daily;
  }
  if (isHourDate(date)) {
    return dateFormats.hourly;
  }
  return null;
};

export const createDateDisplayStr = (dateStr, format) => {
  if (!dateStr) {
    return '';
  }
  if (typeof dateStr !== 'string') {
    return 'Invalid date';
  }
  const [year, monthOrQuarter, dayCand] = dateStr.split('-');
  const day = dayCand || 1;
  const parsedFormat = format || parseDateFormatFromDate(dateStr);
  switch (parsedFormat) {
    case dateFormats.monthly:
      return `${getShortMonthNameFromNumber(monthOrQuarter)} ${year}`;
    case dateFormats.quarterly:
      return `${getShortQuarterNameFromNumber(monthOrQuarter)} ${year}`;
    case dateFormats.hourly:
      // eslint-disable-next-line no-case-declarations
      const hour = moment(dateStr).utc().format('MMM D, HH:mm');
      if (hour === '24:00') {
        return '00:00';
      }
      return hour;
    case dateFormats.weekly:
      return dateStr.split('-').slice(0, 2).join('-');
    case dateFormats.daily:
      return `${getShortMonthNameFromNumber(monthOrQuarter)} ${day}`;
    default:
      return dateStr;
  }
};

export const formatDateToLabel = (date) => moment(date).format('MMM D YYYY');
export const formatDateToShortLabel = (date) => moment(date).format('MMM D');
export const formatDateTime = (date) => moment(date).format('MMM D YYYY, hh:mm');
export const formatTime = (date) => moment(date).format('HH:mm');

export const getLastDayOfWeekDate = (date) => {
  const lastDayOfWeek = `${moment(date).endOf('isoWeek').format('YYYY-MM-DD')}T00:00:00.000`;
  return new Date(lastDayOfWeek);
};
export const getLstDayOfMonthDate = (date) => {
  const lastDayOfMonth = `${moment(date).subtract(0, 'months').endOf('month').format('YYYY-MM-DD')}T00:00:00.000`;
  return new Date(lastDayOfMonth);
};

export const getLastDayOfQuarterDate = (date) => {
  const lastDayOfQuarter = `${moment(date).endOf('quarter').format('YYYY-MM-DD')}T00:00:00.000`;
  return new Date(lastDayOfQuarter);
};

export const getLastDayOfYearDate = (date) => {
  const lastDayOfQuarter = `${moment(date).endOf('year').format('YYYY-MM-DD')}T00:00:00.000`;
  return new Date(lastDayOfQuarter);
};

export const getDatePlusDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const createTimeZoneAgnosticDate = (date) => {
  const newDate = date;
  newDate.setHours(12, 0, 0, 0);
  return newDate;
};

export const createTimeZoneAgnosticDateFromStr = (dateStr, isHour) => {
  const newDateStr = (isHour ? moment(dateStr).utc() : moment(dateStr)).format(
    `YYYY-MM-DD[T]${isHour ? 'HH' : '[12]'}:[00:00.000]Z`,
  );
  const newDate = new Date(newDateStr);
  return newDate;
};

export const replaceDateByGranularity = (date, granLevel, isStartDate, lastProcessTime = '') => {
  if (!date || granLevel === GranularityLevelsTypes.GRAN_LEVEL_HOURLY) {
    return date;
  }
  const referenceDate = lastProcessTime || DateFilter.getDate();
  const formattedDate = moment(date).clone().format('YYYY-MM-DD');
  if (isStartDate) {
    switch (granLevel) {
      case 'month':
        return moment(formattedDate).clone().startOf('month').format('YYYY-MM-DD');
      case 'week': {
        return moment(formattedDate).clone().startOf('isoWeek').format('YYYY-MM-DD');
      }
      default:
        return formattedDate;
    }
  }
  switch (granLevel) {
    case 'month': {
      const endOfMonth = moment(formattedDate).clone().endOf('month').format('YYYY-MM-DD');
      const formattedLastProcessTime = moment(referenceDate).clone().format('YYYY-MM-DD');
      // If selected end month is after the last process date, select the last process date as the end of period.
      return endOfMonth <= formattedLastProcessTime ? endOfMonth : formattedLastProcessTime;
    }
    case 'week': {
      const endOfWeek = moment(formattedDate).clone().endOf('isoWeek').format('YYYY-MM-DD');
      const formattedLastProcessTime = moment(referenceDate).clone().format('YYYY-MM-DD');
      // If selected end week is after the last process date, select the last process date as the end of period.
      return endOfWeek <= formattedLastProcessTime ? endOfWeek : formattedLastProcessTime;
    }
    default:
      return formattedDate;
  }
};

export const padWithZeroIfNeeded = (partOfDate) => `0${partOfDate}`.slice(-2);

export const dateToStr = (date, format = '') => {
  const year = date.getFullYear();
  const month = padWithZeroIfNeeded(date.getMonth() + 1);
  const day = padWithZeroIfNeeded(date.getDate());
  const hours = padWithZeroIfNeeded(date.getHours());
  const minutes = padWithZeroIfNeeded(date.getMinutes());
  const seconds = padWithZeroIfNeeded(date.getSeconds());

  let output;
  switch (format) {
    case 'yyyy-mm-dd':
      output = `${year}-${month}-${day}`;
      break;
    case 'mm-yyyy':
      output = `${month}-${year}`;
      break;
    case 'yyyy-dd-mm':
      output = `${year}-${day}-${month}`;
      break;
    case 'mm-dd-yyyy':
      output = `${month}-${day}-${year}`;
      break;
    case 'dd-mm-yyyy':
      output = `${day}-${month}-${year}`;
      break;
    case 'yyyy-mm-dd hh:mm:ss':
      output = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      break;
    case 'yyyy-dd-mm hh:mm:ss':
      output = `${year}-${day}-${month} ${hours}:${minutes}:${seconds}`;
      break;
    case 'mm-dd-yyyy hh:mm:ss':
      output = `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;
      break;
    case 'dd-mm-yyyy hh:mm:ss':
      output = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
      break;
    case 'yyyy-mm':
      output = `${year}-${month}`;
      break;
    default:
      // mm-dd-yyyy
      output = `${day}-${month}-${year}`;
      break;
  }

  return output;
};

export const buildStartAndEndDate = (start, end, fromStartOfMonth = true) => {
  const endDateObj = moment(end || DateFilter.getDate());
  const endDate = endDateObj.format('YYYY-MM-DD');

  const startDateObj = moment(start || (fromStartOfMonth ? endDateObj.startOf('month') : endDateObj.add(-30, 'day')));
  const startDate = startDateObj.format('YYYY-MM-DD');

  const endMonth = new Date(endDate).getMonth() + 1;
  const startMonth = new Date(startDate).getMonth() + 1;

  return {
    startDate,
    endDate,
    startMonth,
    endMonth,
  };
};

export const getStartEndDatesFromRelativeString = (relativeDateString, lastProcessTime = '') => {
  let startDate;
  let endDate;
  const referenceDate = lastProcessTime || DateFilter.getDate();
  if (relativeDateString === ReportPeriodTime.LAST_YEAR) {
    endDate = moment(referenceDate).format('YYYY-MM-DD');
    startDate = moment(referenceDate).subtract(365, 'days').format('YYYY-MM-DD');
  } else if (relativeDateString === ReportPeriodTime.CURRENT_MONTH) {
    endDate = moment(referenceDate).format('YYYY-MM-DD');
    startDate = moment(referenceDate).startOf('month').format('YYYY-MM-DD');
  } else if (relativeDateString === ReportPeriodTime.PREVIOUS_MONTH) {
    startDate = moment(referenceDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    endDate = moment(referenceDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
  } else if (relativeDateString === ReportPeriodTime.PREVIOUS_2_MONTH) {
    startDate = moment(referenceDate).subtract(2, 'months').startOf('month').format('YYYY-MM-DD');
    endDate = moment(referenceDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
  } else if (relativeDateString === ReportPeriodTime.PREVIOUS_3_MONTH) {
    startDate = moment(referenceDate).subtract(3, 'months').startOf('month').format('YYYY-MM-DD');
    endDate = moment(referenceDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
  } else if (relativeDateString === ReportPeriodTime.PREVIOUS_6_MONTH) {
    startDate = moment(referenceDate).subtract(6, 'months').startOf('month').format('YYYY-MM-DD');
    endDate = moment(referenceDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
  } else if (relativeDateString === ReportPeriodTime.PREVIOUS_12_MONTH) {
    startDate = moment(referenceDate).subtract(12, 'months').startOf('month').format('YYYY-MM-DD');
    endDate = moment(referenceDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
  } else if (relativeDateString === ReportPeriodTime.PREVIOUS_WEEK) {
    startDate = moment(referenceDate).subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
    endDate = moment(referenceDate).subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
  } else if (relativeDateString.match(new RegExp(ReportPeriodTime.PREVIOUS_2_WEEK.replace('2', '\\d')))) {
    startDate = moment(referenceDate)
      .subtract(relativeDateString.match(/\D(\d+)\D/)[1], 'weeks')
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
    endDate = moment(referenceDate).subtract(1, 'isoWeek').endOf('week').format('YYYY-MM-DD');
  } else if (relativeDateString === ReportPeriodTime.YTD) {
    startDate = moment(referenceDate).startOf('year').format('YYYY-MM-DD');
    endDate = moment(referenceDate).format('YYYY-MM-DD');
  } else if (relativeDateString === 'currentQuarter') {
    startDate = moment(referenceDate).startOf('quarter').format('YYYY-MM-DD');
    endDate = moment(referenceDate).format('YYYY-MM-DD');
  } else if (relativeDateString === ReportPeriodTime.PREVIOUS_DAY) {
    startDate = moment(referenceDate).subtract(1, 'days').startOf('day').format('YYYY-MM-DD');
    endDate = moment(referenceDate).subtract(1, 'days').endOf('day').format('YYYY-MM-DD');
  }
  return { start: startDate, end: endDate };
};

export const datePickerSyntexDates = (start, end) => {
  let { startDate, endDate } = buildStartAndEndDate(start || '', end || '');
  startDate = createTimeZoneAgnosticDateFromStr(startDate);
  endDate = createTimeZoneAgnosticDateFromStr(endDate);
  return { startDate, endDate };
};

export const buildTimeDiffDateFromBaseDate = (baseDate, difference = -30, timeUnit = 'd') => {
  const absDifference = Math.abs(difference);
  let newBaseDate = {};
  if (baseDate) {
    newBaseDate =
      Object.prototype.toString.call(baseDate) === '[object Date]'
        ? createTimeZoneAgnosticDate(baseDate)
        : createTimeZoneAgnosticDateFromStr(baseDate);
  } else {
    newBaseDate = createTimeZoneAgnosticDateFromStr(buildStartAndEndDate(null, null).startDate);
  }
  const dateWithTimeDifference =
    difference > 0
      ? createTimeZoneAgnosticDateFromStr(moment(newBaseDate).add(absDifference, timeUnit).format('YYYY-MM-DD'))
      : createTimeZoneAgnosticDateFromStr(moment(newBaseDate).subtract(absDifference, timeUnit).format('YYYY-MM-DD'));
  let startDate = moment(newBaseDate).format('YYYY-MM-DD');
  let endDate = moment(dateWithTimeDifference).format('YYYY-MM-DD');
  if (startDate > endDate) {
    const tempDate = startDate;
    startDate = moment(endDate).format('YYYY-MM-DD');
    endDate = moment(tempDate).format('YYYY-MM-DD');
  }
  return { startDate, endDate };
};

export const getStartOfDay = (date) => moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
export const getEndOfDay = (date) => moment(date).endOf('day').format('YYYY-MM-DD HH:mm:ss');

export const nth = (d) => {
  let suffix = '';
  if (d > 3 && d < 21) {
    suffix = 'th';
  }
  switch (d % 10) {
    case 1:
      suffix = 'st';
      break;
    case 2:
      suffix = 'nd';
      break;
    case 3:
      suffix = 'rd';
      break;
    default:
      suffix = 'th';
      break;
  }

  return `${d}${suffix}`;
};

export const getCurrentQuarterStartAndEndDates = () => {
  const startDate = moment(DateFilter.getDate()).startOf('quarter').format('YYYY-MM-DD');
  const endDate = moment(DateFilter.getDate()).clone().format('YYYY-MM-DD');
  return { startDate, endDate };
};

/**
 * Calc total seconds
 * @param  {String} duration Can be `y1 d2`, `m3 d1`
 */
export const timeDurationToSeconds = (duration) => {
  let sum = 0;
  const mapUnitToSeconds = {
    y: 365 * 24 * 60 * 60,
    m: 30 * 24 * 60 * 60,
    d: 24 * 60 * 60,
  };
  duration.split(' ').forEach((time) => {
    sum += mapUnitToSeconds[time.slice(time.length - 1)] * +time.slice(0, time.length - 1);
  });
  return isNumber(sum) ? sum : null;
};

export const alignedStartEndDateByGranLevel = (startDate, endDate, granLevel, granLevelChanged) => {
  if (
    !granLevel ||
    !startDate ||
    !endDate ||
    !startDate.match(/\d\d\d\d-\d\d-\d\d/) ||
    !endDate.match(/\d\d\d\d-\d\d-\d\d/)
  ) {
    return { start: startDate, end: endDate };
  }
  try {
    if (granLevel === GranularityLevelsTypes.GRAN_LEVEL_HOURLY) {
      const isEndDateWithHour = moment(endDate).get('hour') !== 0;
      return {
        start: (granLevelChanged ? moment(endDate).add(-1, 'week') : moment(startDate))
          .startOf('hour')
          .format('YYYY-MM-DD[T]HH:mm:00'),
        end: (isEndDateWithHour ? moment(endDate).startOf('hour') : moment(endDate).endOf('day')).format(
          'YYYY-MM-DD[T]HH:mm:00',
        ),
      };
    }
    return {
      start: moment(startDate)
        .startOf(granLevel !== 'week' ? granLevel : 'isoWeek')
        .format('YYYY-MM-DD'),
      end: moment
        .min(
          moment(endDate).endOf(granLevel !== 'week' ? granLevel : 'isoWeek'),
          moment(DateFilter.getDate() || undefined),
        )
        .format('YYYY-MM-DD'),
    };
  } catch (e) {
    return { start: startDate, end: endDate };
  }
};

export const standardDateFormat = (date) => (date ? moment(date).format('DD.MM.YYYY') : '');
