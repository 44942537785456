import React from 'react';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import { Container } from 'reactstrap';
import HeatMap from 'recommendationsNew/components/heatMap/heatMap';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import PropTypes from 'prop-types';
import { HeatMapProvider } from 'recommendationsNew/components/heatMap/contexts/heatMapContext';
import RecommendationsList from 'recommendationsNew/components/list/recommendationsList';
import Button from 'shared/components/andtComponents/Button';
import { queryClient } from 'queryClient';
import apiConstants from 'shared/api/apiConstants';
import { RecommendationDetailsWidthProvider } from '../../contexts/recommendationDetailsWidthContext';

const RecommendationExplorer = (props) => {
  const { usersStore } = props;

  return (
    <TableHeaderProvider>
      <Container>
        <PageHeader showDate title={PageNames.RECOMMENDATION_EXPLORER} barIcons isBeta>
          <Button
            text="Refresh"
            overrideStyles={{ marginRight: '-20px' }}
            automationId="refreshBMPiePreview"
            isTextButton
            onClick={() => {
              queryClient.invalidateQueries(apiConstants.QUERY_KEYS.RECOMMENDATIONS);
            }}
            icon={() => <GenerateIcon iconName={ICONS.refresh.name} />}
          />
        </PageHeader>
        <div className="d-flex flex-column gap-4">
          <HeatMapProvider>
            <HeatMap usersStore={usersStore} />
          </HeatMapProvider>
          <RecommendationDetailsWidthProvider>
            <RecommendationsList />
          </RecommendationDetailsWidthProvider>
        </div>
      </Container>
    </TableHeaderProvider>
  );
};

RecommendationExplorer.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

export default RecommendationExplorer;
