import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { cloneDeep } from 'lodash';
import { Card, CardBody, Container } from 'reactstrap';
import Spinner, { SIZES } from 'shared/components/andtComponents/Spinner';
import PageHeader from 'shared/components/PageHeader';
import { OPERATORS_KEYS, PageNames } from 'shared/constants/appConstants';
import { AwsCommonFields, K8S_CUE_FIELDS } from 'shared/constants/awsConstants';
import { ACCOUNT_FEATURES, CLOUD_TYPE_IDS, UsersType as usersType } from 'users/constants/usersConstants';
import NewCustomDashboardPanelModal from 'shared/components/NewCustomDashboardPanelModal';
import FiltersSidebarContainer from 'shared/components/FilterSidebar/FiltersSidebarContainer';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import { isPrimitiveArraysEqual } from 'shared/utils/arrayUtils';
import { SuccessNotificationsLabels } from 'shared/constants/notificationsConstants';
import ReportModal from 'shared/components/reportModal/ReportModal';
import { ReactComponent as ExclamationMarkIcon } from 'shared/img/icons/exclamation-circle.svg';
import AssetsReportContent from 'shared/components/reportModal/AssetsReportContent';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';
import { buildStartAndEndDate, createTimeZoneAgnosticDateFromStr } from 'shared/utils/dateUtil';
import PeriodGranularityFilter from 'shared/components/PeriodGranularityFilter';
import OneChoiceFieldFilter from 'shared/components/OneChoiceFieldFilter';
// eslint-disable-next-line max-len
import K8sGranularityToggle from 'kubernetes/containers/KubernetesDashboard/components/K8sGranularityToggle/K8sGranularityToggle';
import {
  AMORTIZE_COST_TYPES,
  AMORTIZE_COST_TYPES_ITEMS,
  CostTypes,
  GROUP_BY_LOV,
  GroupByLovToAttributes,
  K8S_GROUP_BY_LOV,
  K8sGroupByLovToAttributes,
  mapAssetsGroupByCloudBasedLov,
  mapReportDatePeriodToType,
  ReportSendFrequency,
  USER_SAVED_REPORT_TYPES,
} from 'usage/constants/costAndUsageConstants';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import FieldFilter from 'shared/components/FieldFilter';
import MarginAppliedReloader from 'shared/components/MarginAppliedReloader';
import NoDataFoundComponent from 'shared/components/NoDataFoundComponent';
import {
  k8sCommonFieldToDisplayField,
  k8sGranLevel,
  mapCloudTypeIdToK8sCostTypes,
  mapK8sCostTypesToDisplay,
} from 'shared/constants/k8sConstants';
import { k8sFiltersNodesFields, k8sFiltersPodsFields } from 'kubernetes/constants/k8sConstants';
import { GCPCommonFieldLabels } from 'shared/constants/gcpConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { formatExcludeFilterMap } from 'shared/utils/filtersUtils';
import { isUserCustomer, isUserCustomerEDP } from 'users/utils/userUtil';
import toast from 'shared/components/andtComponents/Toast';
import { AzureCommonFieldLabels } from 'shared/constants/azureConstants';
import Button from 'shared/components/andtComponents/Button';
import SwitchButton from 'shared/components/andtComponents/Switch';
import AssetsTable from 'usage/containers/Assets/components/AssetsTable';
import * as tableHlepers from 'usage/containers/Assets/helpers/assetsTableHelper';
import { prepareFrequencyStartDate } from 'usage/containers/CustomDashboard/helpers/scheduledReportHelper';
import * as custDabrdHelpers from 'usage/containers/CostAndUsageExplorer/helpers/customDashboardHelperMethods';
import SaveReportToolbarButton from 'usage/containers/CostAndUsageExplorer/components/SaveReportToolbarButton';
import ReportsHelperMethods from 'usage/containers/CostAndUsageExplorer/helpers/reportsHelperMethods';
import { withVirtualTagsContextProvider } from 'usage/contexts/virtualTagsContext';
import { withMainDashboardContextConsumer } from 'app/contexts/MainDashboardContext';
import { withAssetsContextConsumer, withAssetsContextProvider } from 'usage/contexts/assetsContext';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import { withReportsContextProvider } from 'usage/contexts/reportsContext';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { getAssetsData } from 'usage/hooks/react-query/useAssets';
import { numberWithCommas } from 'shared/utils/strUtil';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import AssetCsvDownload from './components/AssetCsvDownload';
import styles from './Assets.module.scss';

const MAX_ROWS_TO_EXPORT = 500000;

const removeItemsFromListOfValues = (groupByItems) => {
  const index = groupByItems.indexOf('- None -');
  if (index > -1) {
    groupByItems.splice(index, 1);
  }
};

const addItemsToListOfValues = (groupByItems, itemsToAdd) => {
  if (itemsToAdd) {
    groupByItems.push(...itemsToAdd);
  }
};

const datePickerSyntexDates = (start, end) => {
  let { startDate, endDate } = buildStartAndEndDate(start || '', end || '');
  startDate = createTimeZoneAgnosticDateFromStr(startDate);
  endDate = createTimeZoneAgnosticDateFromStr(endDate);
  return { startDate, endDate };
};

class AssetsPage extends Component {
  constructor(props) {
    const cloudTypeId = props.usersStore.currDispUserCloudAccountType;
    super(props);
    this.baseState = {
      rowsTotal: null,
      selectedOptionsMap: new Map([]),
      excludedFiltersStatusMap: new Map([]),
      likeFiltersStatus: {},
      filtersConfig: {},
      startDate: datePickerSyntexDates(null, null).startDate,
      endDate: datePickerSyntexDates(null, null).endDate,
      selectedColumns: [],
      selectedMeasuresMap: new Map([
        [AMORTIZE_COST_TYPES.UNBLENDED, false],
        [AMORTIZE_COST_TYPES.NET_UNBLENDED, false],
        [AMORTIZE_COST_TYPES.AMORTIZED, false],
        [AMORTIZE_COST_TYPES.NET_AMORTIZED, false],
        [AMORTIZE_COST_TYPES.DISTRIBUTED, false],
      ]),
      selectedMeasure: AMORTIZE_COST_TYPES_ITEMS.UNBLENDED,
      currPeriodGranLevel: 'day',
      tablePresentedColumns: [],
      colErrMsg: false,
      isShowMeasureErrMsg: false,
      isFiltersOpen: false,
      newCustomDashboardPanelModalIsOpen: false,
      newReportModalIsOpen: false,
      overwriteReportModalIsOpen: false,
      currCostType: props.isK8S
        ? mapCloudTypeIdToK8sCostTypes.get(cloudTypeId) || []
        : [CostTypes.COST, CostTypes.DISCOUNT],
      isApplyFiltersButtonDisabled: true,
      isK8S: props.isK8S,
      k8SGranularityState: props.kubernetesStore.k8SGranularity,
    };
    this.state = this.getStateFromSource(props.location.source);
    this.handleClearSelection = this.handleClearSelection.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSelectColumns = this.handleSelectColumns.bind(this);
  }

  componentDidMount() {
    const {
      location: { source },
      kubernetesStore,
    } = this.props;

    if (source && (source.isFromCustomDashboard || source.isFromReports)) {
      if (source.state && source.state.k8SGranularityState) {
        this.setState({ k8SGranularityState: source.state.k8SGranularityState });
        kubernetesStore.setK8sGranularity(source.state.k8SGranularityState);
      }
      this.handleApplyAsset();
    }
  }

  componentDidUpdate() {
    const { usersStore, kubernetesStore } = this.props;
    const { k8SGranularityState } = this.state;
    if (usersStore.currDispUserCloudAccountType !== CLOUD_TYPE_IDS.GCP) {
      return;
    }
    if (k8SGranularityState !== kubernetesStore.k8SGranularity) {
      this.resetSelectedColumnsAndOptionsGCP();
    }
  }

  componentWillUnmount() {
    this.handleClearReport();
  }

  resetSelectedColumnsAndOptionsGCP = () => {
    const { selectedColumns, selectedOptionsMap, excludedFiltersStatusMap } = this.state;
    const tempSelectedColumns = cloneDeep(selectedColumns);
    const { kubernetesStore } = this.props;
    let fieldsToRemove = [];
    if (kubernetesStore.k8SGranularity === k8sGranLevel.NODES) {
      fieldsToRemove = k8sFiltersPodsFields;
    } else {
      fieldsToRemove = k8sFiltersNodesFields;
    }
    const optionsMap = new Map(selectedOptionsMap);
    const excludedMap = new Map(excludedFiltersStatusMap);
    optionsMap.delete(AwsCommonFields.BUSINESS_MAPPING);
    fieldsToRemove.forEach((field) => {
      if (selectedOptionsMap.has(field)) {
        optionsMap.delete(field);
      }
      if (excludedFiltersStatusMap.has(field)) {
        excludedMap.delete(field);
      }
      const fieldKeyIndex = tempSelectedColumns.findIndex((item) => item.value.split(':')[0] === field);
      if (fieldKeyIndex > -1) {
        tempSelectedColumns.splice(fieldKeyIndex, 1);
      }
    });
    this.setState({
      selectedColumns: tempSelectedColumns.filter(
        (c) => !c.value.startsWith(AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS),
      ),
      k8SGranularityState: kubernetesStore.k8SGranularity,
      isApplyFiltersButtonDisabled: false,
      selectedOptionsMap: optionsMap,
      excludedFiltersStatusMap: excludedMap,
    });
  };

  getStateFromSource(source) {
    const { isFromCustomDashboard, isFromReports } = source || {};
    if (!isFromCustomDashboard && !isFromReports) {
      return { ...this.baseState };
    }
    const instance = isFromReports ? this.getReportLocallyById(source.id) : source;

    const { state } = instance || {};
    const { startDate, endDate } = source;
    const {
      selectedOptionsMap,
      excludedFiltersStatusMap,
      likeFiltersStatus,
      filtersConfig,
      selectedColumns,
      selectedMeasuresMap,
      selectedMeasure,
      currPeriodGranLevel,
      tablePresentedColumns,
      currCostType,
    } = state || {};
    return {
      ...this.baseState,
      selectedOptionsMap: new Map(selectedOptionsMap),
      excludedFiltersStatusMap: new Map(excludedFiltersStatusMap),
      likeFiltersStatus: likeFiltersStatus || {},
      filtersConfig: filtersConfig || {},
      ...datePickerSyntexDates(startDate, endDate),
      selectedColumns,
      selectedMeasuresMap: new Map(selectedMeasuresMap),
      selectedMeasure,
      currPeriodGranLevel,
      tablePresentedColumns,
      currCostType,
    };
  }

  getReportLocallyById = (id) => {
    const { invoiceStore, mainDbData, reports } = this.props;
    return invoiceStore.rootStore.usageStore.getReportLocalyById(reports, id, mainDbData.mainDashBoardData);
  };

  getParamsForCustomDashboard = () =>
    custDabrdHelpers.prepareCauParamsForNewAssetsCustomDashboardPanel({ ...this.state });

  handleChangeFilterType = (field, subField, type) => {
    const { excludedFiltersStatusMap = {}, likeFiltersStatus = {}, fieldToFilterdValuesMap = {} } = this.state;
    const likeState =
      type === OPERATORS_KEYS.LIKE
        ? {
            likeFiltersStatus: {
              ...likeFiltersStatus,
              [field]: +!likeFiltersStatus[field],
            },
            fieldToFilterdValuesMap: {
              ...fieldToFilterdValuesMap,
              [field]: [],
            },
          }
        : {};
    this.setState({
      excludedFiltersStatusMap:
        type === OPERATORS_KEYS.LIKE
          ? excludedFiltersStatusMap
          : formatExcludeFilterMap(excludedFiltersStatusMap, field, subField),
      isApplyFiltersButtonDisabled: false,
      ...likeState,
    });
  };

  handleRemoveFieldFromFiltersValuesMap = (field) => {
    this.setState((prevState) => ({
      selectedOptionsMap: prevState.selectedOptionsMap.delete(field),
      isApplyFiltersButtonDisabled: false,
    }));
  };

  handleFilterChange = (filterType, selectedOptions) => {
    this.setState((prevState) => ({
      selectedOptionsMap: new Map(prevState.selectedOptionsMap).set(filterType, selectedOptions),
      isApplyFiltersButtonDisabled: false,
    }));
  };

  handleSelectColumns = (newSelectedRaw = []) => {
    const colErrMsg = '';
    this.setState({
      selectedColumns: newSelectedRaw,
      colErrMsg,
      isApplyFiltersButtonDisabled: false,
    });
  };

  handleSelectOrClearAllColumns = (selectedColumnCounter) => {
    const colErrMsg = '';
    if (selectedColumnCounter > 0) {
      this.setState({
        selectedColumns: [],
        colErrMsg,
        isApplyFiltersButtonDisabled: false,
      });
    } else {
      this.setState({
        selectedColumns: this.prepareListOfValues(),
        colErrMsg,
        isApplyFiltersButtonDisabled: false,
      });
    }
  };

  handleDateChange = async ({ startDate, endDate }) => {
    const { startDate: stateStartDate, endDate: stateEndDate } = this.state;
    const start = startDate || stateStartDate;
    let end = endDate || stateEndDate;
    if (start > end && end !== null) {
      end = start;
    }
    const modStartDate = buildStartAndEndDate(start, end).startDate;
    const modEndDate = buildStartAndEndDate(start, end).endDate;
    this.setState({
      startDate: modStartDate,
      endDate: modEndDate,
      isApplyFiltersButtonDisabled: false,
    });
  };

  prepareOptions = (arr, label = null) => {
    const { usersStore, isK8S } = this.props;
    const isGCP = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP;
    const isAzure = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE;
    const prepareValue = (value) => {
      const labelToValueMap = new Map([
        ['Viewpoint', `${AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS}: `],
        ['Tag', 'customtags:'],
        ['Enrichment Tag', 'accounttags:'],
        ['View', 'viewscustomtags:'],
        ['Virtual Tag', 'virtualcustomtags:'],
        ['Workload', 'workloadvalue:'],
        ['Label', 'labels:'],
      ]);
      if (label) {
        return `${labelToValueMap.get(label)}${value}`;
      }
      return GroupByLovToAttributes.get(value) || K8sGroupByLovToAttributes.get(value) || value;
    };
    const prepareLabel = (valueStr, labelStr) => {
      // eslint-disable-next-line no-param-reassign
      valueStr = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', valueStr);
      const labelToLabelsMapGCPK8S = new Map([
        ['Tag', 'Label'],
        ['Label', 'K8S Label'],
      ]);
      if (isGCP) {
        if (GCPCommonFieldLabels.has(valueStr)) {
          return GCPCommonFieldLabels.get(valueStr);
        }
        if (isK8S && labelToLabelsMapGCPK8S.has(labelStr)) {
          return `${labelToLabelsMapGCPK8S.get(labelStr)}: ${valueStr}`;
        }
      }
      if (isAzure) {
        if (AzureCommonFieldLabels.has(valueStr)) {
          return AzureCommonFieldLabels.get(valueStr);
        }
      }
      return labelStr ? `${labelStr}: ${valueStr}` : LabelCoordinator.getFieldLabel(valueStr) || valueStr;
    };
    return (arr || []).map((value) => ({
      value: prepareValue(value) || value,
      label: prepareLabel(value, label),
    }));
  };

  handleChangeGranLevel = (granLevel) => {
    this.setState({ currPeriodGranLevel: granLevel, isApplyFiltersButtonDisabled: false });
  };

  prepareListOfValues = () => {
    const {
      usersStore,
      isK8S,
      kubernetesStore,
      getCustomTagsKeys,
      getAccountTagsKeys,
      getK8sCustomTagsKeys,
      getWorkloadTypes,
      getK8SBusinessMappingViewpoints,
      getBusinessMappingViewpoints,
      filtersValuesMap,
      virtualTags,
    } = this.props;
    const { currDispUserCloudAccountType } = usersStore;
    let arrK8SLabelsKeys = getK8sCustomTagsKeys();
    const arrViewpoints = (
      (isK8S
        ? getK8SBusinessMappingViewpoints(kubernetesStore.k8SGranularity === k8sGranLevel.PODS)
        : getBusinessMappingViewpoints()) || []
    ).map((v) => v.id);
    let arrCustomTagsKeys = getCustomTagsKeys();
    const arrAccountTagsKeys = getAccountTagsKeys();
    const arrWorkloadTypes = isK8S ? getWorkloadTypes() : [];
    const arrVirtualTagsNamesAndIds = virtualTags || [];
    const arrVirtualTagsNames = arrVirtualTagsNamesAndIds.map((item) => item.name);
    const arrViewsNames = (filtersValuesMap.get(AwsCommonFields.VIEWS_CUSTOM_TAGS) || []).map((value) => value.name);
    const cloudBaseLov = isK8S ? K8S_GROUP_BY_LOV : mapAssetsGroupByCloudBasedLov.get(currDispUserCloudAccountType);
    const groupByItems = Object.values(cloudBaseLov);
    removeItemsFromListOfValues(groupByItems);
    addItemsToListOfValues(
      groupByItems,
      isK8S ? [] : [GROUP_BY_LOV.BY_USAGE_QUANTITY_TYPE, GROUP_BY_LOV.PAYER_ACCOUNT, GROUP_BY_LOV.PAYER_ACCOUNT_NAME],
    );
    if (currDispUserCloudAccountType !== CLOUD_TYPE_IDS.MULTI) {
      addItemsToListOfValues(groupByItems, isK8S ? [] : [GROUP_BY_LOV.BY_ITEM_DESCRIPTION]);
    }
    if (isK8S && currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP) {
      const { k8SGranularity } = kubernetesStore;
      let fieldsToRemove = [];
      if (k8SGranularity === k8sGranLevel.NODES) {
        arrK8SLabelsKeys = [];
        fieldsToRemove = k8sFiltersPodsFields;
      } else {
        arrCustomTagsKeys = [];
        fieldsToRemove = k8sFiltersNodesFields;
      }
      fieldsToRemove.forEach((groupByKey) => {
        const groupByKeyIndex = groupByItems.findIndex(
          (item) => item === (k8sCommonFieldToDisplayField.get(groupByKey) || groupByKey),
        );
        if (groupByKeyIndex > -1) {
          groupByItems.splice(groupByKeyIndex, 1);
        }
      });
    }
    return [
      ...this.prepareOptions(groupByItems),
      ...this.prepareOptions(arrViewpoints, 'Viewpoint'),
      ...this.prepareOptions(arrCustomTagsKeys, 'Tag'),
      ...this.prepareOptions(arrAccountTagsKeys, 'Enrichment Tag'),
      ...this.prepareOptions(arrWorkloadTypes, 'Workload'),
      ...(isK8S
        ? this.prepareOptions(arrK8SLabelsKeys, 'Label')
        : this.prepareOptions(arrVirtualTagsNames, 'Virtual Tag')),
      ...this.prepareOptions(arrViewsNames, 'View'),
    ];
  };

  handleClearReport = (e = null) => {
    const { clearAssetData } = this.props;
    if (e) {
      e.preventDefault();
    }
    clearAssetData();
    this.setState({ isApplyFiltersButtonDisabled: false });
  };

  handleSaveScheduleReport = async (params) => {
    const { saveAssetReport } = this.props;
    const { reportName, emails, recipients, delivery, period } = params;
    const { time, frequency, customFrequency, customStartDate } = delivery;

    const preparedFrequencyStartDate = prepareFrequencyStartDate(
      frequency,
      +frequency === ReportSendFrequency.CUSTOM ? customStartDate : null,
      time,
    );
    const updatedState = Object.assign({}, this.state);
    updatedState.newReportModalIsOpen = false;
    updatedState.overwriteReportModalIsOpen = false;
    updatedState.isStateFromReport = true;
    const periodType = mapReportDatePeriodToType.get(period) || 'relativeDate';

    const savedParams = {
      state: updatedState,
      saveModalRelativeDates: periodType === 'relativeDate' ? parseInt(period, 10) : undefined,
      periodType,
    };
    this.setState({
      newReportModalIsOpen: false,
    });
    await saveAssetReport({
      type: 'saved',
      name: reportName.value,
      email: emails.value,
      recipients,
      reportFreq: frequency,
      savedState: savedParams,
      mailDeliveryFrequency: customFrequency,
      mailFrequencyStartDate: preparedFrequencyStartDate,
      frequencyDeliveryTime: time,
    });
    toast.success(SuccessNotificationsLabels.REPORT_CREATED);
  };

  prepareParamsForOverwriteExistingReport = () => {
    const { location = {}, reports } = this.props;
    const { source = {} } = location;
    const { id } = source;
    const localReport = reports.find((r) => r.id === id);
    const savedState = localReport.baseReportParams;
    Object.keys(savedState.state).forEach((key) => {
      const { [key]: currValue } = this.state;
      savedState.state[key] = currValue;
    });
    savedState.state.saveModalIsOpen = false;
    savedState.state.overwriteReportModalIsOpen = false;
    savedState.state.isStateFromReport = true;
    const paramsForReportSaving = {
      id,
      type: localReport.type,
      name: localReport.name,
      email: localReport.mailParams.email,
      reportFreq: localReport.mailParams.reportFreq,
      savedState,
      mailDeliveryFrequency: localReport.mailParams.customFrequencyInDays,
      mailFrequencyStartDate: localReport.mailParams.firstDate,
      frequencyDeliveryTime: localReport.mailParams.frequencyDeliveryTime,
    };
    return paramsForReportSaving;
  };

  handleOverwriteExistingReport = async (action) => {
    try {
      this.handleCloseOverwriteReportModal();
      if (action === 'delete') {
        const { saveAssetReport } = this.props;
        const params = this.prepareParamsForOverwriteExistingReport();
        if (!params) {
          return;
        }
        await saveAssetReport(params);
      }
    } catch (error) {
      this.handleCloseOverwriteReportModal();
    }
  };

  isChangesAppliedBeforeSave = () => {
    const { isApplyFiltersButtonDisabled } = this.state;
    if (!isApplyFiltersButtonDisabled) {
      toast.error('Apply changes before saving report or dashboard');
      return false;
    }
    return true;
  };
  handleCloseNewCustomDashboardPanelModal = () => {
    this.setState({ newCustomDashboardPanelModalIsOpen: false, currCustomPanelType: null });
  };
  handleSaveDashboardPanelButtonClick = () => {
    const { isK8S } = this.props;
    if (!this.isChangesAppliedBeforeSave()) {
      return;
    }
    this.setState({
      newCustomDashboardPanelModalIsOpen: true,
      currCustomPanelType: isK8S ? 'k8s-assets-table' : 'assets-table',
    });
  };
  handleSaveReportButtonClick = (isOverwriteReport = false) => {
    if (!this.isChangesAppliedBeforeSave()) {
      return;
    }
    if (isOverwriteReport) {
      this.setState({ overwriteReportModalIsOpen: true });
    } else {
      this.setState({ newReportModalIsOpen: true });
    }
  };
  handleCloseSaveReportModal = () => {
    this.setState({ newReportModalIsOpen: false });
  };
  handleCloseOverwriteReportModal = () => {
    this.setState({ overwriteReportModalIsOpen: false });
  };
  handleClearSelection = (e = null) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      selectedOptionsMap: new Map([]),
      excludedFiltersStatusMap: new Map([]),
      likeFiltersStatus: {},
      filtersConfig: {},
      startDate: datePickerSyntexDates(null, null).startDate,
      endDate: datePickerSyntexDates(null, null).endDate,
      selectedColumns: [],
      selectedMeasuresMap: new Map([
        [AMORTIZE_COST_TYPES.UNBLENDED, false],
        [AMORTIZE_COST_TYPES.NET_UNBLENDED, false],
        [AMORTIZE_COST_TYPES.AMORTIZED, false],
        [AMORTIZE_COST_TYPES.NET_AMORTIZED, false],
        [AMORTIZE_COST_TYPES.DISTRIBUTED, false],
      ]),
      selectedMeasure: null,
      isApplyFiltersButtonDisabled: true,
    });
  };

  validateCurrCostTypeArr = (arrCurrCostType) => {
    const { currCostType } = this.state;
    let toUpdateState = true;
    const newCostType = [...arrCurrCostType];
    const undefinedIndex = arrCurrCostType.indexOf(undefined);
    if (undefinedIndex > 0) {
      newCostType.splice(undefinedIndex, 1);
    }
    if (isPrimitiveArraysEqual(currCostType, newCostType)) {
      toUpdateState = false;
    }
    return { toUpdateState, newCostType };
  };

  handleCostTypeChange = (currCostType, selectedOptions) => {
    const selectedCostsType = selectedOptions.map((costType) => costType.value);
    const { toUpdateState } = this.validateCurrCostTypeArr(selectedCostsType);
    if (toUpdateState) {
      this.setState({ currCostType: [...selectedCostsType], isApplyFiltersButtonDisabled: false });
    }
  };

  handleApplyAsset = async () => {
    const { usersStore, kubernetesStore, fetchAssetData, isK8S } = this.props;
    const {
      startDate,
      endDate,
      selectedOptionsMap,
      excludedFiltersStatusMap,
      likeFiltersStatus,
      filtersConfig,
      selectedColumns,
      selectedMeasure,
      currPeriodGranLevel,
      currCostType,
    } = this.state;
    const selectedMeasuresMap = new Map([
      [AMORTIZE_COST_TYPES.UNBLENDED, false],
      [AMORTIZE_COST_TYPES.NET_UNBLENDED, false],
      [AMORTIZE_COST_TYPES.AMORTIZED, false],
      [AMORTIZE_COST_TYPES.NET_AMORTIZED, false],
      [AMORTIZE_COST_TYPES.DISTRIBUTED, false],
    ]);

    if (selectedMeasure && selectedMeasure.value) {
      selectedMeasuresMap.set(selectedMeasure.value, true);
    }
    if (!(selectedColumns || []).length) {
      this.setState({ colErrMsg: 'Please select at least one column' });
    }
    if (!selectedMeasure && usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS) {
      this.setState({ isShowMeasureErrMsg: true });
    }
    if (
      !(selectedColumns || []).length ||
      (!selectedMeasure && usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS)
    ) {
      return false;
    }
    try {
      const { k8SGranularity } = kubernetesStore;
      this.setState({ isApplyFiltersButtonDisabled: true });
      await fetchAssetData({
        startDate,
        endDate,
        granLevel: currPeriodGranLevel,
        columns: selectedColumns,
        filtersMap: selectedOptionsMap,
        excludeFiltersMap: excludedFiltersStatusMap,
        likeFiltersStatus,
        filtersConfig,
        measuresMap: selectedMeasuresMap,
        costTypes: currCostType,
        isK8S,
        k8SGranularity,
      });
      this.setState({ tablePresentedColumns: selectedColumns, rowsTotal: null });
    } catch (error) {
      // console.error(error);
      this.setState({ isApplyFiltersButtonDisabled: false });
    }
    return true;
  };

  handleCountRows = async () => {
    const { kubernetesStore } = this.props;
    const {
      startDate,
      endDate,
      selectedOptionsMap,
      excludedFiltersStatusMap,
      likeFiltersStatus,
      filtersConfig,
      selectedColumns,
      selectedMeasure,
      currPeriodGranLevel,
      currCostType,
    } = this.state;
    const { isK8S } = this.props;
    const selectedMeasuresMap = new Map([
      [AMORTIZE_COST_TYPES.UNBLENDED, false],
      [AMORTIZE_COST_TYPES.NET_UNBLENDED, false],
      [AMORTIZE_COST_TYPES.AMORTIZED, false],
      [AMORTIZE_COST_TYPES.NET_AMORTIZED, false],
      [AMORTIZE_COST_TYPES.DISTRIBUTED, false],
    ]);

    if (selectedMeasure && selectedMeasure.value) {
      selectedMeasuresMap.set(selectedMeasure.value, true);
    }

    try {
      const { k8SGranularity } = kubernetesStore;
      this.setState({ rowsTotalLoading: true });
      const data = await getAssetsData({
        startDate,
        endDate,
        granLevel: currPeriodGranLevel,
        columns: selectedColumns,
        filtersMap: selectedOptionsMap,
        likeFiltersStatus,
        filtersConfig,
        excludeFiltersMap: excludedFiltersStatusMap,
        measuresMap: selectedMeasuresMap,
        costTypes: currCostType,
        isK8S,
        k8SGranularity,
        isCount: true,
      });
      this.setState({ rowsTotal: data.total, rowsTotalLoading: false });
    } catch (error) {
      this.setState({ rowsTotal: null, rowsTotalLoading: false });
    }
  };

  mapFieldToFilterValuesByType = () => {
    const { isK8S, getPageFilters, usersStore, kubernetesStore } = this.props;
    const currCloudType = usersStore.currDispUserCloudAccountType;
    const filters = getPageFilters(isK8S ? PageNames.K8S_USAGE : PageNames.COST_AND_USAGE_EXPLORER, currCloudType);
    if (!checkFeatureFlag(usersStore, ACCOUNT_FEATURES.CUE_K8S_FILTERS)) {
      K8S_CUE_FIELDS.forEach((key) => {
        filters.delete(key);
      });
    }
    if (currCloudType !== CLOUD_TYPE_IDS.GCP) {
      return filters;
    }
    filters.delete('project');
    if (isK8S) {
      let fieldsToRemove = [];
      if (kubernetesStore.k8SGranularity === k8sGranLevel.NODES) {
        fieldsToRemove = k8sFiltersPodsFields;
      } else {
        fieldsToRemove = k8sFiltersNodesFields;
      }
      fieldsToRemove.forEach((field) => {
        if (filters.has(field)) {
          filters.delete(field);
        }
      });
    }
    return filters;
  };

  handleK8SCostTypeChecked = (selected) => {
    const { currCostType } = this.state;
    this.setState(() => ({
      isApplyFiltersButtonDisabled: false,
      currCostType: currCostType.includes(selected)
        ? currCostType.filter((c) => c !== selected)
        : [...currCostType, selected],
    }));
  };

  handleSelectMeasure = (filterType, selectedOption) => {
    this.setState({ selectedMeasure: selectedOption, isShowMeasureErrMsg: false, isApplyFiltersButtonDisabled: false });
  };

  getFiltersConfig = () => {
    const { isK8S, kubernetesStore } = this.props;
    const { filtersConfig } = this.state;
    const conjunctionConfig = {
      set: (key, flag) =>
        this.setState({
          filtersConfig: {
            ...filtersConfig,
            [key]: {
              ...filtersConfig[key],
              conjunction: flag,
            },
          },
          isApplyFiltersButtonDisabled: false,
        }),
      get: (key) => this.state.filtersConfig[key]?.conjunction,
    };
    return {
      [isK8S ? AwsCommonFields.K8S_LABEL_TAGS : AwsCommonFields.CUSTOM_TAGS]: {
        showNotAllocated: true,
        conjunctionSelect: !isK8S,
        conjunctionConfig,
      },
      [AwsCommonFields.CATEGORIES]: {
        disableLike: true,
      },
      [AwsCommonFields.REGION]: {
        disableLike: true,
      },
      [AwsCommonFields.PURCHASE_OPTION]: {
        disableLike: true,
      },
      [AwsCommonFields.QUANTITY_TYPE]: {
        disableLike: true,
      },
      [AwsCommonFields.DIVISION]: {
        disableLike: true,
      },
      [AwsCommonFields.CLOUD_PROVIDER]: {
        disableLike: true,
      },
      [AwsCommonFields.VIRTUAL_CUSTOM_TAGS]: {
        disableLike: true,
      },
      [AwsCommonFields.SUB_VIEWS_CUSTOM_TAGS]: {
        disableLike: true,
      },
      [AwsCommonFields.BUSINESS_MAPPING]: {
        isK8S,
        k8SGranularity: kubernetesStore.k8SGranularity,
      },
    };
  };

  renderParamsPanel = (fieldToFieldDistincValuesMap, selectedMeasure) => {
    const { usersStore, isK8S, kubernetesStore } = this.props;
    const {
      isApplyFiltersButtonDisabled,
      excludedFiltersStatusMap,
      currCostType,
      currPeriodGranLevel,
      startDate,
      endDate,
      selectedColumns,
      colErrMsg,
      isShowMeasureErrMsg,
      isFiltersOpen,
      selectedOptionsMap,
      likeFiltersStatus,
      filtersConfig,
    } = this.state;
    const { currentDisplayedUserType, currDispUserCloudAccountType: cloudTypeId } = usersStore;
    return (
      <div className="assets-form-body">
        <div className="assets-header-container">
          <div className="assets-form-header">
            <div className="assets-title">REPORT PARAMETERS</div>
            {isK8S ? <K8sGranularityToggle kubernetesStore={kubernetesStore} usersStore={usersStore} /> : null}
            <div className="assets-form-buttons">
              <Button
                onClick={(e) => this.handleClearSelection(e)}
                disabled={isApplyFiltersButtonDisabled}
                isTextButton
                text="Clear All"
              />
              <Button
                disabled={isApplyFiltersButtonDisabled}
                isLoading={this.baseState.loading}
                onClick={this.handleApplyAsset}
                text="Apply"
              />
            </div>
          </div>
        </div>
        <div className="separator" />
        <div className="assets-date-picker-container">
          <div>
            <p className="form-field-header">Date Range</p>
          </div>
          <div className="assets-date-filter" style={{ display: 'flex' }}>
            <PeriodGranularityFilter
              currPeriodGranLevel={currPeriodGranLevel}
              handleChangeGranLevel={this.handleChangeGranLevel}
              startDate={startDate}
              endDate={endDate}
              isShowQuarterly
              isShowYearly
              isShowHourly={
                !isK8S &&
                usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP &&
                checkFeatureFlag(usersStore, ACCOUNT_FEATURES.GCP_CUE_HOURLY_GRAN)
              }
            />
            <DatePickerFilter
              onDateChange={this.handleDateChange}
              startDate={startDate}
              endDate={endDate}
              currPeriodGranLevel={currPeriodGranLevel}
              daysLimit={90}
              isMaxDateNeeded={false}
            />
          </div>
        </div>
        <div className="assets-select-columns-container">
          <p className="form-field-header">
            <div className="field-button-header">
              <span className="assets-mandatory-field">(*)</span>
              Columns
              <Button
                onClick={() => this.handleSelectOrClearAllColumns(selectedColumns?.length)}
                isTextButton
                text={selectedColumns?.length === 0 ? 'Select All' : 'Clear All'}
                overrideStyles={{ height: 14 }}
              />
            </div>
          </p>
          <FieldFilter
            type="columns"
            name="columns"
            value={selectedColumns}
            options={this.prepareListOfValues()}
            handleChange={(type, values) => this.handleSelectColumns(values)}
            placeholder="Select columns"
          />
          <p className={`assets-form-err-msg ${colErrMsg ? '' : 'hide'}`}>{colErrMsg}</p>
        </div>
        {usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS ||
        usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.MULTI ? (
          <div className="assets-measures-container">
            <p className="form-field-header">
              <span className="assets-mandatory-field">(*)</span>
              Measures{' '}
              {usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.MULTI && (
                <InfoPopover isSimple>
                  Amortize cost selection is relevant only for AWS, in order to apply Azure Amortize cost please choose
                  the relevant Amortize payer account.
                </InfoPopover>
              )}
            </p>
            <OneChoiceFieldFilter
              type="measure"
              value={selectedMeasure}
              isDisabled={isUserCustomer(currentDisplayedUserType)}
              options={
                isUserCustomerEDP(currentDisplayedUserType) ||
                usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.MULTI
                  ? [AMORTIZE_COST_TYPES_ITEMS.UNBLENDED, AMORTIZE_COST_TYPES_ITEMS.AMORTIZED]
                  : [
                      AMORTIZE_COST_TYPES_ITEMS.UNBLENDED,
                      AMORTIZE_COST_TYPES_ITEMS.NET_UNBLENDED,
                      AMORTIZE_COST_TYPES_ITEMS.AMORTIZED,
                      AMORTIZE_COST_TYPES_ITEMS.NET_AMORTIZED,
                      ...(usersStore.currentDisplayedUserType !== usersType.RESELLER || isK8S
                        ? []
                        : [AMORTIZE_COST_TYPES_ITEMS.DISTRIBUTED]),
                    ]
              }
              handleChange={this.handleSelectMeasure}
            />
            <p className={`assets-form-err-msg ${isShowMeasureErrMsg ? '' : 'hide'}`}>Please select a measure</p>
          </div>
        ) : null}
        <div className="assets-filters-sidebar">
          <p className="form-field-header">Filters</p>
          {isK8S && (
            <div className="field-sidebar-filter ms-4 me-1">
              <div className="field-name-container mb-2">
                <p className="field-name">Cost Types</p>
              </div>
              {(mapCloudTypeIdToK8sCostTypes.get(cloudTypeId) || []).map((costType) => (
                <div className="mb-2">
                  <SwitchButton
                    label={mapK8sCostTypesToDisplay.get(costType)}
                    isChecked={currCostType.includes(costType)}
                    onChange={() => this.handleK8SCostTypeChecked(costType)}
                  />
                </div>
              ))}
            </div>
          )}
          <div className="filters-sidebar">
            <FiltersSidebarContainer
              likeOperator={!isK8S}
              likeFiltersStatus={likeFiltersStatus}
              likeCaseConfig={
                isK8S
                  ? undefined
                  : {
                      get: (field) => filtersConfig[field]?.caseSensitive,
                      set: (field, value) => {
                        this.setState({
                          isApplyFiltersButtonDisabled: false,
                          filtersConfig: {
                            ...filtersConfig,
                            [field]: { ...filtersConfig[field], caseSensitive: +value },
                          },
                        });
                      },
                    }
              }
              filtersConfig={filtersConfig}
              isOpen={isFiltersOpen}
              selectedOptionsMap={selectedOptionsMap}
              fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
              fieldToFieldDistincValuesProps={this.getFiltersConfig()}
              currDispUserCloudAccountType={usersStore.currDispUserCloudAccountType}
              excludedFiltersStatusMap={excludedFiltersStatusMap}
              handleChangeFilterType={this.handleChangeFilterType}
              handleFilterChange={this.handleFilterChange}
              filtersStatusMap={selectedOptionsMap}
              handleRemoveFieldFromFiltersValuesMap={this.handleRemoveFieldFromFiltersValuesMap}
              className="assets"
              showTitle={false}
              // Cost type filter
              handleCostTypeChange={this.handleCostTypeChange}
              selectedCostTypes={currCostType}
              isCostTypeFilter={!isK8S}
            />
          </div>
        </div>
      </div>
    );
  };

  renderError = () => {
    const { assetDataTooBig } = this.props;
    if (assetDataTooBig) {
      const textArr = assetDataTooBig.split('.');
      return <NoDataFoundComponent title={textArr[0]} bodyText={textArr[1]} height="455px" isBorderNeeded={false} />;
    }
    return <NoDataFoundComponent callBack={this.handleApplyAsset} isBorderNeeded={false} />;
  };

  renderTableToolbar = (assetDataLimited) => {
    const {
      location: { source = {} },
      isK8S,
      usersStore,
      assetData,
      assetDataLoading,
      kubernetesStore,
    } = this.props;
    const {
      selectedMeasure,
      selectedColumns,
      currCostType,
      currPeriodGranLevel,
      startDate,
      endDate,
      selectedOptionsMap,
      excludedFiltersStatusMap,
      rowsTotal,
      rowsTotalLoading,
    } = this.state;
    const { isFromReports, id } = source;
    const { currDispUserCloudAccountType } = usersStore;
    if (assetDataLoading || !assetData?.length) {
      return null;
    }
    const currUserMultiCloud = currDispUserCloudAccountType === CLOUD_TYPE_IDS.MULTI;
    const renderCSVDownload = () => (
      <AssetCsvDownload
        tableData={assetData}
        usersStore={usersStore}
        kubernetesStore={kubernetesStore}
        isNeedFetch={assetDataLimited}
        disabled={assetDataLimited && (rowsTotal === null || rowsTotal > MAX_ROWS_TO_EXPORT)}
        filterParams={{
          startDate,
          endDate,
          selectedOptionsMap,
          excludedFiltersStatusMap,
          selectedColumns,
          selectedMeasure,
          currPeriodGranLevel,
          currCostType,
          isK8S,
        }}
      />
    );
    const renderMessage = (smallDesktop) => {
      if (rowsTotal > MAX_ROWS_TO_EXPORT) {
        if (smallDesktop) {
          return (
            <Tooltip
              title={`The number of records exceeds the maximum of 500K
            and cannot be exported to a CSV. Please refine your filters to reduce the number of records.`}
            >
              <div className={styles.notification}>
                <p data-error="true" data-short="true">
                  <ExclamationMarkIcon />
                  Learn more...
                </p>
              </div>
            </Tooltip>
          );
        }
        return (
          <div className={styles.notification}>
            <p data-error="true">
              <ExclamationMarkIcon />
              The number of records exceeds the maximum of 500K and cannot be exported to a CSV. Please refine your
              filters to reduce the number of records.
            </p>
          </div>
        );
      }
      if (rowsTotal === null) {
        if (smallDesktop) {
          return (
            <Tooltip
              title={`Result is limited to ${assetData.length} rows. To
             get full data click 'Download CSV'. Before downloading the CSV you must first
             count the number of report records. The export to CSV option will be available
              if the records count = < 500K.`}
            >
              <div className={styles.notification}>
                <p data-error="true" data-short="true">
                  <ExclamationMarkIcon />
                  Learn more...
                </p>
              </div>
            </Tooltip>
          );
        }
        return (
          <div className={styles.notification}>
            <p data-error="true">
              <ExclamationMarkIcon />
              <span>Result is limited to {assetData.length} rows. To get full data click `Download CSV`</span>
              <br />
              Before downloading the CSV you must first count the number of report records. The export to CSV option
              will be available if the records count ={'<'} 500K.
            </p>
          </div>
        );
      }
      if (smallDesktop) {
        return (
          <Tooltip title="The number of records is lower than the maximum of 500K and can now be exported to a CSV.">
            <div className={styles.notification}>
              <p data-success="true" data-short="true">
                <ExclamationMarkIcon />
                Learn more...
              </p>
            </div>
          </Tooltip>
        );
      }
      return (
        <div className={styles.notification}>
          <p data-success="true">
            <ExclamationMarkIcon />
            The number of records is lower than the maximum of 500K and can now be exported to a CSV.
          </p>
        </div>
      );
    };
    const renderExportBlock = (smallDesktop) => (
      <div className={`${styles.exportBlock} ${smallDesktop ? styles.small : styles.extended}`}>
        {renderMessage(smallDesktop)}
        <div className={styles.exportButtons}>
          {rowsTotal === null ? (
            <Button
              text="Count Records"
              icon={() => <GenerateIcon iconName={ICONS.calculator.name} />}
              onClick={this.handleCountRows}
              isLoading={rowsTotalLoading}
            />
          ) : (
            <div className={styles.exportRecordsCounter} data-error={rowsTotal > MAX_ROWS_TO_EXPORT}>
              <GenerateIcon iconName={ICONS.calculator.name} />
              {numberWithCommas(rowsTotal)} Records
            </div>
          )}
          {renderCSVDownload()}
        </div>
      </div>
    );
    return (
      <div className={styles.header} data-simple={!assetDataLimited}>
        <span className={styles.title}>MY REPORT</span>
        {assetDataLimited ? (
          <>
            {renderExportBlock(true)}
            {renderExportBlock(false)}
          </>
        ) : (
          <div />
        )}
        <div className={styles.actions}>
          {!assetDataLimited && renderCSVDownload()}
          <SaveReportToolbarButton
            buttonStyles={{
              boxShadow: 'none',
            }}
            currUserMultiCloud={currUserMultiCloud}
            handleSaveReport={this.handleSaveReportButtonClick}
            handleSaveDashboardPanelButtonClick={this.handleSaveDashboardPanelButtonClick}
            isDiveFromReports={isFromReports && !!id}
          />
          <Button
            isTextButton
            text="Clear Report"
            icon={() => <GenerateIcon iconName={ICONS.xMark.name} />}
            onClick={this.handleClearReport}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      usageStore,
      invoiceStore,
      location: { source = {} },
      appStore,
      isK8S,
      usersStore,
      assetData,
      assetDataLoading,
      assetDataLimited,
      sendReport,
      getCurrencyNumber,
    } = this.props;
    const {
      newReportModalIsOpen,
      newCustomDashboardPanelModalIsOpen,
      selectedMeasure,
      selectedColumns,
      currCustomPanelType,
      currCostType,
      overwriteReportModalIsOpen,
      currPeriodGranLevel,
    } = this.state;
    const { name } = source;
    const { existingDashboardsNamesAndIds } = usageStore.customDbSubStore.customDashboardModel;
    const { modelIsLoading } = usageStore.customDbSubStore.customDashboardModel.modelIsLoading;
    const { currDispUserCloudAccountType } = usersStore;
    const columns = tableHlepers.prepareTableColumns(
      selectedColumns,
      isK8S,
      currCostType,
      currDispUserCloudAccountType,
      getCurrencyNumber,
    );
    const fieldToFieldDistincValuesMap = this.mapFieldToFilterValuesByType();
    if (usageStore.isLoading || invoiceStore.isLoading) {
      return <Spinner />;
    }
    return (
      <Container>
        {newReportModalIsOpen && (
          <ReportModal
            onClose={this.handleCloseSaveReportModal}
            modalType={USER_SAVED_REPORT_TYPES.ASSET}
            usageStore={usageStore}
            isOpen={newReportModalIsOpen}
            onSave={this.handleSaveScheduleReport}
            onSend={(params) => {
              const data = ReportsHelperMethods.getModalSendReportHandler(this, USER_SAVED_REPORT_TYPES.ASSET)(params);
              sendReport(data);
            }}
            usersStore={usersStore}
            title="Assets Report"
          >
            <AssetsReportContent />
          </ReportModal>
        )}
        <DeleteWarningModal
          modalTitle="Overwrite Warning"
          warningMessage="Be advised that you are about to overwrite this chart data on the existing report"
          deletedItemName={` ${name}`}
          executeButtonTitle="Overwrite & Save"
          isOpen={overwriteReportModalIsOpen}
          handleDelete={this.handleOverwriteExistingReport}
        />
        <NewCustomDashboardPanelModal
          modalIsOpen={newCustomDashboardPanelModalIsOpen}
          getCurrentCauParams={this.getParamsForCustomDashboard}
          onClose={this.handleCloseNewCustomDashboardPanelModal}
          customDashboardStore={usageStore.customDbSubStore}
          existingDashboardsNamesAndIds={existingDashboardsNamesAndIds}
          helpers={custDabrdHelpers}
          type={currCustomPanelType}
          isCustomDbModelLoading={modelIsLoading}
          state={this.state}
          isSelectTimePeriodEnabled={['assets-table', 'k8s-assets-table'].includes(currCustomPanelType)}
          usageStore={usageStore}
        />
        <PageHeader title={isK8S ? PageNames.K8S_ASSETS : PageNames.ASSETS} />
        <Card>
          <CardBody className="assets-body">
            {this.renderParamsPanel(fieldToFieldDistincValuesMap, selectedMeasure)}
            {assetData || assetDataLoading ? (
              <div className={`assets-table-container card${assetDataLoading ? ' asset-loading-data' : ''}`}>
                {this.renderTableToolbar(assetDataLimited)}
                {assetDataLoading && <Spinner size={SIZES.XX_BIG_100} />}
                {!assetDataLoading && assetData.length ? (
                  <AssetsTable
                    usersStore={usersStore}
                    data={assetData}
                    columns={columns}
                    columnsWidths={tableHlepers.prepareTableColumnsWidths(columns)}
                    costColumnsArray={tableHlepers.prepareTableCostColumns(currCostType)}
                    currenciesColumns={[]}
                    tableColumnExtensions={[]}
                    granularity={currPeriodGranLevel}
                  />
                ) : null}
                {!assetDataLoading && !assetData.length ? this.renderError() : null}
              </div>
            ) : null}
          </CardBody>
        </Card>
        <MarginAppliedReloader onChange={this.handleApplyAsset} isPpApplied={appStore.isPpApplied} />
      </Container>
    );
  }
}

AssetsPage.propTypes = {
  usageStore: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
  kubernetesStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  mainDbData: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  filtersValuesMap: PropTypes.object.isRequired,
  isK8S: PropTypes.bool,
  assetDataLimited: PropTypes.bool,
  assetDataLoading: PropTypes.bool.isRequired,
  assetData: PropTypes.array.isRequired,
  assetDataTooBig: PropTypes.string,
  fetchAssetData: PropTypes.func.isRequired,
  clearAssetData: PropTypes.func.isRequired,
  getCustomTagsKeys: PropTypes.func.isRequired,
  getK8sCustomTagsKeys: PropTypes.func.isRequired,
  getAccountTagsKeys: PropTypes.func.isRequired,
  virtualTags: PropTypes.array.isRequired,
  getPageFilters: PropTypes.func.isRequired,
  getK8SBusinessMappingViewpoints: PropTypes.func.isRequired,
  getBusinessMappingViewpoints: PropTypes.func.isRequired,
  reports: PropTypes.array.isRequired,
  saveAssetReport: PropTypes.func.isRequired,
  sendReport: PropTypes.func.isRequired,
  getCurrencyNumber: PropTypes.func.isRequired,
};
AssetsPage.defaultProps = {
  isK8S: false,
  assetDataTooBig: '',
  assetDataLimited: false,
};

const ObserverAssetsPage = withReportsContextProvider(
  withVirtualTagsContextProvider(
    withInvoiceFiltersContextConsumer(
      withMainDashboardContextConsumer(
        withAssetsContextProvider(withAssetsContextConsumer(withUserSettingsConsumer(observer(AssetsPage)))),
      ),
    ),
  ),
);

export default ObserverAssetsPage;
