import React from 'react';
import { Form, Input } from 'reactstrap';
import classNames from 'classnames';
import Select from 'shared/components/andtComponents/SimpleSelect';
import RecipientsSelect from 'shared/components/RecipientsSelect.jsx';
import useChannels from 'shared/components/slackIntegration/hooks/useChannels.js';
import { validateRecipients } from 'shared/utils/sharedUtils.js';
import AnodotReactSelect, { getAnodotReactSelectStyles } from 'shared/components/AnodotReactSelect';
import { Option, ServiceCostAlert } from '../types.ts';
import t from '../texts.ts';
import ColoredBlock from '../components/ColoredBlock';
import styles from '../alerts.module.scss';
import MyEmailBtn from '../components/MyEmailBtn.tsx';
import { createOptions } from '../helpers.ts';
import {
  alertsGranLevelDisplayedLabels,
  alertsLevelDescriptions,
  alertsLevelDisplayedLabels,
  CAU_ALERTS_GRAN_LEVELS,
  costChangeAlertChangeTypesToDisplayLabels,
  cueAlertAndOrToDisplayLabels,
  weekDaysDisplayedLabels,
} from '../../../constants/usageConstants';
import { useRootStore } from '../../../../app/contexts/RootStoreContext';
import FiltersServiceCost from './FiltersServiceCost.tsx';

interface Props {
  alert: ServiceCostAlert;
  disabled?: boolean;
  showErrors?: boolean;
  isChangesValid?: boolean;

  setAlert?(field: keyof ServiceCostAlert | string, value: string | number | Option[]): void;
}

const FormServiceCost: React.FC<Props> = ({ isChangesValid, showErrors, setAlert, alert, disabled }) => {
  const { appStore } = useRootStore();
  const { isPpApplied } = appStore;

  const { getChannels } = useChannels();
  const { data: channels, isLoading: isLoadingChannels } = getChannels();

  const isValidRecipients = validateRecipients(alert.recipientsEmails, alert.recipients);
  const getPricingMode = () => {
    let label = isPpApplied ? 'Customer' : 'Partner';
    if (isPpApplied === undefined) {
      label = 'N/A';
    }
    return label;
  };
  const isEdit = !disabled && !!alert?.uuid;
  const isCreate = !disabled && !alert?.uuid;
  let automationPostfix = '';
  if (isEdit || isCreate) {
    automationPostfix = isEdit ? '-edit' : '-create';
  }

  return (
    <Form
      className={classNames(styles.serviceCostForm, { [styles.formDisabled]: disabled })}
      automation-id={`form${automationPostfix}`}
    >
      <div>
        <ColoredBlock className="mb-3" icon="memo" color="blue">
          <h5>{t('ALERT_NAME')}</h5>
          <Input
            automation-id={`alert-name-field${automationPostfix}`}
            value={alert.alertName}
            margin="normal"
            variant="outlined"
            placeholder={t('ALERT_NAME')}
            className={classNames(styles.inputServiceCost, 'w-100')}
            disabled={disabled}
            invalid={showErrors && !disabled && !isChangesValid && !alert.alertName}
            onChange={(e) => setAlert?.('alertName', e.target.value)}
          />
        </ColoredBlock>
        <ColoredBlock className="mb-3" icon="arrowsTurnToDots" color="lilach">
          <div className={styles.twoColumns}>
            <div>
              <div>
                <h5>{t('ALERT_LEVEL')}</h5>
                <AnodotReactSelect
                  className={styles.select}
                  automationId={`comparing-rule-field${automationPostfix}`}
                  options={createOptions(alertsLevelDisplayedLabels, alertsLevelDescriptions)}
                  value={alert.alertLevel}
                  onChange={(selected) => {
                    setAlert?.('alertLevel', selected);
                  }}
                  disabled={disabled}
                />
              </div>
              {Number(alert.granularity?.value) === CAU_ALERTS_GRAN_LEVELS.DAY_IN_WEEK && (
                <div>
                  <h5>{t('DAY')}</h5>
                  <Select
                    className={styles.select}
                    automationId={`day-of-week-field${automationPostfix}`}
                    options={createOptions(weekDaysDisplayedLabels)}
                    value={alert.dayInWeek?.value}
                    onChange={(selected) => {
                      setAlert?.('dayInWeek', selected);
                    }}
                    placeholder="Select"
                    disabled={disabled}
                  />
                </div>
              )}
            </div>
            <div>
              <h5>{t('COMPARING_RULE')}</h5>
              <Select
                className={styles.select}
                automationId={`comparing-rule-field${automationPostfix}`}
                options={createOptions(alertsGranLevelDisplayedLabels)}
                value={alert.granularity.value}
                onChange={(selected) => {
                  setAlert?.('granularity', selected);
                }}
                placeholder="Select"
                disabled={disabled}
              />
            </div>
          </div>
        </ColoredBlock>
        <ColoredBlock className="mb-3" icon="chartLine" color="orange">
          <div className={styles.twoColumns}>
            <div>
              <div className="mb-2">
                <h5>{t('WHEN')}</h5>
                <Select
                  className={styles.select}
                  automationId={`when-field${automationPostfix}`}
                  options={createOptions(costChangeAlertChangeTypesToDisplayLabels)}
                  value={alert.changeType.value}
                  onChange={(selected) => {
                    setAlert?.('changeType', selected);
                  }}
                  placeholder="Select"
                  disabled={disabled}
                />
              </div>
              <div style={{ marginTop: 25 }}>
                <Select
                  className={styles.select}
                  automationId={`operator-type-field${automationPostfix}`}
                  options={createOptions(cueAlertAndOrToDisplayLabels)}
                  value={alert.operatorType.value}
                  onChange={(selected) => {
                    setAlert?.('operatorType', selected);
                  }}
                  placeholder="Select"
                  disabled={disabled}
                />
              </div>
            </div>
            <div>
              <div className="mb-2">
                <h5>{t('BY_PERCENTS')}</h5>
                <Input
                  automation-id={`by-percents-field${automationPostfix}`}
                  value={alert.changePercent}
                  margin="normal"
                  min={0}
                  variant="outlined"
                  placeholder="0"
                  type="number"
                  className={styles.inputServiceCost}
                  disabled={disabled}
                  invalid={showErrors && !disabled && !isChangesValid && !Number(alert.changePercent)}
                  onChange={(e) => setAlert?.('changePercent', e.target.value)}
                />
              </div>
              <div>
                <h5>{t('FOR_$')}</h5>
                <Input
                  automation-id={`for-number-field${automationPostfix}`}
                  value={alert.changeValue}
                  min={0}
                  margin="normal"
                  variant="outlined"
                  placeholder="0"
                  disabled={disabled}
                  className={styles.inputServiceCost}
                  type="number"
                  invalid={showErrors && !disabled && !isChangesValid && !Number(alert.changeValue)}
                  onChange={(e) => setAlert?.('changeValue', e.target.value)}
                />
              </div>
            </div>
          </div>
        </ColoredBlock>
        <ColoredBlock className={styles.unsetContentOverflowY} icon="envelope" color="eucaliptus">
          <div>
            <div className="d-flex justify-content-between">
              <h5>{t('RECIPIENTS')}</h5>
              {!disabled && <MyEmailBtn onClick={(myEmail) => setAlert?.('recipientsEmails', myEmail)} />}
            </div>
            <Input
              automation-id={`email-field${automationPostfix}`}
              value={alert.recipientsEmails}
              margin="normal"
              variant="outlined"
              placeholder={t('EXAMPLE_EMAIL')}
              className={styles.inputServiceCost}
              disabled={disabled}
              invalid={showErrors && !disabled && !isValidRecipients}
              onChange={(e) => setAlert?.('recipientsEmails', e.target.value)}
            />
            <RecipientsSelect
              className={classNames(styles.select, styles.recipientsSelect)}
              channels={channels}
              isLoading={isLoadingChannels}
              savedRecipients={alert.recipients}
              onChangeHandler={(recipients) => setAlert?.('recipients', recipients)}
              isDisabled={disabled}
              selectStylesConfig={getAnodotReactSelectStyles({ disabled, isEmpty: !alert.recipients, height: 35 })}
            />
          </div>
        </ColoredBlock>
      </div>
      <div className="position-relative">
        <ColoredBlock
          icon="filters"
          color="marine"
          scrollable
          style={{ position: 'absolute', height: '100%', width: '100%' }}
        >
          <FiltersServiceCost alert={alert} disabled={disabled} setAlert={setAlert} />
          <div className="mb-2 mt-2">
            <h5>{t('PRICING_MODE')}</h5>
            <Input
              automation-id={`pricing-mode-field${automationPostfix}`}
              value={getPricingMode()}
              margin="normal"
              variant="outlined"
              className={styles.inputServiceCost}
              disabled
            />
          </div>
        </ColoredBlock>
      </div>
    </Form>
  );
};

export default FormServiceCost;
