import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

const TransparentTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'transparent !important',
  },
}))(Tooltip);

// eslint-disable-next-line react/prop-types
export default function ({
  children,
  blank = false,
  arrow = true,
  placement = 'bottom',
  title,
  disabled = false,
  ...other
}) {
  if (disabled) {
    return children;
  }
  const Component = blank ? TransparentTooltip : Tooltip;
  return (
    <Component
      arrow={arrow}
      placement={placement}
      title={
        <Typography variant="body" style={{ whiteSpace: 'pre-line' }}>
          {title}
        </Typography>
      }
      style={{ cursor: 'pointer' }}
      {...other}
    >
      {children}
    </Component>
  );
}
