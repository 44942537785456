import {
  AGGREGATION_FUNCTION,
  ElasticacheRiPlans,
  SpAnalyzerPaymentOptions,
  SpAnalyzerTerm,
  USAGE_AGGREGATION_BASELINE,
  USAGE_AGGREGATION_BASELINE_KEYS,
} from 'usage/constants/usageConstants';

const K8sWorkloadRightSizing = 'k8s-workload-rightsizing';
export const PREFERENCES_SELECT_VALUES = {
  paymentOption: Object.values(SpAnalyzerPaymentOptions),
  term: Object.values(SpAnalyzerTerm),
  preferredPlan: Object.values(ElasticacheRiPlans),
  cpuUtilizationAggregationFunction: Object.values(AGGREGATION_FUNCTION),
  iopsAggregationFunction: Object.values(AGGREGATION_FUNCTION),
  aggregationBaseline: Object.values(USAGE_AGGREGATION_BASELINE),
};

export const PREFERENCES_SELECTED_KEYS = {
  aggregationBaseline: Object.values(USAGE_AGGREGATION_BASELINE_KEYS),
};

export const PREFERENCES_RADIO_VALUES = {
  [K8sWorkloadRightSizing]: ['minimalCostSavingsPercentage', 'minimalCostSavingsMonthlyQuantity'],
};

export const PREFERENCES_SPECIAL_FIELDS = {
  coveragePercentage: {
    max: 100,
    isPercent: true,
  },
};
export const PREFERENCES_SPECIAL_REC_TYPE_SPECIAL_FIELDS = {
  [K8sWorkloadRightSizing]: {
    daysToCheck: {
      max: 90,
    },
    minimalTimeFrame: {
      smallerThan: 'daysToCheck',
    },
  },
};
