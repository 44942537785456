import { CLOSED_AND_DONE_RECS_DATES_FILTERS, FILTERS } from 'recommendationsNew/consts';
import { FilterTypes } from 'usage/constants/usageConstants';

// Transform data to match the format of react-select
export const transformSelectDataToClientFormat = (pageData) =>
  pageData?.map((item) => {
    const { id, name, ...rest } = item;
    return {
      ...rest,
      value: id,
      label: name,
    };
  });
export const transformParentSelectDataToClientFormat = (pageData, parentKey) =>
  pageData?.map((item) => ({
    value: `${parentKey}: ${item}`,
    label: item,
  }));

export const transformSingleOptionToApiFormat = (option) => {
  const { value, label, ...rest } = option;
  return {
    ...rest,
    id: value,
    name: label,
  };
};

// Transform data to match the format of backend api
export const transformFiltersContextToApiFormat = (filtersParams) => {
  const filters = { ...filtersParams };
  let transformedFilters = {};
  if (!filters) {
    return transformedFilters;
  }
  if (filters?.likeAdditionalFilters) {
    Object.keys(filters.likeAdditionalFilters).forEach((key) => {
      if (!filters?.[key]) {
        filters[key] = undefined;
      }
    });
  }
  Object.keys(filters)?.forEach((key) => {
    const filter = filters[key];
    let transformedFilter;
    if ([FILTERS.CATEGORIES.id].includes(key) && filter?.length > 0) {
      transformedFilter = filter?.map((item) => item.value);
    } else if ([FILTERS.OPEN_RECS_CREATION_DATE.id].includes(key)) {
      transformedFilter = { ...filter };
      if (Object.keys(transformedFilter)?.find((key) => key === 'isCustom')) {
        delete transformedFilter.isCustom;
      }
    } else if ([FILTERS.CLOSED_AND_DONE_RECS_DATES.id].includes(key)) {
      transformedFilter = { ...filter };
      const lastUpdateDate = transformedFilter?.[CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE];
      const operator = transformedFilter?.[CLOSED_AND_DONE_RECS_DATES_FILTERS.OPERATOR];
      const creationDate = transformedFilter?.[CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE];
      if (lastUpdateDate && Object.keys(lastUpdateDate)?.find((key) => key === 'isCustom')) {
        delete transformedFilter[CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE].isCustom;
      }
      if (creationDate && Object.keys(creationDate)?.find((key) => key === 'isCustom')) {
        delete transformedFilter[CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE].isCustom;
      }
      if (operator && !creationDate) {
        delete transformedFilter[CLOSED_AND_DONE_RECS_DATES_FILTERS.OPERATOR];
      }
    } else if ([FILTERS.CUSTOM_TAGS.id, FILTERS.ENRICHMENT_TAGS.id].includes(key)) {
      const filterMap = filter.reduce((acc, item) => {
        let splitTags = item.value.includes(': ') ? item.value.split(': ') : [];
        if (splitTags.length === 0) {
          const firstColonIndex = item.value.indexOf(':');
          splitTags = [item.value.substring(0, firstColonIndex), item.value.substring(firstColonIndex + 1)];
        }
        const prefix = splitTags[0];
        const label = splitTags[1];
        if (!acc[prefix]) {
          acc[prefix] = [];
        }
        acc[prefix].push(label);
        return acc;
      }, {});
      const condition = Object.keys(filterMap).map((key) => ({
        tag: key,
        ...(filterMap[key]?.length ? { eq: filterMap[key] } : null),
        operator: 'OR',
      }));
      if (!condition?.length) {
        transformedFilter = null;
      } else {
        const negate = filters?.excludedAdditionalFilters?.[key] === FilterTypes.EXCLUDE;
        transformedFilter = {
          ...{ negate },
          condition,
        };
      }
    } else if (
      ([
        FILTERS.ASSIGNEE.id,
        FILTERS.BUSINESS_MAP.id,
        FILTERS.COST_CENTER.id,
        FILTERS.INSTANCE_TYPE.id,
        FILTERS.LINKED_ACCOUNT_ID.id,
        FILTERS.RECOMMENDED_INSTANCE_TYPE.id,
        FILTERS.REGION.id,
        FILTERS.RESOURCE_ID.id,
        FILTERS.SERVICES.id,
        FILTERS.TYPES.id,
        FILTERS.VIRTUAL_TAGS.id,
      ].includes(key) &&
        filter?.length > 0) ||
      filters?.likeAdditionalFilters?.[key]?.length > 0
    ) {
      const eqValue = filter?.length > 0 ? filter?.map((item) => item?.value) : undefined;
      const negate = filters?.excludedAdditionalFilters?.[key]
        ? filters?.excludedAdditionalFilters?.[key] === FilterTypes.EXCLUDE
        : undefined;
      const likeValue = filters?.likeAdditionalFilters?.[key] ? [filters?.likeAdditionalFilters?.[key]] : undefined;
      transformedFilter = {
        ...{ negate }, // Relevant for Include/Exclude
        ...(eqValue?.length ? { eq: eqValue } : null),
        ...(likeValue?.length ? { like: likeValue } : null),
      };
    } else if (
      [
        FILTERS.IS_OPEN.id,
        FILTERS.IS_STARRED.id,
        FILTERS.USER_STATUS.id,
        FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id,
        FILTERS.STATUS_FILTER.id,
      ].includes(key)
    ) {
      transformedFilter = filter;
    }
    transformedFilters = {
      ...((Array.isArray(transformedFilter) && Object.keys(transformedFilter)?.length !== 0) ||
      typeof transformedFilter !== 'undefined'
        ? { ...transformedFilters, [key]: transformedFilter }
        : transformedFilters),
    };
  });
  return transformedFilters;
};
