import React, { useMemo, useState } from 'react';
import { Grid, Table, TableColumnResizing, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { ReactComponent as NoPotentialSavings } from 'recommendationsNew/img/savingOpportunitiesEmpty.svg';
import Spinner from 'shared/components/andtComponents/Spinner';
import { palette } from 'shared/constants/colorsConstants';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import { useRecommendationsNewContext } from '../../../contexts/recommendationsNewContext';
import { FILTERS, GROUP_BY_OPTIONS, SORT_BY_OPTIONS } from '../../../consts';
import { ServicesImageTag } from '../heatMapFilters/servicesFilter/selectMultiMenuItem/ServicesImageTag';
import useGroupByOption from '../../../hooks/react-query/useGroupByOption';

import classes from './cloudWaste.module.scss';

const SavingsOpportunitiesTable = () => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const {
    recommendationFilters: filtersContext,
    setRecommendationFilters: setFiltersContext,
    recommendationExternalFilters: externalFiltersContext,
    isDashboardPanel,
  } = useRecommendationsNewContext();

  const defaultSorting = [{ columnName: 'savings', direction: 'desc' }];

  const [columnsWidth, setColumnsWidth] = useState(undefined);

  const filtersNoTypeId = useMemo(() => {
    const { [FILTERS.TYPES.id]: typeId, ...rest } = filtersContext;
    return rest;
  }, [filtersContext]);

  const recommendationsGroupTypeHook = useGroupByOption();
  const { data: recommendationsGroupTypeData, isLoading: isRecGroupByTypeLoading } =
    recommendationsGroupTypeHook.fetchGroupByOption({
      filters: filtersNoTypeId,
      groupBy: GROUP_BY_OPTIONS.items.TYPE_ID.id,
      pageSize: 100,
      sortBy: SORT_BY_OPTIONS.items.SAVINGS.property,
      externalFilters: externalFiltersContext,
    });

  const totalsRow = useMemo(
    () => ({
      potentialAnnualSavings: recommendationsGroupTypeData?.page?.reduce((acc, curr) => acc + curr.savings, 0),
      annualCostRecsUnimplemented: recommendationsGroupTypeData?.page?.reduce((acc, curr) => acc + curr.current, 0),
      totalRecommendationsCount: recommendationsGroupTypeData?.page?.reduce((acc, curr) => acc + curr.recsCount, 0),
    }),
    [recommendationsGroupTypeData],
  );

  const summaryRow = {
    type: { value: 'Total' },
    savings: { value: getCurrencyNumber(totalsRow?.potentialAnnualSavings, 2), isBold: true },
    currentCost: { value: getCurrencyNumber(totalsRow?.annualCostRecsUnimplemented, 2), isBold: true },
    percentOfTotal: {
      value:
        totalsRow?.potentialAnnualSavings && totalsRow?.annualCostRecsUnimplemented
          ? `${((totalsRow?.potentialAnnualSavings * 100) / totalsRow?.annualCostRecsUnimplemented).toFixed(2)}%`
          : `0%`,
    },
    numberOfRecs: { value: totalsRow?.totalRecommendationsCount },
  };

  const columns = [
    { name: 'type', title: 'Type', getCellValue: (row) => ({ typeName: row.label, service: row.service }) },
    {
      name: 'savings',
      title: 'Potential Savings',
      getCellValue: (row) => row.savings,
    },
    {
      name: 'percentOfTotal',
      title: '% of Total',
      getCellValue: (row) =>
        row?.savings && row?.current ? (row.savings * 100) / totalsRow?.annualCostRecsUnimplemented : 0,
    },
    {
      name: 'numberOfRecs',
      title: 'No.',
      getCellValue: (row) => row.recsCount || 0,
    },
  ];

  const onTypeClicked = (type) => {
    if (filtersContext[FILTERS.TYPES.id]?.find((t) => t.value === type.value)) {
      setFiltersContext({
        ...filtersContext,
        [FILTERS.TYPES.id]: filtersContext[FILTERS.TYPES.id].filter((t) => t.value !== type.value),
      });
    } else {
      setFiltersContext({
        ...filtersContext,
        [FILTERS.TYPES.id]: [type],
      });
    }
  };

  const CustomRecommendationTypeFormatter = ({ value }) => (
    <div className={classes.serviceColumnContainer}>
      <div className={classes.serviceIcon}>
        <ServicesImageTag label={value.service} />
      </div>
      <span>{value.typeName}</span>
    </div>
  );

  const CustomNoDataComponent = () => {
    if (!isRecGroupByTypeLoading) {
      return null;
    }
    return <div />;
  };

  const CustomHeaderCell = ({ column, ...restProps }) => (
    <TableHeaderRow.Cell {...restProps} column={column}>
      <div className={`${classes.summaryValue} ${summaryRow[column?.name]?.isBold ? `${classes.isBold}` : ''}`}>
        {summaryRow[column?.name]?.value}
      </div>
    </TableHeaderRow.Cell>
  );

  const CustomHeaderRow = ({ column, ...restProps }) => (
    <>
      <TableHeaderRow.Row {...restProps} column={column}>
        {restProps.children}
      </TableHeaderRow.Row>
      <TableHeaderRow.Row className={classes.summaryRowValue} {...restProps} column={column}>
        {columns.map((c) => (
          <CustomHeaderCell key={c.name} column={c} />
        ))}
      </TableHeaderRow.Row>
    </>
  );

  const TableRow = ({ row, ...restProps }) => {
    const { recommendationFilters: filtersContext } = useRecommendationsNewContext();
    const { [FILTERS.TYPES.id]: selectedTypes } = filtersContext;
    const selected = selectedTypes?.find((type) => type.value === row.value);

    return (
      <Table.Row
        {...restProps}
        onClick={() => onTypeClicked({ label: row.label, value: row.value })}
        style={{ background: selected ? palette.gray[150] : '' }}
      />
    );
  };

  const savingOpportunitiesTooltip = (
    <span>
      The percentage is calculated by dividing the <i>Potential savings cost</i> by the <i>Total actual cost</i>
    </span>
  );

  return (
    <div
      className={`${classes.savingsOpportunitiesContainer} ${isDashboardPanel && classes.dashboardContainer}`}
      ref={(node) => {
        if (node && !columnsWidth) {
          const tableWidth = node.offsetWidth;
          setColumnsWidth([
            { columnName: 'type', width: tableWidth * 0.4 },
            { columnName: 'savings', width: tableWidth * 0.26 },
            { columnName: 'percentOfTotal', width: tableWidth * 0.2 },
            { columnName: 'numberOfRecs', width: tableWidth * 0.14 },
          ]);
        }
      }}
    >
      <div className={classes.title}>
        <div>
          Savings Opportunities <span className={classes.subTitle}>(for Open recommendations only)</span>
        </div>
        <InfoPopover>{savingOpportunitiesTooltip}</InfoPopover>
      </div>
      <div className={classes.summaryTable} id="saving-opportunities">
        {isRecGroupByTypeLoading || !recommendationsGroupTypeData || !columnsWidth ? (
          <Spinner />
        ) : (
          <>
            <Grid rows={recommendationsGroupTypeData?.page} columns={columns}>
              <SortingState defaultSorting={defaultSorting} />
              <IntegratedSorting />
              <TableWrapper virtual noDataCellComponent={CustomNoDataComponent} rowComponent={TableRow} />
              <TableColumnResizing
                resizingMode="nextColumn"
                columnWidths={columnsWidth}
                onColumnWidthsChange={setColumnsWidth}
              />
              <TableHeaderRow showSortingControls rowComponent={CustomHeaderRow} />
              <DataTypeProvider
                for={['type']}
                availableFilterOperations={['greaterThanOrEqual']}
                formatterComponent={CustomRecommendationTypeFormatter}
              />
              <DataTypeProvider
                for={['savings']}
                availableFilterOperations={['greaterThanOrEqual']}
                formatterComponent={(data) => (
                  <span style={{ fontWeight: 700 }}>{getCurrencyNumber(data.value, 2)}</span>
                )}
              />
              <DataTypeProvider
                for={['currentCost']}
                availableFilterOperations={['greaterThanOrEqual']}
                formatterComponent={(data) => (
                  <span style={{ fontWeight: 700 }}>{getCurrencyNumber(data.value, 2)}</span>
                )}
              />
              <DataTypeProvider
                for={['percentOfTotal']}
                availableFilterOperations={['greaterThanOrEqual']}
                formatterComponent={(data) => `${data.value.toFixed(2)}%`}
              />
            </Grid>
            {recommendationsGroupTypeData?.page?.length === 0 && (
              <div className={classes.noPotentialSavingsContainer}>
                <NoPotentialSavings width={130} />
                <div className={classes.noPotentialSavingsText}>
                  <div>No Potential Savings found in this view</div>
                  <div className={classes.noPotentialSavingsSubText}>Try different filters</div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SavingsOpportunitiesTable;
