import React, { useEffect } from 'react';
import { spAnalyzerContext } from 'commitment/containers/spAnalyzerNew/contexts/spAnalyzerContext';
import useSpAnalyzer from 'commitment/containers/spAnalyzerNew/hooks/useSpAnalyzer';
import { usePreferences } from 'users/hooks/react-query/usePreferences';
import { useRootStore } from 'app/contexts/RootStoreContext';
import AnalyzerFilters from './AnalyzerFilters';
import loaderImg from '../img/sp-analyzer-loader.gif';
import emptyStateImage from '../img/sp-empty-state.svg';
import RecommendedPlanData from './RecommendedPlanData';
import SpSpecialMode from './SpSpecialMode';

const RecommendedPlan = () => {
  const { usersStore } = useRootStore();
  const { fetchPreferences } = usePreferences();
  const { data: preferences, isLoading: isPreferencesLoading } = fetchPreferences(usersStore.currDispUserAccountKey);
  const {
    selectedOptions,
    filterStatusType,
    daysToCheck,
    coverage,
    paymentOptions,
    terms,
    hourlyCommitment,
    setHourlyCommitment,
    setPreferences,
    setRecommendationPlanData,
    setPlanDataByKey,
    setModifiedPlanKeyHourlyCommitment,
  } = spAnalyzerContext();

  const { fetchSavingsPlansAnalyzerData } = useSpAnalyzer();
  const { data, isLoading, refetch, isFetching } = fetchSavingsPlansAnalyzerData(
    selectedOptions,
    daysToCheck,
    filterStatusType,
    paymentOptions,
    terms,
    coverage,
    hourlyCommitment,
  );

  useEffect(() => {
    if (data) {
      setRecommendationPlanData(data);
    }
  }, [data]);

  useEffect(() => {
    if (preferences) {
      const savingPreferences = preferences.find((pref) => pref.preferenceKey === 'ec2-savings-plans');
      setPreferences(savingPreferences?.preferences || {});
    }
  }, [preferences]);

  const isPlanDataEmpty = () => {
    if (!data || Object.keys(data).length === 0) {
      return true;
    }
    return !!(data.recommendationPerPlan && Object.values(data.recommendationPerPlan).every((value) => value === null));
  };
  const componentToRender = () => {
    if (isPreferencesLoading || isLoading || isFetching) {
      return (
        <SpSpecialMode
          iconSrc={loaderImg}
          specialText="The Recommended Plan is currently being calculated"
          type="loader"
        />
      );
    }
    if (isPlanDataEmpty()) {
      return (
        <SpSpecialMode
          iconSrc={emptyStateImage}
          specialText={
            <>
              <span>Based on the selected filters, your expenses are already well-covered by savings plans.</span>
              <span>Therefore, we don’t have any new plans to recommend.</span>
            </>
          }
          isLongText
          type="emptyState"
        />
      );
    }
    return <RecommendedPlanData />;
  };
  return (
    <div>
      <AnalyzerFilters
        analyze={() => {
          setHourlyCommitment(null);
          setPlanDataByKey({});
          setModifiedPlanKeyHourlyCommitment({});
          refetch();
        }}
        disabled={isLoading || isFetching}
      />
      {componentToRender()}
    </div>
  );
};

export default RecommendedPlan;
