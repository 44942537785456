import React from 'react';
import Pagination from 'shared/components/andtComponents/Pagination';
import { Search } from '../../../components/Search';

import styles from './AssignRolesModal.module.scss';

const AssignRolesModalHeader = ({ selectedRolesCount, searchText, setSearchText }) => {
  const currentPage = 0;
  const totalPages = 10;
  const pageChange = () => {};

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        <div className={styles.header}>
          <div className={styles.title}>
            Roles <span className={styles.count}>{selectedRolesCount}</span>
          </div>
        </div>
      </div>
      <div className={styles.headerRight}>
        <Search searchText={searchText} setSearchText={setSearchText} />
        <Pagination currPageIdx={currentPage} onPageChange={pageChange} totalPages={totalPages} />
      </div>
    </div>
  );
};

export default AssignRolesModalHeader;
