/* eslint-disable no-empty */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign,no-unused-vars,react/no-unused-state,max-len */
/* eslint-disable arrow-parens,react/jsx-no-undef,no-unreachable */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@pileus-cloud/anodot-frontend-common/dist';
import Spinner from 'shared/components/andtComponents/Spinner';
import AutocompleteSearch from 'shared/components/AutocompleteSearch';
import ImmediateFilterBar from 'shared/components/ImmediateFilterBar';
import PageHeader from 'shared/components/PageHeader';
import { AppCommonFields, PageNames } from 'shared/constants/appConstants';
import { buildStartAndEndDate, createTimeZoneAgnosticDateFromStr } from 'shared/utils/dateUtil';
import {
  CostTrackingConstants,
  DisplayMetricTypes,
  getValueFuncByMetricType,
  mapDisplayMetricTypesToDataAttributes,
  ResourceExplorerTypes,
} from 'usage/constants/costAndUsageConstants';
import CostChart from 'usage/containers/CostAndUsageExplorer/components/SimpleCostChart';
import ControlledToggleButton from 'shared/components/buttons/ControlledToggleButton';
import CostTable from 'usage/containers/CostAndUsageExplorer/components/CostTable/CostTable';
import {
  groupDataByUsageDate,
  prepareDataForDisplayChart,
  prepareDataForDisplayTable,
  prepareDataKeys,
  prepareTableHeaders,
  updateDataIfOthersIsChecked,
} from 'shared/utils/dataPrepareUtil';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { CSVLink } from 'react-csv';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { random5DigitNumber } from 'shared/utils/mathUtil';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';
import { ACCOUNT_FEATURES, CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { getResourcesIdsAndNamesDistinctValues } from 'usage/hooks/react-query/useResources';
import { withResourcesContextConsumer, withResourcesContextProvider } from 'usage/contexts/resourcesContext';
import ResourceDetailsTable from './components/ResourceDetailsTable';
import AzureServicePlansExplorer from '../AzureServicePlansExplorer';
import styles from './ResourceExplorer.module.scss';

const TABS = {
  RESOURCE_EXPLORER: 1,
  AZURE_SERVICE_PLAN_EXPLORER: 2,
};

class ResourceExplorerPage extends Component {
  static propTypes = {
    usageStore: PropTypes.object.isRequired,
    usersStore: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    fetchResource: PropTypes.func.isRequired,
    selectedResource: PropTypes.object.isRequired,
    resourceIsLoading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    const { selectedResource, location } = this.props;
    const { selectedResourceId } = location.state || {};
    this.state = {
      isLeftChoice: undefined,
      selectedResourceId:
        selectedResourceId ||
        (selectedResource && selectedResource.selectedResource ? selectedResource.selectedResource : ''),
      inputValue: '',
      currPeriodGranLevel: CostTrackingConstants.GRAN_LEVEL_DAILY,
      startDate:
        selectedResource && selectedResource.startDate
          ? selectedResource.startDate
          : new Date(buildStartAndEndDate().startDate),
      endDate:
        selectedResource && selectedResource.endDate
          ? selectedResource.endDate
          : new Date(buildStartAndEndDate().endDate),
      displayMetricType: DisplayMetricTypes.COST,
    };
    this.leftResourceExplorerType = this.getResourceExplorerType(true, props.usersStore.currDispUserCloudAccountType);
    this.rightResourceExplorerType = this.getResourceExplorerType(false, props.usersStore.currDispUserCloudAccountType);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleChangeGranLevel = this.handleChangeGranLevel.bind(this);
    this.renderChart = this.renderChart.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.getChartChoice = this.getChartChoice.bind(this);
    this.getChartProperties = this.getChartProperties.bind(this);
  }

  componentDidMount() {
    const { location, fetchResource } = this.props;
    const { selectedResourceId } = location.state || {};
    const { endDate, startDate, currPeriodGranLevel } = this.state;

    if (selectedResourceId) {
      fetchResource({
        periodGranLevel: currPeriodGranLevel,
        startDate,
        endDate,
        resourceField: selectedResourceId,
        resourceValue: selectedResourceId,
      });
    }
  }

  /** ********************************
   * HANDLERS
   ********************************* */

  setIsLeftChoice = (isLeftChoice) => this.setState({ isLeftChoice });

  getResourceExplorerType = (isLeftChoice, cloudTypeId) => {
    if (isLeftChoice) {
      return ResourceExplorerTypes.Operations;
    }
    if (cloudTypeId === CLOUD_TYPE_IDS.GCP) {
      return ResourceExplorerTypes.CostCoverage;
    }
    if (cloudTypeId === CLOUD_TYPE_IDS.AZURE) {
      return ResourceExplorerTypes.ResourceID;
    }
    return ResourceExplorerTypes.InstanceType;
  };

  getIsInstanceData = (data) => data && data.costChartData && !data.costChartData.some((datum) => datum.instanceType);

  getChartChoice = () => (this.state.isLeftChoice ? this.leftResourceExplorerType : this.rightResourceExplorerType);
  getChartProperties = () => this.dataByResourceExplorerType.get(this.getChartChoice());

  dataByResourceExplorerType = new Map([
    [
      ResourceExplorerTypes.Operations,
      {
        display: 'Operation',
        keyFieldName: 'title',
        separateStackKeyName: '',
        isAreaChart: false,
      },
    ],
    [
      ResourceExplorerTypes.CostCoverage,
      {
        display: 'Cost Coverage',
        keyFieldName: 'costType',
        separateStackKeyName: 'regular',
        isAreaChart: true,
      },
    ],
    [
      ResourceExplorerTypes.ResourceID,
      {
        display: 'Resource ID',
        keyFieldName: 'resourceId',
        separateStackKeyName: '',
        isAreaChart: false,
      },
    ],
    [
      ResourceExplorerTypes.InstanceType,
      {
        display: 'Instance Type',
        keyFieldName: 'instanceType',
        separateStackKeyName: '',
        isAreaChart: false,
      },
    ],
  ]);

  handleSelectResource = async (value) => {
    const { fetchResource } = this.props;
    this.setState({
      selectedResource: value,
    });

    const resourceValue = value.value.length ? value.value : this.state.inputValue;
    fetchResource({
      periodGranLevel: this.state.currPeriodGranLevel,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      resourceField: resourceValue,
      resourceValue,
    });
  };

  handleDateChange = async ({ startDate, endDate }) => {
    const { fetchResource, selectedResource } = this.props;

    startDate = startDate || this.state.startDate;
    endDate = endDate || this.state.endDate;
    if (startDate > endDate && endDate !== null) {
      endDate = startDate;
    }
    this.setState({
      startDate,
      endDate,
    });
    try {
      fetchResource({
        periodGranLevel: this.state.currPeriodGranLevel,
        startDate,
        endDate,
        resourceField: selectedResource.selectedResource,
        resourceValue: selectedResource.selectedResource,
      });
    } catch (error) {}
  };

  handleChangeGranLevel = async (granLevel) => {
    const { fetchResource, selectedResource } = this.props;
    this.setState({ currPeriodGranLevel: granLevel });
    try {
      fetchResource({
        periodGranLevel: granLevel,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        resourceField: selectedResource.selectedResource,
        resourceValue: selectedResource.selectedResource,
      });
    } catch (error) {}
  };

  handleInputChange = (input) => {
    this.setState({ inputValue: input });
  };
  /** ********************************
   * PREPARERS
   ********************************* */

  defaultDates = () => {
    const { startDate, endDate } = this.state;
    let { startDateTemp, endDateTemp } = buildStartAndEndDate(startDate, endDate);
    startDateTemp = createTimeZoneAgnosticDateFromStr(startDateTemp);
    endDateTemp = createTimeZoneAgnosticDateFromStr(endDateTemp);
    return { startDateTemp, endDateTemp };
  };

  prepareDataForDisplay(data) {
    const { displayMetricType } = this.state;
    const getValue = getValueFuncByMetricType(displayMetricType);
    const chartProperties = this.getChartProperties();
    const modifiedDailyBalances = prepareDataForDisplayChart(
      data,
      AppCommonFields.USAGE_DATE,
      chartProperties.keyFieldName,
      getValue,
    );
    const tableModifiedDailyBalance = prepareDataForDisplayTable({
      data,
      entryAnchorFieldName: AppCommonFields.USAGE_DATE,
      keyFieldName: chartProperties.keyFieldName,
      valueFieldName: getValue,
    });

    return { modifiedDailyBalances, tableModifiedDailyBalance };
  }

  prepareData = (baseData) => {
    if (!baseData || baseData.length === 0) {
      return { costChartData: [], costTableData: [] };
    }
    // const data = groupDataByUsageDate(baseData);
    const instanceTypeData = baseData.filter((instance) => instance.instanceType !== 'Amazon Elastic Compute Cloud');
    const data = groupDataByUsageDate(
      this.getChartChoice() === ResourceExplorerTypes.InstanceType ? instanceTypeData : baseData,
    );
    const { modifiedDailyBalances, tableModifiedDailyBalance } = this.prepareDataForDisplay(data);
    return { modifiedDailyBalances, tableModifiedDailyBalance };
  };

  prepareChartFiltersAndData = (selectedResource) => {
    const currDisplyedMetricType = mapDisplayMetricTypesToDataAttributes.get(this.state.displayMetricType);
    const { modifiedDailyBalances } = this.prepareData(selectedResource.costChartData);
    const dataKeys = prepareDataKeys(modifiedDailyBalances, 'name', currDisplyedMetricType);
    return {
      modifiedDailyBalances,
      dataKeys,
    };
  };

  showOthersIfChecked = (isShowOthers, modifiedDailyBalances, filteredDataKeys, filteredKeys) => {
    if (isShowOthers) {
      updateDataIfOthersIsChecked(modifiedDailyBalances, filteredDataKeys, filteredKeys, CostTrackingConstants.OTHERS);
    }
  };

  handleToggleChartMode = () => {
    const currMode = this.state.isLeftChoice;
    this.setState({
      isLeftChoice: !currMode,
    });
  };
  /** *******************************
   * RENDERS
   ********************************* */

  renderChart = (selectedResource) => {
    const chartId = random5DigitNumber();
    const { modifiedDailyBalances, dataKeys } = this.prepareChartFiltersAndData(selectedResource);
    const { currDispUserCloudAccountType } = this.props.usersStore;
    const isChartTypeEnabled = !this.getIsInstanceData(selectedResource);
    return (
      <Card>
        <CardBody>
          <Row style={{ justifyContent: 'flex-end' }}>
            {isChartTypeEnabled ? (
              <div
                style={{
                  margin: '0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  maxWidth: '300px',
                }}
              >
                <p style={!this.state.isLeftChoice ? { opacity: '0.5', marginRight: '5px' } : { marginRight: '5px' }}>
                  {this.dataByResourceExplorerType.get(this.leftResourceExplorerType).display}
                </p>
                <ControlledToggleButton
                  checked={!this.state.isLeftChoice}
                  isFixColore
                  selectedItem
                  handler={this.handleToggleChartMode}
                />
                <p
                  style={
                    this.state.isLeftChoice
                      ? { opacity: '0.5', margin: 0, marginLeft: '5px' }
                      : {
                          margin: 0,
                          marginLeft: '5px',
                        }
                  }
                >
                  {this.dataByResourceExplorerType.get(this.rightResourceExplorerType).display}
                </p>
              </div>
            ) : null}
          </Row>
          <Row>
            <CostChart
              data={modifiedDailyBalances}
              dataKeys={dataKeys}
              chartId={chartId}
              displayedMetric={this.state.displayMetricType}
              isRenderDownloadAsPng
              currDispUserCloudAccountType={currDispUserCloudAccountType}
              separateStackKeyName={this.getChartProperties().separateStackKeyName}
              isAreaChart={this.getChartProperties().isAreaChart}
            />
          </Row>
        </CardBody>
      </Card>
    );
  };

  renderTable = (selectedResource) => {
    const chartId = random5DigitNumber();
    const csvTitle = `Resource-${selectedResource.label}-${chartId}`;
    const baseData = selectedResource.costTableData;
    const { tableModifiedDailyBalance } = this.prepareData(baseData);
    const { columns, columnWidths, tableColumnExtensions } = prepareTableHeaders(tableModifiedDailyBalance);
    if (tableModifiedDailyBalance.length > 0) {
      return (
        <Card>
          <CardBody>
            <CSVLink data={tableModifiedDailyBalance} headers={columns.title} filename={`${csvTitle}.csv`}>
              Download as CSV
              <span className="lnr lnr-download" style={{ fontSize: '18px', marginLeft: '8px', marginBottom: '8px' }} />
            </CSVLink>
            <CostTable
              rows={tableModifiedDailyBalance}
              columns={columns}
              renderedColumnWidths={columnWidths}
              tableColumnExtensions={tableColumnExtensions}
              displayedMetric={this.state.displayMetricType}
              csvTitle={csvTitle}
              isShowSummaryRow={false}
            />
          </CardBody>
        </Card>
      );
    }
    return null;
  };

  renderResourceExplorer() {
    const { selectedResource, usersStore } = this.props;
    const { startDate, endDate, currPeriodGranLevel, selectedResourceId } = this.state;
    const { resourceDetails } = selectedResource || {};
    return (
      <>
        <Card>
          <CardBody>
            <ImmediateFilterBar
              renderDatePickerFilter
              startDate={startDate}
              endDate={endDate}
              handleDateChange={this.handleDateChange}
              renderGranPeriodButton
              currPeriodGranLevel={currPeriodGranLevel}
              handleChangeGranLevel={this.handleChangeGranLevel}
            />
            <div style={{ display: 'flex' }}>
              <h5 style={{ marginTop: '20px' }}>Resource:</h5>
              <div style={{ width: '100%' }}>
                <AutocompleteSearch
                  noOptionsMessageTxt="No Resources Found"
                  itemsLimit={500}
                  handleSelect={this.handleSelectResource}
                  value={selectedResourceId || null}
                  asyncFunc={getResourcesIdsAndNamesDistinctValues}
                  placeholder="Start typing resource name or ID to search resource"
                />
              </div>
            </div>
          </CardBody>
        </Card>
        {selectedResource || resourceDetails ? (
          <Card>
            <CardBody>
              <h5>
                Presenting data for{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {selectedResource &&
                  resourceDetails &&
                  resourceDetails.resourceName &&
                  resourceDetails.resourceName !== 'Not Available'
                    ? `${resourceDetails.resourceName} (${resourceDetails.resourceId})`
                    : selectedResource
                    ? selectedResource.selectedResource
                    : resourceDetails.resourceName}
                </span>{' '}
                between <span style={{ fontWeight: 'bold' }}>{moment(startDate).format('MMMM Do, YYYY')}</span> and{' '}
                <span style={{ fontWeight: 'bold' }}>{moment(endDate).format('MMMM Do, YYYY')}</span>
              </h5>
            </CardBody>
          </Card>
        ) : null}
        <Row>
          <Col
            xl={
              selectedResource && selectedResource.resourceDetails && selectedResource.resourceDetails.length ? 8 : 12
            }
            lg={12}
            md={12}
          >
            {selectedResource && selectedResource.costChartData && selectedResource.costChartData.length
              ? this.renderChart(selectedResource, resourceDetails)
              : null}
          </Col>
          <Col
            xl={selectedResource && selectedResource.costChartData && selectedResource.costChartData.length ? 4 : 12}
            lg={12}
            md={12}
          >
            {selectedResource && selectedResource.resourceDetails && selectedResource.resourceDetails.length ? (
              <ResourceDetailsTable
                resourceDetails={selectedResource.resourceDetails[0]}
                currCloudAccount={usersStore.currDispUserCloudAccountType}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col
            xl={
              selectedResource && selectedResource.currResourceRecs && selectedResource.currResourceRecs.length ? 8 : 12
            }
            lg={
              selectedResource && selectedResource.currResourceRecs && selectedResource.currResourceRecs.length ? 8 : 12
            }
            md={12}
          >
            {selectedResource && selectedResource.costTableData && selectedResource.costTableData.length
              ? this.renderTable(selectedResource)
              : null}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { resourceIsLoading, selectedResource, usageStore, usersStore } = this.props;
    const { isLeftChoice } = this.state;
    const isAzureServicePlanExplorerSupported = checkFeatureFlag(
      usersStore,
      ACCOUNT_FEATURES.AZURE_SERVICE_PLAN_EXPLORER,
    );

    if (resourceIsLoading || usageStore.isLoading) {
      return <Spinner />;
    }
    if (isLeftChoice === undefined) {
      this.setIsLeftChoice(this.getIsInstanceData(selectedResource));
    }
    return (
      <Container>
        <Row>
          <Col>
            <PageHeader title={PageNames.RESOURCE_EXPLORER} showDate />
          </Col>
        </Row>
        <Tabs defaultValue={TABS.RESOURCE_EXPLORER}>
          <TabsList className={styles.navbar}>
            <TabsTrigger value={TABS.RESOURCE_EXPLORER} className={styles.navbarButton}>
              Resources
            </TabsTrigger>
            {usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE && isAzureServicePlanExplorerSupported && (
              <TabsTrigger value={TABS.AZURE_SERVICE_PLAN_EXPLORER} className={styles.navbarButton}>
                App Service Plans
              </TabsTrigger>
            )}
          </TabsList>
          <TabsContent value={TABS.RESOURCE_EXPLORER}>{this.renderResourceExplorer()}</TabsContent>
          <TabsContent value={TABS.AZURE_SERVICE_PLAN_EXPLORER}>
            <AzureServicePlansExplorer />
          </TabsContent>
        </Tabs>
      </Container>
    );
  }
}

const ObserverResourceExplorer = withResourcesContextProvider(
  withResourcesContextConsumer(observer(ResourceExplorerPage)),
);
export default ObserverResourceExplorer;
