import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  TableColumnResizing,
  TableRowDetail,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedSelection,
  RowDetailState,
  SelectionState,
  SortingState,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid';
import { ReactComponent as NotContain } from 'shared/img/icons/not-contain.svg';
import { ReactComponent as Contains } from 'shared/img/icons/contains.svg';
import { ReactComponent as Equal } from 'shared/img/icons/equal.svg';
import { ReactComponent as NotEqual } from 'shared/img/icons/not-equal.svg';
import { ReactComponent as GreaterThan } from 'shared/img/icons/greater-than.svg';
import { ReactComponent as LessThan } from 'shared/img/icons/less-than.svg';
import { ReactComponent as GreaterThanOrEqual } from 'shared/img/icons/greater-than-or-equal.svg';
import { ReactComponent as Assign } from 'shared/img/icons/assign.svg';
import TableWrapper from 'shared/components/tables/TableWrapper';
import useTable from 'shared/hooks/customHooks/useTable';
import useRecsList from 'recommendationsNew/hooks/react-query/useRecsList';
import Spinner from 'shared/components/andtComponents/Spinner';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import { debounce } from 'lodash';
import { getTagsColumnsForCsv } from 'shared/utils/exportTagUtil';
import { TagColumn } from 'shared/components/tagColumn/TagColumn';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import { palette } from 'shared/constants/colorsConstants';
import useUserRecAction from 'recommendationsNew/hooks/react-query/useUserRecAction';
import {
  ACTION_STATUS,
  getColumnHeaderByName,
  ACTION_TYPE,
  FILTERS,
  getSortOrder,
  RESERVED_INSTANCE_TYPES,
} from 'recommendationsNew/consts';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { getLinkedAccountName } from 'shared/utils/cloudUtils';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment/moment';
import { useFilterStatus } from 'recommendationsNew/hooks/useFilterStatus';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import RecommendationDataContainer from '../detailedRecommendation/recommendationDataContainer';
import { ReactComponent as NoResults } from '../../img/no-results.svg';
import RecommendationsTableHeader from './recommendationsTableHeader';
import RecommendationExcluded from './recommendationExcluded';
import RecommendationActions from './recommendationActions';
import RecommendationLabel from './recommendationLabel';
import BulkOperationsRow from './bulkOperationsRow';
import RecommendationComments from './recommendationComments';
import RecommendationUserStatus from './recommendationUserStatus';
import RecommendationDetailsWidthContext from '../../contexts/recommendationDetailsWidthContext';
import StarAnimation from '../../img/star-animation.gif';

import classes from './recommendationsList.module.scss';

const containOperator = ['contains'];
const numericFilterOperations = ['greaterThan', 'lessThan'];
const defaultFilterOperations = ['contains', 'notContains', 'equal', 'notEqual'];
const fixedColumns = ['comment', 'star', 'status', 'userStatus', 'userActions'];
const recommendationsColumns = (cloudType, statusFilter, typeIds) => {
  const getSavingColumnHeader = () => {
    if (statusFilter.isDoneOrCompletedOnly) {
      return 'Annual Actual Savings';
    }
    if (statusFilter.isOnlyOpen) {
      return 'Annual Potential Savings';
    }
    return 'Annual Savings';
  };

  const columns = [
    {
      name: 'savings',
      title: getSavingColumnHeader(),
      getCellValue: (row) => row.annualSavings?.unblended,
      statusFilter,
    },
    {
      name: 'annualCost',
      title: 'Current Annual Cost',
      getCellValue: (row) => row.annualCurrentCost?.unblended,
    },
    { name: 'typeName', title: 'Recommendation Type' },
    {
      name: 'linkedAccount',
      title: getLinkedAccountName(cloudType),
      getCellValue: (row) =>
        row.linkedAccountId ? `${row.linkedAccountName || ''} (${row.linkedAccountId})` : row.linkedAccountName,
    },
    {
      name: 'resource',
      title: 'Resource',
      getCellValue: (row) => {
        if (!row.resourceName) {
          return row.resourceId;
        }
        return row.resourceId && row.resourceName !== row.resourceId
          ? `${row.resourceName || ''} (${row.resourceId})`
          : row.resourceName;
      },
    },
    { name: 'age', title: 'Age' },
    { name: 'region', title: 'Region' },
    { name: 'instanceType', title: 'Instance Type' },
    { name: 'labels', title: 'Labels' },
    { name: 'recId', title: 'Id' },
    {
      name: 'createdAtDateTime',
      title: 'Open Date',
      getCellValue: (row) => moment(row.createdAtDateTime).format('YYYY-MM-DD'),
    },
    {
      name: 'lastProcessingDate',
      title: 'Completed Date',
      getCellValue: (row) => (row.open ? '' : row.lastProcessingDate),
    },
  ];
  if (typeIds?.length === 1 && RESERVED_INSTANCE_TYPES.includes(typeIds[0].value)) {
    columns.push({
      name: 'recommendedPlan',
      title: 'Recommended Plan',
      isRecData: true,
      getCellValue: (row) => row?.recData?.recommended_plan,
    });
  }
  if (statusFilter.isExclude) {
    return [
      ...columns,
      {
        name: 'excludedPeriod',
        title: 'Exclusion Period',
        getCellValue: (row) => (row.userStatus?.status === ACTION_STATUS.excluded ? row.userStatus?.period : ''),
      },
      {
        name: 'excludedReason',
        title: 'Exclusion Reason',
        getCellValue: (row) => (row.userStatus?.status === ACTION_STATUS.excluded ? row.userStatus?.reason : ''),
      },
    ];
  }
  return columns;
};

const tagColumns = [
  { name: 'customTags', title: 'Tags', getCellValue: (row) => row.customTags },
  { name: 'enrichmentTags', title: 'Enrichment Tags', getCellValue: (row) => row.customTags },
];

const monthlyColumn = {
  name: 'monthlySavings',
  title: 'Monthly Potential Savings',
  getCellValue: (row) => row.monthlySavings?.unblended,
};

const pageSize = 50;

const actionsColumns = [
  // { name: 'assignee', title: 'Assignee' },

  { name: 'userStatus', title: '' },
  { name: 'star', title: '' },
  { name: 'comment', title: '' },
  { name: 'status', title: '' },
  { name: 'userActions', title: '' },
];

const recommendationColumnsWidths = [
  { columnName: 'savings', width: 170 },
  { columnName: 'typeName', width: 180 },
  { columnName: 'linkedAccount', width: 207 },
  { columnName: 'resource', width: 205 },
  { columnName: 'age', width: 75 },
  { columnName: 'region', width: 85 },
  { columnName: 'instanceType', width: 110 },
  { columnName: 'recommendedPlan', width: 180 },
  { columnName: 'customTags', width: 100 },
  { columnName: 'enrichmentTags', width: 123 },
  // { columnName: 'assignee', width: 60 },
  { columnName: 'star', width: 50 },
  { columnName: 'comment', width: 50 },
  { columnName: 'status', width: 60 },
  { columnName: 'userStatus', width: 40 },
  { columnName: 'userActions', width: 40 },
  { columnName: 'monthlySavings', width: 120 },
  { columnName: 'excludedPeriod', width: 140 },
  { columnName: 'excludedReason', width: 140 },
  { columnName: 'labels', width: 120 },
  { columnName: 'recId', width: 120 },
  { columnName: 'createdAtDateTime', width: 105 },
  { columnName: 'lastProcessingDate', width: 120 },
  { columnName: 'annualCost', width: 180 },
];

const exportColumn = (cloudType) => [
  { label: 'Potential Savings', key: 'savings' },
  { label: 'Current Annual Cost', key: 'annualCost' },
  { label: 'Recommendation Type', key: 'typeName' },
  { label: `${getLinkedAccountName(cloudType)} Name`, key: 'linkedAccountName' },
  { label: `${getLinkedAccountName(cloudType)} ID`, key: 'linkedAccountId' },
  { label: 'resource Name', key: 'resourceName' },
  { label: 'resource ID', key: 'resourceId' },
  { label: 'Age', key: 'age' },
  { label: 'Region', key: 'region' },
  { label: 'Instance Type', key: 'instanceType' },
  { label: 'Open Date', key: 'createdDT' },
  { label: 'Completed Date', key: 'lastProcessedDT' },
  { label: 'Labels', key: 'labels' },
  { label: 'Id', key: 'recId' },
  { label: 'Monthly Potential Savings', key: 'monthlySavings' },
];

const excludeExportColumns = [
  { label: 'Excluded Period', key: 'excludedPeriod' },
  { label: 'Excluded Reason', key: 'excludedReason' },
];

const CustomFilterIcon = ({ type }) => {
  switch (type) {
    case 'contains':
      return <Contains />;
    case 'notContains':
      return <NotContain />;
    case 'equal':
      return <Equal />;
    case 'notEqual':
      return <NotEqual />;
    case 'greaterThan':
      return <GreaterThan />;
    case 'lessThan':
      return <LessThan />;
    case 'greaterThanOrEqual':
      return <GreaterThanOrEqual />;
    default:
      return <Contains />;
  }
};

const CustomFormatterWithTooltip = ({ value, isCentered }) => (
  <div className={isCentered ? classes.center : ''}>
    <Tooltip title={value}>
      <span>{value}</span>
    </Tooltip>
  </div>
);
const CurrencyNumberWithTooltipFormatter = ({ value, className }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const formattedValue = getCurrencyNumber(value);
  return (
    <Tooltip title={formattedValue}>
      <span className={className}>{formattedValue}</span>
    </Tooltip>
  );
};
const LabelsFormatter = ({ value }) => {
  const formattedValue = value?.replace(/,/g, ', ');
  return (
    <Tooltip title={formattedValue}>
      <span>{formattedValue}</span>
    </Tooltip>
  );
};

const CustomHeaderCell = ({ column, ...restProps }) => {
  const { children } = restProps;
  const { statusFilter, name, title } = column;
  if (!column.title) {
    return (
      <TableHeaderRow.Cell {...restProps} column={column}>
        <Tooltip title={title}>
          <span className={classes.headerList} />
        </Tooltip>
      </TableHeaderRow.Cell>
    );
  }
  return (
    <TableHeaderRow.Cell {...restProps} column={column}>
      {name === 'savings' &&
      (statusFilter.isCompleted || statusFilter.isDone) &&
      (statusFilter.isOpen || !statusFilter.isDone) ? (
        <span className={classes.headerList}>
          {children}
          <InfoPopover isSimple>
            Annual Potential/Actual Savings depends on the “Open/Completed” recommendation status.
          </InfoPopover>
        </span>
      ) : (
        <Tooltip title={title}>
          <span className={classes.headerList}>{children}</span>
        </Tooltip>
      )}
    </TableHeaderRow.Cell>
  );
};

const CustomFilterRow = ({ children, ...restProps }) => (
  <TableFilterRow.Row {...restProps} className={`filterRow ${classes.filterRow}`}>
    {children}
  </TableFilterRow.Row>
);
const CustomFilterCell = ({ column, classes, dynamicHideColumns, ...restProps }) => {
  const actionColumns = ['assignee', 'star', 'comment', 'status', 'userStatus', 'userActions'];
  if ([...actionColumns, ...dynamicHideColumns.map((c) => c.name)].includes(column.name)) {
    return <TableFilterRow.Cell {...restProps}> </TableFilterRow.Cell>;
  }
  return <TableFilterRow.Cell {...restProps} className="filterPlaceHolder" automation-id="input-filter" />;
};
const getFilterColumnValue = (operator, value) => {
  switch (operator) {
    case 'contains':
      return { like: [value] };
    case 'notContains':
      return { like: [value], negate: true };
    case 'equal':
      return { eq: [value] };
    case 'notEqual':
      return { eq: [value], negate: true };
    default:
      return {};
  }
};

const statusFormatter = ({ row }) => (
  <div className={classes.statusWrapper}>
    {row.open ? (
      <span className={classes.open} automation-id="open-rec">
        OPEN
      </span>
    ) : (
      <Tooltip title="Completed" arrow placement="left">
        <span className={classes.complete} automation-id="completed-rec">
          COMP. <GenerateIcon iconName={ICONS.check.name} />
        </span>
      </Tooltip>
    )}
  </div>
);

const defaultSortColumn = { columnName: 'savings', direction: 'desc' };
const defaultHiddenColumns = ['monthlySavings', 'labels', 'recId'];
const RecommendationsList = () => {
  const {
    recommendationFilters: filtersContext,
    heatMapGroupByOptions,
    recommendationExternalFilters: externalFilters,
    setRecommendationExternalFilters: setExternalFilters,
    hiddenColumnNames,
    setHiddenColumnNames,
  } = useRecommendationsNewContext();
  const { usersStore } = useRootStore();
  const { NewTableWrapper, NewTableRow, NewTableSelectionBar } = useTable();
  const recsListHook = useRecsList();
  const recActionHook = useUserRecAction();
  const [sorting, setSorting] = useState([defaultSortColumn]);
  const [searchText, setSearchText] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExcludeModal, setShowExcludeModal] = useState(false);
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [recommendationsData, setRecommendationsData] = useState([]);
  const [queryParams, setQueryParams] = useState({});
  const [recColumnsParams, setRecColumnsParams] = useState({ filters: filtersContext });
  const [isPrevPage, setIsPrevPage] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [columnNames, setColumnNames] = useState([]);
  const statusFilter = useFilterStatus(filtersContext);
  const [columns, setColumns] = useState(
    ...[
      recommendationsColumns(usersStore.currDispUserCloudAccountType, statusFilter, filtersContext[FILTERS.TYPES.id]),
    ],
  );
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [showDoneId, setShowDoneId] = useState(null);
  const [showStarId, setShowStarId] = useState(null);
  const [columnsWidth, setColumnsWidth] = useState(recommendationColumnsWidths);

  const { recommendationDetailsWidthRef } = useContext(RecommendationDetailsWidthContext);

  const {
    data: recsData = {},
    refetch: refetchRecs,
    isLoading: isRecsDataLoading,
  } = recsListHook.fetchRecommendations(queryParams, () => {
    setSelectedRows([]);
  });

  const { data: recColumns, isLoading: isGetColumnsLoading } =
    recsListHook.fetchRecommendationColumns(recColumnsParams);

  const { refetch: refetchFullRecs, isLoading: isExportLoading } = recsListHook.fetchFullRecommendations(queryParams);

  const { recUserAction } = recActionHook;

  const onUserActionSuccess = (action, recId) => {
    if (action === ACTION_STATUS.done) {
      setShowDoneId(recId);
    }
    if (action === ACTION_STATUS.star) {
      setShowStarId(recId);
    }
    refetchRecs().then(() => {
      setShowDoneId(null);
      setShowStarId(null);
      setShowLabelModal(false);
    });
  };

  const { mutateAsync: userActionAsync } = recUserAction(onUserActionSuccess);

  const getFirstRowForPagingToken = () => {
    const firstRow = {};
    if (!recsData.paginationToken) {
      return recommendationsData[0];
    }
    Object.keys(recsData.paginationToken).forEach((key) => {
      firstRow[key] = recommendationsData[0][key];
    });
    return firstRow;
  };

  const updateSearchQuery = () => {
    const sortColumns = sorting.filter((sortObj) => columns.some((column) => column.name === sortObj.columnName));
    if (sortColumns.length === 0) {
      sortColumns.push(defaultSortColumn);
      setSorting([defaultSortColumn]);
    }
    const recColumnSorting = sortColumns.map((sCol) => {
      const recData = dynamicColumns.find((dc) => dc.name === sCol.columnName);
      return { ...sCol, isRecData: recData !== undefined };
    });
    setQueryParams({
      filters: filtersContext,
      tableFilters: externalFilters,
      groupBy: heatMapGroupByOptions || queryParams.groupBy,
      sortBy: recColumnSorting || queryParams.sortBy,
      lastItem: currentPage > 0 && !isPrevPage && recommendationsData.length ? recsData.paginationToken : null,
      firstItem: isPrevPage && recommendationsData.length ? getFirstRowForPagingToken() : null,
      pageSize,
      pageNumber: currentPage,
      searchText,
    });
  };
  const enrichmentColData = useMemo(() => recsData.page?.filter((r) => r.enrichmentTags)?.length > 0, [recsData]);

  useEffect(() => {
    setRecColumnsParams({ filters: filtersContext });
  }, [filtersContext]);

  useEffect(() => {
    updateSearchQuery();
  }, [filtersContext, heatMapGroupByOptions, isPrevPage, currentPage]);

  const getDynamicColumnValue = (columnName, row) => {
    const value = row.recData[columnName];
    if (columnName.startsWith('is_')) {
      return value ? 'Yes' : 'No';
    }
    return value || '';
  };
  const manageListColumns = () => {
    if (!recColumns) {
      return;
    }
    const defaultColumns = recommendationsColumns(
      usersStore.currDispUserCloudAccountType,
      statusFilter,
      filtersContext[FILTERS.TYPES.id],
    );
    if (!recColumns.length) {
      setColumns([...defaultColumns, ...tagColumns, monthlyColumn, ...actionsColumns]);
      setColumnNames([]);
      setDynamicColumns([]);
      if (recColumnsParams.filters?.[FILTERS.TYPES.id]?.length === 1) {
        setHiddenColumnNames([]);
      } else {
        setHiddenColumnNames([...hiddenColumnNames, ...defaultHiddenColumns]);
      }
      return;
    }
    const cColumns = recColumns.map((r) => ({
      name: r.columnName,
      recTypes: r.recTypes,
      title: getColumnHeaderByName(r.columnName),
      sortObj: { by: 'rec_data', recDataColumn: r.columnName },
      getCellValue: (row) => (row?.recData ? getDynamicColumnValue(r.columnName, row) : ''),
    }));
    const customColumns = cColumns
      .filter((c) => !defaultColumns.find((col) => col.title === c.title))
      .sort((a, b) => getSortOrder(a.name) - getSortOrder(b.name));

    let updateHiddenColumns = [];
    if (
      !recColumnsParams.filters?.[FILTERS.TYPES.id]?.length ||
      recColumnsParams.filters?.[FILTERS.TYPES.id]?.length > 1
    ) {
      updateHiddenColumns = [
        ...hiddenColumnNames,
        ...defaultHiddenColumns,
        ...customColumns.map((c) => c.name),
        'annualCost',
      ];
    }
    if (statusFilter.isNotCompleted && !updateHiddenColumns.includes('lastProcessingDate')) {
      updateHiddenColumns.push('lastProcessingDate');
    } else {
      updateHiddenColumns = updateHiddenColumns.filter((c) => c !== 'lastProcessingDate');
    }
    if (recsData?.page !== undefined) {
      if (dynamicColumns.length && !enrichmentColData && !hiddenColumnNames.includes('enrichmentTags')) {
        updateHiddenColumns.push('enrichmentTags');
      } else if (enrichmentColData && updateHiddenColumns.includes('enrichmentTags')) {
        updateHiddenColumns = updateHiddenColumns.filter((c) => c !== 'enrichmentTags');
      }
    }
    setHiddenColumnNames(updateHiddenColumns);
    setColumnNames(customColumns.map((c) => c.title));

    setDynamicColumns([...customColumns]);
    setColumns([...defaultColumns, ...customColumns, ...tagColumns, monthlyColumn, ...actionsColumns]);
    setColumnsWidth([
      ...columnsWidth,
      ...recColumns.map((r) => ({
        columnName: r.columnName,
        width: r.columnName.length < 10 ? 100 : 70 + r.columnName.length * 5,
      })),
    ]);
  };

  useEffect(() => {
    if (!isPrevPage && currentPage === 0) {
      updateSearchQuery();
    } else {
      setIsPrevPage(false);
      setCurrentPage(0);
    }
  }, [searchText, sorting, externalFilters, filtersContext, heatMapGroupByOptions]);

  useEffect(() => {
    setRecommendationsData(recsData?.page || recommendationsData);
    setExpandedRowIds([]);

    if (recsData?.page !== undefined && recsData?.page.length === 0) {
      setTotalPages(0);
    } else {
      if (!recsData || !recsData?.total) {
        setTotalPages(totalPages);
        return;
      }
      setTotalPages(
        (recsData.tableTotal || recsData.total) > pageSize
          ? Math.round((recsData.tableTotal || recsData.total) / pageSize)
          : 1,
      );
    }
  }, [recsData]);

  useEffect(() => {
    manageListColumns();
  }, [recColumns, enrichmentColData, recColumnsParams.filters?.[FILTERS.TYPES.id]]);
  useEffect(() => {
    if (!isExpanded) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(recommendationsData.map((row, index) => index));
    }
  }, [isExpanded]);

  const onCurrentPageChange = (newPageIndex) => {
    setIsPrevPage(newPageIndex < currentPage);
    setCurrentPage(newPageIndex);
  };

  const updateFilters = (filters) => {
    const tempFilters = {};
    filters.forEach((f) => {
      switch (f.columnName) {
        case 'savings':
          tempFilters[FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id] = Number(f.value);
          break;
        case 'age':
          tempFilters[FILTERS.AGE_RANGE.id] =
            f.operation === 'greaterThan' ? [Number(f.value), null] : [null, Number(f.value)];
          break;
        case 'customTags':
          tempFilters.custom_tags_search_expression = f.value;
          break;
        case 'enrichmentTags':
          tempFilters.enrichment_tags_search_expression = f.value;
          break;
        default: {
          const columnName = f.columnName?.replace(/([A-Z])/g, '_$1').toLowerCase();
          const columnValue = getFilterColumnValue(f.operation, f.value);
          if (columns.find((c) => c.name === f.columnName)?.isRecData) {
            tempFilters.rec_data = { ...tempFilters.rec_data, [columnName]: columnValue };
          } else {
            tempFilters[columnName] = columnValue;
          }
        }
      }
    });
    setExternalFilters(tempFilters);
  };

  const debouncedSetFilters = debounce(updateFilters, 400);

  const onFiltersChange = (newFilters) => {
    debouncedSetFilters(newFilters);
  };

  useEffect(
    () => () => {
      debouncedSetFilters.cancel();
    },
    [],
  );

  const fetchDataForExport = async (isFromBulk = false) => {
    let exportData;
    if (isFromBulk) {
      exportData = recommendationsData.filter((r, index) => selectedRows.includes(index));
    } else {
      const { data } = await refetchFullRecs();
      exportData = data.page;
    }
    // prepare columns for export
    let extraColumnForExport =
      recColumnsParams.filters?.[FILTERS.TYPES.id]?.length === 1
        ? dynamicColumns.map((c) => ({ label: c.title, key: c.name }))
        : dynamicColumns
            .filter((c) => !hiddenColumnNames.includes(c.name))
            .map((c) => ({ label: c.title, key: c.name }));

    if (statusFilter.isExclude) {
      extraColumnForExport = [...extraColumnForExport, ...excludeExportColumns];
    }
    const exportColumns = exportColumn(usersStore.currDispUserCloudAccountType);
    // prepare data for export
    const dataToExport = (exportData || []).map((rec) => {
      const { annualSavings, recData, annualCurrentCost, monthlySavings, ...rest } = rec;
      const tagsColumn = getTagsColumnsForCsv(rec, 'customTags', 'enrichmentTags');
      tagsColumn.exportTagColumns.forEach((column) => {
        if (!exportColumns.find((c) => c.key === column.key && c.label === column.label)) {
          exportColumns.push(column);
        }
      });
      const extractData = {};
      extraColumnForExport.forEach((ec) => {
        extractData[ec.key] = recData[ec.key];
      });

      return {
        savings: annualSavings?.unblended,
        annualCost: annualCurrentCost?.unblended,
        createdDT: moment(rest.createdAtDateTime).format('YYYY-MM-DD'),
        monthlySavings: monthlySavings?.unblended,
        lastProcessedDT: rest.open ? '' : rest.lastProcessingDate,
        ...rest,
        ...tagsColumn.data,
        ...extractData,
        excludedReason: rec?.userStatus?.reason,
        excludedPeriod: rec?.userStatus?.period,
      };
    });

    return [
      {
        data: dataToExport || [],
        filename: 'Recommendations Export.csv',
        headers: [...exportColumns, ...extraColumnForExport],
      },
    ];
  };

  const getLabels = () => {
    if (!currentRow?.labels) {
      return [];
    }
    return currentRow.labels?.split(',').map((label) => ({ label, recIds: [currentRow.recId] }));
  };

  const labelsOptions = useMemo(() => getLabels(), [currentRow]);

  const setExcluded = (props) => {
    if (props.row.userStatus?.status === ACTION_STATUS.excluded) {
      userActionAsync({
        action: ACTION_STATUS.include,
        recId: props.row.recId,
      });
    } else {
      setCurrentRow(props.row);
      setShowExcludeModal(true);
    }
  };

  const setDone = (props) => {
    userActionAsync({
      action: props.row.userStatus?.status === ACTION_STATUS.done ? ACTION_STATUS.undone : ACTION_STATUS.done,
      recId: props.row.recId,
    });
  };
  const CustomNoDataComponent = () => {
    if (isRecsDataLoading || isGetColumnsLoading) {
      return null;
    }
    return (
      <div className={classes.noResultsWrapper}>
        <NoResults />
        <span className={classes.mainText}>No Recommendation was found</span>
        <span className={classes.subText}>Try different filters</span>
      </div>
    );
  };
  const CustomToggleCell = ({ style, expanded, ...restProps }) => (
    <TableRowDetail.ToggleCell style={style} {...restProps} automation-id="expand-row-icon">
      <div>
        <IconButton>
          <ExpandMoreIcon style={{ transform: expanded ? 'rotate(180deg)' : 'none' }} />
        </IconButton>
      </div>
    </TableRowDetail.ToggleCell>
  );

  const CustomSelectionCell = (props) => {
    const { row, ...restProps } = props;

    return <TableSelection.Cell {...restProps} row={row} automation-id="select-rec-column" />;
  };

  return (
    <div className={classes.recommendationList}>
      {(isRecsDataLoading || isGetColumnsLoading) && (
        <div className={classes.spinner}>
          <Spinner top="90%" />
        </div>
      )}
      <NewTableWrapper className={selectedRows?.length > 0 && 'custom-pager'}>
        <RecommendationsTableHeader
          rowsCount={recsData?.page?.length > 0 ? recsData?.total : 0}
          displayCount={recsData?.tableTotal}
          searchText={searchText}
          setSearchText={setSearchText}
          showFilters={showFilters}
          setShowFilters={(showFilters) => {
            setShowFilters(showFilters);
            if (!showFilters) {
              setExternalFilters(null);
            }
          }}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          fetchExportData={fetchDataForExport}
          isDataExporting={isExportLoading}
          hiddenColumnNames={hiddenColumnNames}
          setHiddenColumnNames={(columns) => {
            setHiddenColumnNames(columns);
          }}
          pageChange={onCurrentPageChange}
          currentPage={currentPage}
          totalPages={totalPages}
          columns={columns.filter((c) => !actionsColumns.find((ac) => ac.name === c.name))}
        />
        <div ref={recommendationDetailsWidthRef}>
          <Grid rows={recommendationsData} columns={columns} automationId="recommendations-list">
            <SortingState
              sorting={sorting}
              columnExtensions={[
                { columnName: 'customTags', sortingEnabled: false },
                { columnName: 'enrichmentTags', sortingEnabled: false },
                { columnName: 'assignee', sortingEnabled: false },
                { columnName: 'star', sortingEnabled: false },
                { columnName: 'comment', sortingEnabled: false },
                { columnName: 'status', sortingEnabled: false },
                { columnName: 'userStatus', sortingEnabled: false },
                { columnName: 'userActions', sortingEnabled: false },
              ]}
              onSortingChange={(sortingColumAndDirection) => setSorting(sortingColumAndDirection)}
            />
            {showFilters && <FilteringState onFiltersChange={onFiltersChange} />}
            <SelectionState selection={selectedRows} onSelectionChange={setSelectedRows} />
            <IntegratedSelection />
            <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
            <DataTypeProvider
              for={['savings']}
              availableFilterOperations={['greaterThanOrEqual']}
              formatterComponent={(props) => (
                <CurrencyNumberWithTooltipFormatter {...props} className={classes.savings} />
              )}
            />
            <DataTypeProvider
              for={['monthlySavings']}
              availableFilterOperations={['greaterThanOrEqual']}
              formatterComponent={CurrencyNumberWithTooltipFormatter}
            />
            <DataTypeProvider
              for={['typeName']}
              formatterComponent={CustomFormatterWithTooltip}
              availableFilterOperations={defaultFilterOperations}
            />
            <DataTypeProvider
              for={['linkedAccount']}
              formatterComponent={CustomFormatterWithTooltip}
              availableFilterOperations={defaultFilterOperations}
            />
            <DataTypeProvider
              for={['resource']}
              availableFilterOperations={defaultFilterOperations}
              formatterComponent={CustomFormatterWithTooltip}
            />
            <DataTypeProvider
              for={['age']}
              formatterComponent={(props) => <CustomFormatterWithTooltip {...props} isCentered />}
              availableFilterOperations={numericFilterOperations}
            />
            <DataTypeProvider
              for={['region']}
              formatterComponent={CustomFormatterWithTooltip}
              availableFilterOperations={defaultFilterOperations}
            />
            <DataTypeProvider
              for={['recommendedPlan']}
              formatterComponent={CustomFormatterWithTooltip}
              availableFilterOperations={defaultFilterOperations}
            />
            <DataTypeProvider
              for={['customTags']}
              formatterComponent={TagColumn}
              availableFilterOperations={containOperator}
            />
            <DataTypeProvider
              for={['enrichmentTags']}
              formatterComponent={TagColumn}
              availableFilterOperations={containOperator}
            />
            <DataTypeProvider for={['labels']} formatterComponent={LabelsFormatter} />
            <DataTypeProvider for={['excludedReason']} formatterComponent={CustomFormatterWithTooltip} />
            <DataTypeProvider for={['assignee']} formatterComponent={() => <Assign />} />
            <DataTypeProvider
              for={['star']}
              formatterComponent={({ row }) => {
                if (showStarId && row.recId === showStarId) {
                  return (
                    <div className={classes.animationWrapper} automation-id="star-animation">
                      <div className={classes.star}>
                        <img src={StarAnimation} alt="star" />
                      </div>
                    </div>
                  );
                }
                return (
                  <Tooltip title="Add Star" arrow placement="bottom" onClick={(e) => e.stopPropagation()}>
                    <span>
                      <GenerateIcon
                        className={`${classes.starIcon} ${usersStore.currentUserReadOnly ? classes.disableIcon : ''}`}
                        iconName={row.starred ? ICONS.star.name : ICONS.starHollow.name}
                        color={row.starred ? palette.mango[500] : palette.gray[400]}
                        size="lg"
                        automation-id={row.starred ? `star-column-${row.recId}` : `unstar-column-${row.recId}`}
                        disabled={usersStore.currentUserReadOnly}
                        onClick={() => {
                          if (!usersStore.currentUserReadOnly) {
                            userActionAsync({
                              action: row.starred ? ACTION_TYPE.unstar : ACTION_TYPE.star,
                              recId: row.recId,
                            });
                          }
                        }}
                      />
                    </span>
                  </Tooltip>
                );
              }}
            />
            <DataTypeProvider
              for={['comment']}
              formatterComponent={(props) => <RecommendationComments {...props} refetchRecs={refetchRecs} />}
            />
            <DataTypeProvider for={['status']} formatterComponent={statusFormatter} />
            <DataTypeProvider
              for={['userStatus']}
              formatterComponent={(props) => (
                <RecommendationUserStatus
                  isExcluded={props.row.userStatus?.status === ACTION_STATUS.excluded}
                  isDone={props.row.userStatus?.status === ACTION_STATUS.done}
                  setExcluded={() => setExcluded(props)}
                  setDone={() => setDone(props)}
                  showDoneId={showDoneId}
                  {...props}
                />
              )}
            />
            <DataTypeProvider
              for={['userActions']}
              formatterComponent={(props) => (
                <RecommendationActions
                  {...props}
                  addLabel={() => {
                    setCurrentRow(props.row);
                    setShowLabelModal(true);
                  }}
                  recType={props.row.typeId}
                />
              )}
            />
            <DataTypeProvider
              for={dynamicColumns.map((column) => column.name)}
              formatterComponent={CustomFormatterWithTooltip}
            />
            <DataTypeProvider
              for={['annualCost']}
              availableFilterOperations={['greaterThanOrEqual']}
              formatterComponent={CurrencyNumberWithTooltipFormatter}
            />
            <DataTypeProvider for={columnNames} formatterComponent={CustomFormatterWithTooltip} />
            <TableWrapper
              virtual
              noDataCellComponent={CustomNoDataComponent}
              rowComponent={(props) => (
                <NewTableRow expandedRowIds={expandedRowIds} setExpandedRowIds={setExpandedRowIds} {...props} />
              )}
              height="auto"
            />
            <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
            <TableColumnResizing
              resizingMode="widget"
              columnWidths={columnsWidth}
              onColumnWidthsChange={setColumnsWidth}
            />
            <TableRowDetail contentComponent={RecommendationDataContainer} toggleCellComponent={CustomToggleCell} />
            <TableHeaderRow showSortingControls cellComponent={CustomHeaderCell} />
            {showFilters && (
              <TableFilterRow
                showFilterSelector
                iconComponent={CustomFilterIcon}
                rowComponent={CustomFilterRow}
                cellComponent={(props) => <CustomFilterCell {...props} dynamicHideColumns={dynamicColumns} />}
              />
            )}
            <NewTableSelectionBar selectedRows={selectedRows}>
              <div className={classes.bulkRow}>
                <BulkOperationsRow
                  exportCsv={() => fetchDataForExport(true)}
                  selectedRecs={recommendationsData.filter((r, index) => selectedRows.includes(index))}
                />
              </div>
            </NewTableSelectionBar>
            <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} />
            <TableFixedColumns rightColumns={fixedColumns} />
          </Grid>
        </div>
      </NewTableWrapper>
      {showExcludeModal && (
        <RecommendationExcluded
          recs={[currentRow]}
          closeModal={(obj) => {
            if (obj) {
              userActionAsync({
                action:
                  currentRow.userStatus?.status === ACTION_STATUS.excluded ? ACTION_TYPE.include : ACTION_TYPE.exclude,
                recId: currentRow.recId,
                actionParams: obj,
              });
            }
            setShowExcludeModal(false);
          }}
        />
      )}
      {showLabelModal && (
        <RecommendationLabel
          closeModal={() => setShowLabelModal(false)}
          recLabels={labelsOptions}
          recIds={[currentRow.recId]}
          saveLabels={(addLabels, removeLabels) => {
            if (!addLabels.length && !removeLabels.length) {
              return;
            }
            userActionAsync({
              action: 'label',
              recId: currentRow.recId,
              actionParams: { data: { label_add: addLabels.join(','), label_delete: removeLabels.join(',') } },
            });
          }}
        />
      )}
    </div>
  );
};

export default RecommendationsList;

RecommendationsList.propTypes = {};
