import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Container } from 'reactstrap';
import { cloneDeep } from 'lodash';
import Spinner from 'shared/components/andtComponents/Spinner';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { CSVLink } from 'react-csv';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import CustomersTable from 'divisions/containers/Customers/components/CustomersTable';
import { useBillingsStatus, useFlexibilityMargin } from '../hooks/useCustomers';
import ExplainFlexibilityModal from './components/ExplainFlexibilityModal';
import {
  FLEXIBILITY_TABLE_COLUMNS,
  FLEXIBILITY_TABLE_COLUMNS_WIDTHS,
  FLEXIBILITY_TABLE_COST_COLUMNS,
} from '../../../constants/customersConstants';

export const BILLING_STATUS_TABLE_COLUMNS = [
  {
    name: 'customerName',
    title: 'Customer',
    getCellValue: (row) => LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', row.customerName),
  },
  { name: 'mtdResellerCost', title: 'MTD Reseller Cost' },
  { name: 'mtdCustomerCost', title: 'MTD Customer Cost' },
  { name: 'totalMarginCost', title: 'Total Margin' },
  { name: 'riFlexibilityMargin', title: 'RI Margin' },
  { name: 'spFlexibilityMargin', title: 'SP Margin' },
  { name: 'publicPriceMargin', title: 'Tiers Margin' },
  { name: 'billingRuleMargin', title: 'Billing Rule Margin' },
  { name: 'creditMargin', title: 'AWS Credits' },
  { name: 'resellerCredit', title: 'Reseller Credit' },
  {
    name: 'userAction',
    title: 'Actions',
  },
];

export const BILLING_STATUS_TABLE_COLUMNS_WIDTHS = [
  { columnName: 'customerName', width: 130 },
  { columnName: 'mtdResellerCost', width: 130 },
  { columnName: 'mtdCustomerCost', width: 130 },
  { columnName: 'totalMarginCost', width: 130 },
  { columnName: 'riFlexibilityMargin', width: 130 },
  { columnName: 'spFlexibilityMargin', width: 130 },
  { columnName: 'publicPriceMargin', width: 130 },
  { columnName: 'billingRuleMargin', width: 130 },
  { columnName: 'creditMargin', width: 130 },
  { columnName: 'resellerCredit', width: 130 },
  { columnName: 'userAction', width: 60 },
];

const CustomersPage = ({ usersStore, invoiceStore, rootStore }) => {
  const [explainMeModalIsOpen, setExplainMeModalIsOpen] = useState(false);
  const [explainMeModaForType, setExplainMeModaForType] = useState('');
  const [divisionId, setDivisionId] = useState(null);
  const [divisionName, setDivisionName] = useState(null);
  const [accountKey, setAccountKey] = useState(null);
  const [flexibilityType, setFlexibilityType] = useState(null);

  const { getBillingsStatus } = useBillingsStatus();
  const { getFlexibilityMarginData } = useFlexibilityMargin();
  const { data: tableData, isLoading: billingsStatusLoading } = getBillingsStatus();
  const { data: flexibilityMarginData, isLoading: flexibilityMarginDataIsLoading } = getFlexibilityMarginData({
    divisionId,
    divisionName,
    accountKey,
    flexibilityType,
    onErrorCB: () => setExplainMeModalIsOpen(false),
    onSuccessCB: () => {
      setExplainMeModalIsOpen(true);
      setExplainMeModaForType(flexibilityType);
    },
  });

  const handleChangeToCustomerView = (e, divisionId, divisionName, accountKey) => {
    usersStore.changeCustomerUserType();
    rootStore.appStore.updatePricingMode(true);
    usersStore.updateCurrDisplayedDivIdAndName(divisionId, divisionName, accountKey);
    rootStore.invalidateStores();
  };

  const tableColumns = FLEXIBILITY_TABLE_COLUMNS(explainMeModaForType);
  const columnWidths = FLEXIBILITY_TABLE_COLUMNS_WIDTHS(explainMeModaForType);

  const handleExplainCustomerFlexibilityMargin = async (e, divisionId, divisionName, accountKey, flexibilityType) => {
    e.preventDefault();
    setDivisionId(divisionId);
    setDivisionName(divisionName);
    setAccountKey(accountKey);
    setFlexibilityType(flexibilityType);
  };

  const toggleExplainMeModal = () => {
    setExplainMeModalIsOpen((prevExplainMeModalIsOpen) => !prevExplainMeModalIsOpen);
  };

  const prepareCsvModifiedRows = (data) => {
    const csvModifiedRows = cloneDeep(data || []);
    return csvModifiedRows.map(({ accountKey, divisionId, divisionTypeId, ...restRow }) => ({ ...restRow }));
  };

  if (billingsStatusLoading) {
    return <Spinner />;
  }

  return (
    <Container>
      <PageHeader title={PageNames.CUSTOMERS} />
      <Card>
        <CardBody>
          <Col xs={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CSVLink
              data={prepareCsvModifiedRows(tableData)}
              headers={BILLING_STATUS_TABLE_COLUMNS.name}
              filename="billing-status.csv"
            >
              Export as CSV
              <span className="lnr lnr-download" style={{ fontSize: '18px', marginLeft: '8px', marginBottom: '8px' }} />
            </CSVLink>
          </Col>
          <CustomersTable
            tableData={tableData || []}
            handleChangeToCustomerView={handleChangeToCustomerView}
            handleExplainCustomerFlexibilityMargin={handleExplainCustomerFlexibilityMargin}
            usersStore={usersStore}
            invoiceStore={invoiceStore}
            columns={BILLING_STATUS_TABLE_COLUMNS}
            columnWidths={BILLING_STATUS_TABLE_COLUMNS_WIDTHS}
          />
          {flexibilityMarginDataIsLoading && <Spinner />}
          {explainMeModalIsOpen && (
            <ExplainFlexibilityModal
              isOpen={explainMeModalIsOpen}
              toggle={toggleExplainMeModal}
              modalType={explainMeModaForType}
              tableData={flexibilityMarginData}
              tableColumns={tableColumns}
              columnWidths={columnWidths}
              costColumnsArray={FLEXIBILITY_TABLE_COST_COLUMNS}
            />
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

CustomersPage.propTypes = {
  usersStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired,
};

const ObserverCustomersPage = observer(CustomersPage);
export default ObserverCustomersPage;
