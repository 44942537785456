/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {
  mapReportDatePeriodToType,
  ReportSendFrequency,
  USER_SAVED_REPORT_TYPES,
} from 'usage/constants/costAndUsageConstants';
import { buildTimeDiffDateFromBaseDate } from 'shared/utils/dateUtil';
import uuidv1 from 'uuid';
import { convertObjToMap } from 'shared/utils/apiUtil';

const getMontyhlyFrequencyStartDate = (saveModalDeliveryTime) => {
  // can be Sunday - Saturday
  const today = new Date();
  const currDay = today.getDate();
  const maxDayInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
  const timeGap =
    currDay >= saveModalDeliveryTime
      ? maxDayInMonth - (currDay - saveModalDeliveryTime)
      : saveModalDeliveryTime - currDay;
  const { endDate } = buildTimeDiffDateFromBaseDate(today, timeGap, 'd');
  return endDate;
};
const getWeeklyFrequencyStartDate = (saveModalDeliveryTime) => {
  // can be Sunday - Saturday
  const today = new Date();
  const currDay = today.getDay();
  const timeGap =
    currDay >= saveModalDeliveryTime ? 7 - (currDay - saveModalDeliveryTime) : saveModalDeliveryTime - currDay;
  const { endDate } = buildTimeDiffDateFromBaseDate(today, timeGap, 'd');
  return endDate;
};
const getFrequencyStartDateByDeliveryTime = (saveModalDeliveryFreq, saveModalDeliveryTime) => {
  let start = new Date();
  if (saveModalDeliveryFreq === ReportSendFrequency.WEEKLY) {
    start = getWeeklyFrequencyStartDate(+saveModalDeliveryTime);
  }
  if (saveModalDeliveryFreq === ReportSendFrequency.MONTHLY) {
    start = getMontyhlyFrequencyStartDate(+saveModalDeliveryTime);
  }
  return start;
};
export const prepareFrequencyStartDate = (
  saveModalDeliveryFreq,
  saveModalCustomMailFrequencyStartDate,
  saveModalDeliveryTime,
) => {
  let startDate = saveModalCustomMailFrequencyStartDate;
  if (+saveModalDeliveryFreq === ReportSendFrequency.WEEKLY || +saveModalDeliveryFreq === ReportSendFrequency.MONTHLY) {
    startDate = getFrequencyStartDateByDeliveryTime(+saveModalDeliveryFreq, saveModalDeliveryTime);
  }
  return startDate;
};

const type = 'saved';
export default class ReportsHelperMethods {
  static modalSaveEditAssetReportHandler = (context, reportId, newReportMetaParams) => {
    let {
      reportName,
      emails,
      recipients,
      delivery,
      period,
      baseReportParams: origReportBaseParams,
    } = newReportMetaParams;
    let { time, frequency, customFrequency, customStartDate } = delivery;
    const updatedState = origReportBaseParams.state;
    const periodType = mapReportDatePeriodToType.get(period) || 'relativeDate';

    const savedParams = {
      state: updatedState,
      saveModalRelativeDates: periodType === 'relativeDate' ? parseInt(period, 10) : undefined,
      periodType,
    };
    const preparedFrequencyStartDate = prepareFrequencyStartDate(frequency, customStartDate, time);
    context.setState({
      editedReportId: null,
    });
    return {
      id: context.state.editedReport.id,
      type: 'saved',
      name: reportName.value,
      email: emails.value,
      recipients,
      reportFreq: frequency,
      savedState: savedParams,
      mailDeliveryFrequency: customFrequency,
      mailFrequencyStartDate: preparedFrequencyStartDate,
      frequencyDeliveryTime: time,
    };
  };

  static formatReportForServer = (reportParams, context, reportSourceType) => {
    const {
      baseReportParams: origReportBaseParams,
      reportName,
      emails,
      recipients,
      isSnapshotChecked,
      isCumulativeChecked,
      delivery,
      period,
    } = reportParams;

    let updatedState;
    if (reportParams.isEdit) {
      updatedState = origReportBaseParams.state;
    } else {
      updatedState = Object.assign({}, context.state);
    }
    updatedState.saveModalIsOpen = false;
    updatedState.overwriteReportModalIsOpen = false;
    updatedState.isStateFromReport = true;
    const chartType = isCumulativeChecked ? 'cumulative' : 'regular';
    let periodType = mapReportDatePeriodToType.get(period) || 'relativeDate';
    if (isSnapshotChecked) {
      periodType = 'snapShot';
    }
    updatedState.periodType = periodType;
    updatedState.selectedOptionsMap = convertObjToMap(updatedState.selectedOptionsMap);
    const fieldToFilterdValuesMap = convertObjToMap(
      context.fieldToFilterdValuesMap || updatedState.fieldToFilterdValuesMap,
    );
    const dataKeyToWhereParamsMap = convertObjToMap(
      context.dataKeyToWhereParamsMap || updatedState.dataKeyToWhereParamsMap,
    );
    const excludedFiltersStatusMap = convertObjToMap(updatedState.excludedFiltersStatusMap);
    if (!isSnapshotChecked) {
      updatedState.startDate = null;
      updatedState.endDate = null;
    }
    delete updatedState.chartTableData;
    const arrBreadCrumbs = context.breadCrumbs ? context.breadCrumbs.getBreadCrumbs() : [];
    const { isPpApplied } = context.props.usersStore.rootStore.appStore;
    let savedParams = {
      state: {
        ...updatedState,
        excludedFiltersStatusMap,
        dataKeyToWhereParamsMap,
        fieldToFilterdValuesMap,
      },
      saveModalKeepDates: isSnapshotChecked,
      saveModalRelativeDates: periodType === 'relativeDate' ? parseInt(period, 10) : undefined,
      periodType,
      chartType,
      fieldToFilterdValuesMap,
      dataKeyToWhereParamsMap,
      excludedFiltersStatusMap,
      arrBreadCrumbs: arrBreadCrumbs.length > 1 ? arrBreadCrumbs.slice(1, arrBreadCrumbs.length) : [],
    };
    savedParams.state.saveModalUserEmail = 0;
    const preparedFrequencyStartDate = prepareFrequencyStartDate(
      delivery.frequency,
      delivery.customStartDate,
      delivery.time,
    );
    if (reportSourceType === USER_SAVED_REPORT_TYPES.CUSTOM_DASHBOARD) {
      savedParams = null;
    }
    let deliveryFrequency = ReportSendFrequency.NONE;
    if (delivery.frequency || delivery.frequency === 0) {
      deliveryFrequency = delivery.frequency;
    }
    return {
      type,
      reportType: reportSourceType,
      saveModalName: reportName.value,
      saveModalUserEmail: emails.value,
      recipients,
      saveModalDeliveryFreq: deliveryFrequency, // after node upgrade change to this saveModalDeliveryFreq ?? ReportSendFrequency.NONE,
      savedParams,
      isPpApplied,
      saveModalCustomMailDeliveryFrequency: delivery.customFrequency,
      preparedFrequencyStartDate,
      saveModalDeliveryTime: delivery.time,
      id: updatedState.editedReport ? updatedState.editedReport.id : null,
      sourceKey: context.state.sourceKey,
    };
  };

  static getModalSaveReportHandler = (_context, reportId) => {
    const context = _context;
    return (reportParams) => {
      const preparedData = ReportsHelperMethods.formatReportForServer(reportParams, context);
      const {
        type: reportType,
        saveModalName,
        saveModalUserEmail,
        recipients,
        saveModalDeliveryFreq,
        savedParams,
        isPpApplied,
        saveModalCustomMailDeliveryFrequency,
        preparedFrequencyStartDate,
        saveModalDeliveryTime,
        id,
      } = preparedData;
      context.setState({
        saveModalIsOpen: false,
        periodType: 'relativeDates',
        saveModalKeepDates: false,
      });
      return {
        id: reportId || id,
        type: reportType,
        name: saveModalName,
        email: saveModalUserEmail,
        recipients,
        reportFreq: saveModalDeliveryFreq,
        savedState: savedParams,
        isPpApplied,
        mailDeliveryFrequency: saveModalCustomMailDeliveryFrequency,
        mailFrequencyStartDate: preparedFrequencyStartDate,
        frequencyDeliveryTime: saveModalDeliveryTime,
        isEdit: reportParams.isEdit,
      };
    };
  };

  static getModalSendReportHandler = (_context, reportSourceType) => {
    const context = _context;
    return (reportParams) => {
      const preparedData = ReportsHelperMethods.formatReportForServer(reportParams, context, reportSourceType);
      const {
        type: reportUserType,
        saveModalName,
        saveModalUserEmail,
        recipients,
        saveModalDeliveryFreq,
        savedParams,
        isPpApplied,
        saveModalCustomMailDeliveryFrequency,
        preparedFrequencyStartDate,
        saveModalDeliveryTime,
        sourceKey,
        reportType,
      } = preparedData;
      return {
        type: reportUserType,
        id: uuidv1(),
        name: saveModalName,
        email: saveModalUserEmail,
        recipients,
        reportFreq: saveModalDeliveryFreq,
        frequencyDeliveryTime: saveModalDeliveryTime,
        customFrequencyInDays: saveModalCustomMailDeliveryFrequency,
        firstDate: preparedFrequencyStartDate,
        creationDate: new Date(),
        isPpApplied,
        savedState: savedParams,
        sourceKey,
        reportType,
      };
    };
  };
}
