import React, { useContext, useState } from 'react';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Button from 'shared/components/andtComponents/Button';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import Pagination from 'shared/components/andtComponents/Pagination';
import UsersIcon from './UsersIcon';
import AddUsersModal from './userModals/AddUsersModal/AddUsersModal';
import UsersContext from './contexts/usersContext';
import { Search } from '../components/Search';

import styles from './UsersHeader.module.scss';

const UsersHeader = ({ totalUsers }) => {
  const [isUserEnabled, setIsUserEnabled] = useState(false);
  const [isUserDisabled, setIsUserDisabled] = useState(false);
  const [searchText, setSearchText] = useState('');

  const { showFilters, setShowFilters, isAllExpanded, setIsAllExpanded } = useContext(UsersContext);

  const [isCsvDataFetching] = useState(false);

  // const usersHook = useUsers();
  // const { data: usersForCSV, isLoading } = usersHook.fetchAllUsers();

  const fetchExportData = () => {};

  const currentPage = 0;
  const totalPages = 10;
  const pageChange = () => {};

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        <Button
          automationId="expand-rows"
          text=""
          isSecondary
          icon={() => <GenerateIcon iconName={isAllExpanded ? ICONS.collapse.name : ICONS.expand.name} />}
          onClick={() => {
            setIsAllExpanded(!isAllExpanded);
          }}
        />
        <div className={styles.header}>
          <UsersIcon />
          <div className={styles.title}>
            Users <span className={styles.count}>({totalUsers})</span>
          </div>
        </div>
        <div className={styles.filters}>
          <Checkbox
            isChecked={isUserEnabled}
            onChange={() => {
              setIsUserEnabled(!isUserEnabled);
            }}
            primary
            text="Enabled"
          />
          <Checkbox
            isChecked={isUserDisabled}
            primary
            onChange={() => {
              setIsUserDisabled(!isUserDisabled);
            }}
            text="Disabled"
          />
        </div>
      </div>
      <div className={styles.headerRight}>
        <AddUsersModal />
        <Search searchText={searchText} setSearchText={setSearchText} />
        <Tooltip title="Filter columns value" arrow>
          <Button
            text="Filter Results"
            automationId="users-filter-results"
            onClick={() => {
              setShowFilters(!showFilters);
            }}
            overrideStyles={showFilters ? { background: '#DFE9FF' } : {}}
            isSecondary
            icon={() => (
              <GenerateIcon
                iconName={showFilters ? ICONS.xMark.name : ICONS.filterList.name}
                className={styles.filtersIcon}
              />
            )}
          />
          <span />
        </Tooltip>
        <Tooltip title="Export" arrow>
          <CustomCSVDownload
            fetchData={fetchExportData}
            automationId="export-recommendations"
            showDownloadIcon
            isLoading={isCsvDataFetching}
            filesNumber={1}
            isSecondary
            isLightButton
            hideText
            style={{ height: 36 }}
          />
          <span />
        </Tooltip>
        <Pagination currPageIdx={currentPage} onPageChange={pageChange} totalPages={totalPages} />
      </div>
    </div>
  );
};

export default UsersHeader;
