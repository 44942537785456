import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'shared/components/andtComponents/Input';
import ErrorMessage from 'shared/components/andtComponents/ErrorMessage';
import Select from 'react-select';
import { ICONS as faICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import Button from 'shared/components/andtComponents/Button';
import { GranularityLevelsTypes } from 'shared/constants/appConstants';
import { createDateDisplayStr } from 'shared/utils/dateUtil';
import { methodOptions } from 'usage/containers/Budget/budgetUtil';
import CheckBox from 'shared/components/CheckBox';
import moment from 'moment';
import AutoFillModal from './AutoFillModal';
import classes from './budgetModal.module.scss';

const selectStyles = (isDisable, hasError) => ({
  control: (styles) => ({
    ...styles,
    backgroundColor: '#E9E9F0',
    borderRadius: '6px',
    borderColor: hasError ? '#D10F37' : '#E9E9F0',
    height: '36px',
    width: 500,
    opacity: isDisable ? 0.5 : 1,
  }),
  option: (styles, { isFocused, isSelected }) => {
    let backgroundColor;
    if (isSelected) {
      backgroundColor = '#dfe9ff';
    } else if (isFocused) {
      backgroundColor = '#E9F0FF';
    }
    return {
      ...styles,
      background: backgroundColor,
    };
  },
  indicatorSeparator: () => ({ display: 'none' }),
});

const CustomInputValue = ({ data, ...props }) => {
  const { label, description } = data;
  return (
    <div {...props}>
      <div className={classes.selectOptionWrapper}>
        <div className={classes.optionLabel}>{label}</div>
        <div className={classes.optionSubLabel}>{description}</div>
      </div>
    </div>
  );
};

CustomInputValue.propTypes = {
  data: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};

const CBSettings = ({ budget, setBudget, saveClicked, budgetValidationErrors, isEditMode }) => {
  const [periodStartDate, setPeriodStartDate] = useState();
  const [isAutoOpen, setIsAutoOpen] = useState(false);
  const { currencySymbol } = useUserSettingsContext();

  const handleUpdateField = (field, val) => {
    setBudget({
      ...budget,
      [field]: val,
    });
  };

  const updateBudgetDates = (startDate, endDate) => {
    setBudget({
      ...budget,
      startDate,
      endDate,
    });
  };
  const renderPlannedInput = (budgetAmounts) => {
    const inputsArr = [];
    (budgetAmounts || budget.budgetAmounts)?.forEach((bAmount) => {
      inputsArr.push(
        <div className={classes.monthWrapper}>
          <span className={classes.monthLabel}>{bAmount?.displayDate}</span>
          <div>
            <Input
              className={classes.monthInput}
              value={bAmount?.amount}
              disabled={isEditMode && new Date(budget.endDate) < new Date()}
              onChange={(e) => {
                const b = budget.budgetAmounts?.find((b) => b.date === bAmount?.date);
                b.amount = Number(e.target.value);
                handleUpdateField('budgetAmounts', budget.budgetAmounts);
              }}
              automationId="budget-owner-input"
              type="number"
            />
          </div>
        </div>,
      );
    });
    return inputsArr;
  };

  const updateBudgetAmounts = (budgetType, startDate, endDate) => {
    if (!budgetType) {
      return;
    }
    const budgetAmounts = [];
    if (budgetType.value === 'expiring') {
      const start = new Date(startDate);
      for (let date = new Date(start.getFullYear(), start.getMonth(), 1); date <= new Date(endDate);) {
        const modifiedDate = createDateDisplayStr(moment(date).format('yyyy-MM-dd').substring(0, 10), 'month');
        const dateFormata = moment(date).format('yyyy-M');
        const dateFormatb = moment(date).format('yyyy-MM');
        const budgetAmountDate = budget.budgetAmounts?.find((b) => b.date === dateFormatb || b.date === dateFormata);
        budgetAmounts.push({
          date: date.getMonth() < 9 ? dateFormata : dateFormatb,
          displayDate: modifiedDate,
          amount: budgetAmountDate ? budgetAmountDate.amount : '',
        });
        date = moment(date).add(1, 'month').toDate();
      }
    }
    handleUpdateField('budgetAmounts', budgetAmounts);
    renderPlannedInput(budgetAmounts);
  };

  useEffect(() => {
    if (!budget.endDate) {
      handleUpdateField('endDate', new Date(new Date().setFullYear(new Date().getFullYear() + 1)));
    } else {
      updateBudgetAmounts(budget.budgetType, budget.startDate, budget.endDate);
    }
  }, [budget.budgetType, budget.startDate, budget.endDate]);
  useEffect(() => {
    if (!budget.startDate) {
      handleUpdateField('startDate', new Date());
    }
  }, []);

  const formatOptionLabel = ({ label, description }) => (
    <div className={classes.customOptionWrapper}>
      <div className={classes.optionLabel}>{label}</div>
      <div className={classes.optionSubLabel}>{description}</div>
    </div>
  );

  const applyAuto = (type, amount, growth) => {
    let updatedBudgetAmounts = [];
    let currentAmount = Number(amount);
    switch (type.value) {
      case 'period':
        updatedBudgetAmounts = budget.budgetAmounts.map((bAmount) => ({
          ...bAmount,
          amount: Math.round(amount / budget.budgetAmounts.length),
        }));
        break;
      case 'fixed':
        updatedBudgetAmounts = budget.budgetAmounts.map((bAmount) => ({
          ...bAmount,
          amount: Number(amount),
        }));
        break;
      case 'growth':
        budget.budgetAmounts.forEach((bAmount) => {
          updatedBudgetAmounts.push({
            ...bAmount,
            amount: currentAmount,
          });
          currentAmount += Math.round((currentAmount * growth) / 100);
        });
        break;
      default:
        break;
    }
    handleUpdateField('budgetAmounts', updatedBudgetAmounts);
  };
  return (
    <>
      <div className={classes.container}>
        <div className={classes.body}>
          <div className={classes.marginBottom}>
            <p className={classes.subHeader}>Budget Period</p>
            <div className={classes.settingsWrapper}>
              <div className={classes.budgetTypeBody}>
                <DatePickerFilter
                  onDateChange={(date) => {
                    if (date.startDate) {
                      setPeriodStartDate(date.startDate);
                    }
                    updateBudgetDates(
                      date.startDate ? date.startDate : budget.startDate,
                      date.endDate ? date.endDate : budget.endDate,
                    );
                  }}
                  startDate={isEditMode ? budget.startDate : periodStartDate}
                  endDate={budget.endDate}
                  currPeriodGranLevel={GranularityLevelsTypes.GRAN_LEVEL_MONTHLY}
                  isDateRangeError={false}
                  forceNoMaxDate
                  isDisabled={isEditMode && new Date(budget.endDate) < new Date()}
                  andtLook
                />
                <ErrorMessage
                  displayError={
                    saveClicked &&
                    !!budgetValidationErrors?.endDate &&
                    (!budget.endDate || new Date(budget.endDate) < new Date())
                  }
                  errMsg={budgetValidationErrors?.endDate}
                  automationId="budget-amount-input-error"
                />
              </div>
            </div>
          </div>
          <p className={classes.subHeader}>Budget Definitions</p>
          <div
            className={`${classes.settingsWrapper} ${
              budget.budgetType?.value === 'expiring' ? classes.typeWrapper : ''
            }`}
          >
            <div className={classes.marginRight}>
              <h5 className={`${classes.header} ${classes.smallMargin}`}>Budget Type</h5>
              <Select
                open
                styles={selectStyles(
                  isEditMode && new Date(budget.endDate) < new Date(),
                  saveClicked && !!budgetValidationErrors?.budgetType && !budget.budgetType,
                )}
                isDisabled={isEditMode && new Date(budget.endDate) < new Date()}
                value={budget.budgetType}
                components={{ SingleValue: CustomInputValue }}
                formatOptionLabel={formatOptionLabel}
                options={methodOptions}
                placeholder="Select"
                onChange={(selectedOption) => {
                  handleUpdateField('budgetType', selectedOption);
                }}
              />
              {budget.budgetType?.value && budget.budgetType?.value !== 'expiringFixed' && (
                <div className={classes.flexible}>
                  <div className={classes.marginRight}>
                    <CheckBox
                      label="Apply Rolling Budget"
                      onChangeFunc={() => handleUpdateField('isFlexible', !budget.isFlexible)}
                      value={budget.isFlexible}
                    />
                  </div>
                  <InfoPopover isSimple>Allocate the remaining budget to move from month to month</InfoPopover>
                </div>
              )}
              <ErrorMessage
                displayError={saveClicked && !!budgetValidationErrors?.budgetType && !budget.budgetType}
                errMsg={budgetValidationErrors?.budgetType}
                automationId={`${isEditMode ? 'edit' : 'create'}-alert-granularity-error`}
              />
            </div>
            {(budget.budgetType?.value === 'recurring' || budget.budgetType?.value === 'expiringFixed') && (
              <div className={classes.marginRight}>
                <h5>Budget Amount({currencySymbol})</h5>
                <Input
                  className={`${classes.fieldInput} ${classes.amount}`}
                  value={budget.budgetAmount}
                  onChange={(e) => handleUpdateField('budgetAmount', Number(e.target.value))}
                  isInvalid={saveClicked && !!budgetValidationErrors?.budgetAmount}
                  automationId={`${isEditMode ? 'edit' : 'create'}-budget-amount-input`}
                  invalidComponent={
                    <ErrorMessage
                      displayError={saveClicked && !!budgetValidationErrors?.budgetAmount}
                      errMsg={budgetValidationErrors?.budgetAmount}
                      automationId={`${isEditMode ? 'edit' : 'create'}-budget-amount-input-error`}
                    />
                  }
                  type="number"
                />
              </div>
            )}
            {budget.budgetType?.value === 'expiring' && (
              <div className={classes.autoFillWrapper}>
                <Button
                  text="Auto-Fill"
                  onClick={() => {
                    setIsAutoOpen(!isAutoOpen);
                  }}
                  overrideStyles={{ backgroundColor: 'transparent', color: '#2671ff' }}
                  icon={() => <GenerateIcon iconName={faICONS.calculator.name} />}
                />
                {isAutoOpen && (
                  <AutoFillModal
                    budget={budget}
                    isOpen={isAutoOpen}
                    setIsOpen={setIsAutoOpen}
                    applyAuto={applyAuto}
                    updateFields={handleUpdateField}
                  />
                )}
              </div>
            )}
          </div>
          {budget.budgetType?.value === 'expiring' && (
            <>
              <div className={classes.planningWrapper}>{renderPlannedInput()}</div>
              <ErrorMessage
                displayError={saveClicked && !!budgetValidationErrors?.budgetAmount}
                errMsg={budgetValidationErrors?.budgetAmount}
                automationId={`${isEditMode ? 'edit' : 'create'}-budget-amount-input-error`}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CBSettings;
CBSettings.propTypes = {
  budget: PropTypes.object.isRequired,
  setBudget: PropTypes.func.isRequired,
  budgetValidationErrors: PropTypes.object.isRequired,
  saveClicked: PropTypes.bool,
  isEditMode: PropTypes.bool,
};
CBSettings.defaultProps = {
  saveClicked: false,
  isEditMode: false,
};
