import React, { useState } from 'react';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import useTable from 'shared/hooks/customHooks/useTable';
import { cloneDeep } from 'lodash';
import { USER_ACTIONS } from '../../../consts';
import AssignRolesModalTable from './AssignRolesModalTable';
import AssignRolesModalHeader from './AssignRolesModalHeader';

import styles from './AssignRolesModal.module.scss';

const AssignRolesModal = ({ isOpen, setIsOpen, onSave, selected, isLoading }) => {
  const [saveClicked] = useState(false);

  const [selectedRoles, setSelectedRoles] = useState(cloneDeep(selected));
  const [searchText, setSearchText] = useState('');

  const availableRoles = [
    { roleId: 123, roleName: 'Admin' },
    { roleId: 1234, roleName: 'User' },
    { roleId: 12345, roleName: 'Assets Viewer' },
    { roleId: 123456, roleName: 'Budget Viewer' },
    { roleId: 1234567, roleName: 'DB Viewer' },
    { roleId: 12345678, roleName: 'Viewer' },
  ];

  const { NewTableWrapper } = useTable();

  return (
    <CustomModal
      className={styles}
      onClose={() => {}}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={MODAL_MODES.ADD}
      footerDisabled={isLoading && saveClicked}
      onSave={async () => {
        await onSave(selectedRoles);
        setIsOpen(false);
      }}
      open={isOpen}
      automationId="assign-role-modal"
      saveTitle="Add"
      saveDisabled={false}
      title={USER_ACTIONS.ASSIGN_ROLES.label}
    >
      <div className={styles.assignRoleContainer}>
        <NewTableWrapper className="sub-table" isCompact>
          <AssignRolesModalHeader
            selectedRolesCount={selectedRoles?.length}
            searchText={searchText}
            setSearchText={setSearchText}
          />
          <AssignRolesModalTable
            roles={availableRoles}
            selectedUserRoles={selectedRoles}
            setSelectedUserRoles={setSelectedRoles}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </NewTableWrapper>
      </div>
    </CustomModal>
  );
};

export default AssignRolesModal;
