import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Tooltip from 'shared/components/andtComponents/Tooltip';

import classes from './selectMultiButton.module.scss';

export function SelectMultiButton({ isSelectMenuOpen, label, onButtonClick, selectedOptions, isAllSelected, tooltip }) {
  return (
    <Tooltip title={tooltip} disabled={!tooltip} placement="top">
      <div
        className={`${classes.selectMultiButton} ${isSelectMenuOpen ? classes.selectMenuOpen : ''}`}
        onClick={onButtonClick}
      >
        <div className={classes.labelContainer}>
          {label}
          <div className={classes.selectedCount}>
            {selectedOptions.length > 0 && !isAllSelected ? `(${selectedOptions.length})` : '(All)'}
          </div>
        </div>
        <GenerateIcon iconName={ICONS.caretDown.name} className={classes.iconCaretDown} />
      </div>
    </Tooltip>
  );
}

SelectMultiButton.propTypes = {
  isAllSelected: PropTypes.bool,
  isSelectMenuOpen: PropTypes.bool,
  label: PropTypes.string,
  onButtonClick: PropTypes.func,
  selectedOptions: PropTypes.array,
  tooltip: PropTypes.node,
};

SelectMultiButton.defaultProps = {
  isAllSelected: false,
  isSelectMenuOpen: false,
  label: '',
  onButtonClick: undefined,
  selectedOptions: [],
  tooltip: '',
};
