import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Button from 'shared/components/andtComponents/Button';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import TextArea from 'shared/components/andtComponents/TextArea';
import Dropzone from 'react-dropzone';
import toast from 'shared/components/andtComponents/Toast';
import moment from 'moment';
import { isUsernameValid } from 'users/utils/userUtil';
import { ReactComponent as DragAndDropUsersFile } from '../../img/dragAndDrop.svg';
import { USER_ACTIONS } from '../../../consts';
import AssignRolesModal from '../AssignRolesModal/AssignRolesModal';
import useUsers from '../../../../../new-user-management/hooks/reactQuery/useUsers';
import ErrorDetails from './ErrorDetails';
import PreviewUserEmails from './PreviewUserEmails';

import styles from './AddUsersModal.module.scss';

const OPTIONS = {
  EMAIL: { value: 'email', label: 'Email', primary: true },
  FILE: { value: 'file', label: 'Upload CSV file', primary: true },
};

const EMAIL_ERRORS = {
  INVALID_EMAIL_FORMAT: 'Wrong Email format',
  SERVER_ERROR: 'User creation failed',
};

const AddUsersModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAssignRolesOpen, setIsAssignRolesOpen] = useState(false);
  const [userEmailErrors, setUserEmailErrors] = useState(null);
  const [type, setType] = useState(OPTIONS.EMAIL.value);
  const [assignedRoles, setAssignedRoles] = useState([]);
  const [acceptedFile, setAcceptedFile] = useState();
  const [emails, setEmails] = useState([]);
  const [emailsFromCsvFile, setEmailsFromCsvFile] = useState();

  const { createUsers } = useUsers();

  const { usersStore } = useRootStore();
  const { currDispUserAccountKey } = usersStore;
  const accountName = usersStore.getCurrentDisplayedAccountName(currDispUserAccountKey);

  const removeCsvFile = () => {
    if (type === OPTIONS.FILE.value) {
      setAcceptedFile(undefined);
      setEmailsFromCsvFile(undefined);
      setUserEmailErrors(undefined);
    }
  };

  const onClose = () => {
    setAcceptedFile(undefined);
    setEmailsFromCsvFile(undefined);
    setUserEmailErrors(undefined);
    setEmails(undefined);
    setAssignedRoles([]);
    setIsOpen(false);
  };

  const saveUsers = async () => {
    const emailsToSave = type === OPTIONS.EMAIL.value ? emails : emailsFromCsvFile;
    if (!emailsToSave?.length) {
      return;
    }
    const invalidEmails = emailsToSave?.filter((e) => e.trim() && !isUsernameValid(e.trim()).validResult);
    if (invalidEmails?.length) {
      const errors = invalidEmails.map((email) => ({ email, error: EMAIL_ERRORS.INVALID_EMAIL_FORMAT }));
      setUserEmailErrors(errors);
      return;
    }

    const users = emailsToSave?.map((email) => ({
      username: email.trim(),
      createdAt: moment().toISOString(),
      roles: assignedRoles,
    }));

    try {
      await createUsers.mutateAsync({ users });
      setIsOpen(false);
      onClose();
    } catch (error) {
      if (error.response.status === 500) {
        const errorEmails = users.map((user) => ({ email: user.username, error: EMAIL_ERRORS.SERVER_ERROR }));
        setUserEmailErrors(errorEmails);
      }
    }
  };

  const readFile = (file) => {
    const reader = new FileReader();
    if (file.size > 10485760) {
      toast.error('File is too big.');
      return;
    }
    try {
      reader.onabort = () => toast.error('Error reading the file');
      reader.onerror = () => toast.error('Error reading the file');
      reader.onload = () => {
        const content = reader.result;
        const uploadedUserEmails = content.split('\r\n');
        if (uploadedUserEmails?.[0] === 'email') {
          setAcceptedFile(file);
          setUserEmailErrors(undefined);
          uploadedUserEmails.shift();

          setEmailsFromCsvFile(uploadedUserEmails);
        } else {
          setUserEmailErrors([{ email: '', error: 'Column header of the CSV file is not "email".' }]);
        }
      };
      reader.readAsText(file);
    } catch (error) {
      toast.error('Error reading the file');
    }
  };

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles?.length) {
      toast.error('Wrong file format, please use CSV files only');
      return;
    }
    readFile(acceptedFiles[0]);
  };

  useEffect(() => {
    if (type === OPTIONS.FILE.value) {
      setUserEmailErrors(null);
    }
  }, [type]);

  const onEmailsTextAreaChange = (e) => {
    setUserEmailErrors(null);
    const emails = e?.target?.value?.split(',');
    setEmails(emails);
  };

  const onOptionChange = (val) => {
    setType(val);
    setUserEmailErrors(null);
  };

  const onAssignRoles = (roles) => {
    setAssignedRoles(roles);
    setUserEmailErrors(undefined);
  };

  return (
    <>
      <Button
        text="Add Users"
        onClick={() => {
          setIsOpen(true);
        }}
        disabled={false}
        icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
        automationId="newUser"
      />
      <CustomModal
        className={styles}
        headerMode={MODAL_MODES.ADD}
        closeOnSave={false}
        saveDisabled={false}
        onSave={saveUsers}
        open={isOpen}
        onClose={onClose}
        title="Add Users"
        automationId="addEditUsersModal"
      >
        <div className={styles.addUsersContainer}>
          <div className={styles.organizationContainer}>
            <div className={styles.title}>Organization:</div>
            <div className={styles.organizationName}>{accountName}</div>
          </div>
          <div className={styles.optionsContainer}>
            <div className={styles.title}>Choose one of the options:</div>
            <div className={styles.options}>
              <div className={styles.option}>
                <RadioButtonList options={[OPTIONS.EMAIL]} value={type} onChange={onOptionChange} />
                <div className={styles.optionContent}>
                  <TextArea
                    disabled={type !== OPTIONS.EMAIL.value}
                    isResizable={false}
                    value={emails?.join(',')}
                    placeholder="Separate addresses by commas. Up to 10 users"
                    onChange={onEmailsTextAreaChange}
                    isInvalid={type === OPTIONS.EMAIL.value && userEmailErrors?.length > 0}
                    invalidComponent={<ErrorDetails emailErrors={userEmailErrors} />}
                  />
                </div>
              </div>
              <div className={styles.option}>
                <RadioButtonList options={[OPTIONS.FILE]} value={type} onChange={onOptionChange} />
                <div className={styles.optionContent}>
                  <div className={styles.description}>
                    <div>{`Upload CSV file with a single column, the column title should be 'email'.`}</div>
                    <div className={styles.emphasize}>Limited up to 10 users.</div>
                  </div>
                  <div>
                    <Dropzone
                      disabled={type === OPTIONS.EMAIL.value}
                      accept=".csv"
                      onDrop={(acceptedFiles, rejectedFiles) => onDrop(acceptedFiles, rejectedFiles)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div
                            className={classNames(styles.uploadFile, {
                              [styles.disabled]: type === OPTIONS.EMAIL.value,
                            })}
                            {...getRootProps()}
                          >
                            {acceptedFile ? (
                              <div className={styles.acceptedFile}>
                                {acceptedFile?.name}
                                <GenerateIcon iconName={ICONS.xMark.name} onClick={removeCsvFile} />
                              </div>
                            ) : (
                              <>
                                <DragAndDropUsersFile />
                                <div>
                                  <input {...getInputProps()} />
                                  <div className={styles.description}>
                                    Drag & Drop a CSV file, or click to select a file
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  <div className="d-flex gap-5">
                    <PreviewUserEmails userEmails={emailsFromCsvFile} />
                    {type === OPTIONS.FILE.value && <ErrorDetails emailErrors={userEmailErrors} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              automationId="assignRole"
              disabled={false}
              isTextButton
              icon={() => <GenerateIcon iconName={USER_ACTIONS.ASSIGN_ROLES.icon} />}
              onClick={() => {
                setIsAssignRolesOpen(true);
              }}
              overrideStyles={{ paddingLeft: 0 }}
              text={USER_ACTIONS.ASSIGN_ROLES.label}
            />
            <AssignRolesModal
              selected={assignedRoles}
              onSave={onAssignRoles}
              isOpen={isAssignRolesOpen}
              setIsOpen={setIsAssignRolesOpen}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default AddUsersModal;
