import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import React from 'react';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Button from 'shared/components/andtComponents/Button';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import DailyHourlySwitchButton from './DailyHourlySwitchButton';
import styles from './recommendedChart.module.scss';
import DownloadChartMenu from './DownloadChartMenu';
import ActualSimulatedSwitchButton from './ActualSimulatedSwitchButton';

const ChartHeader = ({
  header,
  info,
  exportToCsv,
  exportToPng,
  exportToSvg,
  includeEstimatedOption,
  isDailyMode,
  setIsDailyMode,
  isActualMode,
  setIsActualMode,
  isEstimatedMode,
  setIsEstimatedMode,
  automationId,
}) => (
  <div className={styles.headerWrapper}>
    <div className={styles.flex}>
      <span className={styles.header}>{header}</span>
      <Tooltip arrow title={info}>
        <span>
          <GenerateIcon iconName={ICONS.circleInfo.name} className={styles.icon} />
        </span>
      </Tooltip>
      <DailyHourlySwitchButton isDailyMode={isDailyMode} setIsDailyMode={setIsDailyMode} automationId={automationId} />
      {isActualMode !== null && (
        <ActualSimulatedSwitchButton
          isActualMode={isActualMode}
          setIsActualMode={setIsActualMode}
          automationId={automationId}
        />
      )}
    </div>
    <div className={styles.flex}>
      <DownloadChartMenu exportToCsv={exportToCsv} exportToPng={exportToPng} exportToSvg={exportToSvg} />
      {includeEstimatedOption && (
        <Button
          text={
            <Checkbox
              className={styles.checkbox}
              text="Estimated Cost"
              isChecked={!!isEstimatedMode}
              onChange={() => setIsEstimatedMode(!isEstimatedMode)}
            />
          }
          icon={() => <GenerateIcon iconName={ICONS.sparkles.name} className={styles.sparklesIcon} />}
          iconPlacement="right"
        />
      )}
    </div>
  </div>
);

export default ChartHeader;
