import React from 'react';
import BudgetDetails from 'usage/containers/Budget/components/BudgetDetails';
import PropTypes from 'prop-types';

const BudgetChart = ({ budget }) => (
  <div>
    <BudgetDetails budget={budget} isDashboardPanel />
  </div>
);
export default BudgetChart;

BudgetChart.propTypes = {
  budget: PropTypes.object.isRequired,
};
