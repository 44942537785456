import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import TabsSelector from 'shared/components/andtComponents/TabsSelector/TabsSelector';
import { ICONS } from '@pileus-cloud/anodot-frontend-common';
import UserRolesTab from './UserRolesTab';
import EffectivePermissions from './EffectivePermissions';

import styles from './UserExpandedData.module.scss';

export const USER_ROW_TABS = {
  ROLES: {
    iconName: ICONS.userGear.name,
    label: 'Roles',
    id: 'roles',
    isSelected: true,
    getComponent: (row) => <UserRolesTab row={row} />,
  },
  EFFECTIVE_PERMISSIONS: {
    iconName: ICONS.clipboard.name,
    label: 'Effective Permissions',
    id: 'effectivePermissions',
    getComponent: (row) => <EffectivePermissions row={row} />,
  },
};

const UserExpandedData = ({ row }) => {
  const [selectedTab, setSelectedTab] = useState(Object.values(USER_ROW_TABS).find((o) => o.isSelected === true));

  const onSelectedTabComponentChange = (tabId) => {
    setSelectedTab(Object.values(USER_ROW_TABS).find((o) => o.id === tabId));
  };

  const selectedTabGetComponent = useMemo(() => selectedTab.getComponent(row), [selectedTab]);

  return (
    <div className={styles.container}>
      <TabsSelector
        tabsInfo={Object.values(USER_ROW_TABS)}
        onTabSelected={onSelectedTabComponentChange}
        selectedTabId={selectedTab.id}
      />
      <div className={styles.tabContent}>{selectedTabGetComponent}</div>
    </div>
  );
};

UserExpandedData.propTypes = {
  row: PropTypes.object.isRequired,
};

export default UserExpandedData;
