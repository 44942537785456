import React, { useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import {
  FILTERS,
  getUserStatusSwitchButtons,
  SWITCH_BUTTON_LABELS,
  SWITCH_BUTTON_STATUSES,
} from 'recommendationsNew/consts';
import { SwitchButtonsContainer } from '../../../shared/switchButtonsContainer';
import { useHeatMapContext } from '../../contexts/heatMapContext';

import classes from './customUserStatus.module.scss';

const ERROR_EXCLUDE_DONE = 'Choose either Done or Excluded. Both Cannot be selected.';
const NOTE = 'Note the operator used between these filters is logical AND';

export const CustomUserStatus = ({ filter, onFiltersChange }) => {
  const { isWasteChart } = useHeatMapContext();
  const [isErrorMessage, setIsErrorMessage] = useState('');

  const changeSwitchButtonsFilters = (event) => {
    setIsErrorMessage(false);
    if (event.filter === SWITCH_BUTTON_LABELS.STATUS) {
      onFiltersChange({
        ...filter,
        [FILTERS.IS_OPEN.id]: event.filterValue,
      });
    } else if (event.filter === SWITCH_BUTTON_LABELS.DONE) {
      if (filter[FILTERS.USER_STATUS.id]?.excluded === true && event.filterValue === true) {
        setIsErrorMessage(true);
        return;
      }
      onFiltersChange({
        ...filter,
        [FILTERS.USER_STATUS.id]: { ...filter[FILTERS.USER_STATUS.id], done: event.filterValue },
      });
    } else if (event.filter === SWITCH_BUTTON_LABELS.EXCLUDED) {
      if (filter[FILTERS.USER_STATUS.id]?.done === true && event.filterValue === true) {
        setIsErrorMessage(true);
        return;
      }
      onFiltersChange({
        ...filter,
        [FILTERS.USER_STATUS.id]: { ...filter[FILTERS.USER_STATUS.id], excluded: event.filterValue },
      });
    }
  };

  const onUserStateChangeState = (event) => {
    // eslint-disable-next-line no-nested-ternary
    const filterValue = [
      SWITCH_BUTTON_STATUSES.OPEN,
      SWITCH_BUTTON_STATUSES.DONE,
      SWITCH_BUTTON_STATUSES.EXCLUDED,
    ].includes(event.option)
      ? true
      : [
          SWITCH_BUTTON_STATUSES.COMPLETED,
          SWITCH_BUTTON_STATUSES.UN_DONE,
          SWITCH_BUTTON_STATUSES.NOT_EXCLUDED,
        ].includes(event.option)
      ? false
      : null;

    changeSwitchButtonsFilters({ ...event, filterValue });
  };

  return (
    <div className={classes.filtersContainer}>
      {isErrorMessage ? (
        <div className={classes.errorMessageContainer}>
          <GenerateIcon iconName={ICONS.circleExclamationRegular.name} className={classes.circleExclamation} />
          {ERROR_EXCLUDE_DONE}
        </div>
      ) : (
        <div className={classes.note}>{NOTE}</div>
      )}
      <div className={classes.customFilters}>
        <SwitchButtonsContainer
          onFiltersChange={onUserStateChangeState}
          threeStateSwitchButtons={getUserStatusSwitchButtons({
            isOpen: filter[FILTERS.IS_OPEN.id],
            isDone: filter[FILTERS.USER_STATUS.id]?.done,
            isExcluded: filter[FILTERS.USER_STATUS.id]?.excluded,
            isStateDisabled: isWasteChart,
          })}
        />
      </div>
    </div>
  );
};
