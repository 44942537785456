import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HeatMapContent from 'recommendationsNew/components/heatMap/heatMapContent';
import HeatMapSummary from 'recommendationsNew/components/heatMap/heatMapSummary';
import HeatMapCloseButton from 'recommendationsNew/components/heatMap/heatMapCloseButton';
import useGroupByOptions from 'recommendationsNew/hooks/react-query/useGroupByOptions';
import { useHeatMapContext } from 'recommendationsNew/components/heatMap/contexts/heatMapContext';
import HeatMapFilters from 'recommendationsNew/components/heatMap/heatMapFilters/mainFilters/heatMapFilters';
import { GROUP_BY_OPTIONS } from 'recommendationsNew/consts';
import { useRecommendationsNewContext } from '../../contexts/recommendationsNewContext';

import classes from './heatMap.module.scss';

const HeatMap = ({ usersStore }) => {
  const recommendationsGroupByOptionsHook = useGroupByOptions();
  const recommendationsGroupByOptions = recommendationsGroupByOptionsHook.fetchGroupByOptions();
  const { data: groupByOptionsData } = recommendationsGroupByOptions;

  const { setHeatMapGroupByOptions, isHeatMapClosed, setIsHeatMapClosed } = useHeatMapContext();
  const { isDashboardPanel } = useRecommendationsNewContext();

  useEffect(() => {
    if (!isDashboardPanel && groupByOptionsData?.length > 0) {
      const tmpOptions = groupByOptionsData
        ?.filter((option) => option.default === true)
        ?.map((option) => {
          if (
            option.id === GROUP_BY_OPTIONS.items.SERVICE?.id ||
            option.id === GROUP_BY_OPTIONS.items.TYPE_ID?.id ||
            option.id === GROUP_BY_OPTIONS.items.CAT_ID?.id
          ) {
            return { ...option, isPinned: true };
          }
          return option;
        });
      setHeatMapGroupByOptions(tmpOptions);
    }
  }, [groupByOptionsData]);

  const onCloseButtonClick = () => {
    setIsHeatMapClosed(!isHeatMapClosed);
  };

  return (
    <div className={classes.container}>
      <div className={classes.heatMapContainer}>
        <HeatMapFilters usersStore={usersStore} />
        {isHeatMapClosed ? <div /> : <HeatMapContent />}
        <HeatMapSummary />
      </div>
      {!isDashboardPanel && (
        <HeatMapCloseButton
          isClosed={isHeatMapClosed}
          onClick={onCloseButtonClick}
          automation-id="heatmap-close-open-button"
        />
      )}
    </div>
  );
};

HeatMap.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

export default HeatMap;
