import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import InformationOutline from 'mdi-react/InformationOutlineIcon';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import ICONS from 'shared/constants/assetsConstants';
import IconFromPng from 'shared/components/IconFromPng';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';
import { isDecimalNeeded } from 'shared/utils/strUtil';
import { CostTrackingConstants } from 'usage/constants/costAndUsageConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import RecsStatsCenter from 'recommendations/modules/recsStatsCenter';
import {
  keysToRemoveFromCsv,
  LabelActionTypes,
  mapRecommendationsTypeToDisplay,
  MAX_LABELS,
  REC_DASHB_SUMMARY_COLUMN_WIDTHS,
  REC_DASHB_SUMMARY_DEFAULT_SORTING,
  REC_DASHB_SUMMARY_TABLE_COLUMN_EXTENSIONS,
  REC_DASHB_SUMMARY_TABLE_COLUMNS,
} from 'recommendations/constants/recommendationsConstants';
import {
  mapRecommendationsTypeToTableColumns,
  mapRecommendationsTypeToTableColumnsSorting,
} from 'recommendations/constants/recommsTableConstants';
import DateFilter from 'shared/modules/dateFilter';
import CheckBox from 'shared/components/CheckBox';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import NewCustomDashboardPanelModal from 'shared/components/NewCustomDashboardPanelModal';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import { UsersType } from 'users/constants/usersConstants';
import toast from 'shared/components/andtComponents/Toast';
import getDisplayNameByDivisionName from 'divisions/divisionsHelpers';
import AddOrEditRecLabelModal from 'recommendations/containers/Dashboard/components/AddOrEditRecLabelModal';
import {
  prepareFilterObj,
  prepareLinkAccountLabel,
} from 'recommendations/containers/Dashboard/helpers/dataPrepareHelpers';
import * as customDashboardHelpers from 'recommendations/containers/Dashboard/helpers/customDashboardHelperMethods';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { withMainDashboardContextConsumer } from 'app/contexts/MainDashboardContext';
import { deleteRecommendationsLabel } from 'recommendations/hooks/react-query/api';
import useRecommendationOpportunities from 'recommendations/hooks/react-query/useRecommendationOpportunities';
import RecommendationsTable from 'recommendations/containers/Dashboard/components/RecommendationsTable';
import RecommendationFilterBar from 'recommendations/containers/Dashboard/components/RecommendationFilterBar';
import SummaryTable from 'recommendations/containers/Dashboard/components/RecsSummaryTable';
import OpportunitiesChart from 'recommendations/containers/Dashboard/components/OpportunitiesChart';
import { saveRecommsLabel } from 'recommendations/hooks/react-query/recommendationHelpers';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { useRecommendationByType } from 'recommendations/hooks/react-query/useRecommendationByType';
import useDashboardSummary from 'recommendations/hooks/react-query/useDashboardSummary';
import { useAccountTags } from 'usage/hooks/react-query/useAccountTags';

const RecommendationsDashboard = ({
  usersStore,
  usageStore,
  invoiceStore,
  appStore,
  location,
  mainDbData,
  getPageFilters,
}) => {
  const { numStrAbriviaionByDisplayMetric } = useUserSettingsContext();

  const getValidatedInitialEndDate = () => {
    const countDay = DateFilter.getPreviousDayDate();
    const { endDate } = buildStartAndEndDate('', countDay);
    return endDate;
  };

  const [state, setState] = useState({
    recommType: null,
    currPeriodGranLevel: CostTrackingConstants.GRAN_LEVEL_DAILY,
    startDate: moment(getValidatedInitialEndDate()).subtract(30, 'days').format('YYYY-MM-DD'),
    endDate: getValidatedInitialEndDate(),
    recTypeDisplay: null,
    isShowActualCost: false,
    isDateRangeError: false,
    yearlyTotlaCost: 0,
  });

  const [filterSelectedValues, setFilterSelectedValues] = useState(location?.source?.filtersMap || undefined);
  const [modifiedRecommDataForCsv, setModifiedRecommDataForCsv] = useState([]);
  const [modifiedSummaryDataForCsv, setModifiedSummaryDataForCsv] = useState([]);
  const [newCustomDashboardPanelModalIsOpen, setNewCustomDashboardPanelModalIsOpen] = useState(false);
  const [isCustomerPricingMode, setIsCustomerPricingMode] = useState(appStore.isCustomerPricingMode);
  const [currCustomPanelType, setCurrCustomPanelType] = useState(null);
  const [recSignaturesForLabel, setRecSignaturesForLabel] = useState(null);
  const [labelActionType, setLabelActionType] = useState('');
  const [labels, setLabels] = useState(null);
  const [isLabelsFromBatch, setIsLabelsFromBatch] = useState(false);

  const { currentDisplayedUserType, usersModel } = usersStore;
  const { mapLinkedAccIdToDivisionName } = usersModel;

  const { fetchAccountTagsData } = useAccountTags();
  const { data: accountTagsData } = fetchAccountTagsData();

  // Summary Table
  const summaryHook = useDashboardSummary({
    isEnabled: usersStore.currentDisplaydUser.isPayingUser,
    filterMap: filterSelectedValues,
  });
  const summaryDataHook = summaryHook.fetchDashboardSummaryData();
  const { isLoading: isSummaryDataLoading, data: summaryData, isFetching: isSummaryDataFetching } = summaryDataHook;

  // Opportunities
  const opportunitiesHook = useRecommendationOpportunities({
    startDate: state.startDate,
    endDate: state.endDate,
    filterParams: filterSelectedValues,
    periodGranLevel: state.currPeriodGranLevel,
    recommendationType: state.recommType,
  });
  const opportunitiesDataHook = opportunitiesHook.fetchOpportunities();
  const { data: opportunitiesData } = opportunitiesDataHook;

  // Recommendation by type
  const recommendationsHook = useRecommendationByType({
    isEnabled: !!state.recommType,
    recommendationType: state.recommType,
    currentDisplayedUserType,
    mapLinkedAccIdToDivisionName,
  });
  const recommendationByTypeDataHook = recommendationsHook.fetchRecommendationsByTypeData();
  const { isLoading: isRecommendationByTypeDataLoading, data: recommendationByTypeData } = recommendationByTypeDataHook;
  const [isRecommendationsActionInProcess, setIsRecommendationsActionInProcess] = useState(false);

  useEffect(() => {
    const fetchIsUserSubscribedToMonday = () => usersStore.getIsUserSubscribedToMondayWebhook();
    fetchIsUserSubscribedToMonday();

    return async () => {
      await summaryHook.reset();
      await opportunitiesHook.reset();
      await recommendationsHook.reset();
    };
  }, []);

  useEffect(() => {
    const fetchDivisionGroups = async () => {
      if (!usersStore.usersModel.mapLinkedAccIdToDivisionName.length) {
        await usersStore.fetchDivisionGroups(true);
      }
    };
    fetchDivisionGroups();
  }, [isSummaryDataLoading]);

  useEffect(() => {
    const prevStateIsCustomerPricingMode = isCustomerPricingMode;
    const currPropsIsCustomerPricingMode = appStore.isCustomerPricingMode;
    if (currPropsIsCustomerPricingMode !== prevStateIsCustomerPricingMode) {
      setIsCustomerPricingMode(currPropsIsCustomerPricingMode);
    }

    if (modifiedRecommDataForCsv.length) {
      const csvLink = document.querySelector('#csv-link-recomm');
      if (csvLink) {
        csvLink.click();
      }
      setModifiedRecommDataForCsv([]);
    }
    if (modifiedSummaryDataForCsv.length) {
      const csvLink = document.querySelector('#csv-link-summary');
      if (csvLink) {
        csvLink.click();
      }
      setModifiedSummaryDataForCsv([]);
    }
  }, [appStore.isCustomerPricingMode, modifiedRecommDataForCsv.length, modifiedSummaryDataForCsv.length]);

  useEffect(() => {
    if (opportunitiesData?.currentMonthForecatsedCost) {
      const opportunitiesMonthTotalCost = Math.abs(opportunitiesData?.currentMonthForecatsedCost);
      if (opportunitiesMonthTotalCost) {
        let yearlyTotalCost;
        try {
          yearlyTotalCost = mainDbData.mainDashBoardData.annualforecastedTotalCost;
        } catch (error) {
          yearlyTotalCost = opportunitiesMonthTotalCost * 12;
        }
        setState({ ...state, yearlyTotlaCost: yearlyTotalCost });
      }
    }
  }, [opportunitiesData?.currentMonthForecatsedCost]);

  const recommendationsByCurrFilter = useMemo(() => {
    let tmpRecommendations = recommendationByTypeData;
    if (tmpRecommendations?.length) {
      const filterObject = prepareFilterObj(state.recommType, filterSelectedValues);
      tmpRecommendations = RecsStatsCenter.getFilteredRecsByCriteriaObject(
        filterObject,
        recommendationByTypeData,
        accountTagsData,
      );
    }
    return tmpRecommendations;
  }, [recommendationByTypeData, filterSelectedValues, state.recommType, accountTagsData]);

  async function recommTypeSelectHandler(newRecommType, newRecTypeDisplay) {
    if ((state.recommType || newRecommType) && state.recommType !== newRecommType) {
      setState({ ...state, recommType: newRecommType, recTypeDisplay: newRecTypeDisplay });
    }
  }

  const getParamsForCustomDashboard = () =>
    customDashboardHelpers.prepareParamsForNewOppCustomDashboardPanel({
      ...state,
      filterSelectedValues,
      recommTypeSelectHandler,
    });

  const handleChangeGranLevel = async (periodGranLevel, isDateRangeError) => {
    setState({ ...state, currPeriodGranLevel: periodGranLevel, isDateRangeError });
  };

  const removeColumnsFromTable = (columnsToRemoveArray, columns) => {
    columnsToRemoveArray.forEach((columnToRemove) => {
      const index = columns.findIndex((col) => col.name === columnToRemove);
      if (index > -1) {
        columns.splice(index, 1);
      }
    });
  };

  const addAdditionalColumns = (columns, recs) => {
    removeColumnsFromTable(['linkedAccount', 'resource', 'resourceTags'], columns);
    if (recs[0].linkedAccountId) {
      columns.unshift({
        name: 'linkedAccount',
        title: prepareLinkAccountLabel(usersStore.currDispUserCloudAccountType, true),
        getCellValue: (row) =>
          row.linkedAccountName && row.linkedAccountName !== row.linkedAccountId
            ? `${row.linkedAccountName} (${row.linkedAccountId})`
            : row.linkedAccountId,
      });
    }
    if (recs[0].resourceId) {
      columns.unshift({
        name: 'resource',
        title: 'Resource',
        getCellValue: (row) =>
          row.resourceName && row.resourceName !== 'Not Available' && row.resourceName.trim().length > 0
            ? `${row.resourceName} (${row.resourceId})`
            : row.resourceId,
      });
    }
    if (recs.some((rec) => !!rec.resourceTags)) {
      columns.splice(-1, 0, {
        name: 'resourceTags',
        title: 'Tags',
        getCellValue: (row) => (row.resourceTags && Array.isArray(row.resourceTags) ? row.resourceTags.join('\n') : ''),
      });
    }
    if (recs.some((rec) => rec.maxMemory && rec.memory !== 'Not Available')) {
      columns.splice(-1, 0, {
        name: 'maxMemory',
        title: 'Max Memory',
        getCellValue: (row) => (row.maxMemory === 'Not Available' ? 0 : +row.maxMemory),
      });
    }
    if (recs.some((rec) => rec.cpuUtil)) {
      columns.splice(-1, 0, {
        name: 'cpuUtil',
        title: 'Max CPU Utilization (%)',
        getCellValue: (row) => `${row.cpuUtil.toFixed(isDecimalNeeded(row.cpuUtil, 2) ? 2 : 0)} %`,
      });
    }
    if (recs.some((rec) => !!rec.labels) && !columns.some((column) => column.name === 'labels')) {
      columns.splice(columns.length - 1, 0, {
        name: 'labels',
        title: 'Labels',
        getCellValue: (row) => row.labels && row.labels.toString(),
      });
    }
  };

  const preparedRecommendationsTableData = useMemo(() => {
    let recommendations = recommendationsByCurrFilter;
    const baseColumns = mapRecommendationsTypeToTableColumns.get(state.recommType);
    const sortingOptions = mapRecommendationsTypeToTableColumnsSorting.get(state.recommType);
    const tableColumns = baseColumns ? baseColumns.filter((col) => col !== -1) : [];
    if (recommendationsByCurrFilter?.length) {
      recommendations = RecsStatsCenter.sortRecommendations(recommendations);
      if (recommendations.length) {
        addAdditionalColumns(tableColumns, recommendations);
      }
    }
    return { recomms: recommendations, tableColumns, sortingOptions };
  }, [recommendationsByCurrFilter, state.recommType]);

  const modifiedRowForCsv = (row, resourceNum = 1) => {
    let copyRow = JSON.parse(JSON.stringify(row));
    if (copyRow.linkedAccountId && copyRow.linkedAccountName) {
      copyRow['Linked Account'] = `${copyRow.linkedAccountName} (${copyRow.linkedAccountId})`;
      delete copyRow.linkedAccountName;
    }
    try {
      copyRow.tags = [];
      if (Array.isArray(copyRow.resourceTags)) {
        copyRow.tags = copyRow.resourceTags.map((rt) => rt.replace(/"/g, ''));
      } else if (typeof copyRow.resourceTags === 'object' && copyRow.resourceTags !== null) {
        copyRow.tags = Object.entries(copyRow.resourceTags).map(
          ([key, value]) => `${key.replace(/"/g, '')}: ${value.replace(/"/g, '')}`,
        );
      } else {
        copyRow.tags = Object.entries(row.rawResourceTags[row['Resource ID']] || row.rawResourceTags).map(
          ([key, value]) => `${key.replace(/"/g, '')}: ${value.replace(/"/g, '')}`,
        );
      }
    } catch (error) {
      copyRow.tags = [];
    }
    if (!copyRow.resources || !copyRow.resources.length) {
      copyRow = { 'Resource ID': copyRow.resourceId, 'Resource Name': copyRow.resourceName, ...copyRow };
    }
    if (copyRow.recommEc2Instance) {
      copyRow['Suggested Model'] = copyRow.recommEc2Instance.instance;
    }
    copyRow['Payer Account'] = copyRow.accountId;
    copyRow['Current EBS Model'] = copyRow.ebsType;
    copyRow['Suggested EBS Model'] = copyRow.newEbsType;
    copyRow['Annual Potential Savings'] = (+copyRow.costPotentialSavings / resourceNum).toFixed(2);
    copyRow['Current Annual Cost'] = ((+copyRow.currMonthlyCost * 12) / resourceNum).toFixed(2);
    copyRow['Recommended Annual Cost'] = ((+copyRow.recommMonthlyCost * 12) / resourceNum).toFixed(2);
    copyRow['Current Model RI Coverage'] = copyRow.isCurrentReserved;
    copyRow['Target Model RI Coverage'] = copyRow.isRecommendedReserved;
    copyRow.maxCpuUtil = row.cpuUtil;
    delete copyRow.resourceTags;
    delete copyRow.resourceId;
    delete copyRow.resourceName;
    delete copyRow.costPotentialSavings;
    delete copyRow.accountId;
    delete copyRow.ebsType;
    delete copyRow.newEbsType;
    delete copyRow.currMonthlyCost;
    delete copyRow.recommMonthlyCost;
    delete copyRow.monthlyCostPotentialSavings;
    delete copyRow.isCurrentReserved;
    delete copyRow.isRecommendedReserved;
    delete copyRow.cpuUtil;

    Object.entries(copyRow).forEach(([key, value]) => {
      delete copyRow.key;
      if (
        keysToRemoveFromCsv.includes(key) ||
        (typeof value === 'object' && (key !== 'tags' || !copyRow[key].length)) ||
        !copyRow[key]
      ) {
        delete copyRow[key];
      }
    });
    // convert all column names to readable view - tableName -> Table Name
    return Object.keys(copyRow).reduce((acc, key) => {
      const value = copyRow[key];
      if (key.match(/^[a-z].*/) && !key.includes(' ')) {
        const newKey = key.slice(0, 1).toUpperCase() + key.slice(1).replaceAll(/([a-z])([A-Z])/g, '$1 $2');
        copyRow[newKey] = value;
        return { ...acc, [newKey]: typeof value === 'number' ? value.toFixed(2) : value };
      }
      return { ...acc, [key]: value };
    }, {});
  };

  const prepareCsvModifiedRowsForSummaryTable = (data) => {
    if (!data) {
      return [];
    }
    return data
      .filter(({ type }) => type === 'total' || mapRecommendationsTypeToDisplay.get(type))
      .map((row) => {
        const { type, savings, percentOfTotal, quantity } = row;
        return {
          Type: type === 'total' ? 'Total' : mapRecommendationsTypeToDisplay.get(type) || type,
          Savings: (Math.round((savings || 0) * 100) + Number.EPSILON) / 100,
          'Percent Of Total': percentOfTotal,
          Quantity: quantity,
        };
      });
  };

  const prepareCsvModifiedRowsForRecomm = (recommendations) => {
    let csvModifiedRows = JSON.parse(JSON.stringify(recommendations || []));
    if (recommendations?.length) {
      csvModifiedRows = csvModifiedRows.reduce((acc, row) => {
        const currRow = row;
        if (row.resources && row.resources.length) {
          const newRows = row.resources.map((resource) => {
            const newRow = { 'Resource ID': resource.resource_id, 'Resource Name': resource.resource_name, ...row };
            return modifiedRowForCsv(newRow, row.resources.length);
          });
          acc.push(...newRows);
          return acc;
        }
        const modifiedCurrRow = modifiedRowForCsv(currRow);
        acc.push(modifiedCurrRow);
        return acc;
      }, []);
    }
    return csvModifiedRows;
  };

  const filterValuesOfRecWithNoMatchingLinkedAccountId = (mapFilterValues) => {
    if (
      !mapFilterValues.get(AwsCommonFields.ACCOUNT_TAGS) ||
      !mapFilterValues.get(AwsCommonFields.ACCOUNT_TAGS).length
    ) {
      mapFilterValues.delete(AwsCommonFields.ACCOUNT_TAGS);
    }
    const linkAccIdsValues = mapFilterValues.get('linkedaccname');
    if (linkAccIdsValues) {
      const filtredValues = linkAccIdsValues.filter(({ linkedAccountId }) =>
        recommendationByTypeData.some((rec) => rec.linkedAccountId === linkedAccountId),
      );
      if (filtredValues.length) {
        mapFilterValues.set('linkedaccname', filtredValues);
      }
    }
  };

  const exportSummaryAsCsv = (data) =>
    Promise.resolve([
      {
        data: prepareCsvModifiedRowsForSummaryTable(data),
        filename: 'recommendations_summary.csv',
      },
    ]);

  const handleDateChange = async ({ startDate, endDate, isDateRangeError }) => {
    const start = startDate || state.startDate;
    let end = endDate || state.endDate;
    if (start > end && end !== null) {
      end = start;
    }
    setState({
      ...state,
      startDate: buildStartAndEndDate(start, end).startDate,
      endDate: buildStartAndEndDate(start, end).endDate,
      isDateRangeError,
    });
  };

  const handleApplyFiltersButtonClick = async (filterSelectedValues) => {
    setFilterSelectedValues(filterSelectedValues);
  };

  const toggleLabelModal = () => {
    setRecSignaturesForLabel(null);
    setLabelActionType('');
    setLabels(null);
  };

  const handleSaveDashboardPanelButtonClick = (e, type) => {
    e.preventDefault();
    setNewCustomDashboardPanelModalIsOpen(true);
    setCurrCustomPanelType(type);
    segmentEvent({
      target: `saveAsDashboardPanel-${type}`,
    });
  };

  const handleCloseNewCustomDashboardPanelModal = () => {
    setNewCustomDashboardPanelModalIsOpen(false);
    setCurrCustomPanelType(null);
  };

  const handleDoneClicked = async (recKey) => {
    const { recommType } = state;
    try {
      setIsRecommendationsActionInProcess(true);
      await recommendationsHook.completeRecommendationByKeys(recKey);
      setState({ ...state, recommType });
    } catch (error) {
      toast.error('Setting the recommendation as Done failed');
    } finally {
      await summaryHook.invalidate();
      setIsRecommendationsActionInProcess(false);
    }
  };

  const handleAddRecToMonday = async (recs) => {
    try {
      const modifiedRecs = recs.map((rec) => ({
        ...rec,
        title: mapRecommendationsTypeToDisplay.get(rec.type) || rec.type,
      }));
      await recommendationsHook.addRecommendationsToMondayBoard(modifiedRecs);
      toast.success('Add the recommendation to Monday.com board completed successfully');
    } catch (error) {
      toast.error('Add the recommendation to Monday.com board failed');
    } finally {
      await recommendationsHook.invalidate();
    }
  };

  const handleToggleIsShowActualCost = () => {
    setState({ ...state, isShowActualCost: !state.isShowActualCost });
  };

  const handleExcludeClicked = async (recs, excludeData, excludeMessageMap) => {
    try {
      setIsRecommendationsActionInProcess(true);
      await recommendationsHook.excludeRecommendation(recs, excludeData, excludeMessageMap);
    } catch (error) {
      toast.error('Excluding recommendation failed');
    } finally {
      await summaryHook.invalidate();
      setIsRecommendationsActionInProcess(false);
    }
  };

  const handleLabelActionClicked = (recs, actionType, isBatch = false) => {
    const prevLabels = labels;

    setRecSignaturesForLabel(recs.map((rec) => rec.signature));
    setLabelActionType(actionType);
    setLabels(isBatch ? [] : recs.reduce((acc, rec) => [...acc, ...(rec.labels || [])], []) || prevLabels);
    setIsLabelsFromBatch(isBatch);
  };

  const handleRemoveLabel = (index) => {
    const newLabels = [...labels];
    if (!newLabels) {
      return;
    }
    newLabels.splice(index, 1);
    setLabels(newLabels);
  };

  const handleAddLabel = (label) => {
    if (!label) {
      return;
    }
    const newLabels = Array.isArray(labels) ? [...labels, label] : [label];
    if (newLabels.length > MAX_LABELS) {
      return;
    }
    setLabels(newLabels);
  };

  const handleLabelSaved = async (isFromBatch = false) => {
    if (!labels) {
      return;
    }
    try {
      toggleLabelModal();
      setIsRecommendationsActionInProcess(true);
      const isSuccess = await saveRecommsLabel(recommendationByTypeData, labels, recSignaturesForLabel, isFromBatch);
      if (!isSuccess) {
        toast.error('Something went wrong, please try again later');
      }
    } catch (error) {
      toast.error('Something went wrong, please try again later');
      toggleLabelModal();
    } finally {
      await recommendationsHook.invalidate();
      setIsRecommendationsActionInProcess(false);
    }
  };

  const handleDeleteLabel = async (action) => {
    if (action === 'cancel') {
      toggleLabelModal();
    } else if (action === 'delete') {
      try {
        toggleLabelModal();
        const isSuccess = await deleteRecommendationsLabel(recSignaturesForLabel);
        if (!isSuccess) {
          toast.error('Something went wrong, please try again later');
        }
      } catch (error) {
        toggleLabelModal();
      } finally {
        await recommendationsHook.invalidate();
      }
    }
    return null;
  };

  const isLabelModalOpen = () => labelActionType === LabelActionTypes.ADD || labelActionType === LabelActionTypes.EDIT;

  const renderRecommendationsTable = (recommendations, tableColumns, sortingOptions) => {
    const { recommType } = state;
    const { currentDisplayedUserType, currentUserReadOnly } = usersStore;

    return (
      <>
        {isRecommendationByTypeDataLoading || isRecommendationsActionInProcess ? (
          <div style={{ height: 100, width: 100, position: 'absolute', zIndex: 1 }}>
            <Spinner />
          </div>
        ) : null}
        {recommendations?.length && !(isRecommendationByTypeDataLoading || isRecommendationsActionInProcess) ? (
          <RecommendationsTable
            recomms={recommendations}
            prepareCsvModifiedRowsForRecomm={prepareCsvModifiedRowsForRecomm}
            recommType={recommType}
            tableColumns={tableColumns}
            sortingOptions={sortingOptions}
            recHandlers={{
              recCompletedHandler: handleDoneClicked,
              recExcludeHandler: handleExcludeClicked,
              recLabelHandler: handleLabelActionClicked,
              addRecToMonday: handleAddRecToMonday,
            }}
            currentDisplayedUserType={currentDisplayedUserType}
            currentUserReadOnly={currentUserReadOnly}
            cloudTypeId={usersStore.currDispUserCloudAccountType}
            usersStore={usersStore}
          />
        ) : null}
      </>
    );
  };

  const { recommType, currPeriodGranLevel, startDate, endDate, isShowActualCost, isDateRangeError, recTypeDisplay } =
    state;

  const customDashboardModelIsLoading = usageStore?.customDashboardModel?.modelIsLoading;

  const summaryTableData = useMemo(
    () => summaryData?.filter(({ type }) => type === 'total' || mapRecommendationsTypeToDisplay.get(type)),
    [summaryData],
  );

  const { recomms, tableColumns, sortingOptions } = preparedRecommendationsTableData;
  if (currentDisplayedUserType === UsersType.RESELLER) {
    tableColumns.unshift({
      name: 'customerName',
      title: 'Customer',
      getCellValue: (rec) => {
        const { linkedAccountId } = rec;
        const divisionName = mapLinkedAccIdToDivisionName.get(linkedAccountId);
        return getDisplayNameByDivisionName(divisionName);
      },
    });
  }
  const filterValues = getPageFilters(PageNames.RECOMMENDATION_DASHBOARD, usersStore.currDispUserCloudAccountType);
  filterValuesOfRecWithNoMatchingLinkedAccountId(filterValues);
  return (
    <Container>
      <PageHeader title={PageNames.RECOMMENDATION_DASHBOARD} showDate />
      <RecommendationFilterBar
        filterSelectedValues={filterSelectedValues}
        filterValues={filterValues}
        handleApplyFiltersButtonClick={handleApplyFiltersButtonClick}
        usersStore={usersStore}
        invoiceStore={invoiceStore}
      />
      <Card style={{ paddingBottom: '5px' }}>
        <CardBody>
          <hr />
          <Row>
            <Col sm={6} md={6} lg={6} xl={6}>
              <div
                style={{
                  width: '100%',
                  height: '30px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  aligItems: 'flex-start',
                }}
              >
                <div className="d-flex">
                  <h5
                    className="bold-text me-1"
                    style={{
                      height: '30px',
                      'text-align': 'left',
                    }}
                  >
                    SAVINGS OPPORTUNITIES
                  </h5>
                  <Tooltip
                    title="Choose a category below to see more details on the specific savings opportunity"
                    arrow
                    placement="top"
                  >
                    <div>
                      <InformationOutline size={18} />
                    </div>
                  </Tooltip>
                </div>
                {summaryTableData ? (
                  <div style={{ display: 'flex' }}>
                    <CustomCSVDownload
                      filesNumber={1}
                      showDownloadIcon
                      hideText
                      isLoading={false}
                      fetchData={() => exportSummaryAsCsv(summaryTableData)}
                      classNames="btn link"
                    />
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      className="waste-save-btn"
                      style={{ marginLeft: '15px', marginTop: '-2px' }}
                      role="button"
                      tabIndex={0}
                      onClick={(e) => handleSaveDashboardPanelButtonClick(e, 'recomm-summery-table')}
                    >
                      <IconFromPng size="20px" matchHeight icon={ICONS.save} />
                    </a>
                  </div>
                ) : null}
              </div>

              {!summaryTableData || isSummaryDataLoading || isSummaryDataFetching ? (
                <div style={{ height: 100, width: 100, position: 'absolute', zIndex: 1 }}>
                  <Spinner />
                </div>
              ) : (
                <SummaryTable
                  data={summaryTableData}
                  columns={REC_DASHB_SUMMARY_TABLE_COLUMNS}
                  columnWidths={REC_DASHB_SUMMARY_COLUMN_WIDTHS}
                  tableColumnExtensions={REC_DASHB_SUMMARY_TABLE_COLUMN_EXTENSIONS}
                  defaultSorting={REC_DASHB_SUMMARY_DEFAULT_SORTING}
                  currenciesColumns={['savings']}
                  recommTypeSelectHandler={(newRecommType, newRecTypeDisplay) =>
                    recommTypeSelectHandler(newRecommType, newRecTypeDisplay)
                  }
                  currentRecType={recommType}
                  isSelectTypeActive={!isSummaryDataLoading && !isSummaryDataFetching}
                  usersStore={usersStore}
                />
              )}
            </Col>
            <Col sm={6} md={6} lg={6} xl={6} className="recs-dashboard-cloud-wasting-chart">
              <div className="header-container">
                <h5 className="bold-text">CLOUD WASTE</h5>
                {opportunitiesData?.opportunity ? (
                  <div className="waste-chart-btns-container">
                    <CheckBox
                      label="Display Actual Cost"
                      labelClassName="styled-label"
                      onChangeFunc={() => handleToggleIsShowActualCost()}
                      value={isShowActualCost}
                      automationId="displayActualCost"
                    />
                    <a
                      className="waste-save-btn"
                      role="button"
                      tabIndex={0}
                      onClick={(e) => handleSaveDashboardPanelButtonClick(e, 'recomm-cloud-waste')}
                    >
                      <IconFromPng size="20px" matchHeight icon={ICONS.save} />
                    </a>
                  </div>
                ) : null}
              </div>
              {!opportunitiesData?.opportunity ? (
                <div style={{ height: 100, width: 100, position: 'absolute', zIndex: 1 }}>
                  <Spinner />
                </div>
              ) : (
                <div
                  style={{ padding: '10px', margin: '0', width: '100%', maxHeight: '280px' }}
                  className="chart-box-style-no-shadow"
                >
                  <OpportunitiesChart
                    recommendationsOpportunities={opportunitiesData.opportunity}
                    id="id123"
                    invoiceStore={invoiceStore}
                    filterSelectedValues={filterSelectedValues}
                    handleDateChange={handleDateChange}
                    handleChangeGranLevel={handleChangeGranLevel}
                    currPeriodGranLevel={currPeriodGranLevel}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={DateFilter.getPreviousDayDate()}
                    isDateRangeError={isDateRangeError}
                    recommType={recommType}
                    recTypeDisplay={recTypeDisplay}
                    isShowActualCost={isShowActualCost}
                    numStrAbriviaionByDisplayMetric={numStrAbriviaionByDisplayMetric}
                  />
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      {recommType && summaryTableData?.length > 1 && !isSummaryDataLoading && (
        <Card>
          <CardBody>
            <div
              style={{
                width: '100%',
                height: '30px',
                display: 'flex',
                justifyContent: 'space-between',
                aligItems: 'flex-start',
              }}
            >
              <h5
                className="bold-text"
                style={{
                  height: '30px',
                  'text-align': 'left',
                }}
              >
                {`${mapRecommendationsTypeToDisplay.get(recommType) || recommType} Recommendations`}
              </h5>
              {recomms ? (
                <CustomCSVDownload
                  filesNumber={1}
                  showDownloadIcon
                  isLoading={false}
                  data={[
                    {
                      data: prepareCsvModifiedRowsForRecomm(recomms),
                      filename: `${mapRecommendationsTypeToDisplay.get(recommType)}.csv`,
                    },
                  ]}
                  hideText
                />
              ) : null}
            </div>
            {renderRecommendationsTable(recomms, tableColumns, sortingOptions)}
          </CardBody>
        </Card>
      )}
      <NewCustomDashboardPanelModal
        modalIsOpen={newCustomDashboardPanelModalIsOpen}
        getCurrentCauParams={getParamsForCustomDashboard}
        onClose={handleCloseNewCustomDashboardPanelModal}
        customDashboardStore={usageStore?.customDbSubStore}
        existingDashboardsNamesAndIds={
          usageStore?.customDbSubStore?.customDashboardModel?.existingDashboardsNamesAndIds
        }
        helpers={customDashboardHelpers}
        type={currCustomPanelType}
        isCustomDbModelLoading={customDashboardModelIsLoading}
        state={state}
        isSelectTimePeriodEnabled={currCustomPanelType === 'recomm-cloud-waste'}
        usageStore={usageStore}
      />
      <AddOrEditRecLabelModal
        isOpen={isLabelModalOpen()}
        toggle={toggleLabelModal}
        modalType={labelActionType}
        labels={labels}
        onRemoveLabel={handleRemoveLabel}
        onAddLabel={handleAddLabel}
        onLabelSave={handleLabelSaved}
        isLablesFromBatch={isLabelsFromBatch}
      />
      <DeleteWarningModal
        isOpen={labelActionType === LabelActionTypes.REMOVE}
        handleDelete={handleDeleteLabel}
        warningMessage="Be advise you are about to delete the label "
        modalTitle="Delete Label"
      />
    </Container>
  );
};

const propTypes = {
  usersStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  mainDbData: PropTypes.object.isRequired,
  getPageFilters: PropTypes.func.isRequired,
};

RecommendationsDashboard.propTypes = propTypes;
const ObserverRecommendationsDashbaord = withInvoiceFiltersContextConsumer(
  withMainDashboardContextConsumer(observer(RecommendationsDashboard)),
);
export default ObserverRecommendationsDashbaord;
