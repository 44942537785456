import React from 'react';
import { observer } from 'mobx-react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { Badge, Container, Card, CardBody } from 'reactstrap';
import {
  Grid,
  TableHeaderRow,
  PagingPanel,
  TableColumnResizing,
  TableRowDetail,
  Toolbar,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
  IntegratedFiltering,
  DataTypeProvider,
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  RowDetailState,
  SearchState,
} from '@devexpress/dx-react-grid';
import { idleCriteriaRulesColumnsArray, idleCriteriaRulesColumnWidths } from 'usage/constants/usageConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import { alpha } from '@mui/system/colorManipulator';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import TableWrapper from 'shared/components/tables/TableWrapper';
import Button from 'shared/components/andtComponents/Button';
import { useIdleInstances } from 'usage/hooks/react-query/useIdleInstances';
import { useRootStore } from 'app/contexts/RootStoreContext';
import CreateOrEditRuleModal from './CreateOrEditRuleModal';

const styles = () => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha('#646777', 0.01),
    },
  },
});

const RowDetail = ({ row, filtersValuesMap, usageStore }) => {
  const preparedLinkedAccountsOptions = usageStore.getPreparedLinkedAccountsOptions(
    row.linkedAccounts,
    filtersValuesMap.get('linkedaccid'),
  );
  return (
    <div style={{ padding: '10px' }}>
      <ul style={{ display: 'flex', flexDirection: 'row', 'list-style-type': 'none' }}>
        {preparedLinkedAccountsOptions.map((la) => (
          <li style={{ padding: '2px' }}>
            <Badge color="light" pill style={{ border: 'solid 1px #70bbfd', background: '#fff' }}>
              <div style={{ padding: '2px', color: '#70bbfd' }}>
                <span>{la.label}</span>
              </div>
            </Badge>
          </li>
        ))}
      </ul>
    </div>
  );
};

const IdleCriteriaRulesPanel = ({ existingCriteriasNames, filtersValuesMap }) => {
  const { usersStore, usageStore } = useRootStore();
  const pageSizes = [10, 15, 20, 0];
  const [columnWidths, setColumnWidths] = React.useState(idleCriteriaRulesColumnWidths);
  const [changeCriteria, setChangeCriteria] = React.useState(null);
  const [deleteCriteria, setDeleteCriteria] = React.useState(null);
  const { useCreateCriteria, useDeleteCriteria, useUpdateCriteria, fetchIdleInstancesCriteria } = useIdleInstances();
  const { data = [] } = fetchIdleInstancesCriteria();
  const { isLoading: isCreateLoading, mutateAsync: handleCreate } = useCreateCriteria();
  const { mutateAsync: handleDelete } = useDeleteCriteria();
  const { isLoading: isUpdateLoading, mutateAsync: handleUpdate } = useUpdateCriteria();
  const isLoading = isCreateLoading || isUpdateLoading;
  const prepareCurrentEditedCriteriaParamsToState = (criteriaData) => {
    const { criteriaName, maxCpu, maxNetwork, linkedAccounts, criteriaId } = criteriaData;
    const preparedLinkedAccountsOptions = usageStore.getPreparedLinkedAccountsOptions(
      linkedAccounts,
      filtersValuesMap.get('linkedaccid'),
    );
    const linkedAccSelectedOptionsMap = new Map();
    linkedAccSelectedOptionsMap.set('linkedaccid', preparedLinkedAccountsOptions);
    return {
      criteriaId,
      criteriaName,
      maxCpu,
      maxNetwork,
      linkedAccSelectedOptionsMap,
    };
  };
  const handleEditCriteriaClicked = (criteriaData) => {
    const currentEditedCriteriaParams = prepareCurrentEditedCriteriaParamsToState(criteriaData);
    setChangeCriteria(currentEditedCriteriaParams);
  };

  const handleOpenAddRuleModalClick = () => {
    setChangeCriteria({});
  };
  const handleDeleteCriteriaClicked = (id, name) => {
    setDeleteCriteria({ id, name });
  };
  const deleteAlertHandler = async (action) => {
    setDeleteCriteria(null);
    if (action === 'delete') {
      await handleDelete(deleteCriteria.id);
    }
  };
  const handleCreateOrEdit = async ({ criteriaName, maxCpu, maxNetwork, linkedAccSelectedOptionsMap, criteriaId }) => {
    setChangeCriteria(null);
    const params = {
      criteriaName,
      maxCpu: +maxCpu,
      maxNetwork: +maxNetwork,
      linkedAccounts: linkedAccSelectedOptionsMap.get('linkedaccid').map(({ value }) => value),
      criteriaId,
    };
    if (criteriaId) {
      await handleUpdate(params);
    } else {
      await handleCreate(params);
    }
  };

  const userActionsFormatter = (data) => {
    const { currentUserReadOnly } = usersStore;
    return (
      <div className="d-flex gap-2">
        <ReadOnlyDisplayWrapper isHide={false} userReadOnly={currentUserReadOnly}>
          <Button
            text=""
            isTextButton
            icon={() => <GenerateIcon iconName={ICONS.delete.name} />}
            onClick={() => handleDeleteCriteriaClicked(data.row.criteriaId, data.row.criteriaName)}
          />
        </ReadOnlyDisplayWrapper>
        <ReadOnlyDisplayWrapper isHide={false} userReadOnly={currentUserReadOnly}>
          <Button
            isTextButton
            text=""
            icon={() => <GenerateIcon iconName={ICONS.edit.name} />}
            onClick={() => handleEditCriteriaClicked(data.row)}
          />
        </ReadOnlyDisplayWrapper>
      </div>
    );
  };

  const renderTable = (idleInstancesCriterias) => (
    <Grid rows={idleInstancesCriterias} columns={idleCriteriaRulesColumnsArray}>
      <SortingState defaultSorting={[{ columnName: 'usageDate', direction: 'desc' }]} />
      <PagingState defaultCurrentPage={0} />
      <SearchState />
      <RowDetailState />
      <IntegratedFiltering />
      <IntegratedSorting />
      <IntegratedPaging />

      <DataTypeProvider for={['userAction']} formatterComponent={userActionsFormatter} />

      <TableWrapper styles={styles} />
      <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
      <TableHeaderRow showSortingControls />
      <TableRowDetail
        contentComponent={({ row }) => (
          <RowDetail row={row} filtersValuesMap={filtersValuesMap} usageStore={usageStore} />
        )}
      />
      <PagingPanel pageSizes={pageSizes} />
      <Toolbar />
      <SearchPanel />
    </Grid>
  );

  const renderTableOrNoCriteriaMessage = (idleInstancesCriterias) => {
    if (!idleInstancesCriterias || !idleInstancesCriterias.length) {
      return (
        <div className="criteria-form-card description-container">
          <h5 className="idle-instances-description">
            In order to present the Idle Instances, please set a criteria first. The criteria contains three parameters
            - Max CPU Utilization Limit Percent, Max Network In & Out Limit and Linked Accounts. Once all parameters are
            selected and submitted, Instance monitoring will be available from the next data processing.
          </h5>
        </div>
      );
    }
    return renderTable(idleInstancesCriterias);
  };

  if (isLoading) {
    return (
      <Container>
        <Card>
          <CardBody>
            <Spinner />
          </CardBody>
        </Card>
      </Container>
    );
  }
  return (
    <Container>
      <Card>
        <CardBody>
          <Button
            onClick={handleOpenAddRuleModalClick}
            text="Create Idle Criteria Rule"
            icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
          />
          {renderTableOrNoCriteriaMessage(data)}
        </CardBody>
      </Card>
      {changeCriteria && (
        <CreateOrEditRuleModal
          onClose={() => setChangeCriteria(null)}
          handleSubmit={handleCreateOrEdit}
          criteria={changeCriteria.criteriaId ? changeCriteria : null}
          existingCriteriasNames={existingCriteriasNames}
        />
      )}
      <DeleteWarningModal
        deletedItemName={deleteCriteria?.name || ''}
        isOpen={!!deleteCriteria}
        handleDelete={deleteAlertHandler}
        warningMessage="Be advise you are about to delete a"
        modalTitle="Delete Criteria"
      />
    </Container>
  );
};

export default withInvoiceFiltersContextConsumer(observer(IdleCriteriaRulesPanel));
