import React from 'react';
import { observer } from 'mobx-react';
import { Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import ColoredTabs from 'shared/components/tabs/ColoredTabs';
import { TABS_IDS } from 'users/constants/usersConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import { ICONS } from '@pileus-cloud/anodot-frontend-common';
import Users from './Users/Users';
import Roles from './Roles/Roles';

const Organization = ({ usersStore, location, ...props }) => {
  if (usersStore.isLoading) {
    return <Spinner />;
  }

  const navObjectsMap = new Map([
    // [TABS_IDS.COST_CENTER, { id: 0, name: 'Cost Centers', title: '', icon: 'listTree' }],
    [TABS_IDS.USERS, { id: 0, name: 'Users', title: '', icon: ICONS.mainUserRegular.name }],
    [TABS_IDS.ROLES, { id: 1, name: 'Roles', title: '', icon: ICONS.userGear.name }],
  ]);
  const componentsMap = new Map([
    // [TABS_IDS.COST_CENTER, () => 'New Cost Centers'],
    [TABS_IDS.USERS, Users],
    [TABS_IDS.ROLES, Roles],
  ]);
  const getArrOfComponents = (tabOrderArray) =>
    tabOrderArray.map((tabId) => {
      const C = componentsMap.get(tabId);
      return (
        <TableHeaderProvider>
          <C key={tabId} {...props} usersStore={usersStore} location={location} />
        </TableHeaderProvider>
      );
    });
  return (
    <Container>
      <PageHeader title={PageNames.ORGANIZATION} />
      <Row>
        <ColoredTabs
          arrOfNavItems={[...navObjectsMap.values()]}
          defaultAccountIndex={
            location.state?.defaultTabId
              ? [...navObjectsMap.keys()].indexOf(location.state?.defaultTabId)
              : location.defaultAccountIndex || location.state?.defaultAccountIndex
          }
        >
          {getArrOfComponents([...componentsMap.keys()])}
        </ColoredTabs>
      </Row>
    </Container>
  );
};

Organization.propTypes = {
  history: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
};

export default observer(Organization);
