import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { SORT_BY_OPTIONS } from 'recommendationsNew/consts';
import { useHeatMapContext } from 'recommendationsNew/components/heatMap/contexts/heatMapContext';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import OptionsSwitchButton from '../shared/optionsSwitchButton';
import useGroupByOptions from '../../hooks/react-query/useGroupByOptions';

import classes from './heatMap.module.scss';

const HeatMapDimensions = ({ onGroupByOptionClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dimensionsPanelLabel = 'Add Dimensions';

  const recommendationsGroupByOptionsHook = useGroupByOptions();
  const recommendationsGroupByOptions = recommendationsGroupByOptionsHook.fetchGroupByOptions();
  const { data: groupByOptionsData } = recommendationsGroupByOptions;

  const {
    heatMapSortByOption: sortByOptionContext,
    setHeatMapSortByOption,
    heatMapGroupByOptions: groupByOptionsContext,
  } = useHeatMapContext();

  const dimensionsPanelOptions = useMemo(() => {
    if (!groupByOptionsData?.length) {
      return null;
    }
    return groupByOptionsData?.filter((property) => !groupByOptionsContext?.find((p) => p.id === property.id));
  }, [groupByOptionsContext]);

  const switchButtonTooltipContent = (
    <>
      <b>Potential Savings or Actual Savings</b>
      <p>Depends on the selected status Open/Completed</p>
    </>
  );

  return (
    <>
      <div className={`${classes.dimensionsPanelContainer} ${isOpen ? classes.visible : classes.hidden}`}>
        <div className={classes.dimensionsPanelContent}>
          <div className={classes.groupByOptions}>
            <div className={classes.addPropertyLabel}>{dimensionsPanelLabel}</div>
            <div className={classes.addPropertiesContainer}>
              {dimensionsPanelOptions?.map((option) => (
                <div
                  key={option.id}
                  className={classes.addPropertyName}
                  onClick={() => onGroupByOptionClick(option)}
                  automation-id={`heatmap-${option.name}-add-dimensions-panel`}
                >
                  {option.name}
                </div>
              ))}
            </div>
          </div>
          <Tooltip placement="left" title={switchButtonTooltipContent}>
            <div>
              <OptionsSwitchButton
                automation-id="heatmap-sort-by-add-dimensions-panel"
                label={SORT_BY_OPTIONS.title}
                optionOne={SORT_BY_OPTIONS.items.SAVINGS}
                optionTwo={SORT_BY_OPTIONS.items.RECS_COUNT}
                selectedOption={sortByOptionContext}
                toggleOption={(newSortByOption) => {
                  setHeatMapSortByOption(newSortByOption);
                }}
              />
            </div>
          </Tooltip>
        </div>
      </div>

      <Tooltip placement="left" title={dimensionsPanelLabel} arrow disabled={!!isOpen}>
        <div
          className={classes.dimensionsPanelOpener}
          automation-id="heatmap-dimensions"
          onClick={() => setIsOpen((prevState) => !prevState)}
        >
          <GenerateIcon iconName={isOpen ? ICONS.chevronRight.name : ICONS.plus.name} />
        </div>
      </Tooltip>
    </>
  );
};

HeatMapDimensions.propTypes = {
  onGroupByOptionClick: PropTypes.func.isRequired,
};

export default HeatMapDimensions;
