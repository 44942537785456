import React, { useState } from 'react';
import Button from 'shared/components/andtComponents/Button';
import { GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import AssignRolesModal from '../userModals/AssignRolesModal/AssignRolesModal';
import RemoveUserRole from './RemoveUserRole';
import { Search } from '../../components/Search';
import { USER_ACTIONS } from '../../consts';

import styles from '../UsersHeader.module.scss';

const UserRolesTabHeader = ({ rolesCount, selectedUserRoles, searchText, setSearchText }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        <div className={styles.header}>
          <div className={styles.title}>
            Roles <span className={styles.count}>({rolesCount})</span>
          </div>
        </div>
      </div>
      <div className={styles.headerRight}>
        {selectedUserRoles?.length > 0 ? (
          <RemoveUserRole isMultiple selectedCounter={selectedUserRoles?.length} />
        ) : (
          <div />
        )}
        <Button
          text={USER_ACTIONS.ASSIGN_ROLES.label}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          disabled={false}
          icon={() => <GenerateIcon iconName={USER_ACTIONS.ASSIGN_ROLES.icon} />}
          automationId="assignRole"
        />
        <AssignRolesModal isOpen={isOpen} setIsOpen={setIsOpen} />
        <Search searchText={searchText} setSearchText={setSearchText} />
      </div>
    </div>
  );
};

export default UserRolesTabHeader;
