import React from 'react';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common/dist';
import PropTypes from 'prop-types';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import { palette } from 'shared/constants/colorsConstants';

import styles from './ErrorDetails.module.scss';

const ErrorDetails = ({ emailErrors }) => {
  if (!emailErrors?.length) {
    return <div />;
  }
  return (
    <>
      {emailErrors.length <= 3 ? (
        <Popover>
          <PopoverTrigger>
            <div className={styles.errorMessage}>
              <GenerateIcon iconName={ICONS.triangleExclamation.name} className={styles.errorIcon} />
              <div>View error details</div>
              <GenerateIcon iconName={ICONS.chevronDown.name} className={styles.errorIcon} />
            </div>
          </PopoverTrigger>
          <PopoverContent className={styles.popoverContent}>
            <div className={styles.errorContent}>
              <div className={styles.errorsTitle}>
                {emailErrors.length} error{emailErrors.length === 1 ? ' was' : 's were'} found:
              </div>
              {emailErrors?.map((userEmailError) => (
                <div key={userEmailError} className={styles.errorEmail}>
                  <div>{userEmailError.email}</div>
                  <div className="d-flex align-items-center gap-2">
                    <GenerateIcon iconName={ICONS.triangleExclamation.name} className={styles.error} />
                    <div className={styles.error}>{userEmailError.error}</div>
                  </div>
                </div>
              ))}
            </div>
          </PopoverContent>
        </Popover>
      ) : (
        <div>
          <CustomCSVDownload
            showDownloadIcon
            hideText
            filesNumber={1}
            data={[{ data: emailErrors, filename: 'UserEmailErrors.csv' }]}
            headers={['email', 'error']}
            isLoading={false}
            style={{ color: palette.red[400], fontSize: 14, padding: 0 }}
            className={styles.downloadErrors}
          >
            <div>Download Error Details</div>
          </CustomCSVDownload>
        </div>
      )}
    </>
  );
};

ErrorDetails.props = {
  userEmailErrors: PropTypes.array,
};

export default ErrorDetails;
