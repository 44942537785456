import React from 'react';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';

import Button from 'shared/components/andtComponents/Button';
import OnboardingLinks from 'app/containers/Onboarding/utils/OnboardingLinks';

import { ReactComponent as RocketIcon } from './assets/rocket.svg';
import styles from './AccountDetailsModal.module.scss';

const AccountDetailsHelp = {};

AccountDetailsHelp.OnboardingType = {
  component: () => (
    <>
      <div className={styles.onboardingTypeLabel}>
        <p>
          <RocketIcon className={styles.rocketIcon} />
          Automatically
        </p>
        <span>Run scripts automatically in order to grant access.</span>
        <Button
          onClick={() => window.open(OnboardingLinks.GCP.onboardingType, '_blank')}
          text="Learn more"
          isTextButton
          overrideStyles={{ height: 20 }}
        />
      </div>
      <div className={styles.onboardingTypeLabel}>
        <p>
          <GenerateIcon iconName={ICONS.gear.name} />
          Manually
        </p>
        <span>Grant Anodot access to your Cloud accounts and update each script manually.</span>
        <Button
          text="Learn more"
          onClick={() => window.open(OnboardingLinks.GCP.onboardingType, '_blank')}
          isTextButton
          overrideStyles={{ height: 20 }}
        />
      </div>
    </>
  ),
};

AccountDetailsHelp.MSPAccountType = {
  component: () => (
    <div className={styles.mspAccountTypeHelp}>
      <p className={styles.mspAccountTypeHelpTitle}>
        <GenerateIcon iconName={ICONS.mainUser.name} />
        Dedicated Account
      </p>
      <p>
        This is for a single customer where all of the projects will be associated with him. If this is not the case,
        please select Shared Account.
      </p>
      <Button
        onClick={() => window.open(OnboardingLinks.GCP.customerType, '_blank')}
        text="Learn more"
        isTextButton
        overrideStyles={{ paddingLeft: 0, height: 20 }}
      />
      <p className={styles.mspAccountTypeHelpTitle}>
        <GenerateIcon iconName={ICONS.users.name} />
        Shared Account
      </p>
      <p>This is for multiple customers, every project must be manually assigned to the relevant customer.</p>
      <Button
        onClick={() => window.open(OnboardingLinks.GCP.customerType, '_blank')}
        text="Learn more"
        isTextButton
        overrideStyles={{ paddingLeft: 0, height: 20 }}
      />
    </div>
  ),
};

export default AccountDetailsHelp;
