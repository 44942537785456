import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import CustomModal from 'shared/components/andtComponents/Modal';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import { ReactComponent as AZUREIcon } from 'shared/img/cloud-providers/simple/azure.svg';
import { ReactComponent as CloudsDimmed } from 'app/containers/OnboardingWelcome/assets/clouds-dimmed.svg';
import onboardingStyles from 'app/containers/Onboarding/SharedPages/Footer/Footer.module.scss';
import { ONBOARDING_TYPES } from 'app/containers/Onboarding/utils/OnboardingConstants';
import ListWithHelp from 'shared/components/andtComponents/ListWithHelp/ListWithHelp';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import { Routes } from 'shared/constants/routes';
import Select from 'shared/components/andtComponents/SimpleSelect';
import { ReactComponent as ManualImg } from './assets/manual.svg';
import { ReactComponent as AutomaticImg } from './assets/automatic.svg';
import { AZURE_ACCOUNT_TYPES, AZURE_ONBOARDING_FIELDS } from '../../AzureOnboardingConstants';
import AccountDetailsHelp from './AccountDetailsHelp';
import styles from './AccountDetailsModal.module.scss';

const AccountDetailsModal = ({ nextStep }) => {
  const {
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const accountType = getValues(AZURE_ONBOARDING_FIELDS.ACCOUNT_TYPE);
  const autoCreateCustomersProperty = getValues(AZURE_ONBOARDING_FIELDS.AUTO_CREATE_CUSTOMERS_PROPERTY);
  const setAccountType = (value) => {
    setValue(AZURE_ONBOARDING_FIELDS.ACCOUNT_TYPE, value);
    if (value === 'shared') {
      setValue(AZURE_ONBOARDING_FIELDS.RESELLER_CUSTOMER, '');
    }
  };
  const onboardingType = getValues(AZURE_ONBOARDING_FIELDS.ONBOARDING_TYPE);
  const setOnboardingType = (value) => {
    setValue(AZURE_ONBOARDING_FIELDS.ONBOARDING_TYPE, value);
  };
  const azureAccountType = getValues(AZURE_ONBOARDING_FIELDS.AZURE_ACCOUNT_TYPE);
  const setAzureAccountType = (value) => {
    setValue(AZURE_ONBOARDING_FIELDS.AZURE_ACCOUNT_TYPE, value);
  };
  const customer = getValues(AZURE_ONBOARDING_FIELDS.RESELLER_CUSTOMER);
  const setCustomer = (value) => setValue(AZURE_ONBOARDING_FIELDS.RESELLER_CUSTOMER, value);
  const autoAssignLinkedAccounts = getValues(AZURE_ONBOARDING_FIELDS.AUTO_ASSIGN_LINKED_ACCOUNTS);
  const setAutoAssignLinkedAccounts = (value) => setValue(AZURE_ONBOARDING_FIELDS.AUTO_ASSIGN_LINKED_ACCOUNTS, value);
  const renderSelectOnboardingType = () => (
    <div>
      <p className={styles.subTitle}>How would you like to complete the onboarding?</p>
      <div className={styles.selectBlock} data-onboarding="true">
        <RadioButtonList
          options={[
            {
              value: ONBOARDING_TYPES.AUTOMATIC,
              key: 1,
              label: (
                <span>
                  Automatically <i>Coming Soon!</i>
                </span>
              ),
              primary: true,
              disabled: true,
            },
            {
              value: ONBOARDING_TYPES.MANUAL,
              key: 2,
              label: 'Manually',
              primary: true,
            },
          ]}
          value={onboardingType}
          onChange={(val) => {
            setOnboardingType(val);
          }}
        />
        <div className={styles.onboardingTypeIllustration}>
          {onboardingType === ONBOARDING_TYPES.MANUAL ? <ManualImg /> : <AutomaticImg />}
        </div>
      </div>
    </div>
  );

  const renderFilterOutService365 = () => (
    <div>
      <p className={styles.subTitle}>Azure Services</p>
      <div className={styles.checkboxBox}>
        <Checkbox
          primary
          className={styles.checkbox}
          isChecked={!!getValues(AZURE_ONBOARDING_FIELDS.FILTER_OUT_MICROSOFT_365)}
          onChange={() => {
            setValue(
              AZURE_ONBOARDING_FIELDS.FILTER_OUT_MICROSOFT_365,
              !getValues(AZURE_ONBOARDING_FIELDS.FILTER_OUT_MICROSOFT_365),
            );
          }}
          text="Exclude Microsoft 365 and Software Subscriptions records"
        />
      </div>
    </div>
  );
  const renderSelectAzureAccountType = () => (
    <div>
      <p className={styles.subTitle}>Select your Azure billing account type</p>
      <div className={styles.selectBlock}>
        <RadioButtonList
          options={[
            {
              value: AZURE_ACCOUNT_TYPES.MCA_EXPORT,
              key: 1,
              label: 'MCA',
              primary: true,
            },
            {
              value: AZURE_ACCOUNT_TYPES.CSP_EXPORT,
              key: 2,
              label: 'CSP',
              primary: true,
            },
            {
              value: AZURE_ACCOUNT_TYPES.PAYG_EXPORT,
              key: 3,
              label: 'Pay as you go',
              primary: true,
            },
            {
              value: AZURE_ACCOUNT_TYPES.EA_EXPORT,
              key: 4,
              label: 'EA',
              primary: true,
            },
          ]}
          value={azureAccountType}
          onChange={(val) => {
            setAzureAccountType(val);
          }}
        />
      </div>
    </div>
  );

  const renderSelectMSPAccountType = () => (
    <div>
      <p className={styles.subTitle}>Which type of account do you want?</p>
      <div className={styles.selectBlock}>
        <div className={styles.accountTypes}>
          <div className={classNames(styles.accountTypeBlock, { [styles.active]: accountType === 'dedicated' })}>
            <div className={styles.accountTypeHeader}>
              <RadioButtonList
                options={[{ value: 'dedicated', label: 'Dedicated Account', primary: true }]}
                value={accountType}
                onChange={(val) => {
                  setAccountType(val);
                }}
              />
            </div>
            <div className={styles.accountTypeBody}>
              <div className={styles.customerInfo} data-disabled={accountType !== 'dedicated'}>
                <label htmlFor="customer-name">Customer Name:</label>
                <Input
                  name="customer-name"
                  value={customer}
                  placeholder="Type name"
                  isInvalid={!!errors[AZURE_ONBOARDING_FIELDS.RESELLER_CUSTOMER]}
                  invalidComponent={errors[AZURE_ONBOARDING_FIELDS.RESELLER_CUSTOMER]}
                  onChange={(e) => {
                    setCustomer(e.target.value);
                  }}
                  className={styles.input}
                />
                <Checkbox
                  className={styles.checkbox}
                  isChecked={!!autoAssignLinkedAccounts}
                  onChange={() => setAutoAssignLinkedAccounts(!autoAssignLinkedAccounts)}
                  text="Auto assign subscriptions to the customer"
                />
              </div>
            </div>
          </div>
          <div className={classNames(styles.accountTypeBlock, { [styles.active]: accountType === 'shared' })}>
            <div className={styles.accountTypeHeader}>
              <RadioButtonList
                options={[{ value: 'shared', label: 'Shared Account', primary: true }]}
                value={accountType}
                onChange={(val) => {
                  setAccountType(val);
                }}
              />
            </div>
            <div className={styles.accountTypeBody}>
              {azureAccountType === AZURE_ACCOUNT_TYPES.CSP_EXPORT ? (
                <div className={styles.customerInfo} data-disabled={accountType !== 'shared'}>
                  <RadioButtonList
                    className={styles.radioSecondary}
                    options={[{ value: 'automatic', label: 'Create customers automatically using Azure logic' }]}
                    value={autoCreateCustomersProperty ? 'automatic' : ''}
                    onChange={() => {
                      setValue(AZURE_ONBOARDING_FIELDS.AUTO_CREATE_CUSTOMERS_PROPERTY, 'customer_id');
                    }}
                  />
                  <div data-disabled={!autoCreateCustomersProperty}>
                    <p>Create and group the customer by:</p>
                    <Select
                      isClearable={false}
                      placeholder="Select field"
                      name="autoCreateCustomersProperty"
                      value={autoCreateCustomersProperty || undefined}
                      options={[
                        { value: 'customer_id', label: 'Customer tenant ID' },
                        { value: 'customer_name', label: 'Customer name' },
                      ]}
                      onChange={(value) => {
                        setValue(AZURE_ONBOARDING_FIELDS.AUTO_CREATE_CUSTOMERS_PROPERTY, value);
                      }}
                      className={styles.input}
                    />
                  </div>
                  <RadioButtonList
                    className={styles.radioSecondary}
                    options={[{ value: 'manual', label: 'Create customers manually' }]}
                    value={!autoCreateCustomersProperty ? 'manual' : ''}
                    onChange={() => {
                      setValue(AZURE_ONBOARDING_FIELDS.AUTO_CREATE_CUSTOMERS_PROPERTY, '');
                    }}
                  />
                </div>
              ) : (
                <Link
                  className={styles.link}
                  to={{
                    pathname: Routes.CUSTOMERS_MANAGE,
                  }}
                >
                  <GenerateIcon iconName={ICONS.users.name} />
                  Go to <span>Manage Customers</span>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.background}>
      <div className={styles.bgFooter}>
        <CloudsDimmed className={styles.bgClouds} />
      </div>
      <CustomModal
        open
        closeOnOutside={false}
        overrideStyles={{
          width: 968,
        }}
        size="big"
        onClose={() => {}}
        className={{
          container: styles.modalContainer,
          modal: `${styles.modalBody} ${getValues(AZURE_ONBOARDING_FIELDS.IS_MSP) ? '' : styles.direct}`,
          title: styles.modalTitle,
        }}
        closeOnSave={false}
        title={
          <div className={styles.header}>
            <div className={styles.headerContent}>Account details and onboarding experience</div>
            <div className={styles.headerLogo}>
              <AZUREIcon />
            </div>
          </div>
        }
        footerDisabled
      >
        <div className={styles.body}>
          <ListWithHelp
            styles={{
              listGap: '24px',
              helpWidth: 410,
            }}
            help={{
              'onboarding-type': AccountDetailsHelp.OnboardingType,
              'azure-account-type': AccountDetailsHelp.AzureAccountType,
              'msp-account-type':
                azureAccountType === AZURE_ACCOUNT_TYPES.CSP_EXPORT
                  ? AccountDetailsHelp.CPSAccountType
                  : AccountDetailsHelp.MSPAccountType,
              'azure-filter-out-service-365': AccountDetailsHelp.FilterOutService365,
            }}
            list={[
              {
                render: renderSelectOnboardingType,
                key: 'onboarding-type',
              },
              {
                render: renderSelectAzureAccountType,
                key: 'azure-account-type',
              },
              // TODO: enable when backend ready
              // {
              //   render: renderFilterOutService365,
              //   key: 'azure-filter-out-service-365',
              // },
              {
                render: renderSelectMSPAccountType,
                key: 'msp-account-type',
                disabled: !getValues(AZURE_ONBOARDING_FIELDS.IS_MSP),
              },
            ]}
          />
        </div>
        <div className={onboardingStyles.footer}>
          <div />
          <div className={onboardingStyles.footerMenu}>
            <Button
              onClick={async () => {
                await nextStep();
              }}
              text="Next"
              iconPlacement="right"
              icon={() => <GenerateIcon iconName={ICONS.chevronRight.name} />}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

AccountDetailsModal.propTypes = {
  nextStep: PropTypes.func.isRequired,
};

export default AccountDetailsModal;
