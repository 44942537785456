import React, { useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { StatusSelection } from './statusSelection';

import classes from './statusOptionTemplate.module.scss';

export const StatusOptionTemplate = ({
  option,
  isSelected,
  onOptionSelect,
  onExpand,
  isExpandable,
  hideSelection,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(isExpandable && isSelected);

  const childrenWithProp = () =>
    isExpanded
      ? React.Children.map(children, (child) => React.cloneElement(child, { ...child.props, isExpanded }))
      : null;

  const onOptionClick = () => {
    setIsExpanded(true);
    onOptionSelect(option);
  };
  const onExpandClick = () => {
    const newIsExpanded = !isExpanded;
    setIsExpanded(newIsExpanded);
    onExpand(option, newIsExpanded);
  };

  return (
    <div className={classes.statusFilterTitleAndSelectContainer}>
      {!hideSelection ? (
        <StatusSelection
          isSelected={isSelected}
          onClick={onOptionClick}
          automationId={`status-filter-selection-for-${option.id}-option`}
        />
      ) : (
        <div />
      )}
      <div className={classes.optionContainer}>
        <div className={classes.titleContainer}>
          <div>{option.label}</div>
          {isExpandable ? (
            <GenerateIcon
              iconName={isExpanded ? ICONS.chevronUp.name : ICONS.chevronDown.name}
              className={classes.arrowIcon}
              onClick={onExpandClick}
              automation-id={`status-filter-expand-button-${option.id}-option`}
            />
          ) : null}
        </div>
        <div className={classes.filtersContainer}>{isExpandable ? childrenWithProp() : children}</div>
      </div>
    </div>
  );
};
