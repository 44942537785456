import React from 'react';
import { ReactComponent as SlackIcon } from 'shared/img/icons/slackIcon.svg';
import { ReactComponent as JiraIcon } from 'shared/img/icons/jira.svg';

export const shouldIncludeRecipient = (searchText, recipient, channel) =>
  recipient.recipientData.name?.toLowerCase().includes(searchText.toLowerCase()) ||
  channel.name.toLowerCase().includes(searchText.toLowerCase()) ||
  channel.channelType.toLowerCase().includes(searchText.toLowerCase());

export const titleByChannelType = {
  SENDGRID: 'Email',
  SLACK: 'Slack',
  JIRA_CLOUD: 'Jira',
};

export const iconByChannelType = {
  SLACK: React.createElement(SlackIcon),
  JIRA_CLOUD: React.createElement(JiraIcon),
};
