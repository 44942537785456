/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Switch.module.scss';

const propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  eventKey: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  automationId: PropTypes.string,
};
const defaultProps = {
  eventKey: null,
  isDisabled: false,
  label: '',
  className: '',
  style: {},
  automationId: '',
};

let globalSwitchIndex = 0;

const SwitchButton = ({ isChecked, onChange, eventKey, isDisabled, label, className, style, automationId }) => {
  const [switchIndex, setSwitchIndex] = useState(0);

  useEffect(() => {
    setSwitchIndex(globalSwitchIndex);
    globalSwitchIndex += 1;
  }, []);

  const toggleChange = useCallback(
    (e) => {
      if (isDisabled) {
        return;
      }
      if (eventKey) {
        onChange({ eventKey, value: !isChecked }, e);
      } else {
        onChange(!isChecked, e);
      }
    },
    [eventKey, onChange, isChecked, isDisabled],
  );

  return (
    <div
      className={`${styles.wrapper} ${className}`}
      style={{
        ...style,
        pointerEvents: isDisabled ? 'none' : 'auto',
      }}
    >
      <input
        automationId={automationId}
        type="checkbox"
        checked={isChecked}
        onChange={() => {}}
        className={styles.toggle}
        id={`switch-${switchIndex}`}
        disabled={isDisabled}
        onClick={toggleChange}
      />
      <span onClick={toggleChange} />
      {label && <label htmlFor={`switch-${switchIndex}`}>{label}</label>}
    </div>
  );
};

SwitchButton.propTypes = propTypes;
SwitchButton.defaultProps = defaultProps;

export default SwitchButton;
