import React, { useEffect, useState } from 'react';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import { spAnalyzerContext } from 'commitment/containers/spAnalyzerNew/contexts/spAnalyzerContext';
import styles from './recommPlanDetails.module.scss';
import RecommendedPlanCube from './RecommendedPlanCube';

const RecommendedPlanDetails = ({ selectedPlan, setSelectedPlan }) => {
  const { preferences, recommendationPlanData: recPlanData, planDataByKey } = spAnalyzerContext();
  const [preferencePlanKey, setPreferencePlanKey] = useState(null);
  const recPerPlan = recPlanData?.recommendationPerPlan || {};

  const shareProperties = {
    type: recPlanData?.savingsPlanType,
    currentMonthlyOdCost: recPlanData?.currentMonthlyOdCost,
    currentMonthlyNetCost: recPlanData?.currentMonthlyNetCost,
    simulatedCurrentMonthlyNetCost: recPlanData?.simulatedCurrentMonthlyNetCost,
    simulatedCurrentMonthlyOdCost: recPlanData?.simulatedCurrentMonthlyOdCost,
  };

  useEffect(() => {
    const preferenceKey = Object.keys(recPerPlan).find(
      (key) =>
        recPerPlan[key]?.term === preferences?.term &&
        recPerPlan[key]?.paymentOption.toLowerCase() === preferences?.paymentOption.toLowerCase(),
    );
    setPreferencePlanKey(preferenceKey);
    setSelectedPlan(preferenceKey);
  }, [recPerPlan]);
  return (
    <div className={styles.detailsContainer}>
      <div className={styles.detailsWrapper}>
        {Object.keys(recPerPlan || {})
          .sort()
          .map((key) => (
            <RecommendedPlanCube
              plan={planDataByKey?.[key] || recPerPlan[key]}
              planKey={key}
              preferencePlanKey={preferencePlanKey}
              shareProperties={shareProperties}
              selectedKey={selectedPlan}
              setSelectedPlan={(key) => {
                if (recPerPlan[key]) {
                  setSelectedPlan(key);
                }
              }}
              automationId={`${key}-plan-cube`}
            />
          ))}
      </div>
      <div className={styles.centerContainer}>
        <GenerateIcon iconName={ICONS.caretDown.name} className={styles.arrowIcon} />
      </div>
    </div>
  );
};

export default RecommendedPlanDetails;
