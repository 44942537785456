import { Container } from 'reactstrap';
import React from 'react';
import ColoredTabs from 'shared/components/tabs/ColoredTabs';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { SP_TABS } from 'commitment/containers/spAnalyzerNew/utils/consts';
import RecommendedPlan from './components/RecommendedPlan';
import { SpAnalyzerProvider } from './contexts/spAnalyzerContext';
import comingSoonImg from './img/coming-soon.gif';
import SpSpecialMode from './components/SpSpecialMode';

const SpAnalyzerNew = () => (
  <Container>
    <PageHeader showDate title={PageNames.SAVINGS_PLANS_ANALYZER} />
    <div>
      <SpAnalyzerProvider>
        <ColoredTabs arrOfNavItems={SP_TABS} defaultAccountIndex={SP_TABS[0].id} automation-id="test">
          <RecommendedPlan />
          <SpSpecialMode iconSrc={comingSoonImg} specialText="Coming Soon!" type="comingSoon" />
        </ColoredTabs>
      </SpAnalyzerProvider>
    </div>
  </Container>
);

export default SpAnalyzerNew;
