import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import {
  BASE_PROPERTIES,
  COST_PROPERTIES,
  RDS_PROPERTIES,
  OPTIONS_PROPERTIES,
} from '../../recommendationPropertyUtils';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationOptionsPanel from '../../recommendationGenericComponents/recommendationOptionsPanel';
import RecommendationOptionsContext from '../../recommendationGenericComponents/recommendationOptionsContext';
import OtherResourceIdRecommendations from '../../recommendationGenericComponents/otherResourceIdRecommendations';
import RecommendationCommand, { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';

const Ec2VersionUpgrade = ({ recommendation }) => {
  const { selectedOptionIndex } = useContext(RecommendationOptionsContext);

  const isSeparateResource =
    (recommendation?.resourceId || recommendation?.resourceName) && isEmptyArray(recommendation?.resources);

  const properties = { ...RDS_PROPERTIES, ...COST_PROPERTIES, ...BASE_PROPERTIES };

  const RECOMMENDATION_OPTION_DATA = [
    properties.RECOMMENDED_INSTANCE_TYPE,
    properties.PHYSICAL_PROCESSOR,
    properties.MEMORY,
    properties.STORAGE,
    properties.VCPU,
  ];

  const CURRENT_PROPERTIES = [
    properties.CURRENT_INSTANCE_TYPE,
    properties.PHYSICAL_PROCESSOR,
    properties.CURRENT_INSTANCE_TYPE_FAMILY,
    properties.CURRENT_ANNUAL_COST,
  ];

  const ALTERNATIVE_PROPERTIES = [
    ...RECOMMENDATION_OPTION_DATA,
    properties.RECOMMENDED_INSTANCE_TYPE_FAMILY,
    properties.RECOMMENDED_ANNUAL_COST,
    OPTIONS_PROPERTIES.POTENTIAL_SAVINGS,
  ];

  const topThreeAlternatives = recommendation?.recData?.alternatives?.slice(0, 3);

  const optionsPanelComponent =
    Array.isArray(topThreeAlternatives) && topThreeAlternatives.length ? (
      <RecommendationOptionsPanel
        alternatives={topThreeAlternatives}
        currentProperties={CURRENT_PROPERTIES}
        alternativeProperties={ALTERNATIVE_PROPERTIES}
        recommendation={recommendation}
      />
    ) : null;

  const description =
    `AWS introduces different types of instances according to application loads, memory, ` +
    `storage and networking capacity to offer better performance. We recommend you to use the ` +
    `latest generation of instances to get the best performance and pricing.`;

  const insideLookComponent = <RecommendationInsideLook description={description} />;

  const otherResourceIdRecComponent = recommendation.resourceId ? (
    <OtherResourceIdRecommendations resourceId={recommendation.resourceId} />
  ) : null;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} />
  );

  const cliInstructions = [
    {
      actionText: 'Modify grub.cfg file',
      actionCommand:
        `sudo vim /boot/grub/grub.cfg add nvme.io_timeout=4294967295 parameter in grub configuration. ` +
        `EXAMPLE: linux /boot/vmlinuz-4.4.0-1057-aws root=UUID=60d051e7-7afa-41f2-1e3-df75bd6ff8bb ` +
        `ro console=tty1 console=ttyS0 nvme.io_timeout=4294967295`,
    },
    {
      actionText: 'Stop instance',
      actionCommand: `aws ec2 stop-instances --instance-id ${
        isSeparateResource ? recommendation.resourceId : 'RESOURCE_ID'
      }`,
    },
    {
      actionText: 'Enabling Enhanced Networking',
      actionCommand: `aws ec2 modify-instance-attribute --instance-id ${
        isSeparateResource ? recommendation.resourceId : 'RESOURCE_ID'
      } --ena-support`,
    },
    {
      actionText: `Change instance type to ${properties.RECOMMENDED_INSTANCE_TYPE_FAMILY.getterFunction(
        recommendation,
        selectedOptionIndex,
      )} type`,
      actionCommand: `aws ec2 modify-instance-attribute --instance-id ${
        isSeparateResource ? recommendation.resourceId : 'RESOURCE_ID'
      } --instance-type "{ \\"Value\\": \\"${properties.RECOMMENDED_INSTANCE_TYPE.getterFunction(
        recommendation,
        selectedOptionIndex,
      )}\\" }"`,
    },
    {
      actionText: 'Start the instance',
      actionCommand: `aws ec2 start-instances --instance-id ${
        isSeparateResource ? recommendation.resourceId : 'RESOURCE_ID'
      }`,
    },
  ];

  const consoleInstructionsComponent = [
    {
      actionText: 'Changing EC2 Instance Type using the AWS Console',
      actionLink: 'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-instance-resize.html',
    },
  ];

  const commandsList = [
    { instructions: cliInstructions, type: COMMAND_TYPES.CLI, comment: '', commentUrl: '' },
    { instructions: consoleInstructionsComponent, type: COMMAND_TYPES.CONSOLE, comment: '', commentUrl: '' },
  ];

  const commandComponent = <RecommendationCommand commandsList={commandsList} />;

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  return (
    <RecommendationDetailsLayout
      insideLook={insideLookComponent}
      otherResourceIdRecommendations={otherResourceIdRecComponent}
      optionsPanel={optionsPanelComponent}
      details={detailsComponent}
      command={commandComponent}
      preferences={preferencesComponent}
    />
  );
};

Ec2VersionUpgrade.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default Ec2VersionUpgrade;
