import { useMutation, useQuery } from 'react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { useRootStore } from 'app/contexts/RootStoreContext';
import {
  createUsers,
  deleteUsers,
  downloadUsers,
  enableDisableUsers,
  fetchAllUsers,
  fetchUsers,
  resetUsersPassword,
  updateUser,
} from './apiUsers';
import { STALE_TIME } from '../../../containers/Organization/consts';
import { handleError } from './helperFunctions';

export default function useUsers() {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const queryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS, userAccountKey];

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    createUsers: useMutation(({ users }) => createUsers(users), {
      onSuccess: () => queryClient.invalidateQueries(apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS),
      onError: handleError,
    }),
    updateUser: useMutation(({ user }) => updateUser(user), {
      onSuccess: () => queryClient.invalidateQueries(apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS),
      onError: handleError,
    }),
    deleteUsers: useMutation(({ userIds }) => deleteUsers(userIds), {
      onSuccess: () => queryClient.invalidateQueries(apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS),
      onError: handleError,
    }),
    fetchUsers: () =>
      useQuery(queryKey, () => fetchUsers(), {
        retry: false,
        staleTime: STALE_TIME,
      }),
    fetchAllUsers: () =>
      useQuery(queryKey, () => fetchAllUsers(), {
        retry: false,
        staleTime: STALE_TIME,
      }),
    enableDisableUser: useMutation(({ userIds, enable }) => enableDisableUsers(userIds, enable), {
      onSuccess: () => queryClient.invalidateQueries(apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS),
      onError: handleError,
    }),
    resetUsersPassword: useMutation(({ userIds, enable }) => resetUsersPassword(userIds, enable), {
      onError: handleError,
    }),
    exportCsv: (selectedUsers) =>
      useQuery([...queryKey, ...selectedUsers], () => downloadUsers(selectedUsers), {
        retry: false,
        staleTime: STALE_TIME,
      }),
  };
}
