import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import IconFromPng from 'shared/components/IconFromPng';
import Spinner from 'shared/components/andtComponents/Spinner';
import { palette } from 'shared/constants/colorsConstants';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import styles from './Button.module.scss';

const Button = ({
  text,
  onClick,
  isTextButton,
  isSecondary,
  isSecondaryWithHover,
  isError,
  isErrorOutlined,
  isGhost,
  isLoading,
  isGreen,
  icon,
  iconSize,
  disabled,
  automationId,
  type,
  name,
  overrideStyles,
  iconPlacement,
  className,
  isLightButton,
}) => {
  const handleClick = (val) => {
    segmentEvent({
      target: automationId || text || icon,
      automationId,
    });
    if (onClick) {
      onClick(val);
    }
  };
  const renderIcon = () =>
    icon ? (
      <div className={styles.iconWrapper}>
        <IconFromPng size={`${iconSize}px`} icon={icon} />
      </div>
    ) : null;
  return (
    <button
      className={classNames(
        className?.button,
        className?.icon,
        styles.andtButton,
        { [styles.ghost]: isGhost },
        { [styles.green]: isGreen },
        { [styles.textButton]: isTextButton },
        { [styles.secondary]: isSecondary || isSecondaryWithHover },
        { [styles.secondaryHover]: isSecondaryWithHover },
        { [styles.error]: isError },
        { [styles.errorOutlined]: isErrorOutlined },
      )}
      name={name}
      style={{ ...overrideStyles }}
      onClick={handleClick}
      disabled={disabled || isLoading}
      // eslint-disable-next-line react/button-has-type
      type={type || 'button'}
      // eslint-disable-next-line react/no-unknown-property
      automation-id={automationId}
    >
      <div className={classNames(styles.buttonContent, `${isLoading ? styles.loading : ''}`, className?.buttonContent)}>
        {isLoading && (
          <div className={styles.spinnerWrapper}>
            <Spinner color={isGhost || isTextButton || isLightButton ? palette.blue[500] : palette.white[500]} />
          </div>
        )}
        {iconPlacement === 'left' && renderIcon()}
        {text ? <span className={classNames(styles.buttonText, className?.buttonText)}>{text}</span> : null}
        {iconPlacement === 'right' && renderIcon()}
      </div>
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isTextButton: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isSecondaryWithHover: PropTypes.bool,
  isGreen: PropTypes.bool,
  isGhost: PropTypes.bool,
  isLoading: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
  iconSize: PropTypes.number,
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
  isLightButton: PropTypes.bool,
  isErrorOutlined: PropTypes.bool,
  automationId: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  overrideStyles: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconPlacement: PropTypes.oneOf(['left', 'right']),
};

Button.defaultProps = {
  isLoading: false,
  isTextButton: false,
  isSecondary: false,
  isSecondaryWithHover: false,
  isGhost: false,
  isGreen: false,
  isError: false,
  isLightButton: false,
  isErrorOutlined: false,
  icon: null,
  name: null,
  iconSize: 12,
  disabled: false,
  automationId: '',
  type: 'submit',
  overrideStyles: {},
  className: {},
  iconPlacement: 'left',
};

export default Button;
