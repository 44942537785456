import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { prepareCloudHirarcheyObject } from 'users/utils/rolesUtil';
import { withRolesContextConsumer } from 'users/contexts/RolesProvider';
import { withLinkedAccountsContextConsumer } from 'users/contexts/LinkedAccountsProvider';
import { useRootStore } from 'app/contexts/RootStoreContext';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  TableFilterRow,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { cloudTypeToIcon } from 'shared/constants/appConstants';
import SimpleSelect from 'shared/components/andtComponents/SimpleSelect';
import Button from 'shared/components/andtComponents/Button';
import useTable from 'shared/hooks/customHooks/useTable';
import useRoles from 'users/hooks/react-query/useRoles';
import { CLOUD_TYPE_IDS, mapCloudTypeIdToDescription } from 'users/constants/usersConstants';
import {
  getAccountByAccountId,
  getLinkedAccountsInAccount,
} from 'users/containers/RolesAndUsers/components/AccountsChooser/utils';
// eslint-disable-next-line max-len
import {
  useTotalLinkedAccounts,
  useTotalLinkedAccountsSelected,
} from 'users/containers/RolesAndUsers/components/AccountsChooser/hooks/useCounters';
import { groupBy } from 'lodash';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import {
  useLinkedAccountsTableState,
  useUsersTableState,
} from 'users/containers/RolesAndUsers/contexts/rolesAndUsersContext';
import { ROLE_TYPES } from 'users/containers/RolesAndUsers/constants/rolesAndUsersConstants';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import { getLinkedAccountName } from 'shared/utils/cloudUtils';
import useFormattedLinkedAccounts from 'users/containers/RolesAndUsers/hooks/useFormattedLinkedAccounts';
import styles from './LinkedAccountsTable.module.scss';

const LinkedAccountsTable = ({ role, cloudAccountsLinkedAccount: linkedAccountsRaw }) => {
  const [deletedAccounts, setDeletedAccounts] = React.useState([]);
  const roleId = role.uuid;
  const { NewTableRowDetail, NewTableWrapper, NewTableRow, NewTableColumnsFilterSwitch, NewTableSelectionBar } =
    useTable();
  const cloudTypeToId = new Map([...mapCloudTypeIdToDescription.entries()].map((r) => r.reverse()));
  const { usersStore } = useRootStore();
  const { updateRole } = useRoles();
  const { anySelected: usersChecked } = useUsersTableState(roleId);
  const cloudAccountsLinkedAccount = useFormattedLinkedAccounts(linkedAccountsRaw);
  const {
    page,
    setPage,
    setSize,
    size,
    setExpandedRows,
    selectedRows,
    setSelectedRows,
    expandedRows,
    setShowFilterRows,
    showFilterRows,
    cloudTypeId,
    setCloudTypeId,
    getLinkedAccountsPage,
    setLinkedAccountsPage,
  } = useLinkedAccountsTableState(roleId);
  const { mutateAsync: handleUpdate } = updateRole();
  const accounts = useMemo(
    () => prepareCloudHirarcheyObject(usersStore.getAllCurrDisplayedUserAccounts()),
    [usersStore.getAllCurrDisplayedUserAccounts()],
  );
  const handleRemoveLinkedAccounts = async (accountsToClean) => {
    const params = {
      uuid: role.uuid,
      roleName: role.roleName,
      roleUserType: role.roleUserType,
      roleType: 'userRole',
      useSameAccountKey: true,
      accounts: role.accounts,
    };
    params.accounts = params.accounts
      .map((acc) => {
        const accountToClean = accountsToClean.find((a) => a.accountId === acc.accountId);
        if (!accountToClean) {
          return acc;
        }
        const selected = getLinkedAccountsInAccount(
          role.accounts.find((acc) => acc.accountId === accountToClean?.accountId),
          cloudAccountsLinkedAccount?.get(accountToClean?.accountId),
        );
        acc.linkedAccountIds = selected
          .filter((ln) => !accountToClean.linkedAccountIds.includes(ln.linkedAccountId))
          .map((ln) => ln.linkedAccountId);
        if (acc.linkedAccountIds.length === 0) {
          return null;
        }
        return acc;
      })
      .filter(Boolean);
    await handleUpdate(params);
  };
  const filteredAccounts = useMemo(
    () =>
      role.accounts
        .map((acc) => ({
          ...acc,
          info: getAccountByAccountId(accounts, acc.accountId),
        }))
        .filter((acc) => !!acc.info)
        .filter((acc) => (cloudTypeId === null ? acc : acc.info.cloudTypeId === cloudTypeId))
        .sort(
          (a, b) =>
            a.info.cloudTypeId.toString().localeCompare(b.info.cloudTypeId.toString()) ||
            a.info.accountName?.localeCompare(b.info.accountName || ''),
        ),
    [accounts, role, cloudTypeId],
  );
  const totalLinkedAccounts = useTotalLinkedAccounts(accounts, cloudAccountsLinkedAccount);
  const selectedLinkedAccounts = useTotalLinkedAccountsSelected(accounts, role.accounts, cloudAccountsLinkedAccount);
  const detailsFormatter = (data) => {
    const account = data.row.info;
    const rowInd = filteredAccounts.findIndex((acc) => acc.accountId === account?.accountId);
    const selected = getLinkedAccountsInAccount(data.row, cloudAccountsLinkedAccount?.get(account?.accountId)).length;
    const { cloudTypeId, accountName } = account || {};
    const CloudIcon = cloudTypeToIcon[+cloudTypeId];
    return (
      <div className={styles.accountNameCell}>
        {CloudIcon ? <CloudIcon /> : null}
        <p>{accountName}</p>
        <span>
          ({selected}/{cloudAccountsLinkedAccount?.get(account?.accountId)?.length || 0})
        </span>
        <NewTableColumnsFilterSwitch
          setShowFilterIds={setShowFilterRows}
          showFilterIds={showFilterRows}
          expandedRowIds={expandedRows}
          rowInd={rowInd}
        />
      </div>
    );
  };
  const renderRow = ({ row, children, ...restProps }) => (
    <NewTableRow expandedRowIds={expandedRows} {...restProps}>
      {children}
    </NewTableRow>
  );

  const actionsFormatter = (account) => (data) =>
    (
      <Button
        text=""
        disabled={role.roleType === ROLE_TYPES.DEFAULT_ADMIN}
        icon={() => <GenerateIcon iconName={ICONS.deleteRegular.name} />}
        onClick={() => {
          // eslint-disable-next-line react/destructuring-assignment
          const { linkedAccountId } = data.row;
          setDeletedAccounts([{ accountId: account?.accountId, linkedAccountIds: [linkedAccountId] }]);
        }}
        isTextButton
      />
    );

  const renderRowDetail = (data) => {
    const account = data.row.info;
    const rowInd = filteredAccounts.findIndex((acc) => acc.accountId === account?.accountId);
    const linkedAccounts = getLinkedAccountsInAccount(data.row, cloudAccountsLinkedAccount.get(account.accountId));
    return (
      <NewTableRowDetail>
        <div className="sub-table">
          <Grid
            rows={linkedAccounts}
            columns={[
              {
                title: `${getLinkedAccountName(usersStore.currDispUserCloudAccountType)} Name & ID`,
                name: 'linkedAccountName',
                getCellValue: (row) => `${row.linkedAccountName} (${row.linkedAccountId})`,
              },
              {
                title: ' ',
                name: 'actions',
              },
            ]}
          >
            <FilteringState defaultFilters={[]} />
            <IntegratedFiltering />
            <SortingState defaultSorting={[{ columnName: 'linkedAccountName', direction: 'asc' }]} />
            <IntegratedSorting />
            <SelectionState
              selection={selectedRows
                .filter((row) => row.accountId === account?.accountId)
                .map((row) => row.linkedAccountId)}
              onSelectionChange={(vals) =>
                setSelectedRows([
                  ...selectedRows.filter((r) => r.accountId !== account?.accountId),
                  ...vals.map((v) => ({ accountId: account?.accountId, linkedAccountId: v })),
                ])
              }
            />
            <IntegratedSelection />
            <PagingState
              currentPage={getLinkedAccountsPage(account?.accountId)}
              onCurrentPageChange={(page) => setLinkedAccountsPage(account?.accountId, page)}
              defaultPageSize={10}
            />
            <IntegratedPaging />
            <DataTypeProvider for={['actions']} formatterComponent={actionsFormatter(account)} />
            <TableWrapper
              columnExtensions={[
                { columnName: 'linkedAccountName', width: '70%' },
                { columnName: 'actions', width: 'auto', align: 'right' },
              ]}
            />
            <TableHeaderRow showSortingControls />
            {role.roleType === ROLE_TYPES.DEFAULT_ADMIN ? null : <TableSelection showSelectAll />}
            {showFilterRows.includes(rowInd) ? <TableFilterRow showFilterSelector /> : null}
            <PagingPanel pageSizes={[10]} />
          </Grid>
        </div>
      </NewTableRowDetail>
    );
  };
  return (
    <div className={styles.container} data-disabled={!!usersChecked}>
      <NewTableSelectionBar selectedRows={selectedRows}>
        <div className={styles.selectedLns}>
          <Button
            isTextButton
            text="Delete"
            icon={() => <GenerateIcon iconName={ICONS.delete.name} />}
            onClick={() => {
              const groupedByAccount = groupBy(selectedRows, 'accountId');
              setDeletedAccounts(
                Object.entries(groupedByAccount).map(([accountId, data]) => {
                  const linkedAccounts = getLinkedAccountsInAccount(
                    filteredAccounts.find((acc) => acc.accountId === accountId),
                    cloudAccountsLinkedAccount.get(accountId),
                  );
                  return {
                    accountId,
                    linkedAccountIds: data
                      .map(({ linkedAccountId }) => linkedAccounts[linkedAccountId]?.linkedAccountId)
                      .filter(Boolean),
                  };
                }),
              );
            }}
          />
        </div>
      </NewTableSelectionBar>
      <div className={styles.title}>
        {getLinkedAccountName(usersStore.currDispUserCloudAccountType)}s{' '}
        <span>
          ({selectedLinkedAccounts}/{totalLinkedAccounts})
        </span>
        <InfoPopover isSimple>
          The account count is as follows:
          <br />
          count of associated {getLinkedAccountName(usersStore.currDispUserCloudAccountType).toLowerCase()}s / count of
          active {getLinkedAccountName(usersStore.currDispUserCloudAccountType).toLowerCase()}s
        </InfoPopover>
      </div>
      <NewTableWrapper className={styles.tableWrapper}>
        <div className={styles.cloudSelectWrapper}>
          <SimpleSelect
            value={cloudTypeId === null ? 'ALL_CLOUDS' : mapCloudTypeIdToDescription.get(cloudTypeId)}
            isPrimarySmall
            onChange={(val) => {
              if (val === 'ALL_CLOUDS') {
                setCloudTypeId(null);
                return;
              }
              setCloudTypeId(cloudTypeToId.get(val));
            }}
            isClearable={false}
            placeholder="All Clouds"
            options={[
              { value: 'ALL_CLOUDS', label: 'All Clouds' },
              ...[...mapCloudTypeIdToDescription.entries()]
                .filter(([val]) => val !== CLOUD_TYPE_IDS.MULTI)
                .map(([, label]) => ({ value: label, label })),
            ]}
          />
        </div>
        <Grid rows={filteredAccounts} columns={[{ title: '', name: 'details' }]}>
          <RowDetailState expandedRowIds={expandedRows} onExpandedRowIdsChange={setExpandedRows} />
          <PagingState pageSize={size} onPageSizeChange={setSize} currentPage={page} onCurrentPageChange={setPage} />
          <IntegratedPaging />
          <DataTypeProvider for={['details']} formatterComponent={detailsFormatter} />
          <TableWrapper rowComponent={renderRow} />
          <TableRowDetail contentComponent={renderRowDetail} />
          <PagingPanel pageSizes={[5, 10, 15]} />
        </Grid>
      </NewTableWrapper>
      <DeleteWarningModal
        isOpen={deletedAccounts.length > 0}
        handleDelete={async (action) => {
          if (action === 'delete') {
            await handleRemoveLinkedAccounts(deletedAccounts);
          }
          setDeletedAccounts([]);
          setSelectedRows([]);
        }}
        modalTitle="Remove Linked Accounts"
        warningMessage={`Be advised, You are about to remove linked accounts from ${role.roleName}.`}
      />
    </div>
  );
};

LinkedAccountsTable.propTypes = {
  role: PropTypes.object.isRequired,
  cloudAccountsLinkedAccount: PropTypes.object.isRequired,
};

export default withLinkedAccountsContextConsumer(withRolesContextConsumer(LinkedAccountsTable));
