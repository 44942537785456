import React from 'react';
import styles from './toggleSwitchButton.module.scss';

const ActualSimulatedSwitchButton = ({ isActualMode, setIsActualMode, automationId }) => (
  <div className={styles.switchButtonWrapper}>
    <div
      className={`${styles.switchButton} ${isActualMode && styles.selectedOption}`}
      onClick={() => setIsActualMode(!isActualMode)}
      automation-id={`${automationId}Actual`}
    >
      <span>Actual</span>
    </div>
    <div
      className={`${styles.switchButton} ${!isActualMode && styles.selectedOption}`}
      onClick={() => setIsActualMode(!isActualMode)}
      automation-id={`${automationId}Simulate`}
    >
      <span>Simulated</span>
    </div>
  </div>
);

export default ActualSimulatedSwitchButton;
