export const awsServiceNameToIconMapping = {
  CloudTrail: 'CloudTrail',
  DocumentDB: 'DocumentDB',
  'Dynamo DB': 'DynamoDB',
  DynamoDB: 'DynamoDB',
  EBS: 'EBS',
  EC2: 'EC2',
  ECS: 'ECS',
  ELB: 'ELB',
  'Load Balancer': 'ELB',
  ElastiCache: 'ElastiCache',
  'Elastic IP': 'ElasticIP',
  ElasticIP: 'ElasticIP',
  Fargate: 'Fargate',
  Kinesis: 'Kinesis',
  KMS: 'KMS',
  'Neptune DB': 'Neptune',
  Neptune: 'Neptune',
  OpenSearch: 'OpenSearch',
  ElasticSearch: 'OpenSearch',
  RDS: 'RDS',
  Redshift: 'Redshift',
  S3: 'S3',
  VPC: 'VPC',
  K8S: 'K8S',
  EKS: 'EKS',
};

export const azureServiceNameToIconMapping = {
  'App Service': 'AppService',
  'Cosmos DB': 'CosmosDB',
  Cosmos: 'CosmosDB',
  'Data Explorer': 'DataExplorer',
  Kusto: 'DataExplorer',
  Database: 'DB',
  DB: 'DB',
  Disk: 'Disk',
  Storage: 'Disk',
  'Load Balancer': 'LoadBalancer',
  ELB: 'LoadBalancer',
  LoadBalancer: 'LoadBalancer',
  IP: 'IP',
  'Maria DB': 'MariaDB',
  MariaDB: 'MariaDB',
  MySQL: 'MySQL',
  PostgreSQL: 'PostgreSQL',
  Redis: 'Redis',
  SQL: 'SQL',
  Snapshot: 'Snapshot',
  'Virtual Machine': 'VirtualMachine',
  VM: 'VirtualMachine',
};

export const gcpServiceNameToIconMapping = {
  'Virtual Machine': 'VirtualMachine',
  VM: 'VirtualMachine',
  IP: 'IP',
  CUD: 'CUD',
};
