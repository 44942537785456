import React from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import classes from './anodotReactSelect.module.scss';

export const formatOptionLabel = ({ label, description }) => (
  <div className={classes.customOptionWrapper}>
    <div className={classes.optionLabel}>{label}</div>
    <div className={classes.optionSubLabel}>{description}</div>
  </div>
);

export const CustomInputValue = ({ data }) => (
  <div className={classes.selectOptionWrapper}>
    <div>{data.label}</div>
  </div>
);

export const getAnodotReactSelectStyles = ({ height, disabled, isEmpty, backgroundColor = 'rgb(233, 233, 240)' }) => ({
  container: (base) => ({ ...base, height, borderRadius: 5 }),
  valueContainer: (base) => ({ ...base, height: '100%', flexWrap: 'nowrap' }),
  indicatorsContainer: (base) => ({
    ...base,
    height,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: isEmpty ? 'unset' : 'none',
    color: '#8995a0',
    stroke: 'none',
  }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  clearIndicator: (base) => ({ ...base, color: 'rgb(61, 76, 89)', stroke: 'none' }),
  control: (base, state) => ({
    ...base,
    borderColor: `${state.isHovered ? 'rgb(212, 217, 224)' : 'transparent'} !important`,
    borderRadius: 5,
    backgroundColor: disabled ? 'rgb(244, 247, 251)' : backgroundColor,
    color: disabled ? 'rgb(137, 149, 160)' : 'rgb(61, 76, 89)',
    fontSize: 16,
    height,
    minHeight: 'unset',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  placeholder: (base) => ({
    ...base,
    color: disabled ? 'rgb(137, 149, 160)' : '#8995a0',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? 'rgb(233, 233, 240)' : 'white',
    cursor: 'pointer',
  }),
});

export interface AnodotReactSelectProps {
  className?: string;
  value: any;
  options: any[];
  placeholder?: string;
  onChange(selected: string): void;
  automationId?: string;
  disabled?: boolean;
  height?: number;
}

const AnodotReactSelect: React.FC<AnodotReactSelectProps> = ({
  className,
  value,
  options,
  placeholder = 'Select',
  onChange,
  disabled,
  automationId,
  height = 36,
}) => {
  const activeOption = options?.find((o) => o?.value === value) || value;

  return (
    <ReactSelect
      automationId={automationId}
      className={classNames(classes.anodotReactSelect, className, { disabled })}
      classNamePrefix="anodot-react-select"
      open
      value={activeOption}
      components={{ SingleValue: CustomInputValue }}
      formatOptionLabel={formatOptionLabel}
      options={options}
      placeholder={placeholder}
      onChange={(option) => onChange(option?.value)}
      isDisabled={disabled}
      isClearable
      styles={getAnodotReactSelectStyles({ height, disabled, isEmpty: !value })}
    />
  );
};

export default AnodotReactSelect;
