import { palette } from 'shared/constants/colorsConstants';

export const PAYMENT_OPTIONS = ['all upfront', 'partial upfront', 'no upfront'];

export const TERMS = ['1-year', '3-year'];

export const SP_TABS = [
  { id: '0', name: 'Recommended Plan', title: '', icon: 'memoPad' },
  { id: '1', name: `Forecasted Plans`, title: '', icon: 'binoculars' },
];

export const RECOMMENDATION_PLAN_PROPERTIES = [
  { name: 'Hourly Commitment', key: 'recommendedHourlyCommitment', isEditable: true },
  { name: 'Type', key: 'type', isShareProperty: true },
  { name: 'Term', key: 'term' },
  { name: 'Upfront Cost', key: 'recommendedUpfrontPayment', isCurrency: true },
  { name: 'Monthly Recurring Charges', key: 'monthlyRecurringCharges', isCurrency: true },
  { name: 'Total Commitment', key: 'totalYearCommitment', isCurrency: true, isEndGroup: true },
  { name: 'Current monthly EC2 compute cost', key: 'currentMonthlyNetCost', isShareProperty: true, isCurrency: true },
  {
    name: 'Current Simulated monthly EC2 compute cost',
    key: 'simulatedCurrentMonthlyNetCost',
    info: `Simulation of a monthly cost based on today's coverage percentage (As the saving pans analyzer algorithm).`,
    isShareProperty: true,
    isCurrency: true,
  },
  { name: 'Expected monthly EC2 compute cost', key: 'recommendedMonthlyNetCost', isCurrency: true },
  {
    name: 'Total Savings',
    key: 'totalSaving',
    calculatedFields: ['currentMonthlyNetCost', 'recommendedMonthlyNetCost'],
    isCurrency: true,
    isEndGroup: true,
  },
  { name: 'Current On Demand Cost', key: 'currentMonthlyOdCost', isShareProperty: true, isCurrency: true },
  {
    name: 'Current Simulated On Demand Cost',
    key: 'simulatedCurrentMonthlyOdCost',
    info: `Simulation of a monthly on demand cost based on today's coverage percentage
           (As the saving pans analyzer algorithm).`,
    isShareProperty: true,
    isCurrency: true,
  },
  {
    name: 'Estimated On Demand Cost',
    key: 'recommendedMonthlyOdCost',
    info: 'Estimated costs based on selected "days to check" and normalized for a month.',
    isCurrency: true,
  },
];

export const EXPORT_COLUMNS = [
  { label: 'Date', key: 'date' },
  { label: 'Current Savings Plan (Before Purchase)', key: 'currentSP' },
  { label: 'Recommended Savings Plan (After Purchase)', key: 'onDemand' },
  { label: 'On Demand Equivalent', key: 'recommendedSP' },
  { label: 'Current Savings Plan', key: 'hourlyCommitment', isSimulateColumn: false },
  { label: 'Current On-Demand', key: 'actualOnDemand', isSimulateColumn: false },
  { label: 'Simulate Savings Plan', key: 'simulateHourlyCommitment', isSimulateColumn: true },
  { label: 'Simulate On-Demand', key: 'actualSimulateOnDemand', isSimulateColumn: true },
  { label: 'Recommended Savings Plan', key: 'accHourlyCommitment', isEstimateColumn: true },
  { label: 'Estimated On-Demand', key: 'actualAccOnDemand', isEstimateColumn: true },
];
export const CHART_TYPES = {
  onDemand: 'onDemand',
  actual: 'actual',
};

export const ON_DEMAND_CHART_LEGEND = [
  { key: 'currentSP', name: 'Current Savings Plan (Before Purchase)', color: palette.blue[500], opacity: 0.2 },
  { key: 'recommendedSP', name: 'Recommended Savings Plan (After Purchase)', color: '#21B1D2', opacity: 0.2 },
  { key: 'onDemand', name: 'On Demand Equivalent', color: '#CD84E7', opacity: 0.2 },
];
export const ACTUAL_CHART_LEGEND = [
  { key: 'hourlyCommitment', name: 'Current Savings Plan ', color: '#00BBD4', isEstimated: false, opacity: 0.2 },
  {
    key: 'netCost',
    exportKey: 'actualOnDemand',
    name: 'Current On-Demand',
    color: '#CD84E7',
    isEstimated: false,
    opacity: 0.2,
  },
  {
    key: 'simulateHourlyCommitment',
    name: 'Simulate Savings Plan ',
    color: '#00BBD4',
    isEstimated: false,
    isSimulate: true,
    opacity: 0.2,
  },
  {
    key: 'simulateNetCost',
    exportKey: 'actualSimulateOnDemand',
    name: 'Simulate On-Demand',
    color: '#CD84E7',
    isEstimated: false,
    isSimulate: true,
    opacity: 0.2,
  },
  { key: 'accHourlyCommitment', name: 'Recommended Savings Plan', color: '#00BBD4', isEstimated: true, opacity: 0.2 },
  {
    key: 'accNetCost',
    exportKey: 'actualAccOnDemand',
    name: 'Estimated On-Demand',
    color: '#CD84E7',
    isEstimated: true,
    opacity: 0.2,
  },
];
export const ON_DEMAND_TP_MAP_DATA = {
  regularMode: {
    fields: [
      { key: 'currentSP', name: 'Current Savings Plan', color: palette.blue[500] },
      { key: 'recommendedSP', name: 'Recommended Savings Plan', color: '#21B1D2' },
      { key: 'onDemand', name: 'On Demand Equivalent', color: '#CD84E7' },
    ],
    summary: [{ name: 'Total', totalFields: ['currentSP', 'recommendedSP', 'onDemand'] }],
  },
};
export const ACTUAL_TP_MAP_DATA = {
  regularMode: {
    fields: [
      { name: 'Current Savings Plan', key: 'hourlyCommitment', color: '#00BBD4' },
      { name: 'Current On-Demand', key: 'actualOnDemand', color: '#CD84E7' },
    ],
    summary: [{ name: 'Current Cost', displayField: 'netCost' }],
  },
  simulateMode: {
    fields: [
      { name: 'Simulate Savings Plan', key: 'simulateHourlyCommitment', color: '#00BBD4' },
      { name: 'Simulate On-Demand', key: 'actualSimulateOnDemand', color: '#CD84E7' },
    ],
    summary: [{ name: 'Simulate Cost', displayField: 'simulateNetCost' }],
  },
  estimatedMode: {
    fields: [
      { name: 'Recommended Savings Plan', key: 'accHourlyCommitment', color: '#00BBD4' },
      { name: 'Estimated On-Demand', key: 'actualAccOnDemand', color: '#CD84E7' },
    ],
    summary: [
      { name: 'Estimated Cost', displayField: 'accNetCost' },
      {
        name: 'Estimated Savings',
        minusFields: ['netCost', 'accNetCost'],
      },
      { name: 'Current Cost', displayField: 'netCost' },
    ],
  },
  simulateEstimatedMode: {
    fields: [
      { name: 'Recommended Savings Plan', key: 'accHourlyCommitment', color: '#00BBD4' },
      { name: 'Estimated On-Demand', key: 'actualAccOnDemand', color: '#CD84E7' },
    ],
    summary: [
      { name: 'Estimated Cost', displayField: 'accNetCost' },
      {
        name: 'Estimated Savings',
        minusFields: ['simulateNetCost', 'accNetCost'],
      },
      { name: 'Simulate Cost', displayField: 'simulateNetCost' },
    ],
  },
};
