import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import {
  Grid,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedSelection,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import useTable from 'shared/hooks/customHooks/useTable';
import TableWrapper from 'shared/components/tables/TableWrapper';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import {
  FILTER_NUMBER_OR_DATE_OPERATIONS,
  FILTER_OPERATIONS,
} from 'shared/components/andtComponents/TableComponents/const';
import {
  CustomFilterCell,
  CustomFilterIcon,
  CustomFilterRow,
  CustomFormatterWithTooltip,
  CustomFormatterWithTooltipAndLeftComponent,
  CustomHeaderCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import { ReactComponent as NoResults } from './img/no-results.svg';
import RolesContext from './contexts/rolesContext';
import { getColumns, getDefaultSorting, getSortingColumns, ROLES_COLUMNS } from '../consts';
// import UsersBulkOperations from './UsersBulkOperations';
// import UserExpandedData from './usersExpandedRowComponents/UserExpandedData';
// import UserActions from './UserActions';
// import UserImpersonate from './usersTableComponents/UserImpersonate';
// import UserStatusSwitchButton from './usersTableComponents/UserStatusSwitchButton';
// import UserRoles from './usersTableComponents/UserRoles';
import styles from './RolesTable.module.scss';
import RolesIcon from './RolesIcon';

export const RolesNoData = (users) => {
  if (!users) {
    return null;
  }
  return (
    <div className={styles.noResultsWrapper}>
      <NoResults />
      <span className={styles.mainText}>No Roles were found</span>
      <span className={styles.subText}>Try different filters</span>
    </div>
  );
};

const UsersTable = ({ users }) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(ROLES_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );

  const rightAlignedColumns = [ROLES_COLUMNS.ADD_USERS.columnName, ROLES_COLUMNS.ACTIONS.columnName];

  const { NewTableRow /* , NewTableSelectionBar */ } = useTable();

  const [expandedRowIds, setExpandedRowIds] = useState([]);

  const { showFilters, setSearchText, selectedRows, setSelectedRows, isAllExpanded, setIsAllExpanded } =
    useContext(RolesContext);

  const updateFilters = (filters) => {
    const tempFilters = {};
    filters.forEach((f) => {
      tempFilters[f.columnName] = { like: [f.value] };
    });
    setSearchText(tempFilters);
  };

  const debouncedSetFilters = debounce(updateFilters, 500);

  const onFiltersChange = (newFilters) => {
    debouncedSetFilters(newFilters);
  };

  useEffect(() => {
    if (!isAllExpanded) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(users?.map((row, index) => index));
    }
  }, [isAllExpanded]);

  useEffect(() => {
    if (expandedRowIds.length === 0) {
      setIsAllExpanded(false);
    }
  }, [expandedRowIds]);

  return (
    <Grid rows={users} columns={getColumns(ROLES_COLUMNS)} automationId="users-list">
      <SortingState
        columnExtensions={getSortingColumns(ROLES_COLUMNS)}
        defaultSorting={getDefaultSorting(ROLES_COLUMNS)}
      />
      {showFilters && <FilteringState onFiltersChange={onFiltersChange} />}
      <SelectionState selection={selectedRows} onSelectionChange={setSelectedRows} />
      <IntegratedSelection />
      <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
      <DataTypeProvider
        for={[ROLES_COLUMNS.ROLE_NAME.columnName]}
        formatterComponent={() => (
          <CustomFormatterWithTooltipAndLeftComponent>
            <RolesIcon />
          </CustomFormatterWithTooltipAndLeftComponent>
        )}
        availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
      />
      <DataTypeProvider
        for={[ROLES_COLUMNS.ROLE_ID.columnName]}
        formatterComponent={CustomFormatterWithTooltip}
        availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
      />
      <DataTypeProvider
        for={[ROLES_COLUMNS.USERS_COUNT.columnName]}
        formatterComponent={CustomFormatterWithTooltip}
        availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
      />
      <DataTypeProvider
        for={[ROLES_COLUMNS.CREATED_AT.columnName]}
        formatterComponent={(props) => (
          <CustomFormatterWithTooltip value={moment(props.createdAt).format('MMM DD YYYY, hh:mm:ss A')} isSmallText />
        )}
        availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
      />
      <DataTypeProvider
        for={[ROLES_COLUMNS.CREATED_BY.columnName]}
        formatterComponent={CustomFormatterWithTooltip}
        availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
      />
      <DataTypeProvider
        for={[ROLES_COLUMNS.UPDATED_AT.columnName]}
        formatterComponent={(props) => (
          <CustomFormatterWithTooltip value={moment(props.lastLogin).format('MMM DD YYYY, hh:mm:ss A')} isSmallText />
        )}
        availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
      />
      <DataTypeProvider
        for={[ROLES_COLUMNS.UPDATED_BY.columnName]}
        formatterComponent={CustomFormatterWithTooltip}
        availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
      />
      {/* <DataTypeProvider */}
      {/*  for={[ROLES_COLUMNS.ADD_USERS.columnName]} */}
      {/*  formatterComponent={AddUsersToRole} */}
      {/*  availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS} */}
      {/* /> */}
      {/* <DataTypeProvider for={[ROLES_COLUMNS.ACTIONS.columnName]} formatterComponent={RoleActions} /> */}
      <TableWrapper
        virtual
        noDataCellComponent={() => RolesNoData(users)}
        rowComponent={(props) => (
          <NewTableRow expandedRowIds={expandedRowIds} setExpandedRowIds={setExpandedRowIds} {...props} />
        )}
        height="auto"
      />
      <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
      <TableColumnResizing
        resizingMode="nextColumn"
        columnWidths={localColumnsWidth}
        onColumnWidthsChange={setLocalColumnsWidth}
      />
      {/* <TableRowDetail contentComponent={RoleExpandedData} toggleCellComponent={CustomToggleCell} /> */}
      <TableHeaderRow showSortingControls cellComponent={CustomHeaderCell} />
      {showFilters && (
        <TableFilterRow
          showFilterSelector
          iconComponent={CustomFilterIcon}
          rowComponent={CustomFilterRow}
          cellComponent={(props) => <CustomFilterCell {...props} actionColumns={rightAlignedColumns} />}
        />
      )}
      {/* <NewTableSelectionBar selectedRows={selectedRows} counterClass={styles.counterClass}> */}
      {/*  <RolesBulkOperations /> */}
      {/* </NewTableSelectionBar> */}
    </Grid>
  );
};

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
};

export default UsersTable;
