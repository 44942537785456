import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { AwsCommonFields, K8S_CUE_FIELDS } from 'shared/constants/awsConstants';
import { GcpCommonFields } from 'shared/constants/gcpConstants';
import { AzureCommonFields } from 'shared/constants/azureConstants';
import { PageNames } from 'shared/constants/appConstants';

export const getBudgetFiltersArrByCloudType = (cloudType) => {
  const filters = {
    [CLOUD_TYPE_IDS.AWS]: [
      AwsCommonFields.CHARGE_TYPE,
      AwsCommonFields.DIVISION,
      AwsCommonFields.REGION,
      AwsCommonFields.LINKED_ACCOUNT_ID,
      AwsCommonFields.SERVICE,
      AwsCommonFields.QUANTITY_TYPE,
      AwsCommonFields.SUB_SERVICE,
      AwsCommonFields.CUSTOM_TAGS,
      AwsCommonFields.OPERATION,
      AwsCommonFields.PURCHASE_OPTION,
      AwsCommonFields.FAMILY_TYPE,
      AwsCommonFields.INSTANCE_TYPE,
      AwsCommonFields.BUSINESS_MAPPING,
      AwsCommonFields.USAGE_TYPE,
      AwsCommonFields.RESOURCE,
      AwsCommonFields.AVAILABILITY_ZONE,
      AwsCommonFields.ACCOUNT_TAGS,
      AwsCommonFields.VIRTUAL_CUSTOM_TAGS,
      AwsCommonFields.SUB_VIEWS_CUSTOM_TAGS,
    ],
    [CLOUD_TYPE_IDS.GCP]: [
      AzureCommonFields.DIVISION,
      AzureCommonFields.SUBSCRIPTION_ID,
      AzureCommonFields.SERVICE,
      AwsCommonFields.ACCOUNT_TAGS,
      AwsCommonFields.BUSINESS_MAPPING,
      AwsCommonFields.FAMILY_TYPE,
      AwsCommonFields.OPERATION,
      AwsCommonFields.REGION,
      GcpCommonFields.CUSTOM_TAGS,
      GcpCommonFields.COST_TYPE_DESCRIPTION,
      AwsCommonFields.RESOURCE,
    ],
    [CLOUD_TYPE_IDS.AZURE]: [
      AzureCommonFields.DIVISION,
      AzureCommonFields.SUBSCRIPTION_ID,
      AzureCommonFields.REGION,
      AzureCommonFields.SERVICE,
      AzureCommonFields.METER_MAME,
      AzureCommonFields.METER_CATEGORY,
      AzureCommonFields.SUB_METER_CATEGORY,
      AzureCommonFields.CUSTOM_TAGS,
      AzureCommonFields.BUSINESS_MAPPING,
      AzureCommonFields.PURCHASE_OPTION,
      AzureCommonFields.USAGE_TYPE,
      AzureCommonFields.LINE_TYPE,
      AzureCommonFields.RESOURCE_GROUP,
      AwsCommonFields.RESOURCE,
      AwsCommonFields.ACCOUNT_TAGS,
      AzureCommonFields.VIRTUAL_CUSTOM_TAGS,
    ],
    [CLOUD_TYPE_IDS.MULTI]: [
      AwsCommonFields.COST_TYPE,
      AwsCommonFields.CLOUD_PROVIDER,
      AwsCommonFields.PAYER_ACCOUNT,
      AwsCommonFields.LINKED_ACCOUNT_ID,
      AwsCommonFields.SERVICE,
      AwsCommonFields.DIVISION,
      AwsCommonFields.OPERATION,
      AwsCommonFields.BUSINESS_MAPPING,
      AwsCommonFields.FAMILY_TYPE,
      AwsCommonFields.REGION,
      AwsCommonFields.CATEGORIES,
      AwsCommonFields.ACCOUNT_TAGS,
    ],
  };
  return filters[cloudType] || [];
};
export const getAlertsFiltersByCloudType = (cloudType) => {
  const baseFilters = [
    AwsCommonFields.SERVICE,
    AwsCommonFields.LINKED_ACCOUNT_NAME,
    AwsCommonFields.FAMILY_TYPE,
    AwsCommonFields.DIVISION,
    AwsCommonFields.CHARGE_TYPE,
    // AwsCommonFields.BUSINESS_MAPPING,
    AwsCommonFields.ACCOUNT_TAGS,
    AwsCommonFields.USAGE_TYPE,
  ];
  const alertsFilters = {
    [CLOUD_TYPE_IDS.AWS]: [...baseFilters, AwsCommonFields.CUSTOM_TAGS],
    [CLOUD_TYPE_IDS.GCP]: baseFilters,
    [CLOUD_TYPE_IDS.AZURE]: [...baseFilters, AwsCommonFields.CUSTOM_TAGS],
  };
  return alertsFilters[cloudType] || [];
};
const getMetricsFiltersArrByCloudType = (cloudType) => {
  const filters = {
    [CLOUD_TYPE_IDS.AWS]: [AwsCommonFields.LINKED_ACCOUNT_ID, AwsCommonFields.INSTANCE_TYPE, AwsCommonFields.RESOURCE],
  };
  return filters[cloudType] || [];
};
const getCueFiltersArrByCloudType = (cloudType, fetchK8S) => {
  const filters = {
    [CLOUD_TYPE_IDS.MULTI]: [
      AwsCommonFields.CLOUD_PROVIDER,
      AwsCommonFields.PAYER_ACCOUNT,
      AwsCommonFields.LINKED_ACCOUNT_ID,
      AwsCommonFields.SERVICE,
      AwsCommonFields.DIVISION,
      AwsCommonFields.OPERATION,
      AwsCommonFields.CATEGORIES,
      AwsCommonFields.BUSINESS_MAPPING,
      AwsCommonFields.FAMILY_TYPE,
      AwsCommonFields.REGION,
      AwsCommonFields.ACCOUNT_TAGS,
    ],
    [CLOUD_TYPE_IDS.AWS]: [
      AwsCommonFields.REGION,
      AwsCommonFields.LINKED_ACCOUNT_ID,
      AwsCommonFields.SERVICE,
      AwsCommonFields.DIVISION,
      AwsCommonFields.CUSTOM_TAGS,
      AwsCommonFields.SUB_VIEWS_CUSTOM_TAGS,
      AwsCommonFields.VIRTUAL_CUSTOM_TAGS,
      AwsCommonFields.PAYER_ACCOUNT,
      AwsCommonFields.QUANTITY_TYPE,
      AwsCommonFields.OPERATION,
      AwsCommonFields.PURCHASE_OPTION,
      AwsCommonFields.FAMILY_TYPE,
      AwsCommonFields.INSTANCE_TYPE,
      AwsCommonFields.SUB_SERVICE,
      AwsCommonFields.CHARGE_TYPE,
      AwsCommonFields.CATEGORIES,
      AwsCommonFields.BUSINESS_MAPPING,
      AwsCommonFields.USAGE_TYPE,
      AwsCommonFields.AVAILABILITY_ZONE,
      AwsCommonFields.RESOURCE,
      AwsCommonFields.ACCOUNT_TAGS,
      AwsCommonFields.NORMALIZATION_FACTOR,
      AwsCommonFields.COST_CATEGORY,
      AwsCommonFields.TAX_TYPE,
      AwsCommonFields.BILL_TYPE,
      AwsCommonFields.INVOICE_ID,
      AwsCommonFields.PRODUCT_NAME,
      AwsCommonFields.PLATFORM,
      AwsCommonFields.DB_ENGINE,
      AwsCommonFields.BILLING_ENTITY,
      AwsCommonFields.LEGAL_ENTITY,
      AwsCommonFields.INSTANCE_TYPE_FAMILY,
      AwsCommonFields.PAYMENT_OPTION,
    ],
    [CLOUD_TYPE_IDS.GCP]: [
      AwsCommonFields.REGION,
      AwsCommonFields.LINKED_ACCOUNT_ID,
      AwsCommonFields.PROJECT,
      AwsCommonFields.SERVICE,
      AwsCommonFields.OPERATION,
      AwsCommonFields.FAMILY_TYPE,
      AwsCommonFields.INSTANCE_TYPE,
      AwsCommonFields.DIVISION,
      AwsCommonFields.CUSTOM_TAGS,
      GcpCommonFields.COST_TYPE_DESCRIPTION,
      AwsCommonFields.CATEGORIES,
      AwsCommonFields.BUSINESS_MAPPING,
      AwsCommonFields.RESOURCE,
      AwsCommonFields.SUB_VIEWS_CUSTOM_TAGS,
      AwsCommonFields.ACCOUNT_TAGS,
    ],
    [CLOUD_TYPE_IDS.AZURE]: [
      AzureCommonFields.SUBSCRIPTION_ID,
      AzureCommonFields.RESOURCE_GROUP,
      AzureCommonFields.METER_CATEGORY,
      AzureCommonFields.SUB_METER_CATEGORY,
      AwsCommonFields.REGION,
      AwsCommonFields.SERVICE,
      AwsCommonFields.OPERATION,
      AwsCommonFields.PURCHASE_OPTION,
      AwsCommonFields.PAYER_ACCOUNT,
      AzureCommonFields.LINE_TYPE,
      AwsCommonFields.QUANTITY_TYPE,
      AwsCommonFields.DIVISION,
      AwsCommonFields.CUSTOM_TAGS,
      AwsCommonFields.VIRTUAL_CUSTOM_TAGS,
      AwsCommonFields.SUB_VIEWS_CUSTOM_TAGS,
      AwsCommonFields.CATEGORIES,
      AwsCommonFields.BUSINESS_MAPPING,
      AwsCommonFields.USAGE_TYPE,
      AwsCommonFields.RESOURCE,
      AwsCommonFields.ACCOUNT_TAGS,
      AzureCommonFields.BENEFIT,
    ],
  };
  if (fetchK8S) {
    filters[CLOUD_TYPE_IDS.AWS].push(
      ...[
        AwsCommonFields.K8S_NAMESPACE,
        AwsCommonFields.CUE_K8S_LABEL_TAGS,
        AwsCommonFields.K8S_CLUSTER,
        AwsCommonFields.K8S_NODE_GROUP,
      ],
    );
  }
  return filters[cloudType] || [];
};
const getCueCategoriesFiltersArrByCloudType = (cloudType, fetchK8S) => {
  const filters = {
    [CLOUD_TYPE_IDS.AWS]: [
      AwsCommonFields.REGION,
      AwsCommonFields.LINKED_ACCOUNT_ID,
      AwsCommonFields.SERVICE,
      AwsCommonFields.DIVISION,
      AwsCommonFields.CUSTOM_TAGS,
      AwsCommonFields.VIRTUAL_CUSTOM_TAGS,
      AwsCommonFields.SUB_VIEWS_CUSTOM_TAGS,
      AwsCommonFields.PAYER_ACCOUNT,
      AwsCommonFields.OPERATION,
      AwsCommonFields.PURCHASE_OPTION,
      AwsCommonFields.FAMILY_TYPE,
      AwsCommonFields.INSTANCE_TYPE,
      AwsCommonFields.SUB_SERVICE,
      AwsCommonFields.CHARGE_TYPE,
      AwsCommonFields.AVAILABILITY_ZONE,
      AwsCommonFields.RESOURCE,
      AwsCommonFields.USAGE_TYPE,
      AwsCommonFields.CATEGORIES,
      AwsCommonFields.ACCOUNT_TAGS,
      AwsCommonFields.ITEM_DESCRIPTION,
      AwsCommonFields.NORMALIZATION_FACTOR,
      AwsCommonFields.COST_CATEGORY,
      AwsCommonFields.TAX_TYPE,
      AwsCommonFields.PLATFORM,
      AwsCommonFields.DB_ENGINE,
      AwsCommonFields.BILLING_ENTITY,
      AwsCommonFields.LEGAL_ENTITY,
      AwsCommonFields.INSTANCE_TYPE_FAMILY,
      AwsCommonFields.PAYMENT_OPTION,
    ],
    [CLOUD_TYPE_IDS.GCP]: [
      AwsCommonFields.REGION,
      AwsCommonFields.LINKED_ACCOUNT_ID,
      AwsCommonFields.SERVICE,
      AwsCommonFields.OPERATION,
      AwsCommonFields.FAMILY_TYPE,
      AwsCommonFields.INSTANCE_TYPE,
      AwsCommonFields.DIVISION,
      GcpCommonFields.COST_TYPE_DESCRIPTION,
      AwsCommonFields.CUSTOM_TAGS,
      AwsCommonFields.VIRTUAL_CUSTOM_TAGS,
      AwsCommonFields.SUB_VIEWS_CUSTOM_TAGS,
      AwsCommonFields.RESOURCE,
      AwsCommonFields.CATEGORIES,
      AwsCommonFields.ACCOUNT_TAGS,
      AwsCommonFields.ITEM_DESCRIPTION,
    ],
    [CLOUD_TYPE_IDS.AZURE]: [
      AzureCommonFields.SUBSCRIPTION_ID,
      AzureCommonFields.RESOURCE_GROUP,
      AzureCommonFields.METER_CATEGORY,
      AzureCommonFields.SUB_METER_CATEGORY,
      AwsCommonFields.REGION,
      AwsCommonFields.SERVICE,
      AwsCommonFields.OPERATION,
      AwsCommonFields.PURCHASE_OPTION,
      AwsCommonFields.PAYER_ACCOUNT,
      AzureCommonFields.LINE_TYPE,
      AzureCommonFields.BENEFIT,
      AwsCommonFields.DIVISION,
      AwsCommonFields.CUSTOM_TAGS,
      AwsCommonFields.VIRTUAL_CUSTOM_TAGS,
      AwsCommonFields.SUB_VIEWS_CUSTOM_TAGS,
      AwsCommonFields.RESOURCE,
      AwsCommonFields.USAGE_TYPE,
      AwsCommonFields.CATEGORIES,
      AwsCommonFields.ACCOUNT_TAGS,
      AwsCommonFields.ITEM_DESCRIPTION,
    ],
    [CLOUD_TYPE_IDS.MULTI]: [
      AwsCommonFields.CLOUD_PROVIDER,
      AwsCommonFields.PAYER_ACCOUNT,
      AwsCommonFields.LINKED_ACCOUNT_ID,
      AwsCommonFields.SERVICE,
      AwsCommonFields.DIVISION,
      AwsCommonFields.OPERATION,
      AwsCommonFields.FAMILY_TYPE,
      AwsCommonFields.REGION,
      AwsCommonFields.CATEGORIES,
      AwsCommonFields.ACCOUNT_TAGS,
    ],
  };
  if (fetchK8S) {
    filters[CLOUD_TYPE_IDS.AWS].push(
      ...[
        AwsCommonFields.K8S_NAMESPACE,
        AwsCommonFields.CUE_K8S_LABEL_TAGS,
        AwsCommonFields.K8S_CLUSTER,
        AwsCommonFields.K8S_NODE_GROUP,
      ],
    );
  }
  return filters[cloudType] || [];
};
const getK8SFiltersArrByCloudType = (cloudType) => {
  const filters = {
    [CLOUD_TYPE_IDS.AWS]: [
      AwsCommonFields.CLUSTER,
      AwsCommonFields.NODE,
      AwsCommonFields.POD,
      AwsCommonFields.NODE_GROUP,
      AwsCommonFields.WORKLOAD,
      AwsCommonFields.NAMESPACE,
      AwsCommonFields.K8S_REGION,
      AwsCommonFields.LINKED_ACCOUNT_ID,
      AwsCommonFields.PAYER_ACCOUNT,
      AwsCommonFields.K8S_INSTANCE_TYPE,
      AwsCommonFields.K8S_LABEL_TAGS,
      AwsCommonFields.CUSTOM_TAGS,
      AwsCommonFields.BUSINESS_MAPPING,
      AwsCommonFields.SUB_VIEWS_CUSTOM_TAGS,
      AwsCommonFields.ACCOUNT_TAGS,
    ],
    [CLOUD_TYPE_IDS.AZURE]: [
      AzureCommonFields.CLUSTER,
      AzureCommonFields.NODE,
      AwsCommonFields.POD,
      AzureCommonFields.NODE_GROUP,
      AwsCommonFields.WORKLOAD,
      AzureCommonFields.NAMESPACE,
      AzureCommonFields.K8S_REGION,
      AzureCommonFields.SUBSCRIPTION_ID,
      AwsCommonFields.K8S_INSTANCE_TYPE,
      AzureCommonFields.K8S_LABEL_TAGS,
      AwsCommonFields.ACCOUNT_TAGS,
      AzureCommonFields.BUSINESS_MAPPING,
    ],
    [CLOUD_TYPE_IDS.GCP]: [
      GcpCommonFields.CLUSTER,
      GcpCommonFields.NODE,
      AwsCommonFields.POD,
      GcpCommonFields.NODE_GROUP,
      GcpCommonFields.NAMESPACE,
      GcpCommonFields.K8S_REGION,
      GcpCommonFields.LINKED_ACCOUNT_ID,
      GcpCommonFields.PAYER_ACCOUNT,
      GcpCommonFields.K8S_INSTANCE_TYPE,
      GcpCommonFields.K8S_LABEL_TAGS,
      GcpCommonFields.CUSTOM_TAGS,
      GcpCommonFields.BUSINESS_MAPPING,
      GcpCommonFields.SUB_VIEWS_CUSTOM_TAGS,
      AwsCommonFields.ACCOUNT_TAGS,
    ],
  };
  return filters[cloudType] || [];
};
const getCueBusinessMappingsFiltersArrByCloudType = (cloudType, fetchK8S) => {
  const categoriesFilters = getCueCategoriesFiltersArrByCloudType(cloudType, fetchK8S);
  return categoriesFilters.filter((c) => ![AwsCommonFields.CATEGORIES].includes(c) && !K8S_CUE_FIELDS.includes(c));
};
const getK8SBusinessMappingsFiltersArrByCloudType = (cloudType, fetchK8S) => {
  const k8sFilters = getK8SFiltersArrByCloudType(cloudType, fetchK8S);
  return k8sFilters.filter((c) => ![AwsCommonFields.BUSINESS_MAPPING].includes(c));
};
const getCueDashboardFiltersArrByCloudType = (cloudType, fetchK8S) => {
  const categoriesFilters = getCueCategoriesFiltersArrByCloudType(cloudType, fetchK8S);
  return [...categoriesFilters, AwsCommonFields.BUSINESS_MAPPING];
};

// eslint-disable-next-line import/prefer-default-export
export const mapPageNameToFiltersList = new Map([
  [PageNames.COST_AND_USAGE_EXPLORER, getCueFiltersArrByCloudType],
  [PageNames.METRICS_EXPLORER, getMetricsFiltersArrByCloudType],
  [PageNames.FILTER_GROUP, getCueCategoriesFiltersArrByCloudType],
  [PageNames.BUSINESS_MAPPING, getCueBusinessMappingsFiltersArrByCloudType],
  [PageNames.BUSINESS_MAPPING_K8S, getK8SBusinessMappingsFiltersArrByCloudType],
  [PageNames.CUSTOM_DASHBOARD, getCueDashboardFiltersArrByCloudType],
  [PageNames.CUE_ALERTS, getAlertsFiltersByCloudType],
  [
    PageNames.EC2_USAGE,
    () => [
      AwsCommonFields.REGION,
      AwsCommonFields.INSTANCE_TYPE,
      AwsCommonFields.LINKED_ACCOUNT_ID,
      AwsCommonFields.OS,
    ],
  ],
  [
    PageNames.SAVINGS_PLANS_ANALYZER,
    () => [
      // AwsCommonFields.OS, // TODO - uncomment once we will suport OS filter
      // AwsCommonFields.ENVIRONMENT,
      // AwsCommonFields.PROJECT,
      // AwsCommonFields.SERVICE, // TODO - uncomment once we will suport service filter
      AwsCommonFields.INSTANCE_TYPE,
      AwsCommonFields.LINKED_ACCOUNT_ID,
    ],
  ],
  [PageNames.BUDGET, getBudgetFiltersArrByCloudType],
  [PageNames.AWS_RESELLER, () => [AwsCommonFields.SERVICE, AwsCommonFields.OPERATION, AwsCommonFields.REGION]],
  [PageNames.RECOMMENDATIONS_REPORTS, () => [AwsCommonFields.LINKED_ACCOUNT_NAME, AwsCommonFields.DIVISION]],
  [PageNames.CUSTOMERS_REPORTS, () => [AwsCommonFields.DIVISION]],
  [
    PageNames.RECOMMENDATION_DASHBOARD,
    () => [AwsCommonFields.LINKED_ACCOUNT_ID, AwsCommonFields.ACCOUNT_TAGS, AwsCommonFields.REC_DB_CUSTOM_TAGS],
  ],
  [PageNames.ANOMALY_DETECTION, () => [AwsCommonFields.LINKED_ACCOUNT_ID, AwsCommonFields.SERVICE]],
  [PageNames.IDLE_INSTANCES, () => [AwsCommonFields.LINKED_ACCOUNT_ID]],
  [PageNames.EBS_USAGE, () => [AwsCommonFields.LINKED_ACCOUNT_NAME]],
  [PageNames.RI_UTILIZATION, () => [AwsCommonFields.LINKED_ACCOUNT_ID, AwsCommonFields.PAYER_ACCOUNT]],
  // removed pods filter - too heavy for the browser, > 2M pods
  [PageNames.K8S_USAGE, getK8SFiltersArrByCloudType],
]);
