import React from 'react';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { Routes } from 'shared/constants/routes';
import { spAnalyzerContext } from 'commitment/containers/spAnalyzerNew/contexts/spAnalyzerContext';
import styles from './planPreferences.module.scss';

export const PlanPreferences = () => {
  const { preferences } = spAnalyzerContext();
  const getTooltipText = () => (
    <div className={styles.tooltipWrapper}>
      <div>
        Days to check: <span className={styles.value}> {preferences?.daysToCheck}</span>
      </div>
      <div>
        Coverage %:<span className={styles.value}> {preferences?.coveragePercentage} </span>
      </div>
      <div>
        Term: <span className={styles.value}> {preferences?.term}</span>
      </div>
      <div>
        Payment Option:<span className={styles.value}> {preferences?.paymentOption} </span>
      </div>
      <div className={styles.urlText}>
        *As configured in the
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={Routes.RECOMMENDATION_PREFERENCES}
          automation-id="preferncesLink"
        >
          <span className={styles.link}> Recommendation preferences page</span>
        </a>
      </div>
    </div>
  );
  return (
    <div className={styles.preferences}>
      <Tooltip title={getTooltipText()}>
        <span>MY PREFERENCES</span>
        <div className={styles.flexWrapper} automation-id="openPreferncesLink">
          <GenerateIcon iconName={ICONS.gearRegular.name} className={styles.blueIcon} />
          <span className={styles.linq}>Open</span>
        </div>
      </Tooltip>
    </div>
  );
};
