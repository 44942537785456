import React, { useCallback, useState } from 'react';
import CustomModal from 'shared/components/andtComponents/Modal';
import { validateRecipients } from 'shared/utils/sharedUtils.js';
import { Option, ServiceCostAlert } from '../types.ts';
import FormServiceCost from './FormServiceCost.tsx';
import styles from '../alerts.module.scss';
import {
  CAU_ALERTS_GRAN_LEVELS,
  CAU_ALERTS_OPERATOR_TYPE_VALUES,
  CAU_ALERTS_WEEK_DAYS,
} from '../../../constants/usageConstants';

interface ServiceCostModalProps {
  onClose(): void;

  onUpdate(newAlert: ServiceCostAlert): void;

  isEdit?: boolean;
  alert?: ServiceCostAlert | null;
}

const UpdateServiceCostModal: React.FC<ServiceCostModalProps> = ({ onClose, isEdit, alert, onUpdate }) => {
  const [newAlert, setNewAlert] = useState(alert || ({ filters: {} } as ServiceCostAlert));
  const [showErrors, setShowErrors] = useState(false);
  const isRecipientsValid = validateRecipients(newAlert.recipientsEmails, newAlert.recipients);
  const isChangesValid =
    !!newAlert.alertName &&
    (newAlert.operatorType.value === CAU_ALERTS_OPERATOR_TYPE_VALUES.OR
      ? [newAlert.changePercent, newAlert.changeValue].some((d) => Boolean(Number(d)))
      : Boolean(Number(newAlert.changePercent) && Number(newAlert.changeValue)));
  const setAlert = useCallback((key: keyof ServiceCostAlert | string, value: string | number) => {
    setShowErrors(false);
    setNewAlert((s) => {
      const clone: ServiceCostAlert = { ...s };
      if (key.startsWith('filters.')) {
        const [_, filterName, filterField] = key.split('.');
        const newFilters: ServiceCostAlert['filters'] = JSON.parse(JSON.stringify(clone.filters));
        newFilters[filterName] = newFilters[filterName] || { operator: 'IS', value: [] };
        newFilters[filterName][filterField] = value;
        clone.filters = newFilters;
      } else {
        if (key === 'dayInWeek') {
          clone[key] = clone[key] || ({} as Option);
          (clone[key] || ({} as Option)).value = value;
          return clone;
        }
        if (key === 'granularity' && Number(value) === CAU_ALERTS_GRAN_LEVELS.DAY_IN_WEEK) {
          clone.dayInWeek = clone.dayInWeek || ({} as Option);
          clone.dayInWeek.value = clone.dayInWeek.value || String(CAU_ALERTS_WEEK_DAYS.MONDAY);
        }
        if (clone[key] !== null && typeof clone[key] === 'object' && 'value' in (clone[key] || {})) {
          (clone[key] || {}).value = value;
          return clone;
        }
        clone[key] = value;
      }
      return clone;
    });
  }, []);

  const onSave = useCallback(
    (e) => {
      if (isRecipientsValid && isChangesValid) {
        onUpdate(newAlert);
      } else {
        e.preventDefault();
        e.stopPropagation();
        setShowErrors(true);
      }
    },
    [onUpdate, newAlert, isChangesValid],
  );

  return (
    <CustomModal
      className={styles.bigModal}
      onClose={onClose}
      onCloseClick={onClose}
      headerMode={isEdit ? 'edit' : 'add'}
      onSave={onSave}
      open
      closeOnSave={false}
      saveTitle={isEdit ? 'Edit' : 'Create'}
      saveDisabled={showErrors && (!isRecipientsValid || !newAlert.changePercent || !newAlert.changeValue)}
      title={`${isEdit ? 'Edit' : 'Create'} Alert`}
      automationId="update-alert-modal"
    >
      <FormServiceCost alert={newAlert} setAlert={setAlert} showErrors={showErrors} isChangesValid={isChangesValid} />
    </CustomModal>
  );
};

export default UpdateServiceCostModal;
