import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { SelectMulti, ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import Input from 'shared/components/andtComponents/Input';
import CustomModal from 'shared/components/andtComponents/Modal';
import toast from 'shared/components/andtComponents/Toast';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import styles from './CreateOrUpdateCustomerModal.module.scss';

const CreateOrUpdateCustomerModal = ({ editedCustomer, handleUpdateLinkedAccounts, onClose, linkedAccountsMode }) => {
  const { usersStore } = useRootStore();
  const customers = usersStore.usersModel.divisionGroups;
  const accountId = usersStore.getCurrDisplayedAccountId();
  const [customerName, setCustomerName] = useState(
    editedCustomer ? editedCustomer.divisionNameDisplay || editedCustomer.divisionName : '',
  );
  const [customerCode, setCustomerCode] = useState(editedCustomer ? editedCustomer.divisionCode : '');
  const [linkedAccounts, setLinkedAccounts] = useState(
    editedCustomer
      ? editedCustomer.linkedAccounts.map((l) => ({
          label: l.linkedAccountName,
          value: l.linkedAccountId,
        }))
      : [],
  );
  const getPayerAccount = () => {
    const { currDispUserCloudAccountType, getCurrDisplayedAccount } = usersStore;
    const account = getCurrDisplayedAccount;
    if (account && currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS) {
      const linkedAccountId = account.accountId;
      const linkedAccountName = account.accountName.split('(')[0];
      return { linkedAccountId, linkedAccountName };
    }
    return null;
  };
  const addPayerLinkedAccountToLinkedAccounts = (linkedAccounts, payerLinkedAccount) => {
    if (!payerLinkedAccount) {
      return;
    }
    const allSelectedLinkedAccounts = [];
    customers.forEach((div) => {
      const linkedAccountIds = div.linkedAccounts.map((la) => la.linkedAccountId);
      allSelectedLinkedAccounts.push(...linkedAccountIds);
    });
    const isPayerAccountAlreadySelected = allSelectedLinkedAccounts.includes(payerLinkedAccount.linkedAccountId);
    if (!isPayerAccountAlreadySelected) {
      linkedAccounts.unshift({
        label: `${payerLinkedAccount.linkedAccountName} (Payer Account)`,
        value: payerLinkedAccount.linkedAccountId,
      });
    }
  };
  const linkedAccountNames = useMemo(() => {
    const linkedAccounts = [
      ...(editedCustomer
        ? editedCustomer.linkedAccounts.map((l) => ({
            label: l.linkedAccountName,
            value: l.linkedAccountId,
          }))
        : []),
      ...usersStore.usersModel.availbleDivisionLinkedAccounts.map((ln) => ({
        label: ln.linkedAccountName,
        value: ln.linkedAccountId,
      })),
    ];
    addPayerLinkedAccountToLinkedAccounts(linkedAccounts, getPayerAccount());
    return linkedAccounts;
  }, [usersStore.usersModel.availbleDivisionLinkedAccounts, editedCustomer, accountId]);
  const nameTaken = useMemo(
    () =>
      customers
        .filter((c) => !editedCustomer || c.divisionId !== editedCustomer.divisionId)
        .some((c) => c.divisionName?.toLowerCase() === customerName?.toLowerCase()),
    [customerName, customers, editedCustomer],
  );
  const handleSaveCustomer = async () => {
    const params = {
      divisionName: customerName,
      divisionCode: customerCode,
      linkedAccounts: linkedAccounts.map((l) => ({
        linkedAccountId: l.value,
        linkedAccountName: l.label,
      })),
    };
    try {
      if (!editedCustomer) {
        await usersStore.createNewDivisionGroup(
          customerName,
          linkedAccounts.map((a) => a.value),
          params,
        );
      } else if (linkedAccountsMode) {
        await handleUpdateLinkedAccounts(
          editedCustomer,
          linkedAccounts.map((ln) => ({
            linkedAccountId: ln.value,
            linkedAccountName: ln.label,
          })),
        );
      } else {
        await usersStore.updateDivision(editedCustomer.divisionId, customerName, customerCode);
      }
      onClose();
    } catch (e) {
      toast.error('Something went wrong please try again later');
    }
  };
  const headerMode = editedCustomer ? 'edit' : 'add';
  return (
    <CustomModal
      saveDisabled={!customerName || !linkedAccounts.length || nameTaken}
      className={styles.modal}
      // eslint-disable-next-line no-nested-ternary
      title={linkedAccountsMode ? '' : editedCustomer ? 'Edit Customer' : 'Create Customer'}
      open
      onClose={onClose}
      onSave={handleSaveCustomer}
      headerMode={linkedAccountsMode ? undefined : headerMode}
      automationId={`${editedCustomer ? 'edit' : 'create'}CustomerModal`}
    >
      <div className={styles.container}>
        {!linkedAccountsMode ? (
          <>
            <div>
              <p className={styles.label}>Customer Name</p>
              <Input
                label="Customer Name"
                name="customerName"
                automationId="createUpdateCustomerName"
                isInvalid={nameTaken}
                invalidComponent={nameTaken ? 'Customer name already exists' : null}
                overrideStyles={{ height: 36 }}
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value.replace(/[^\sa-z0-9_-]/gi, ''))}
                placeholder="Enter customer name"
              />
            </div>
            <div>
              <p className={styles.label}>Customer Code</p>
              <Input
                label="Customer Code"
                name="customerCode"
                automationId="createUpdateCustomerCode"
                overrideStyles={{ height: 36 }}
                value={customerCode}
                onChange={(e) => setCustomerCode(e.target.value)}
                placeholder="Enter customer code"
              />
            </div>
          </>
        ) : null}
        {linkedAccountsMode || !editedCustomer ? (
          <div>
            <p className={styles.label}>Linked Account</p>
            <div className={styles.selectContainer} automation-id="createCustomerLinkedAccount">
              <SelectMulti
                label="Linked Account"
                showCounter
                options={linkedAccountNames}
                handleSelectionChange={(vals) => {
                  setLinkedAccounts(vals);
                }}
                selected={linkedAccounts}
              />
            </div>
            {linkedAccounts.length > 0 && (
              <div className={styles.chipList}>
                {linkedAccounts.map((ln) => (
                  <Chip
                    key={ln.value}
                    label={ln.label}
                    deleteIcon={<GenerateIcon iconName={ICONS.xMark.name} className="chip-delete-icon" />}
                    onDelete={() => setLinkedAccounts(linkedAccounts.filter((l) => ln !== l))}
                  />
                ))}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </CustomModal>
  );
};

CreateOrUpdateCustomerModal.propTypes = {
  handleUpdateLinkedAccounts: PropTypes.func,
  editedCustomer: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  linkedAccountsMode: PropTypes.bool.isRequired,
};

CreateOrUpdateCustomerModal.defaultProps = {
  editedCustomer: null,
  handleUpdateLinkedAccounts: () => {},
};

export default CreateOrUpdateCustomerModal;
