import React, { useMemo } from 'react';
import { isDecimalNeeded } from 'shared/utils/strUtil';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import moment from 'moment';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import styles from './recommendedChart.module.scss';

export const CustomChartTooltip = ({ active, payload, turnedOffLines, clickedLineId, tooltipMapData }) => {
  const getTooltipFormat = (value) => {
    const { currencySymbol } = useUserSettingsContext();
    if (value === null || value === undefined) {
      return '-';
    }
    return `${Number(value).toFixed(isDecimalNeeded(value, 2) ? 2 : 0)}${currencySymbol}`;
  };

  const getDateFormat = (date) => {
    const format = date.includes(' ') ? 'MMM DD YYYY HH:mm' : 'MMM DD YYYY';
    return moment(date).format(format);
  };

  const turnedOnLines = useMemo(() => {
    let lines = payload?.filter((entry) => !turnedOffLines?.includes(`${entry.dataKey}Legend`));
    if (clickedLineId) {
      const clickedLine = lines?.filter((entry) => entry.id === clickedLineId);
      if (clickedLine) {
        lines = clickedLine;
      }
    }
    return lines;
  }, [payload, turnedOffLines, clickedLineId]);

  const calculateSumOfFields = (fields) =>
    turnedOnLines.filter((line) => fields.includes(line.dataKey))?.reduce((sum, entry) => sum + Number(entry.value), 0);
  const getTotalRowValue = (summeryRow) => {
    if (summeryRow.displayField) {
      return getTooltipFormat(payload[0].payload[summeryRow.displayField]);
    }
    if (summeryRow.totalFields) {
      const totalValue = calculateSumOfFields(summeryRow.totalFields);
      return getTooltipFormat(totalValue);
    }
    if (summeryRow.minusFields?.length) {
      const calculatedFields = summeryRow.minusFields.map((field) => payload[0].payload[field]);
      return getTooltipFormat(calculatedFields.reduce((a, b) => Number(a) - Number(b)));
    }
    return '';
  };

  if (active && payload?.length && turnedOnLines?.length) {
    const dataPoint = payload[0]?.payload;
    return (
      <div className={styles.customizedTooltip}>
        <div className={styles.tooltipTitleContainer}>
          <GenerateIcon iconName={ICONS.calendarDay.name} />
          <div className={styles.tooltipTitle}>{getDateFormat(payload[0].payload.date)}</div>
        </div>
        <div className={styles.tooltipDataContainer}>
          {tooltipMapData?.fields?.map((entry) => (
            <div className={styles.tooltipDataPair}>
              <div className={styles.tooltipDataCircle} style={{ backgroundColor: entry.color }} />
              <div className={styles.tooltipData}>
                <span>{entry.name}</span>
                <span>{`${getTooltipFormat(dataPoint[entry.key])}`}</span>
              </div>
            </div>
          ))}
          {tooltipMapData?.summary?.length && (
            <div className={styles.totalRowWrapper}>
              {tooltipMapData.summary.map((row) => (
                <div className={`${styles.totalRow} ${row.minusFields?.length && styles.specialRow}`}>
                  <span className={styles.totalData}>{row.name}</span>
                  <span className={styles.totalData}> {`${getTotalRowValue(row)}`}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
  return null;
};
