import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomModal from 'shared/components/andtComponents/Modal';
import Stepper from 'shared/components/andtComponents/Stepper/Stepper';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { getFilterStatusTypeMapByCloudType } from 'usage/containers/Budget/budgetUtil';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { mapBudgetParams } from 'usage/containers/Budget/hooks/useBudgets';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import CBDetailsAndFilters from './CBDetailsAndFilters';
import CBSettings from './CBSettings';
import CBAlerts from './CBAlerts';
import classes from './budgetModal.module.scss';
import { CustomProgress } from './CustomProgress';
import { validateRecipients } from 'shared/utils/sharedUtils';

const BUDGET_STEPS_CONFIG = [
  {
    title: 'Details & Filters',
    titleShort: 'Details & Filters',
    component: CBDetailsAndFilters,
  },
  {
    title: 'Settings',
    titleShort: 'Settings',
    component: CBSettings,
  },
  {
    title: 'Alerts',
    titleShort: 'Alerts',
    component: CBAlerts,
  },
];

const BudgetModal = ({
  fieldToFieldDistincValuesMap,
  saveBudget,
  editBudget,
  budgetsNames,
  isOpen,
  setIsOpen,
  newBudget,
  isLoading,
}) => {
  const [screenIndex, setScreenIndex] = useState(0);
  const [saveClicked, setSaveClicked] = useState(false);
  const { usersStore } = useRootStore();
  const [filterMapTypes, setFilterMapTypes] = useState(
    getFilterStatusTypeMapByCloudType(usersStore.currDispUserCloudAccountType, editBudget, [
      AwsCommonFields.CHARGE_TYPE,
    ]),
  );
  const [budget, setBudget] = useState(editBudget ? { ...editBudget } : newBudget);
  const [likeFiltersStatus, setLikeFiltersStatus] = useState({});
  const [budgetValidations, setBudgetValidations] = useState({});
  const Page = BUDGET_STEPS_CONFIG[screenIndex].component;

  const isValidTab = (indexTab, budget) => {
    let validations = {};

    switch (indexTab) {
      case 0:
        if (!budget.budgetName) {
          validations = { ...validations, budgetName: 'Budget Name Is Required' };
        } else if (budgetsNames.includes(budget.budgetName)) {
          validations = { ...validations, budgetNameUnique: 'Budget Name Already Exists' };
        }
        if (usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS && !budget.costType) {
          validations = { ...validations, costType: 'Cost Basis Is Required' };
        }
        setBudgetValidations(validations);
        return !Object.keys(validations).length > 0;
      case 1:
        if (new Date(budget.endDate) < new Date()) {
          validations = { endDate: 'The budget end date must be after the current month.' };
        }
        if (
          (budget.budgetType?.value !== 'expiring' && !budget.budgetAmount) ||
          (budget.budgetType?.value === 'expiring' &&
            (!budget.budgetAmounts?.length || budget.budgetAmounts.find((b) => !b.amount)))
        ) {
          validations = { ...validations, budgetAmount: 'Amount Is Required' };
        }
        if (!budget.budgetType) {
          validations = { ...validations, budgetType: 'Budget Type Is Required' };
        }
        setBudgetValidations(validations);
        return !Object.keys(validations).length > 0;
      case 2:
        budget.alerts?.forEach((alert) => {
          if (!alert.whenToAlert?.length) {
            validations = { ...validations, alertTrigger: 'Alert Trigger Is Required' };
          }
          if (!alert.alertGranularity?.length) {
            validations = { ...validations, alertGranularity: 'Alert Granularity Is Required' };
          }
          if (!alert.alertPercent) {
            validations = { ...validations, alertPercent: 'Alert Percent Is Required' };
          }
          if (Number(alert.alertPercent) < 0) {
            validations = { ...validations, alertPercent: 'Alert Percent Can Not Be Negative' };
          }
          if (!validateRecipients(alert.alertEmail, alert.channelRecipients)) {
            validations = {
              ...validations,
              alertRecipient:
                alert.alertEmail || alert.channelRecipients?.filter((r) => r.recipientData.type !== 'SENDGRID').length
                  ? 'One or more recipients emails are not valid'
                  : 'Select at least one recipient',
            };
          }
          setBudgetValidations(validations);
        });
        return !Object.keys(validations).length > 0;
      default:
        return true;
    }
  };

  const setNextPage = () => {
    setSaveClicked(true);
    if (!isValidTab(screenIndex, budget)) {
      return;
    }
    if (screenIndex + 1 === BUDGET_STEPS_CONFIG.length) {
      saveBudget(mapBudgetParams(budget));
    } else {
      setScreenIndex(screenIndex + 1);
    }
  };

  return (
    <CustomModal
      className={classes}
      onClose={() => {}}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={editBudget ? 'edit' : 'add'}
      secondPrimaryTitle={
        <>
          <GenerateIcon iconName={ICONS.chevronLeft.name} />
          <span> Back </span>
        </>
      }
      footerDisabled={isLoading && saveClicked}
      onSecondPrimaryClick={() => setScreenIndex(screenIndex - 1)}
      onSave={setNextPage}
      open={isOpen}
      automationId={`${editBudget ? 'edit' : 'create'}-budget-modal`}
      saveTitle={
        screenIndex + 1 < BUDGET_STEPS_CONFIG.length ? (
          <>
            <span> Next </span>
            <GenerateIcon iconName={ICONS.chevronRight.name} />
          </>
        ) : (
          `${editBudget ? 'Save Changes' : 'Create Budget'}`
        )
      }
      saveDisabled={
        screenIndex + 1 === BUDGET_STEPS_CONFIG.length &&
        editBudget?.endDate &&
        new Date(editBudget?.endDate) < new Date()
      }
      title={`${editBudget ? 'Save' : 'Create'} Budget`}
      secondPrimaryDisabled={screenIndex === 0}
    >
      <Stepper activeStepIndex={screenIndex} steps={BUDGET_STEPS_CONFIG} />
      {isLoading && saveClicked ? (
        <CustomProgress
          setCloseWindow={(result) => setIsOpen(result)}
          isLoading={isLoading}
          isEditMode={!!editBudget}
        />
      ) : (
        <div className={classes.pageScreen}>
          <div className={classes.body}>
            {Page && (
              <Page
                budget={budget}
                setBudget={setBudget}
                budgetValidationErrors={budgetValidations}
                saveClicked={saveClicked}
                isEditMode={!!editBudget}
                fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
                filterMapTypes={filterMapTypes}
                setFilterMapTypes={setFilterMapTypes}
                likeFiltersStatus={likeFiltersStatus}
                setLikeFiltersStatus={setLikeFiltersStatus}
                budgetsNames={budgetsNames}
                cloudType={usersStore.currDispUserCloudAccountType}
              />
            )}
          </div>
        </div>
      )}
    </CustomModal>
  );
};

export default BudgetModal;

BudgetModal.propTypes = {
  fieldToFieldDistincValuesMap: PropTypes.object.isRequired,
  saveBudget: PropTypes.func.isRequired,
  editBudget: PropTypes.object,
  newBudget: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired,
  budgetsNames: PropTypes.array.isRequired,
};

BudgetModal.defaultProps = {
  editBudget: null,
  newBudget: null,
  isLoading: false,
};
