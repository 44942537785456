import { useMutation, useQueryClient } from 'react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';

const fetchRealmData = async (username) => {
  try {
    const realm = username.toLowerCase().includes('pileuscloud.com')
      ? null
      : await API.get('billings', `/api/v1/users/realm?username=${username.toLowerCase()}`);
    return realm;
  } catch (error) {
    return null;
  }
};

export const useUpdateUserRole = (mutationSettings = {}) =>
  useMutation({
    mutationFn: ({ userKey, newRoleId }) =>
      API.put('billings', '/api/v1/users/update-user-role', { body: { users: [{ userKey, newRoleId }] } }),
    ...mutationSettings,
  });

export const useUsers = () => {
  const queryClient = useQueryClient();
  return {
    fetchRealm: (username) =>
      queryClient.fetchQuery({
        queryKey: apiConstants.QUERY_KEYS.REALM,
        queryFn: async () => fetchRealmData(username),
      }),
  };
};
