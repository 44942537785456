/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'reactstrap';
import IconInputField from 'shared/components/IconInputField';
import IconInputTextArea from 'shared/components/IconInputTextArea';
import { CurrenciesSelect } from 'users/containers/Onboarding/shared/components/UserCurrency/CurrencySelect';
import OnboardingLinks from 'app/containers/Onboarding/utils/OnboardingLinks';

const nameValidation = {
  marginTop: '5px',
  color: 'red',
  fontSize: '10px',
};

class EaDetailsConfiguration extends PureComponent {
  static propTypes = {
    enrollmentId: PropTypes.string.isRequired,
    apiAccessKey: PropTypes.string.isRequired,
    accountName: PropTypes.string.isRequired,
    currencySymbol: PropTypes.string,
    apiAccessKeyEffectiveDate: PropTypes.string.isRequired,
    handleEaConfigInputsChange: PropTypes.func.isRequired,
    disableEnterKeySub: PropTypes.func.isRequired,
    handleSelectCurrency: PropTypes.func.isRequired,
    usersStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      nameIsValid: true,
    };
  }

  accountNameValidation = (name, e) => {
    const reg = /[^A-Za-z0-9 ]/;
    if (reg.test(name) || name.length > 15) {
      this.setState({ nameIsValid: false });
    } else {
      this.setState({ nameIsValid: true });
      this.props.handleEaConfigInputsChange(name, e);
    }
  };

  render() {
    const {
      enrollmentId,
      disableEnterKeySub,
      apiAccessKey,
      accountName,
      handleEaConfigInputsChange,
      apiAccessKeyEffectiveDate,
      currencySymbol,
      handleSelectCurrency,
      usersStore,
    } = this.props;
    const { nameIsValid } = this.state;

    return (
      <>
        <Row>
          <Col xs={12} md={12} lg={8} xl={8}>
            <h4 className="inner-wizard-items-header" style={{ paddingBottom: '20px' }}>
              <span>Complete the following fields:</span>
            </h4>
            <p>
              Please review the Connect Azure EA account guide <br />{' '}
              <a id="EaConnectGuide" rel="noopener noreferrer" target="_blank" href={OnboardingLinks.AZURE.eaGuide}>
                EA account guide{' '}
              </a>
            </p>
            <br />
            <Form onKeyPress={disableEnterKeySub} className="form">
              <div className="form__form-group">
                <IconInputField
                  icon="lnr lnr-cloud"
                  handler={this.accountNameValidation}
                  label="Display Account Name"
                  name="accountName"
                  value={accountName}
                  type="text"
                  placeHolder="Enter a display name for your account, length of 2 - 15 and alphanumeric characters"
                />
                {!nameIsValid && (
                  <div style={nameValidation}>Please use alphanumeric values only and length between 2-15</div>
                )}
              </div>
              <div className="form__form-group">
                <IconInputField
                  icon="lnr lnr-database"
                  handler={handleEaConfigInputsChange}
                  label="Enrollment Id"
                  name="enrollmentId"
                  value={enrollmentId}
                  type="text"
                  placeHolder="Enter your Enrollment Id"
                />
              </div>
              <div className="form__form-group">
                <IconInputTextArea
                  icon="lnr lnr-cloud"
                  handler={handleEaConfigInputsChange}
                  label="API Access Key"
                  name="apiAccessKey"
                  value={apiAccessKey}
                  // type="text"
                  placeHolder="Enter your API Access Key"
                />
              </div>
              <div className="form__form-group">
                <IconInputField
                  icon="lnr lnr-cloud"
                  handler={handleEaConfigInputsChange}
                  label="API Access Key Effective Date"
                  name="apiAccessKeyEffectiveDate"
                  value={apiAccessKeyEffectiveDate}
                  type="date"
                  placeHolder="Enter last effective date"
                />
              </div>
              <CurrenciesSelect
                usersStore={usersStore}
                currencySymbol={currencySymbol}
                handleSelectCurrency={handleSelectCurrency}
              />
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

EaDetailsConfiguration.defaultProps = {
  currencySymbol: null,
};

export default EaDetailsConfiguration;
