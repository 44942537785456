import React from 'react';
import { GenerateIcon } from '@pileus-cloud/anodot-frontend-common';

import classes from './TabsSelector.module.scss';

const TabsSelector = ({ tabsInfo, onTabSelected, selectedTabId }) => (
  <div className={classes.tabsContainer}>
    {tabsInfo?.map((tab) => (
      <div
        key={tab.id}
        className={`${tab.id === selectedTabId ? classes.selected : ''} ${classes.tab}`}
        onClick={() => onTabSelected(tab.id)}
      >
        <GenerateIcon iconName={tab.iconName} className={classes.icon} />
        {tab.label}
      </div>
    ))}
  </div>
);

export default TabsSelector;
