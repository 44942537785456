import React from 'react';
import styles from './spSpecialMode.module.scss';

const SpSpecialMode = ({ iconSrc, specialText, isLongText, type }) => (
  <div className={styles.bigIconWrapper}>
    <img className={styles.image} src={iconSrc} alt={type} />
    <span className={`${styles.specialText} ${isLongText && styles.longText}`}>{specialText}</span>
  </div>
);

export default SpSpecialMode;
