import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { SelectMulti } from '@pileus-cloud/anodot-frontend-common/dist';
import { SelectMultiButton } from './selectMultiButton';

export const FilterWrapper = ({
  allFilters,
  filterDataLength,
  filterType,
  handleSelectionChange,
  onSelectMultiButtonClick,
  selectFilterMultiMenuComponentGetter,
  tooltip,
}) => {
  const [selectedFilterOptions, setSelectedFilterOptions] = useState(allFilters?.[filterType?.id]);

  useEffect(() => {
    if (allFilters && filterType) {
      setSelectedFilterOptions(allFilters[filterType.id]);
    }
  }, [allFilters, filterType]);

  const isAllSelected = useMemo(
    () => !(selectedFilterOptions?.length && filterDataLength !== selectedFilterOptions.length),
    [filterType?.label, selectedFilterOptions],
  );

  const selectButtonComponentGetter = (onButtonClick, selectedOptions, isSelectMenuOpen) => (
    <SelectMultiButton
      isSelectMenuOpen={isSelectMenuOpen}
      label={filterType?.label}
      onButtonClick={onButtonClick}
      selectedOptions={selectedOptions}
      isAllSelected={isAllSelected}
      tooltip={tooltip}
    />
  );

  return (
    <div className="d-flex flex-column z-2" automation-id={`heatmap-filter-${filterType.label}`}>
      <SelectMulti
        handleSelectionChange={(selectedOptions) => setSelectedFilterOptions(selectedOptions)}
        label={filterType?.label}
        onSelectMultiButtonClick={(isMenuOpen) => onSelectMultiButtonClick(isMenuOpen, filterType?.id)}
        selected={selectedFilterOptions}
        selectButtonComponentGetter={selectButtonComponentGetter}
        selectMultiMenuComponentGetter={selectFilterMultiMenuComponentGetter}
        onSelectMultiMenuClose={() => handleSelectionChange(selectedFilterOptions)}
        showCounter={false}
      />
    </div>
  );
};
FilterWrapper.propTypes = {
  filterType: PropTypes.object.isRequired,
  filterDataLength: PropTypes.number,
  allFilters: PropTypes.object.isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
  onSelectMultiButtonClick: PropTypes.func.isRequired,
  selectFilterMultiMenuComponentGetter: PropTypes.func.isRequired,
  tooltip: PropTypes.node,
};

FilterWrapper.defaultProps = {
  filterDataLength: 0,
  tooltip: '',
};
