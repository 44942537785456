/* eslint-disable max-len */
/* eslint-disable no-unneeded-ternary */

class ApiGateway {
  constructor(
    commonApi,
    invoicesApi,
    usersApi,
    usageApi,
    divisionsApi,
    kubernetesApi,
    commitmentApi,
    customDashboardApi,
    rolesApi,
  ) {
    this.commonApi = commonApi;
    this.invoicesApi = invoicesApi;
    this.usersApi = usersApi;
    this.usageApi = usageApi;
    this.divisionsApi = divisionsApi;
    this.kubernetesApi = kubernetesApi;
    this.commitmentApi = commitmentApi;
    this.customDashboardApi = customDashboardApi;
    this.rolesApi = rolesApi;
  }

  updateUserSlackData = async (slackUserData) => {
    const result = await this.usersApi.updateUserSlackData(slackUserData);
    return result;
  };

  signIn = async (username, password) => {
    const userID = await this.usersApi.signIn(username, password);
    return userID;
  };

  resendSignUp = async (username) => {
    const result = await this.usersApi.resendSignUp(username);
    return result;
  };

  processUserPayment = async (userPaymentData) => {
    try {
      const result = await this.usersApi.processUserPayment(userPaymentData);
      return result;
    } catch (error) {
      return null;
    }
  };

  fetchSubUserDetails = async (userKey) => this.usersApi.getSubUserInfo(userKey);

  getCloudAccountData = async (accountId, cloudTypeId) => {
    const result = await this.usersApi.getCloudAccountData(accountId, cloudTypeId);
    return result;
  };
  deleteAccount = async (accountId, cloudTypeId) => {
    const result = await this.usersApi.deleteAccount(accountId, cloudTypeId);
    return result;
  };
  createPileusUser = async (newPileusUserData, registerUserInfoParams) => {
    try {
      const result = await this.usersApi.createPileusUser(newPileusUserData, registerUserInfoParams);
      if (result) {
        return result;
      }
    } catch (error) {
      return null;
    }

    return null;
  };

  registerUser = async (registerData) => {
    const result = await this.usersApi.registerUser(registerData);
    return result;
  };

  connectUserToCloudAccount = async (connectData) => {
    const result = await this.usersApi.connectUserToCloudAccount(connectData);
    return result;
  };

  checkNewCloudAccount = async (accountId) => {
    const result = await this.usersApi.checkNewCloudAccount(accountId);
    return result;
  };

  insertUserCloudAccountInterface = async (connectData) => {
    const result = await this.usersApi.insertUserCloudAccountInterface(connectData);
    return result;
  };

  getUser = async () => {
    const user = await this.usersApi.getUserWithPlainSubUsers();
    return user;
  };

  updateUserTypeAndAwsAccId = async (newType, awsAccountID, isConnectionSuccess) => {
    const result = await this.usersApi.updateUserTypeAndAwsAccId(newType, awsAccountID, isConnectionSuccess);
    return result;
  };
  updateUserData = async (setParams, setValues, whereParams, whereValues) => {
    const result = await this.usersApi.updateUserData(setParams, setValues, whereParams, whereValues);
    return result;
  };
  updateUserGeneralData = async (params) => {
    const result = await this.usersApi.updateUserGeneralData(params);
    return result;
  };
  updateUserRole = async (body) => {
    const result = await this.usersApi.updateUserRole(body);
    return result;
  };
  updateUserAccount = async (accountId, accountKey, newAccountName, newRoleARN) => {
    const result = await this.usersApi.updateUserAccount(accountId, accountKey, newAccountName, newRoleARN);
    return result;
  };

  getUserAccounts = async () => {
    const result = await this.usersApi.getUserAccounts();
    return result;
  };

  updateUserMarginPercentage = async (marginPercentageNumber) => {
    const result = await this.usersApi.updateUserMarginPercentage(marginPercentageNumber);
    return result;
  };
  getUserMarginPercent = async () => {
    const result = await this.usersApi.getUserMarginPercent();
    return result;
  };
  getRoutesPermissions = async () => {
    const result = await this.usersApi.getRoutesPermissions();
    return result;
  };

  getIsUserSubscribedToMondayWebhook = async () => {
    const isUserSubscribed = await this.usersApi.getIsUserSubscribedToMondayWebhook();
    return isUserSubscribed;
  };

  getCompanySlackChannels = async () => {
    const slackChannels = await this.usersApi.getCompanySlackChannels();
    return slackChannels;
  };

  getEC2InstancesData = async (startDate, endDate, currPeriodGranLevel, filterParams) => {
    try {
      const eC2InstancesData = await this.usageApi.getEC2InstancesData(
        startDate,
        endDate,
        currPeriodGranLevel,
        filterParams,
      );
      return eC2InstancesData;
    } catch (error) {
      return [];
    }
  };

  getMetricsTypes = () => this.invoicesApi.getMetricsTypes();
  getMetricsData = async (params) => this.invoicesApi.getMetricsData(params);

  getRdsInstanceCosts = async (startDate, endDate) => {
    const result = await this.usageApi.getRdsInstanceCosts(startDate, endDate);
    return result;
  };

  getS3BucketCosts = async (startDate, endDate) => {
    const result = await this.usageApi.getS3BucketCosts(startDate, endDate);
    return result;
  };

  getEc2ResourcesData = async (userKey, projectName, envName, instanceType, startDate, endDate, filterParams) => {
    const result = await this.usageApi.getEc2ResourcesData(
      userKey,
      projectName,
      envName,
      instanceType,
      startDate,
      endDate,
      filterParams === undefined ? '' : filterParams,
    );
    return result;
  };

  getEc2InstanceByDate = async (userKey, startDate, endDate) => {
    const result = await this.usageApi.getEc2InstanceByDate(startDate, endDate);
    return result;
  };

  getResourceOperationsData = async (periodGranLevel, startDate, endDate, resourceField, resourceValue) => {
    const result = await this.usageApi.getResourceOperationsData(
      periodGranLevel,
      startDate,
      endDate,
      resourceField,
      resourceValue,
    );
    return result;
  };

  getResourceDetails = async (resourceField, resourceValue, startDate, endDate) => {
    const result = await this.usageApi.getResourceDetails(resourceField, resourceValue, startDate, endDate);
    return result;
  };

  getRiCoverage = async (startDate, endDate) => {
    const result = await this.usageApi.getRiCoverage(startDate, endDate);
    return result;
  };
  getRiPurchased = async (startDate, endDate) => {
    const result = await this.usageApi.getRiPurchased(startDate, endDate);
    return result;
  };

  // Alerts

  saveCauAlerts = async (alerts) => {
    const result = await this.usageApi.saveCauAlerts(alerts);
    return result;
  };
  deleteCauAlert = async (alertId) => {
    const result = await this.usageApi.deleteCauAlert(alertId);
    return result;
  };
  fetchCauAlerts = async () => {
    const result = await this.usageApi.fetchCauAlerts();
    return result;
  };

  // AZURE ***********************

  getAzureStorageUsageData = async (startDate, endDate) => {
    const data = await this.usageApi.getAzureStorageUsageData(startDate, endDate);
    return data;
  };
  getAzureDbCostData = async (startDate, endDate) => {
    const data = await this.usageApi.getAzureDbCostData(startDate, endDate);
    return data;
  };

  // Notifications

  getUserNotificationSettings = async () => {
    const data = await this.usersApi.getUserNotificationSettings();
    return data;
  };

  updateUserNotificationSettings = async (body) => {
    const result = await this.usersApi.updateUserNotificationSettings(body);
    return result;
  };

  // linked accounts
  getAvailableLinkedAccounts = async () => {
    const result = await this.usersApi.getAvailableLinkedAccounts();
    return result;
  };

  getAvailableDivisionLinkedAccounts = async () => {
    const result = await this.usersApi.getAvailableDivisionLinkedAccounts();
    return result;
  };

  fetchUserLinkedAccounts = async (cloudId) => {
    const result = await this.usersApi.fetchUserLinkedAccounts(cloudId);
    return result;
  };

  updateLinkedAccounts = async (linkedAccounts) => {
    const result = await this.usersApi.updateLinkedAccounts(linkedAccounts);
    return result;
  };

  // External keys

  signinWithToken = async (selectedRole = null) => {
    const result = await this.usersApi.signinWithToken(selectedRole);
    return result;
  };

  setExternalUserNameToUserKey = async (externalUserName, userKey) => {
    const result = await this.usersApi.setExternalUserNameToUserKey(externalUserName, userKey);
    return result;
  };

  getUserKeyFromUserName = async (userName) => {
    const result = await this.usersApi.getUserKeyFromUserName(userName);
    return result;
  };

  // ALerts

  getUserAlerts = async () => {
    try {
      const fetchedData = await this.usersApi.getUserAlerts();
      if (fetchedData) {
        return fetchedData;
      }
    } catch (error) {
      return error;
    }
    return [];
  };

  updateAlertDismiss = async (dueDate, alertId) => {
    try {
      const result = await this.usersApi.updateAlertDismiss(dueDate, alertId);
      return result;
    } catch (error) {
      return false;
    }
  };

  // Sub Users
  getSubUsers = async () => {
    const result = await this.usersApi.getSubUsers();
    return result;
  };
  deleteSubUser = async (userKey, accountKey) => {
    const result = await this.usersApi.deleteSubUser(userKey, accountKey);
    return result;
  };
  deleteSubUsersBulk = async (...props) => this.usersApi.deleteSubUsersBulk(...props);

  createNewUsersInRole = async (body) => {
    const result = await this.usersApi.createNewUsersInRole(body);
    return result;
  };
  createNewCustomerSubUser = async (body) => {
    const result = await this.usersApi.createNewCustomerSubUser(body);
    return result;
  };

  createSubUserDashbaord = async (body) => {
    const result = await this.usersApi.createSubUserDashbaord(body);
    return result;
  };

  getDivisionGroups = async () => {
    const result = await this.divisionsApi.getDivisionGroups();
    return result;
  };
  createDivisionGroup = async (body) => {
    const result = await this.divisionsApi.createDivisionGroup(body);
    return result;
  };

  deleteDivisionGroup = async (divisionId, divisionName) => {
    const result = await this.divisionsApi.deleteDivisionGroup(divisionId, divisionName);
    return result;
  };

  updateDivisionGroupName = async (divId, body) => {
    const result = await this.divisionsApi.updateDivisionGroupName(divId, body);
    return result;
  };
  updateDivisionGroup = async (divId, body) => {
    const result = await this.divisionsApi.updateDivisionGroup(divId, body);
    return result;
  };

  updateDivisionGroupLinkAccounts = async (divId, linkedAccIds, divisionName, divisionCode, divisionTypeId, roleId) => {
    const result = await this.divisionsApi.updateDivisionGroupLinkAccounts(
      divId,
      linkedAccIds,
      divisionName,
      divisionCode,
      divisionTypeId,
      roleId,
    );
    return result;
  };

  getResellerCustomerInvoiceData = async (rows, type) => {
    const result = await this.divisionsApi.getResellerCustomerInvoiceData(rows, type);
    return result;
  };

  // Savings Plans Analyzer
  getSavingsPlansAnalyzerData = async (
    includeFilterParams,
    excludeFilterParams,
    daysToCheck,
    selectedPaymentOption,
    selectedTerm,
    coverage,
  ) => {
    const result = await this.commitmentApi.getSavingsPlansAnalyzerData(
      // const result = await this.usageApi.getSavingsPlansAnalyzerData(
      includeFilterParams,
      excludeFilterParams,
      daysToCheck,
      selectedPaymentOption,
      selectedTerm,
      coverage,
    );
    return result;
  };

  // Commitments ***************
  getEc2PricingMethodData = async (granLevel, startDate, endDate, filterParams) => {
    const result = await this.usageApi.getEc2PricingMethodData(
      granLevel,
      startDate,
      endDate,
      filterParams === undefined ? '' : filterParams,
    );
    return result;
  };
  getCommitmentDashboardData = async (granLevel = 'monthly', startDate, endDate, filterParams = '') => {
    const result = await this.commitmentApi.getCommitmentDashboardData(
      granLevel,
      startDate,
      endDate,
      filterParams === undefined ? '' : filterParams,
    );
    return result;
  };
  getCommitmentTableData = async (endDate, commitmentService, linkedAccount, commitmentType, payerAccount) => {
    // const data = await this.usageApi.getCommitmentTableData(endDate, commitmentService, linkedAccount, commitmentType);
    const data = await this.commitmentApi.getCommitmentTableData(
      endDate,
      commitmentService,
      linkedAccount,
      commitmentType,
      payerAccount,
    );
    return data || [];
  };
  getCommitmentChartsAndStatisticsData = async (endDate, commitmentType) => {
    // const data = await this.usageApi.getCommitmentChartsAndStatisticsData(endDate, commitmentType);
    const data = await this.commitmentApi.getCommitmentChartsAndStatisticsData(endDate, commitmentType);
    return data || [];
  };
  // getCommitmentRiFlexabilityData = async (arn, startDate, endDate) => {
  //   const data = await this.usageApi.getCommitmentRiFlexabilityData(arn, startDate, endDate);
  //   return data || [];
  // };
  getRiFlexabilityData = async (arn, startDate, endDate) => {
    const data = await this.commitmentApi.getRiFlexabilityData(arn, startDate, endDate);
    return data || [];
  };
  getRiChargeBackData = async (arn, startDate, endDate) => {
    const data = await this.commitmentApi.getRiChargeBackData(arn, startDate, endDate);
    return data || [];
  };
  getServicePlansChargeBackData = async (arn, startDate, endDate) => {
    // const data = await this.usageApi.getServicePlansChargeBackData(arn, startDate, endDate);
    const data = await this.commitmentApi.getServicePlansChargeBackData(arn, startDate, endDate);
    return data || [];
  };

  getAzureRiCoverage = async (startDate, endDate) => {
    const data = await this.usageApi.getAzureRiCoverage(startDate, endDate);
    return data || [];
  };

  sendOnBoardingEvent = async (cloudTypeId) => this.usersApi.sendOnBoardingEvent(cloudTypeId);
  // AZURE On Boarding
  saveAzureOnBoardingAppRegistrationConfiguration = async (appConfiguration) => {
    const result = await this.usersApi.saveAzureOnBoardingAppRegistrationConfiguration(appConfiguration);
    return result;
  };
  deleteAppRegistrationConfiguration = async (data) => {
    const result = await this.usersApi.deleteAppRegistrationConfiguration(data);
    return result;
  };
  updateAppName = async (data) => {
    const result = await this.usersApi.updateAppName(data);
    return result;
  };
  getAzureRegisteredApps = async () => {
    const result = await this.usersApi.getAzureRegisteredApps();
    return result;
  };
  connectAzureBillingExportAccount = async (billingExportConfiguration) => {
    const result = await this.usersApi.connectAzureBillingExportAccount(billingExportConfiguration);
    return result;
  };
  migrateAzureEAToMCAAccount = async (billingExportConfiguration) => {
    const result = await this.usersApi.migrateAzureEAToMCAAccount(billingExportConfiguration);
    return result;
  };
  connectAzureEaAccount = async (eaDetailsConfiguration) => {
    const result = await this.usersApi.connectAzureEaAccount(eaDetailsConfiguration);
    return result;
  };
  testAppRegistraionConfiguration = async (appRegConfiguration) => {
    const result = await this.usersApi.testAppRegistraionConfiguration(appRegConfiguration);
    return result;
  };

  // GCP On Boarding
  connectGcpAccount = async (gcpDetailsConfiguration) => {
    const result = await this.usersApi.connectGcpAccount(gcpDetailsConfiguration);
    return result;
  };

  getUserDataByAccount = (accountId) => this.usersApi.getUserDataByAccount(accountId);
  // K8S usage
  getK8sUsageData = async (startDate, endDate) => {
    const result = await this.kubernetesApi.getK8sUsageData(startDate, endDate);
    return result;
  };
  getK8sCostAndUsage = async (
    groupBy,
    secondaryGroupBy = false,
    whereParams,
    startDate,
    endDate,
    periodGranLevel,
    filterParams,
    costType,
    selectedUsageType,
    selectedUsageTypeMetric,
    excludeFilterParams,
    likeFilterParams,
    isNetUnblended,
    isShowAmortizeCost,
    isNetAmortize,
    panelId,
    dashboardId,
    wasteCostAllocationChecked,
    k8SGranularity,
    filtersConfigParams,
  ) => {
    const invoicesMonthlyServicesBalance = await this.kubernetesApi.getK8sCostAndUsage(
      groupBy,
      secondaryGroupBy,
      whereParams === '' ? '' : `${whereParams}`,
      startDate,
      endDate,
      periodGranLevel,
      filterParams === undefined ? '' : filterParams,
      costType,
      selectedUsageType,
      selectedUsageTypeMetric,
      excludeFilterParams,
      likeFilterParams,
      isNetUnblended,
      isShowAmortizeCost,
      isNetAmortize,
      panelId,
      dashboardId,
      wasteCostAllocationChecked,
      k8SGranularity,
      filtersConfigParams,
    );
    if (invoicesMonthlyServicesBalance.code && invoicesMonthlyServicesBalance.code === 'BAD_REQUEST_ERROR') {
      return [];
    }
    return invoicesMonthlyServicesBalance;
  };
  getK8sPodData = async (...props) => {
    const result = await this.kubernetesApi.getK8sPodData(...props);
    return result;
  };

  // ********* CUSTOM DASHBOARD *********
  fetchCustomDashboardsTemplates = async () => {
    const result = await this.customDashboardApi.fetchCustomDashboardsTemplates();
    return result;
  };
  deleteCustomDashboardTemplate = async (templateId) => {
    const result = await this.customDashboardApi.deleteCustomDashboardTemplate(templateId);
    return result;
  };
  createCustomDashboardTemplate = async (dbId) => {
    const result = await this.customDashboardApi.createCustomDashboardTemplate(dbId);
    return result;
  };
  updateCustomDashboardTemplate = async (template) => {
    const result = await this.customDashboardApi.updateCustomDashboardTemplate(template);
    return result;
  };
  fetchCustomDashboards = async () => {
    const result = await this.customDashboardApi.fetchCustomDashboards();
    return result;
  };
  fetchCustomDbrdPanels = async () => {
    const result = await this.customDashboardApi.fetchCustomDbrdPanels();
    return result;
  };
  fetchPanelById = async (id, accountId) => {
    const result = await this.customDashboardApi.fetchPanelById(id, accountId);
    return result;
  };
  createNewCustomDashboard = async (dashboardParams, panelParams) => {
    const result = await this.customDashboardApi.createNewCustomDashboard(dashboardParams, panelParams);
    return result;
  };
  cloneCustomDashboard = async (
    dashboardParams,
    panelsIdsToClonedIds,
    cloneIdsToCloneNames,
    dbAccountId,
    isFromTemplate,
  ) => {
    const result = await this.customDashboardApi.cloneCustomDashboard(
      dashboardParams,
      panelsIdsToClonedIds,
      cloneIdsToCloneNames,
      dbAccountId,
      isFromTemplate,
    );
    return result;
  };
  createPanelFromTemplate = async (predefinedPanelId, customDashboardPanelName) => {
    const result = await this.customDashboardApi.createPanelFromTemplate(predefinedPanelId, customDashboardPanelName);
    return result;
  };
  updateExistingCustomDashboard = async (dashboardParams, panelParams) => {
    const result = await this.customDashboardApi.updateExistingCustomDashboard(dashboardParams, panelParams);
    return result;
  };
  removeCustomDashboard = async (dashboardId) => {
    const result = await this.customDashboardApi.removeDashboard(dashboardId);
    return result;
  };
  updateCustomDashboard = async (dashboard) => {
    const result = await this.customDashboardApi.updateDashboard(dashboard);
    return result;
  };
  updateCustomDashboardPanel = async (panel) => {
    const result = await this.customDashboardApi.updatePanel(panel);
    return result;
  };
  // KPIS - USAGE STORE
  getKpiBusinessUnitCoverageData = async (service, granularity, startDate, endDate) => {
    const result = await this.usageApi.getKpiBusinessUnitCoverageData(service, granularity, startDate, endDate);
    return result;
  };
  getKpiCoverageByPricingMethod = async (service, granularity, startDate, endDate) => {
    const result = await this.usageApi.getKpiCoverageByPricingMethod(service, granularity, startDate, endDate);
    return result;
  };
  getKpieBusinessUnitReservationUtilization = async (service, granularity, startDate, endDate) => {
    const result = await this.usageApi.getKpieBusinessUnitReservationUtilization(
      service,
      granularity,
      startDate,
      endDate,
    );
    return result;
  };
  getKpiCashBasis = async (granularity, startDate, endDate) => {
    const result = await this.usageApi.getKpiCashBasis(granularity, startDate, endDate);
    return result;
  };
  getKpiCostPerHour = async (service, granularity, startDate, endDate) => {
    const result = await this.usageApi.getKpiCostPerHour(service, granularity, startDate, endDate);
    return result;
  };
  getKpiMonthlyCostPerHour = async (service, granularity, startDate, endDate) => {
    const result = await this.usageApi.getKpiMonthlyCostPerHour(service, granularity, startDate, endDate);
    return result;
  };
  getKpiQtdCoverageByService = async (service, granularity, startDate, endDate) => {
    const result = await this.usageApi.getKpiQtdCoverageByService(service, granularity, startDate, endDate);
    return result;
  };
  getKpiQtdUtilizationByService = async (service, year, startMonth, endMonth) => {
    const result = await this.usageApi.getKpiQtdUtilizationByService(service, year, startMonth, endMonth);
    return result;
  };
  getKpiSavingsOnDemand = async (yearQuarter) => {
    const result = await this.usageApi.getKpiSavingsOnDemand(yearQuarter);
    return result;
  };
  qtdPileusSavings = async () => {
    const result = await this.usageApi.qtdPileusSavings();
    return result;
  };

  // CUSTOM FILTERS
  createCustomFilter = async (dbId, data) => {
    const result = await this.customDashboardApi.createCustomFilter(dbId, data);
    return result;
  };
  updateCustomFilter = async (dbId, data) => {
    const result = await this.customDashboardApi.updateCustomFilter(dbId, data);
    return result;
  };
  deleteCustomFilter = async (id) => {
    const result = await this.customDashboardApi.deleteCustomFilter(id);
    return result;
  };

  // Target Goals **********************************
  fetchAllTargetGoals = async () => {
    const result = await this.usersApi.fetchAllTargetGoals();
    return result;
  };
  createNewTargetGoal = async (data) => {
    const result = await this.usersApi.createNewTargetGoal(data);
    return result;
  };
  deleteTargetGoal = async (uuid) => {
    const result = await this.usersApi.deleteTargetGoal(uuid);
    return result;
  };
  updateTargetGoal = async (data) => {
    const result = await this.usersApi.updateTargetGoal(data);
    return result;
  };

  updateDefaultAccountId = async (data) => {
    const result = await this.usersApi.updateDefaultAccountId(data);
    return result;
  };

  // ASSETS *************************************
  getAssetData = async (
    startDate,
    endDate,
    granLevel,
    columns,
    filtersMap,
    excludeFiltersMap,
    measuresMap,
    costTypes,
    isFull,
    isK8S,
    panelId,
    dashboardId,
    k8SGranularity,
  ) => {
    const result = await this.usageApi.getAssetData(
      startDate,
      endDate,
      granLevel,
      columns,
      filtersMap,
      excludeFiltersMap,
      measuresMap,
      costTypes,
      isFull,
      isK8S,
      panelId,
      dashboardId,
      k8SGranularity,
    );
    return result;
  };
}

export default ApiGateway;
