/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  RowDetailState,
  DataTypeProvider,
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableRowDetail,
  TableFilterRow,
  PagingPanel,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Undo';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import TableWrapper from 'shared/components/tables/TableWrapper';
import AlertCircleOutline from 'mdi-react/AlertCircleOutlineIcon';
import EditPencil from 'shared/img/icons/PencilIcon';
import ChangeToCustomerViewActionButton from 'shared/components/ChangeToCustomerViewActionButton';
import ResellerPrivatePricing from 'shared/components/resellerPrivatePricing/ResellerPrivatePricing';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const EditButton = ({ onExecute }) => <EditPencil onClick={onExecute} />;
EditButton.propTypes = {
  onExecute: PropTypes.func.isRequired,
};

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes" size="large">
    <SaveIcon />
  </IconButton>
);
CommitButton.propTypes = {
  onExecute: PropTypes.func.isRequired,
};
const CancelButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Cancel changes" size="large">
    <CancelIcon />
  </IconButton>
);
CancelButton.propTypes = {
  onExecute: PropTypes.func.isRequired,
};

const commandComponents = {
  edit: EditButton,
  commit: CommitButton,
  cancel: CancelButton,
};

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return <CommandButton onExecute={onExecute} />;
};
Command.propTypes = {
  id: PropTypes.number.isRequired,
  onExecute: PropTypes.func.isRequired,
};

const FilterIcon = ({ type, ...restProps }) => <TableFilterRow.Icon type={type} {...restProps} />;

FilterIcon.propTypes = { type: PropTypes.string.isRequired };

const buttonContainerStyle = {
  display: 'flex',
  flexFlow: 'row',
  alignSelf: 'center',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '80%',
  height: '100%',
};
const textStyle = {
  width: '50%',
};

const colStyle = {
  position: 'relative',
  width: '100%',
  minHeight: '1px',
  paddingRight: '15px',
  flexBasis: 0,
  flexGrow: 1,
  maxWidth: '100%',
};

const CustomersTable = ({
  tableData,
  handleChangeToCustomerView,
  handleExplainCustomerFlexibilityMargin,
  usersStore,
  invoiceStore,
  columns,
  columnWidths: initialColumnWidths,
}) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const [columnsWidth, setColumnsWidth] = useState(initialColumnWidths);
  const [pageSizes, setPageSizes] = useState([10, 15, 20, 0]);
  const currencyColumns = useRef([
    'mtdResellerCost',
    'publicPriceMargin',
    'billingRuleMargin',
    'totalMarginCost',
    'mtdCustomerCost',
    'creditMargin',
    'resellerCredit',
    'riMargin',
    'usageMargin',
    'spFlexibilityMargin',
    'riFlexibilityMargin',
    'partnerMargin',
    'NetRISavings',
  ]);
  const numericFilterOperations = useRef([
    'equal',
    'notEqual',
    'greaterThan',
    'greaterThanOrEqual',
    'lessThan',
    'lessThanOrEqual',
  ]);

  // const getNumOfDigitsAfterDecimalSeperator = (num) => {
  //   const numForCalculate = +num < 0 ? +num * -1 : +num;
  //   return +numForCalculate % 1 > 0.05 && numForCalculate % 1 <= 0.95 ? 1 : 0;
  // };

  const changeColumnWidths = (columnsWidth) => setColumnsWidth(columnsWidth);

  const currenciesFormatter = (value) => {
    if (_.isNumber(value)) {
      // const numOfDigitsAfterDecimalSeperator = getNumOfDigitsAfterDecimalSeperator(value);

      const cost = getCurrencyNumber(value, 1);
      return `${cost}`;
    }
    return '';
  };

  const userActionsFormatter = (data) => (
    <ChangeToCustomerViewActionButton data={data} handleChangeToCustomerView={handleChangeToCustomerView} />
  );

  const marginFormatter = (data, marginType, marginField) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={textStyle}>
        <span>{currenciesFormatter(data.row[marginField])}</span>
      </div>
      <div style={colStyle}>
        <LinkIconButton containerStyle={buttonContainerStyle}>
          <a
            href="#"
            onClick={(e) =>
              handleExplainCustomerFlexibilityMargin(
                e,
                data.row.divisionId,
                data.row.customerName,
                data.row.accountKey,
                marginType,
              )
            }
          />
          <AlertCircleOutline size={16} />
        </LinkIconButton>
      </div>
    </div>
  );

  const RowDetail = ({ row }) => (
    <div style={{ margin: '20px' }}>
      <ResellerPrivatePricing
        usersStore={usersStore}
        invoiceStore={invoiceStore}
        divId={row.divisionId}
        divisionName={row.customerName}
      />
    </div>
  );
  RowDetail.propTypes = {
    row: PropTypes.object.isRequired,
  };
  return (
    <div className="card">
      <Paper>
        <Grid rows={tableData} columns={columns}>
          <RowDetailState />
          <FilteringState defaultFilters={[]} />
          <SortingState
            defaultSorting={[
              { columnName: 'mtdResellerCost', direction: 'desc' },

              { columnName: 'mtdCustomerCost', direction: 'desc' },
            ]}
          />
          <PagingState pagesdefaultCurrentPage={0} onPageSizeChange={setPageSizes} />
          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          <DataTypeProvider
            for={currencyColumns.current}
            formatterComponent={(value) => currenciesFormatter(value.value)}
            availableFilterOperations={numericFilterOperations.current}
          />

          <DataTypeProvider
            for={['riFlexibilityMargin']}
            formatterComponent={(data) => marginFormatter(data, 'ri', 'riFlexibilityMargin')}
          />
          <DataTypeProvider
            for={['spFlexibilityMargin']}
            formatterComponent={(data) => marginFormatter(data, 'sp', 'spFlexibilityMargin')}
          />
          <DataTypeProvider
            for={['publicPriceMargin']}
            formatterComponent={(data) => marginFormatter(data, 'pp', 'publicPriceMargin')}
          />
          <DataTypeProvider
            for={['billingRuleMargin']}
            formatterComponent={(data) => marginFormatter(data, 'billingRules', 'billingRuleMargin')}
          />
          <DataTypeProvider
            for={['creditMargin']}
            formatterComponent={(data) => marginFormatter(data, 'creditMargin', 'creditMargin')}
          />
          <DataTypeProvider
            for={['resellerCredit']}
            formatterComponent={(data) => marginFormatter(data, 'resellerCredit', 'resellerCredit')}
          />

          <DataTypeProvider for={['userAction']} formatterComponent={userActionsFormatter} />
          <TableWrapper />
          <TableColumnResizing columnWidths={columnsWidth} onColumnWidthsChange={changeColumnWidths} />
          <TableHeaderRow showSortingControls />
          <TableFilterRow showFilterSelector iconComponent={FilterIcon} />
          <PagingPanel pageSizes={pageSizes} />
          <TableRowDetail contentComponent={RowDetail} />
        </Grid>
      </Paper>
    </div>
  );
};

CustomersTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  handleChangeToCustomerView: PropTypes.func.isRequired,
  handleExplainCustomerFlexibilityMargin: PropTypes.func.isRequired,
  usersStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  columnWidths: PropTypes.array.isRequired,
};

export default CustomersTable;
