import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useTable from 'shared/hooks/customHooks/useTable';
import Spinner from 'shared/components/andtComponents/Spinner';
import { RowDetailState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import useChannels from 'shared/components/slackIntegration/hooks/useChannels';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { Container } from 'reactstrap';
import TableHeader from 'shared/components/tableHeader/TableHeader';
import Button from 'shared/components/andtComponents/Button';
import { useTableHeaderContext } from 'shared/components/tableHeader/TableHeaderContext';
import ChannelDetails from './ChannelDetails';
import AddSlackChannelModal from './AddSlackChannelModal';
import AddJiraChannelModal from './AddJiraChannelModal';
import { shouldIncludeRecipient, titleByChannelType, iconByChannelType } from './channelsHelpers';
import { ReactComponent as ChannelsNotFoundImg } from './assets/channels-not-found.svg';
import { ReactComponent as ChannelsEmptyState } from './assets/channels-empty-state.svg';
import styles from './channels.module.scss';

const NoChannelsFound = () => (
  <div className={styles.emptyChannels}>
    <ChannelsNotFoundImg />
    <h3>There are no channels found</h3>
    <p>Change your search filter to present data</p>
  </div>
);

const CreateChannelButton = ({ onClick, isGhost }) => {
  const [isCreateChannelsSelectOpen, setIsChannelsSelectOpen] = useState(false);
  return (
    <div className={styles.createChannelButtonContainer}>
      <Button
        text="Create Channel"
        onClick={() => setIsChannelsSelectOpen(true)}
        isGhost={isGhost}
        icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
      />
      {isCreateChannelsSelectOpen && (
        <>
          <div className={styles.createChannelSelect}>
            <Button
              text="Jira"
              onClick={() => {
                onClick({ channelType: 'JIRA_CLOUD' });
                setIsChannelsSelectOpen(false);
              }}
              isSecondary
              icon={() => iconByChannelType.JIRA_CLOUD}
              className={{ buttonContent: styles.createChannelButtonContent }}
            />
            <Button
              text="Slack"
              onClick={() => {
                onClick({ channelType: 'SLACK' });
                setIsChannelsSelectOpen(false);
              }}
              isSecondary
              icon={() => iconByChannelType.SLACK}
              className={{ buttonContent: styles.createChannelButtonContent }}
            />
          </div>
          <div className={styles.closeWrapper} onClick={() => setIsChannelsSelectOpen(false)} />
        </>
      )}
    </div>
  );
};

export default function Channels() {
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [channelToAdd, setChannelToAdd] = useState(null);
  const { searchText } = useTableHeaderContext();

  const { NewTableWrapper, NewTableRow } = useTable();
  const { getChannels, deleteChannel } = useChannels();
  const { mutateAsync: handleDeleteChannel, isLoading: isDeletingChannel } = deleteChannel();
  const { data: channels, isLoading: isLoadingChannels } = getChannels();

  useEffect(() => {
    if (searchText) {
      setExpandedRowIds(channels.map((_, i) => i)); // Expand all channels when searching
    } else if (channels?.length === 1) {
      setExpandedRowIds([0]); // Expand channel if there is only one channel
    } else {
      setExpandedRowIds([]);
    }
  }, [channels?.length, searchText]);

  if (isLoadingChannels) {
    return <Spinner />;
  }

  const channelComponent = (channel) => (
    <div className={styles.channelRow}>
      {iconByChannelType[channel.channelType]}
      <h4 className={styles.title}>
        {titleByChannelType[channel.channelType]}
        {channel.name && `: ${channel.name}`}
        {channel.recipients?.length >= 0 && (
          <span>
            &nbsp;(
            {searchText &&
              `${channel.recipients.filter((r) => shouldIncludeRecipient(searchText, r, channel)).length}/`}
            {channel.recipients.length})
          </span>
        )}
      </h4>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setChannelToAdd(channel);
        }}
        text="Add Channel"
        isTextButton
        icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
      />
      <Button
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteChannel(channel.channelId);
        }}
        isTextButton
        icon={() => <GenerateIcon iconName={ICONS.delete.name} />}
        isLoading={isDeletingChannel}
      />
    </div>
  );

  const addChannelModal = (channel) => {
    const addChannelModalByType = {
      SLACK: (
        <AddSlackChannelModal
          selectedChannel={channelToAdd}
          channels={channels.filter((c) => c.channelType === 'SLACK')}
          onChannelSelect={setChannelToAdd}
          onClose={() => {
            setChannelToAdd(null);
          }}
        />
      ),
      JIRA_CLOUD: (
        <AddJiraChannelModal
          selectedChannel={channelToAdd}
          channels={channels.filter((c) => c.channelType === 'JIRA_CLOUD')}
          onChannelSelect={setChannelToAdd}
          onClose={() => {
            setChannelToAdd(null);
          }}
        />
      ),
    };
    return addChannelModalByType[channel.channelType || 'SLACK'];
  };

  const EmptyChannels = () => (
    <div className={styles.emptyChannels}>
      <ChannelsEmptyState />
      <h4>Currently you have no connected channels</h4>
      <CreateChannelButton onClick={setChannelToAdd} isGhost />
    </div>
  );

  // Hide channels without recipients only when searching
  const filteredChannels = searchText
    ? channels.filter((c) => c.recipients.some((r) => shouldIncludeRecipient(searchText, r, c)))
    : channels;

  return (
    <Container className={styles.channels}>
      {channels.length === 0 ? (
        <EmptyChannels closeModal={() => setChannelToAdd(null)} />
      ) : (
        <>
          <TableHeader
            titleComponent={<p className={styles.headerTitle}>Channels</p>}
            createButtonComponent={<CreateChannelButton onClick={setChannelToAdd} />}
            expanded={expandedRowIds.length > 0}
            onCreate={() => setChannelToAdd({ channelType: 'JIRA_CLOUD' })}
            setExpanded={(shouldExpand) => setExpandedRowIds(shouldExpand ? channels.map((_, i) => i) : [])}
            tableName="Channel"
            includeDetails
            isCreateLast
            isInline
          />
          {filteredChannels.length === 0 ? (
            <NoChannelsFound />
          ) : (
            <NewTableWrapper>
              <Grid rows={filteredChannels} columns={[{ getCellValue: (row) => channelComponent(row) }]}>
                <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
                <Table
                  rowComponent={(props) => (
                    <NewTableRow setExpandedRowIds={setExpandedRowIds} expandedRowIds={expandedRowIds} {...props} />
                  )}
                />
                <TableRowDetail contentComponent={(props) => <ChannelDetails searchText={searchText} {...props} />} />
              </Grid>
            </NewTableWrapper>
          )}
        </>
      )}
      {channelToAdd && addChannelModal(channelToAdd)}
    </Container>
  );
}

Channels.propTypes = {
  usersStore: PropTypes.object.isRequired,
};
