import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import ICONS from 'shared/constants/assetsConstants';
import { mapRegionNamesToDescription } from 'shared/constants/awsConstants';
import IconFromPng from 'shared/components/IconFromPng';
import { downloadFileSafari } from '../helpers/newUserHelpers';
import connectToFileDataGenerator from '../helpers/connectToFileDataGenerator';
import { CONNECT_TO_FILE_NAME_COMPLETION } from '../constants/newUserConstants';

const awsInterfaceFile = {
  id: 0,
  fileNameCompletion: CONNECT_TO_FILE_NAME_COMPLETION,
  fileGen: ({ invoiceBucketName, arnRoleNum, awsAccountID }) =>
    connectToFileDataGenerator({ invoiceBucketName, arnRoleNum, awsAccountID }),
};

const downloadFileHandler = (wasClicked, fileName, id, invoiceBucketName, arnRoleNum, awsAccountID) => {
  downloadFileSafari(
    wasClicked,
    fileName,
    id,
    awsInterfaceFile.fileGen({
      invoiceBucketName,
      arnRoleNum,
      awsAccountID,
    }),
  );
};

const DownloadConnectFileContainer = React.memo(
  ({ invoiceBucketName, arnRoleNum, awsAccountID, bucketRegionName, onSubmit }) => {
    const fileName = `${awsAccountID}${awsInterfaceFile.fileNameCompletion}`;
    const id = `${awsAccountID}`;
    return (
      <Row>
        <Col xs={12} md={12} lg={12} xl={12}>
          <h4 className="inner-wizard-items-header" style={{ 'padding-bottom': '20px' }}>
            <span>Review your account details</span>
          </h4>
          <br />
        </Col>
        <Col xs={12} md={12} lg={12} xl={12}>
          <Row style={{ justifyContent: 'space-between' }}>
            <Col>
              <p className="inner-wizard-input-legend">Bucket Name :</p>
              <p>
                <b>{`${invoiceBucketName}`}</b>
              </p>
            </Col>
            <Col>
              <p className="inner-wizard-input-legend">ARN Number :</p>
              <p>
                <b>{arnRoleNum}</b>
              </p>
            </Col>
            <Col>
              <p className="inner-wizard-input-legend">AWS account ID :</p>
              <p>
                <b>{awsAccountID}</b>
              </p>
            </Col>
            <Col>
              <p className="inner-wizard-input-legend">Bucket Region Name :</p>
              <p>
                <b>{bucketRegionName}</b>
                <span style={{ fontSize: '12px' }}>{` (${mapRegionNamesToDescription.get(bucketRegionName)})`}</span>
              </p>
            </Col>
            <Col />
          </Row>
          <br />
          <hr />
        </Col>
        <Col xs={12} md={12} lg={12} xl={12}>
          <h4 className="inner-wizard-items-header" style={{ 'padding-bottom': '20px' }}>
            <span>Download the &quot;Connect to Anodot&quot; File</span>
            <br />
            <br />
          </h4>
          <Row className="filter-button-inner-wrapper" style={{ marginTop: '20px' }}>
            <Col className="filter-button-inner-button" xs="auto" md="auto" lg="auto" xl="auto">
              <LinkIconButton>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a
                  id={`${id}_1`}
                  href="#"
                  onClick={() => {
                    downloadFileHandler(true, fileName, `${id}_1`, invoiceBucketName, arnRoleNum, awsAccountID);
                  }}
                />
                <IconFromPng size={16} icon={ICONS.download} />
              </LinkIconButton>
            </Col>
            <Col style={{ paddingRight: '0px' }}>
              <h4 className="inner-wizard-items-header" style={{ 'padding-bottom': '20px' }}>
                Connect to Anodot File
              </h4>
            </Col>
          </Row>
          <hr />
          <h4
            className="inner-wizard-items-header"
            style={{ paddingBottom: '20px', marginBottom: '30px', marginTop: '30px' }}
          >
            <span>{'Upload the "Connect to Anodot File" file to '}</span>
            <a
              style={{ whiteSpace: 'nowrap', fontSize: '16px' }}
              className="find-my-id"
              rel="noopener noreferrer"
              target="_blank"
              /* eslint-disable-next-line max-len */
              href={`https://s3.console.aws.amazon.com/s3/buckets/${invoiceBucketName}/?region=${bucketRegionName}&tab=overview`}
            >
              your invoices bucket
            </a>
          </h4>
          <hr />
          <br />
        </Col>
        <Col xs={12} md={12} lg={12} xl={12}>
          <Row className="filter-button-inner-wrapper">
            <Col className="filter-button-inner-button" xs="auto" md="auto" lg="auto" xl="auto">
              <Button color="primary" onClick={onSubmit}>
                Connect
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  },
);
DownloadConnectFileContainer.propTypes = {
  invoiceBucketName: PropTypes.string.isRequired,
  arnRoleNum: PropTypes.string.isRequired,
  awsAccountID: PropTypes.string.isRequired,
  bucketRegionName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default DownloadConnectFileContainer;
