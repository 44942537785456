import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { adaptV4Theme, createTheme } from '@mui/material/styles';
import { BrowserRouter, withRouter } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'bootstrap/dist/css/bootstrap.css';
import RootStore from 'app/store/rootStore';
import * as fsHandler from 'shared/modules/fullStoryHandler';
import { ModelNames } from 'shared/constants/appConstants';
import ApiGateway from 'shared/modules/apiGateway';
import CommonApi from 'shared/api/commonApi';
import InvoicesApi from 'invoices/api/invoicesApi';
import UsageApi from 'usage/api/usageApi';
import CommitmentApi from 'commitment/api/commitmentApi';
import DivisionsApi from 'divisions/api/divisionsApi';
import KubernetesApi from 'kubernetes/api/kubernetesApi';
import CustomDashboardApi from 'usage/containers/CustomDashboard/api/customDashboardApi';
import { withRootStoreContextProvider } from 'app/contexts/RootStoreContext';
import { withInvoiceFiltersContextProvider } from 'invoices/contexts/InvoiceFiltersContext';
import { signOut, UsersApi } from 'users/api/usersApi';
import { createCustomHeaders } from 'shared/utils/apiUtil';
import { checkIfTokenRefreshNeeded, setLocalStorage, parseJwt } from 'shared/utils/tokenUtil';
import Spinner from 'shared/components/andtComponents/Spinner';
import {
  getKeycloakInstance,
  getRealmLocally,
  resetInstance,
  persistRealm,
  persistUsername,
} from 'users/containers/LogIn/keycloak.service';
// models
import UsersModel from 'users/models/usersModel';
import CostAndUsageAlertsModel from 'usage/models/costAndUsageAlertsModel';
import CustomDashboardModel from 'usage/store/subStores/customDashBoard/customDashboardModel';
import CommitmentModel from 'commitment/models/commitmentModel';
import KubernetesModel from 'kubernetes/models/kubernetesModel';
import { UserSettingsProvider } from 'users/utils/contexts/UserSettingsContext';
import config from 'config';

// Layout & Scss
import '../../../scss/app.scss';
import ScrollToTop from './ScrollToTop';
// Routing
import Router from './Router';
import CustomThemeApplier from './components/CustomThemeApplier';
import { logoutOkta, logoutPing } from './logoutIdp';

fsHandler.init();

const muiTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: '#2671FF',
      },
      secondary: {
        main: 'rgba(0, 0, 0, 0.87)',
      },
    },
    overrides: {
      MuiTableCell: {
        root: {
          padding: 4,
        },
      },
      MuiTableSortLabel: {
        root: {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
      Table: {
        stickyTable: {
          zIndex: 0,
        },
      },
      MuiTableRow: {
        root: {
          height: 48,
        },
      },
    },
  }),
);

const createApiGateway = () => {
  const commonApi = new CommonApi();
  const invoicesApi = new InvoicesApi();
  const usersAPI = new UsersApi();
  const usageApi = new UsageApi();
  const divisionsApi = new DivisionsApi();
  const kubernetesApi = new KubernetesApi();
  const commitmentApi = new CommitmentApi();
  const customDashboardApi = new CustomDashboardApi();
  const apiGateway = new ApiGateway(
    commonApi,
    invoicesApi,
    usersAPI,
    usageApi,
    divisionsApi,
    kubernetesApi,
    commitmentApi,
    customDashboardApi,
  );
  return apiGateway;
};

const createModels = (apiGateway) => {
  const usersModel = new UsersModel(apiGateway);
  const costAndUsageAlertsModel = new CostAndUsageAlertsModel(apiGateway);
  const kubernetesModel = new KubernetesModel(apiGateway);
  const commitmentModel = new CommitmentModel(apiGateway);
  const customDashboardModel = new CustomDashboardModel(apiGateway);
  const modelsMap = new Map();
  modelsMap.set(ModelNames.USERS_MODEL, usersModel);
  modelsMap.set(ModelNames.CUE_ALERTS_MODEL, costAndUsageAlertsModel);
  modelsMap.set(ModelNames.K8S_USAGE_MODEL, kubernetesModel);
  modelsMap.set(ModelNames.COMMITMENT_MODEL, commitmentModel);
  modelsMap.set(ModelNames.CUSTOM_DASHBOARD_MODEL, customDashboardModel);
  return modelsMap;
};

const createRootStore = () => {
  const apiGateway = createApiGateway();
  const map = createModels(apiGateway);
  const rootStore = new RootStore(map);
  return rootStore;
};

const rootStore = createRootStore();

config.apiReqHeaders.setCreateHeadersFunc(createCustomHeaders(rootStore));

const handleVisibilityChange = () => {
  if (document.visibilityState === 'visible') {
    // do not await here
    checkIfTokenRefreshNeeded();
  }
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      newTabLoading: true,
      isAuthenticated: false,
      username: '',
    };
  }

  async componentDidMount() {
    this.handleNewTab();
    const { queryClient } = this.props;
    rootStore.queryClient = queryClient;
    document.addEventListener('visibilitychange', handleVisibilityChange);
    // bind handleLogout to window in order to be able to call it from apiMiddleware
    window.handleLogout = this.handleLogout.bind(this);
  }
  componentWillUnmount() {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  }
  setUsername = (username) => {
    this.setState({ username });
  };

  // eslint-disable-next-line class-methods-use-this
  getDispUserKeyFromStorage = () =>
    window.sessionStorage.getItem('dispUserKey') || window.localStorage.getItem('dispUserKey');

  initiateKeycloakLoggedInUser = async () => {
    const keycloakInstance = await getKeycloakInstance();
    if (keycloakInstance?.token) {
      const { token: keycloakToken } = keycloakInstance;
      const keycloakTokenPayload = parseJwt(keycloakToken);
      if (!keycloakTokenPayload.iss.startsWith('https://cognito-idp') && !keycloakInstance?.authenticated) {
        window.sessionStorage.clear();
        window.localStorage.clear();
        await this.handleLogout();
      }
      setLocalStorage('authToken', keycloakToken, keycloakTokenPayload['custom:useSessionStorage'] === '1');
      setLocalStorage(
        'refreshToken',
        keycloakInstance.refreshToken,
        keycloakTokenPayload['custom:useSessionStorage'] === '1',
      );
      const { usersStore } = rootStore;
      const userData = await usersStore.signinWithToken();
      const { userKey, userName } = userData;
      usersStore.updateCurrentAuthUser(userKey);
      usersStore.updateCurrentDisplayedUserKey(userKey);
      usersStore.updateCurrentDisplayedUserName(userName);
    }
  };

  updateAccountScopeFromUrl = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const accountKey = queryParams.get('accountKey');
    const divisionId = queryParams.get('divisionId');
    const isPpApplied = queryParams.get('isPpApplied');
    if (accountKey != null) {
      rootStore.usersStore.handleDisplayedAccountChange(+accountKey);
    }
    if (divisionId != null) {
      rootStore.usersStore.updateCurrDisplayedDivisionId(+divisionId);
    }
    if (isPpApplied != null) {
      rootStore.appStore.updatePricingMode(!!JSON.parse(isPpApplied));
    }
  };

  handleNewTab = async () => {
    if (rootStore) {
      await this.initiateKeycloakLoggedInUser();
      if (this.isSessionStorageAuth()) {
        const dispUserKey = this.getDispUserKeyFromStorage();
        const authUserKey = window.sessionStorage.getItem('authUserKey') || window.localStorage.getItem('authUserKey');
        if (authUserKey) {
          rootStore.usersStore.updateCurrentAuthUser(authUserKey);
          rootStore.usersStore.updateCurrentDisplayedUserKey(authUserKey);
          await rootStore.usersStore.initMainUser();
          this.userHasAuthenticated();
          if (authUserKey !== dispUserKey) {
            await rootStore.usersStore.handleDisplayedUserChange(dispUserKey);
          }
          this.updateAccountScopeFromUrl();
          rootStore.fetchData(dispUserKey);
        }
      }
    }
    this.setState(() => ({ newTabLoading: false }));
  };

  userHasAuthenticated = () => {
    this.setState({ isAuthenticated: true });
    const userKey = rootStore.usersStore.currentDisplayedUserKey;
    const email = rootStore.usersStore.currentDisplayedUserName;
    const displayName = email;
    try {
      fsHandler.identify(userKey, displayName, email);
    } catch (error) {
      // console.log('warn identify');
    }
  };

  // eslint-disable-next-line class-methods-use-this
  isSessionStorageAuth = () => {
    const authToken = window.sessionStorage.getItem('authToken') || window.localStorage.getItem('authToken');
    const refreshToken = window.sessionStorage.getItem('refreshToken') || window.localStorage.getItem('refreshToken');
    const authTokenData = authToken?.split('.')[1];
    if (!authToken || !authTokenData) {
      return false;
    }
    const validBase64TokenData = authTokenData.replace(/-/g, '+').replace(/_/g, '/');
    const userData = JSON.parse(window.atob(validBase64TokenData));
    const exp = userData.exp * 1000; // seconds to milliseconds
    const now = Date.now();
    const isLive = exp > now;
    return isLive || refreshToken;
  };

  clearOnLogout = () => {
    window.sessionStorage.clear();
    window.localStorage.clear();
    persistRealm({ realmValue: '' }); // remove realm from cookies
    persistUsername({ usernameValue: '' }); // remove username from cookies
  };

  async handleLogout(redirect = '') {
    try {
      const realm = getRealmLocally();
      if (realm) {
        // in keycloak authentication mode
        this.clearOnLogout();
        const keycloakInstance = await getKeycloakInstance();
        keycloakInstance?.logout({ logoutMethod: 'GET' });
        resetInstance();
      } else {
        // in cognito authentication mode
        const token = window.sessionStorage.getItem('authToken') || window.localStorage.getItem('authToken');
        // eslint-disable-next-line max-len
        let ssoLogoutConfiguration;
        try {
          ssoLogoutConfiguration = await signOut(); // Need to await for sign out before clearing the session storage
          // eslint-disable-next-line no-empty
        } catch {}
        this.setState({ isAuthenticated: false });
        this.clearOnLogout();
        this.setUsername('');
        rootStore.invalidateStoresLogOut();
        if (ssoLogoutConfiguration?.isLogoutIdP) {
          const tokenPayload = parseJwt(token);
          const idpSettings = ssoLogoutConfiguration?.idpIdentifiers?.[tokenPayload.identities[0].providerName];
          const { idp, clientId } = idpSettings || {};
          const idToken = tokenPayload['custom:id_token'];
          let issuer;
          if (idToken) {
            const idTokenPayload = parseJwt(idToken);
            const { iss } = idTokenPayload;
            issuer = iss;
          }
          switch (idp) {
            case 'okta':
              logoutOkta({ idToken, issuer });
              return;
            case 'ping':
              logoutPing({ clientId });
              return;
            default:
              break;
          }
        }
        if (redirect) {
          window.location.href = redirect;
        } else {
          window.location.reload();
        }
      }
    } catch (e) {
      // if we get here, it means that user token is already expired
      // console.error(e);
    }
  }

  render() {
    const { newTabLoading, isAuthenticated, username } = this.state;
    const { usersStore, invoiceStore, usageStore, appStore, kubernetesStore, commitmentStore } = rootStore;
    const childProps = {
      isAuthenticated,
      newTabLoading,
      userHasAuthenticated: this.userHasAuthenticated,
      isSessionStorageAuth: this.isSessionStorageAuth,
      handleLogout: this.handleLogout.bind(this),
      username,
      setUsername: this.setUsername.bind(this),
      rootStore,
      usersStore,
      invoiceStore,
      usageStore,
      appStore,
      kubernetesStore,
      commitmentStore,
    };
    const customTheme = usersStore.getCurrDisplayedUserTheme();
    if (newTabLoading) {
      return <Spinner />;
    }
    return (
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={muiTheme}>
            <UserSettingsProvider>
              <ScrollToTop>
                <div>
                  <ToastContainer hideProgressBar position="bottom-left" />
                  <CustomThemeApplier customThemeName={customTheme} />
                  <Router childProps={childProps} />
                  <ReactQueryDevtools initialIsOpen={false} />
                </div>
              </ScrollToTop>
            </UserSettingsProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  queryClient: PropTypes.object.isRequired,
};

export default withInvoiceFiltersContextProvider(withRootStoreContextProvider(withRouter(App), rootStore), rootStore);
