import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import InformationOutline from 'mdi-react/InformationOutlineIcon';
// eslint-disable-next-line max-len
import RecommendationOptionsContext from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationOptionsContext';

import { bytesToGb, strGbToSize } from 'shared/utils/strUtil';
import classes from './recommendationOption.module.scss';

const POTENTIAL_SAVING_LABEL = 'Annual Potential/Actual Savings';

const RecommendationOptionButton = ({
  recommendation,
  properties,
  isCurrent,
  optionIndex,
  isRecommendedOption,
  minHeight,
  noticeComment,
}) => {
  const isRecommended = optionIndex === 0;

  const { selectedOptionIndex, setSelectedOptionIndex } = useContext(RecommendationOptionsContext);

  const { getCurrencyNumber } = useUserSettingsContext();
  const [title, setTitle] = useState('');
  const isSelected = selectedOptionIndex === optionIndex;

  const option = recommendation?.recData?.alternatives?.[optionIndex];

  useEffect(() => {
    let tmpTitle = `OPTION ${optionIndex + 1}`;
    if (isCurrent) {
      tmpTitle = 'CURRENT';
    } else if (isRecommended) {
      tmpTitle = isRecommendedOption ? 'RECOMMENDED OPTION' : `${tmpTitle} (RECOMMENDED)`;
    }
    setTitle(tmpTitle);
  }, []);

  const optionSelectionChanged = () => {
    if (!isCurrent && setSelectedOptionIndex) {
      return setSelectedOptionIndex(optionIndex);
    }
    return undefined;
  };

  const getPropertyValue = (property) => {
    let propertyValue = '';
    if (!property) {
      return '';
    }

    if (!property) {
      return propertyValue;
    }

    const value = property.getterFunction(recommendation, optionIndex);
    if (property.isBSize) {
      propertyValue = value || !property.displayNA ? strGbToSize(bytesToGb(value, 10, true)) : property.displayNA;
    } else if (property.isPercent) {
      propertyValue = value ? `${value}%` : `${property.displayNA ? 'N/A' : ''}`;
    } else if (property.isMilliCores) {
      propertyValue = value ? `${value} milliCores` : '';
    } else if (typeof value === 'string' && property.isCurrency) {
      propertyValue = getCurrencyNumber(value);
    } else if (typeof value === 'number') {
      const decimal = value % 1 > 0 ? 2 : 0;
      propertyValue = property.isCurrency
        ? getCurrencyNumber(value, decimal)
        : value.toLocaleString(undefined, {
            maximumFractionDigits: decimal,
          });
    } else {
      propertyValue = value || property.displayNA;
    }
    return propertyValue;
  };

  const getPropertyName = (property) => {
    const propertyName = property?.labelForOption || property?.label;
    return propertyName ? `${propertyName}:` : null;
  };

  const isPropertyDifferentFromCurrent = (property) => {
    if (isCurrent || !property || property?.isNoColorInOptions) {
      return false;
    }
    return property?.getterFunction(recommendation, optionIndex) !== property?.getterFunction(recommendation);
  };

  const optionSavingAmount = option?.saving_amount || option?.savings_amount || 0;

  return (
    <div className={classes.optionContainer}>
      <div className={isRecommendedOption && classes.recommendedOptionContainer}>
        <div
          className={`${classes.option}${isCurrent ? '' : ` ${classes.notCurrent}`}${
            isSelected ? ` ${classes.selected}` : ''
          } ${isCurrent && isRecommendedOption && classes.currentRecommended}`}
          style={isRecommendedOption && { minHeight }}
          onClick={optionSelectionChanged}
        >
          <div className={`${classes.titleContainer}${isRecommended ? ` ${classes.recommended}` : ''}`}>
            <div className={classes.title}>{title}</div>
            {isCurrent && !isRecommendedOption ? (
              <div />
            ) : (
              <Tooltip placement="top" title={POTENTIAL_SAVING_LABEL} arrow>
                <div className={classes.savingAmount}>
                  {optionSavingAmount ? getCurrencyNumber(parseFloat(optionSavingAmount.replace(/,/g, '')), 2) : ''}
                </div>
              </Tooltip>
            )}
          </div>
          <div className={classes.dataContainer}>
            {properties?.map((property) => {
              const propValue = property?.getterFunction(recommendation, optionIndex);
              return (
                <>
                  <div
                    className={classNames(
                      classes.dataTitle,
                      property?.isBold && propValue && classes.bold,
                      property?.isRed && property?.isRed(recommendation) && propValue && classes.redValue,
                    )}
                  >
                    {getPropertyName(property)}
                    {property?.info ? (
                      <div>
                        <Tooltip title={property.info} arrow placement="top">
                          <div>
                            <InformationOutline size={18} />
                          </div>
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={`${classes.dataValue} ${
                      isPropertyDifferentFromCurrent(property) ? classes.differentFromCurrent : ''
                    } ${property?.isBold && classes.bold} 
                    ${property?.isRed && property?.isRed(recommendation) && propValue && classes.redValue}`}
                  >
                    <div className={property?.isBold && !isCurrent && propValue ? classes.chipValue : ''}>
                      {getPropertyValue(property)}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          {noticeComment && <div className={classes.noticeComment}>{noticeComment}</div>}
        </div>
        {isRecommendedOption && isCurrent && <div className={classes.arrowRight} />}
      </div>
      {isSelected && !isRecommendedOption && <div className={classes.arrowDown} />}
    </div>
  );
};

RecommendationOptionButton.propTypes = {
  recommendation: PropTypes.object.isRequired,
  isCurrent: PropTypes.bool,
  optionIndex: PropTypes.number,
  properties: PropTypes.object,
};

RecommendationOptionButton.defaultProps = {
  isCurrent: false,
  optionIndex: undefined,
  properties: undefined,
};

export default RecommendationOptionButton;
