import React from 'react';

import styles from './UserExpandedData.module.scss';

const EffectivePermissions = ({ user }) => {
  const userName = user?.userName;

  return (
    <div className={styles.container}>
      <div className={styles.header}>Effective Permissions </div>
      <div className={styles.effecivePermissionsTableContainer}>Effective Permissions</div>
      {userName}
    </div>
  );
};

export default EffectivePermissions;
