import React, { useEffect, useRef, useState } from 'react';
import Spinner, { SIZES } from 'shared/components/andtComponents/Spinner';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import { debounce } from 'lodash';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { FILTERS } from 'recommendationsNew/consts';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import useDynamicRanges from 'recommendationsNew/hooks/react-query/useDynamicRanges';
import classes from './annualSavingsMinValueFilter.module.scss';

const ANNUAL_SAVINGS_TITLE = 'Annual savings';

export function AnnualSavingsMinValueFilter() {
  const rangeBarRef = useRef();
  const { recommendationFilters: filtersContext, setRecommendationFilters: setFiltersContext } =
    useRecommendationsNewContext();
  const { [FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id]: annualSavingsGreaterThan } = filtersContext;

  const rangeFiltersContext = {
    [FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id]: annualSavingsGreaterThan,
  };
  const [rangeFiltersCopy, setRangeFiltersCopy] = useState(rangeFiltersContext);
  const [lowerLimit, setLowerLimit] = useState(0);
  const [upperLimit, setUpperLimit] = useState(0);
  const [minValueLimitSelected, setMinValueLimitSelected] = useState(
    rangeFiltersCopy[FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id],
  );
  const dynamicRangesHook = useDynamicRanges();
  const { data: dynamicRanges, isLoading: isDynamicRangesLoading } = dynamicRangesHook.fetchDynamicRanges();
  const [rangeBarUnitToPixelsRatio, setRangeBarUnitToPixelsRatio] = useState(1);

  const { currencySymbol } = useUserSettingsContext();

  useEffect(() => {
    if (dynamicRanges && !isDynamicRangesLoading) {
      setLowerLimit(dynamicRanges?.annualSavingsRange?.min);
      setUpperLimit(dynamicRanges?.annualSavingsRange?.max);
      let newFilters = { ...rangeFiltersCopy };
      if (!rangeFiltersCopy[FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id]) {
        newFilters = {
          ...newFilters,
          [FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id]: dynamicRanges.annualSavingsRange?.min,
        };
      }
      setRangeFiltersCopy(newFilters);
    }
  }, [dynamicRanges, isDynamicRangesLoading]);

  useEffect(() => {
    setMinValueLimitSelected(rangeFiltersCopy[FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id]);
  }, [rangeFiltersCopy[FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id]]);

  useEffect(() => {
    if (!isDynamicRangesLoading) {
      setMinValueLimitSelected(filtersContext[FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id] || lowerLimit);
    }
  }, [filtersContext[FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id]]);
  useEffect(() => {
    if (!isDynamicRangesLoading && rangeBarRef.current?.clientWidth && upperLimit - lowerLimit > 0) {
      setRangeBarUnitToPixelsRatio(rangeBarRef.current?.clientWidth / (upperLimit - lowerLimit));
    } else {
      setRangeBarUnitToPixelsRatio(1);
    }
  }, [rangeBarRef.current?.clientWidth, isDynamicRangesLoading, upperLimit, lowerLimit]);

  function pxToUnit(px) {
    return Math.round(px / rangeBarUnitToPixelsRatio);
  }

  function unitToPx(unit) {
    return Math.round(unit * rangeBarUnitToPixelsRatio);
  }

  const selectedPointStyle = {
    left: `${unitToPx(minValueLimitSelected)}px`,
  };

  const updateRangeValue = (newFilters) => {
    setFiltersContext(newFilters);
  };
  const debouncedOnAnnualSavingsValueChange = debounce(updateRangeValue, 500);
  const onAnnualSavingsValueChange = (e) => {
    const newFilters = {
      ...filtersContext,
      ...rangeFiltersCopy,
      [FILTERS.ANNUAL_SAVINGS_GREATER_THAN.id]: e.minValueLimit,
    };
    setRangeFiltersCopy(newFilters);
    debouncedOnAnnualSavingsValueChange(newFilters);
  };
  const handleLowerLimitMouseDown = (e) => {
    const startX = e.clientX;
    const initialPosition = unitToPx(minValueLimitSelected);

    const handleMouseMove = (e) => {
      const deltaX = e.clientX - startX;
      const newPosition = Math.min(unitToPx(upperLimit), Math.max(0, initialPosition + Math.round(deltaX)));
      onAnnualSavingsValueChange({ minValueLimit: pxToUnit(newPosition) });
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className={classes.rangeFilterContainer}>
      <Tooltip title="Filter recommendations by Annual Potential/Actual Savings amount">
        <div className={classes.rangeArea}>
          {ANNUAL_SAVINGS_TITLE} <GenerateIcon iconName={ICONS.greaterThanEqual.name} />
          {isDynamicRangesLoading ? (
            <div>
              <Spinner size={SIZES.MEDIUM_50} position="relative" />
            </div>
          ) : (
            <div className={classes.rangeValue}>
              <div>{currencySymbol}</div>
              <div>{minValueLimitSelected?.toLocaleString() || 0}</div>
            </div>
          )}
        </div>
      </Tooltip>
      <div className={classes.rangeBar} ref={rangeBarRef}>
        <div
          className={classes.selectedPointContainer}
          style={selectedPointStyle}
          onMouseDown={handleLowerLimitMouseDown}
        >
          <div className={classes.selectedPoint} />
        </div>
      </div>
    </div>
  );
}
