import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/system/colorManipulator';
import { DataTypeProvider, RowDetailState } from '@devexpress/dx-react-grid';
import { Grid, TableColumnResizing, TableHeaderRow, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@mui/styles';
import Spinner from 'shared/components/andtComponents/Spinner';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import IconFromPng from 'shared/components/IconFromPng';
import ICONS from 'shared/constants/assetsConstants';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { useVirtualTags } from 'usage/hooks/react-query/useVirtualTags';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import * as constants from '../constants/virtualTagsConstants';
import VirtualTagModal from './VirtualTagCreateEditModal';
import VirtualTagsActionButton from './VirtualTagsActionButton';

// eslint-disable-next-line react/prop-types
const CreateVirtualTagButton = ({ disabled, handler }) => (
  <Row className="dotted_box">
    <div className="dotted_box-title">
      <span>Create Virtual Tag</span>
    </div>
    <br />
    <div>
      <LinkIconButton>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href="/" onClick={(e) => handler(e, disabled)} />
        <IconFromPng size="auto" icon={ICONS.plus} className={`${disabled ? 'img-disabled' : ''}`} />
      </LinkIconButton>
    </div>
    {disabled ? (
      <div>
        <br />
        <span>No tags were identified to create views</span>
      </div>
    ) : null}
  </Row>
);
CreateVirtualTagButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handler: PropTypes.func.isRequired,
};

const styles = () => ({
  tableStriped: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha('#646777', 0.01),
    },
  },
  textAdjustment: {
    fontSize: '25.5vw',
  },
});

const VirtualTagsTable = ({ usersStore, getCustomTagsKeys }) => {
  const [virtualTagForEdit, setVirtualTagForEdit] = React.useState(null);
  const [virtualTagForDelete, setVirtualTagForDelete] = React.useState(null);
  const [columnWidths, setColumnWidths] = React.useState(constants.VIRTUAL_TAGS_COLUMN_WIDTHS);
  const [nameValidError, setNameValidError] = React.useState('');

  const { fetchVirtualTags, useAddVirtualTag, useDeleteVirtualTag, useUpdateVirtualTag } = useVirtualTags();
  const addVirtualTag = useAddVirtualTag();
  const deleteVirtualTag = useDeleteVirtualTag();
  const updateVirtualTag = useUpdateVirtualTag();
  const { data: virtualTags = [], isLoading } = fetchVirtualTags();

  const getCustomtagsOptions = () => {
    const arrCustomTagsKeys = getCustomTagsKeys();
    return (
      arrCustomTagsKeys &&
      arrCustomTagsKeys.reduce((acc, currCustomTag) => {
        const foundedCustomTag = virtualTags.find(
          (virtualTag) => virtualTag && virtualTag.customTags.find((customTag) => currCustomTag === customTag.name),
        );
        if (!foundedCustomTag) {
          acc.push({ label: currCustomTag, value: currCustomTag });
        }
        return acc;
      }, [])
    );
  };

  const closeModal = () => {
    setVirtualTagForEdit(null);
  };

  const validateNewName = (value, existingVirtualTagsNames) => {
    const existingNamesAndIds = existingVirtualTagsNames.filter(
      (nameAndId) => nameAndId.name !== virtualTagForEdit.name && nameAndId.id !== virtualTagForEdit.uuid,
    );
    let nameValidError = '';
    if (existingNamesAndIds.some(({ name }) => name.toLowerCase() === value.toLowerCase())) {
      nameValidError = 'Name already exists';
    }
    if (!value) {
      nameValidError = 'Name must have a value';
    }
    return nameValidError;
  };

  const handleChangeVirtualTagName = (value) => {
    const virtualTagsNamesAndIds = virtualTags.map((vt) => ({
      name: vt.name,
      id: vt.uuid,
    }));
    const nameValidError = validateNewName(value, virtualTagsNamesAndIds);
    setNameValidError(nameValidError);
    setVirtualTagForEdit({ ...virtualTagForEdit, name: value });
  };

  const handleChangeCustomTags = (values) => {
    setVirtualTagForEdit({ ...virtualTagForEdit, customTags: values });
  };

  const handleSaveVirtualTag = async () => {
    virtualTagForEdit.customTags = virtualTagForEdit.customTags.map((customTag) => customTag.value);
    if (virtualTagForEdit.uuid) {
      await updateVirtualTag.mutate(virtualTagForEdit);
    } else {
      await addVirtualTag.mutate(virtualTagForEdit);
    }
    setVirtualTagForEdit(null);
  };

  const handleDeleteVirtualTag = async (action) => {
    if (action === 'delete') {
      await deleteVirtualTag.mutate(virtualTagForDelete);
    }
    setVirtualTagForDelete(null);
  };

  const addNewVirtualTag = (e, isDisabled) => {
    e.preventDefault();
    if (isDisabled) {
      return;
    }
    setVirtualTagForEdit({ name: '', customTags: [], createdBy: usersStore.currentDisplayedUserName });
  };

  const editVirtualTagClick = (virtualTag) => {
    const copyVirtualTag = virtualTag;
    const virtualTagForEdit = {
      ...copyVirtualTag,
      customTags: copyVirtualTag.customTags.map((customTag) => ({ label: customTag.name, value: customTag.name })),
      oldCustomTags: copyVirtualTag.customTags,
    };
    setVirtualTagForEdit(virtualTagForEdit);
  };

  const changeColumnWidths = (columnWidths) => {
    setColumnWidths(columnWidths);
  };

  const renderRowCustomTags = ({ row }) => (
    <div className="card">
      <Grid rows={row.customTags} columns={constants.VIRTUAL_TAGS_TABLE_CUSTOM_TAGS_COLUMNS}>
        <TableWrapper columnExtensions={constants.VIRTUAL_TAGS_TABLE_CUSTOM_TAGS_COLUMNS} />
        <TableHeaderRow />
      </Grid>
    </div>
  );

  const handlersForModal = {
    closeModal,
    changeVirtualTagCustomTags: handleChangeCustomTags,
    changeVirtualTagsName: handleChangeVirtualTagName,
    saveVirtualTag: handleSaveVirtualTag,
  };
  const virtualTagActionButtonHandlers = {
    edit: editVirtualTagClick,
    deleteVirtualTag: (virtualTag) => setVirtualTagForDelete(virtualTag.uuid),
  };

  const userActionsFormatter = (data) => {
    withStyles(data);
    return (
      <ReadOnlyDisplayWrapper userReadOnly={usersStore.currentUserReadOnly}>
        <VirtualTagsActionButton virtualTag={data.row} handlers={virtualTagActionButtonHandlers} />
      </ReadOnlyDisplayWrapper>
    );
  };

  const options = getCustomtagsOptions();
  return (
    <div style={{ width: '100%' }}>
      <div style={{ minHeight: '100px' }}>
        <ReadOnlyDisplayWrapper isHide={false} userReadOnly={usersStore.currentUserReadOnly}>
          <Row>
            <Col xs={12} md={12} lg={12} xl={12}>
              {isLoading ? (
                <Spinner />
              ) : (
                <CreateVirtualTagButton disabled={!options || !options.length} handler={addNewVirtualTag} />
              )}
            </Col>
          </Row>
        </ReadOnlyDisplayWrapper>
      </div>
      <Row style={{ minHeight: '100px' }}>
        <Col xs={12} md={12} lg={12} xl={12}>
          {isLoading ? (
            <Spinner />
          ) : (
            <Paper>
              <Grid rows={virtualTags} columns={constants.VIRTUAL_TAGS_TABLE_COLUMNS}>
                <RowDetailState />
                <DataTypeProvider for={['userAction']} formatterComponent={userActionsFormatter} />
                <TableWrapper styles={styles} />
                <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={changeColumnWidths} />
                <TableHeaderRow />
                <TableRowDetail contentComponent={renderRowCustomTags} />
              </Grid>
            </Paper>
          )}
        </Col>
      </Row>
      <VirtualTagModal
        onClose={closeModal}
        isOpen={virtualTagForEdit}
        virtualTag={virtualTagForEdit}
        handlers={handlersForModal}
        options={options}
        nameValidError={nameValidError}
      />
      {virtualTagForDelete && (
        <DeleteWarningModal
          isOpen={virtualTagForDelete}
          handleDelete={handleDeleteVirtualTag}
          modalTitle="Delete Virtual Tag"
          warningMessage={`Be advised, You are about to delete ${
            (virtualTagForDelete && virtualTagForDelete.name) || ''
          } Virtual Tag`}
          executeButtonTitle="Delete"
        />
      )}
    </div>
  );
};

VirtualTagsTable.propTypes = {
  usersStore: PropTypes.object.isRequired,
  getCustomTagsKeys: PropTypes.func.isRequired,
};

const ObserverVirtualTagsTable = withInvoiceFiltersContextConsumer(observer(VirtualTagsTable));
export default ObserverVirtualTagsTable;
