import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { palette } from 'shared/constants/colorsConstants';
import { isDecimalNeeded } from 'shared/utils/strUtil';
import moment from 'moment/moment';
// eslint-disable-next-line max-len
import { CustomizedLegend } from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationChart/customizedLegend';
import { EXPORT_COLUMNS } from 'commitment/containers/spAnalyzerNew/utils/consts';
import { downloadChartAsPng } from 'shared/utils/downloadAsPng';
import styles from './recommendedChart.module.scss';
import { CustomChartTooltip } from './CustomChartTooltip';
import ChartHeader from './ChartHeader';

const legendWrapperStyle = {
  bottom: '-10px',
  left: '0',
  position: 'absolute',
  width: 'auto',
};

// when includeEstimatedOption = true - The chart can include 4 mode:
// actual, actual+estimate, simulate, simulate+estimate
// set the display legend and relevant tooltip according to the mode
const RecommendedChart = ({
  data,
  legend,
  header,
  info,
  chartType,
  includeEstimatedOption,
  tooltipMapData,
  isAnimationActive,
  automationId,
}) => {
  const [isDailyMode, setIsDailyMode] = useState(true);
  const [isActualMode, setIsActualMode] = useState(true);
  const [isEstimatedMode, setIsEstimatedMode] = useState(false);
  const [hiddenKeys, setHiddenKeys] = useState(null);
  const chartRef = useRef();
  const [chartData, setChartData] = useState(isDailyMode ? data.daily : data.hourly);

  // Calculate the maximum value in your data
  const maxValue = Math.max(...chartData.map((item) => Math.max(...legend.map((leg) => item[leg.key]))));
  const chartWidth = isDailyMode ? '100%' : 50 * chartData.length; // Calculate the chart width for long data

  const CustomizedAxisTick = ({ x, y, payload }) => {
    const { value } = payload;
    const dateFormat = moment(value).format('MMM DD');
    if (isDailyMode) {
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="end" className={styles.chartTick}>
            <tspan x="0" dy="1.2em">
              {dateFormat}
            </tspan>
          </text>
        </g>
      );
    }
    const timeFormat = moment(value).format('HH:mm');
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" className={styles.chartTick}>
          <tspan x="0" dy="0.8em">
            {dateFormat}
          </tspan>
          <tspan x="0" dy="1.25em">
            {timeFormat}
          </tspan>
        </text>
      </g>
    );
  };

  const fetchChartToCsv = () => {
    const exportColumns = EXPORT_COLUMNS.filter(
      (col) => col.key === 'date' || legend.find((l) => l.exportKey === col.key || l.key === col.key),
    );
    let headers = [...exportColumns];
    if (includeEstimatedOption) {
      headers = isActualMode
        ? headers.filter((col) => col.isSimulateColumn === false || (isEstimatedMode && col.isEstimateColumn))
        : headers.filter((col) => col.isSimulateColumn || (isEstimatedMode && col.isEstimateColumn));
    }
    return [
      {
        data: chartData || [],
        filename: `${header}.csv`,
        headers,
      },
    ];
  };
  const downloadAsSvg = () => {
    const chartElement = chartRef.current;
    if (chartElement) {
      const svgElement = chartElement.current.getElementsByTagName('svg')[0];
      const serializer = new XMLSerializer();
      const svgStr = serializer.serializeToString(svgElement);

      const blob = new Blob([svgStr], { type: 'image/svg+xml' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${header}.svg`;
      link.click();

      URL.revokeObjectURL(url);
    }
  };

  useEffect(() => {
    setChartData(isDailyMode ? data.daily : data.hourly);
  }, [isDailyMode, data]);

  useEffect(() => {
    setHiddenKeys(isEstimatedMode ? legend.filter((leg) => !leg.isEstimated).map((leg) => leg.key) : null);
  }, [isEstimatedMode]);

  const isLegendDisplay = (legend) =>
    useMemo(() => {
      if (includeEstimatedOption) {
        if (isActualMode) {
          return !legend.isSimulate && (!legend.isEstimated || isEstimatedMode);
        }
        return legend.isSimulate || (isEstimatedMode && legend.isEstimated);
      }
      return true;
    }, [includeEstimatedOption, isActualMode, isEstimatedMode]);

  const currentTooltipMapData = useMemo(() => {
    if (isEstimatedMode) {
      return isActualMode ? tooltipMapData?.estimatedMode : tooltipMapData.simulateEstimatedMode;
    }
    return !includeEstimatedOption || isActualMode ? tooltipMapData.regularMode : tooltipMapData.simulateMode;
  }, [isActualMode, isEstimatedMode, includeEstimatedOption]);

  return (
    <div className={styles.chartWrapper}>
      <ChartHeader
        header={header}
        includeEstimatedOption={includeEstimatedOption}
        info={info}
        exportToCsv={fetchChartToCsv}
        exportToPng={() => downloadChartAsPng(chartRef.current)}
        exportToSvg={downloadAsSvg}
        isDailyMode={isDailyMode}
        setIsDailyMode={setIsDailyMode}
        isActualMode={includeEstimatedOption ? isActualMode : null}
        setIsActualMode={includeEstimatedOption ? setIsActualMode : null}
        isEstimatedMode={isEstimatedMode}
        setIsEstimatedMode={setIsEstimatedMode}
        automationId={automationId}
      />
      <div className={styles.chart}>
        <ResponsiveContainer width={chartWidth} height={270} ref={chartRef}>
          <AreaChart width={500} height={400} data={chartData} margin={{ top: 20, right: 0, bottom: 0, left: 0 }}>
            <CartesianGrid strokeDasharray="3 0" vertical={false} />
            <XAxis dataKey="date" axisLine={false} tickLine={false} interval={0} tick={<CustomizedAxisTick />} />
            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) =>
                `${value ? '$' : ''}${Number(value).toFixed(isDecimalNeeded(value, 2) ? 2 : 0)}`
              }
              tick={{ fill: palette.gray[400] }}
              domain={[0, maxValue * 1.1]}
            />
            <Tooltip content={<CustomChartTooltip tooltipMapData={currentTooltipMapData} />} />
            {legend.map((leg) =>
              isLegendDisplay(leg) ? (
                <Area
                  type={chartType}
                  dataKey={leg.key}
                  stroke={!isEstimatedMode || leg.isEstimated ? leg.color : palette.gray[400]}
                  fill={!isEstimatedMode || leg.isEstimated ? leg.color : 'none'}
                  fillOpacity={leg.opacity}
                  strokeWidth={2}
                  name={leg.name}
                  isAnimationActive={isAnimationActive}
                  strokeDasharray={!isEstimatedMode || leg.isEstimated ? 'none' : '4 3'}
                />
              ) : null,
            )}
            <Legend
              wrapperStyle={legendWrapperStyle}
              content={
                <CustomizedLegend className={{ customizedLegend: styles.customizedLegend }} hideKeys={hiddenKeys} />
              }
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default RecommendedChart;
