import React, { useContext } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { getHistoryFiltersViewStateCopy } from 'recommendationsNew/components/heatMap/heatMapFilters/filterStates';
import { useRecommendationsNewContext } from '../../../contexts/recommendationsNewContext';
import HeatMapActionsContext from '../contexts/heatMapActionsContext';
import { useHeatMapContext } from '../contexts/heatMapContext';

import classes from './heatMapActions.module.scss';

const SavingsTracker = () => {
  const { setRecommendationFilters } = useRecommendationsNewContext();
  const { setIsSavingsTracker } = useContext(HeatMapActionsContext);
  const { isWasteChart } = useHeatMapContext();

  const handleClick = () => {
    if (isWasteChart) {
      return;
    }
    setIsSavingsTracker(true);
    setRecommendationFilters(getHistoryFiltersViewStateCopy());
  };

  return (
    <Tooltip title="Savings Tracker (History)" disabled={isWasteChart}>
      <div className={`${classes.actionsPanelButton} ${isWasteChart ? classes.isDisabled : ''}`} onClick={handleClick}>
        <GenerateIcon iconName={ICONS.sackDollar.name} automation-id="savings-tracker" />
      </div>
    </Tooltip>
  );
};

export default SavingsTracker;
