import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { SORT_BY_OPTIONS } from 'recommendationsNew/consts';

const HeatMapContext = createContext();

export const HeatMapProvider = ({ children }) => {
  const [heatMapGroupByOptions, setHeatMapGroupByOptions] = useState([]);
  const [heatMapSortByOption, setHeatMapSortByOption] = useState(SORT_BY_OPTIONS.items.SAVINGS.property);

  const [isHeatMapClosed, setIsHeatMapClosed] = useState(false);

  const [isWasteChart, setIsWasteChart] = useState(false);

  return (
    <HeatMapContext.Provider
      value={{
        heatMapSortByOption,
        setHeatMapSortByOption,
        heatMapGroupByOptions,
        setHeatMapGroupByOptions,
        isWasteChart,
        setIsWasteChart,
        isHeatMapClosed,
        setIsHeatMapClosed,
      }}
    >
      {children}
    </HeatMapContext.Provider>
  );
};

HeatMapProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export const useHeatMapContext = () => useContext(HeatMapContext);
