import React from 'react';
import { withStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';
import classes from './tagColumn.module.scss';

const WhiteTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'white !important',
    borderRadius: '6px',
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 36, 0.25);',
    zIndex: 100,
  },
}))(Tooltip);
export const TagColumn = ({ column, row }) => {
  const tags = column.name === 'customTags' ? row.customTags || {} : row.enrichmentTags || {};
  const tagKeys = Object.keys(tags);
  const displayTags = [...Object.keys(tags)].splice(0, 1);

  return (
    <div className={classes.tagWrapper}>
      {displayTags.map((tag) => (
        <Tooltip title={tagKeys.length <= 2 ? `${tag}: ${tags[tag]}` : null} arrow placement="bottom">
          <div className={`${classes.tdWrapper} ${classes.truncate}`} key={`tag-${tag}`}>
            <span className={classes.tagName}>{`${tag}:`}</span>
            <span className={classes.tagValue}>{tags[tag]}</span>
          </div>
        </Tooltip>
      ))}
      {tagKeys.length > 2 && (
        <div>
          <WhiteTooltip
            placement="bottom"
            title={
              <div className={classes.tagTooltipWrap}>
                {tagKeys.map((tag) => (
                  <div className={classes.tooltipRow} key={`tagTP-${tag}`}>
                    <span className={classes.tagName}>{tag}</span>
                    <span className={classes.tagValue}>{tags[tag]}</span>
                    <br />
                  </div>
                ))}
              </div>
            }
          >
            <span className={classes.tagsCount}>{`+${tagKeys.length - 1}`}</span>
          </WhiteTooltip>
        </div>
      )}
    </div>
  );
};
