import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Card, CardBody, Container, Row } from 'reactstrap';
import Spinner from 'shared/components/andtComponents/Spinner';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import IconFromPng from 'shared/components/IconFromPng';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import ICONS from 'shared/constants/assetsConstants';
import WeightsTable from './WeightsTable';
import AddOrUpdateWeightsModal from './AddOrUpdateWeightsModal';
import { useKubernetes } from '../../../hooks/useKubernetes';

export const AWS_K8S_WEIGHTS_TABLE_COLUMNS = [
  { name: 'instance', title: 'Instance Family' },
  { name: 'cpu', title: 'CPU' },
  { name: 'memory', title: 'Memory' },
  { name: 'network', title: 'Network' },
  { name: 'userAction', title: ' ' },
];
export const AWS_K8S_WEIGHTS_TABLE_COLUMNS_WIDTHS = [
  { columnName: 'instance', width: 200 },
  { columnName: 'cpu', width: 150 },
  { columnName: 'memory', width: 150 },
  { columnName: 'network', width: 150 },
  { columnName: 'userAction', width: 150 },
];
export const AWS_K8S_WEIGHTS_TABLE_COLUMNS_EXTENSIONS = [
  { columnName: 'cpu', align: 'center' },
  { columnName: 'network', align: 'center' },
  {
    columnName: 'memory',
    align: 'center',
  },
];

export const AddWeightsButton = ({ handler }) => (
  <Row style={{ display: 'flex', justifyContent: 'flex-start', margin: '0 0 10px 0', alignItems: 'center' }}>
    <div style={{ padding: '5px', marginRight: '10px' }}>
      <LinkIconButton>
        <button type="button" className="btn-no-style" onClick={handler} />
        <IconFromPng size={30} icon={ICONS.plus} />
      </LinkIconButton>
    </div>
    <div style={{ fontSize: '1.2vw', padding: '5px' }}>
      <span>Add Cost Weights to Instance Type</span>
    </div>
  </Row>
);
AddWeightsButton.propTypes = {
  handler: PropTypes.func.isRequired,
};

const KubernetesWeightsPage = ({ usersStore, invoiceStore, filtersValuesMap }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalState, setModalState] = useState('');
  const [currEditedId, setCurrEditedId] = useState(null);
  const [weightsParams, setWeightsParams] = useState({
    instance: '',
    cpu: '',
    memory: '',
    network: '',
    totalScore: '',
  });
  const [inputsErrorMessage, setInputsErrorMessage] = useState({
    instance: '',
    cpu: '',
    memory: '',
    network: '',
    totalScore: '',
  });

  const { fetchWeightsData, addWeightsData, updateWeightsData, deleteWeightsData } = useKubernetes();
  const { data: weightsData, isLoading } = fetchWeightsData();
  const { mutateAsync: addWeights } = addWeightsData();
  const { mutateAsync: updateWeights } = updateWeightsData();
  const { mutateAsync: deleteWeights } = deleteWeightsData();

  const defaultWeightsParams = {
    instance: '',
    cpu: '',
    memory: '',
    network: '',
    totalScore: '',
  };
  const defaultInputsErrorMessage = {
    instance: '',
    cpu: '',
    memory: '',
    network: '',
    totalScore: '',
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setCurrEditedId(null);
    setWeightsParams({ ...defaultWeightsParams });
    setInputsErrorMessage({ ...defaultInputsErrorMessage });
  };
  const resetToBaseStateParams = () => {
    setIsModalOpen(false);
    setCurrEditedId(null);
    setWeightsParams({ ...defaultWeightsParams });
    setInputsErrorMessage({ ...defaultInputsErrorMessage });
  };
  const handleOpenAddWeightsModalClick = () => {
    setIsModalOpen(true);
    setModalState('add');
  };
  const newWeightsDataIsValid = (weightsParams) => {
    const { instance, cpu, memory, network } = weightsParams;
    inputsErrorMessage.instance = !instance ? 'Please select an instance' : '';
    inputsErrorMessage.totalScore = +cpu + +memory + +network !== 10 ? 'Total weights must be equal to 10' : '';
    if (Object.values(inputsErrorMessage).some((val) => !!val)) {
      setInputsErrorMessage({ ...inputsErrorMessage });
      return false;
    }
    return true;
  };
  const handleAddWeightsData = async () => {
    if (!newWeightsDataIsValid(weightsParams)) {
      return;
    }
    await addWeights(weightsParams);
    toggleModal();
  };
  const handleDeleteWeightClick = async (id) => {
    await deleteWeights(id);
    resetToBaseStateParams();
  };
  const handleEditWeightsData = async () => {
    if (!newWeightsDataIsValid(weightsParams)) {
      return;
    }
    await updateWeights({ currEditedId, weightsParams });
    toggleModal();
  };
  const handleEditWeightsClick = (row) => {
    const { id, ...rest } = row;
    const instance = { value: row.instance, label: row.instance };
    const selectedweightsParams = { ...rest, instance };
    const inputsErrorMessage = defaultInputsErrorMessage;
    setModalState('edit');
    setIsModalOpen(true);
    setWeightsParams(selectedweightsParams);
    setInputsErrorMessage(inputsErrorMessage);
    setCurrEditedId(id);
  };
  const handleInstanceChange = (selectedOption, selectedValue) => {
    weightsParams[selectedOption] = selectedValue ? { ...selectedValue } : selectedValue;
    inputsErrorMessage[selectedOption] = selectedValue ? '' : 'Please select an instance';
    setWeightsParams({ ...weightsParams });
    setInputsErrorMessage({ ...inputsErrorMessage });
  };
  const handleNumericFilterChange = (e) => {
    const attr = e.target.id;
    const val = e.target.value;
    // eslint-disable-next-line no-nested-ternary
    weightsParams[attr] = String(Math.min(Math.max(+val, 0), 10));
    const { cpu, memory, network } = weightsParams;
    inputsErrorMessage.totalScore = +cpu + +memory + +network !== 10 ? 'Total weights must be equal to 10' : '';

    setWeightsParams({ ...weightsParams });
    setInputsErrorMessage({ ...inputsErrorMessage });
  };

  if (isLoading) {
    return (
      <Container>
        <Card>
          <CardBody>
            <Spinner />
          </CardBody>
        </Card>
      </Container>
    );
  }

  const inputListOfValues = filtersValuesMap.get('instancefamily') || [];
  const { currentUserReadOnly } = usersStore;
  const instanceNotToShow =
    weightsData && weightsData.filter((wd) => wd.id !== currEditedId).map(({ instance }) => instance);
  return (
    <Container>
      <AddWeightsButton handler={handleOpenAddWeightsModalClick} />
      {weightsData.length > 0 && (
        <WeightsTable
          rows={weightsData}
          columns={AWS_K8S_WEIGHTS_TABLE_COLUMNS}
          columnWidths={AWS_K8S_WEIGHTS_TABLE_COLUMNS_WIDTHS}
          tableColumnExtensions={AWS_K8S_WEIGHTS_TABLE_COLUMNS_EXTENSIONS}
          defaultHiddenColumnNames={[]}
          defaultSorting={[]}
          deleteHandler={handleDeleteWeightClick}
          editHandler={handleEditWeightsClick}
          csvTitle="Kubernetes insatnce weights"
          currentUserReadOnly={currentUserReadOnly}
        />
      )}
      <AddOrUpdateWeightsModal
        modalState={modalState}
        toggle={toggleModal}
        isOpen={isModalOpen}
        inputListOfValues={inputListOfValues}
        inputSelectedOption={weightsParams}
        selectedInputsErrorMessage={inputsErrorMessage}
        handleAddWeightsData={handleAddWeightsData}
        handleEditWeightsData={handleEditWeightsData}
        handleInstanceChange={handleInstanceChange}
        invoiceStore={invoiceStore}
        handleNumericFilterChange={handleNumericFilterChange}
        instanceNotToShow={instanceNotToShow}
      />
    </Container>
  );
};

KubernetesWeightsPage.propTypes = {
  usersStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  filtersValuesMap: PropTypes.object.isRequired,
};

const ObserverKubernetesWeightsPage = withInvoiceFiltersContextConsumer(observer(KubernetesWeightsPage));
export default ObserverKubernetesWeightsPage;
