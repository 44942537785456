import { Option, ServiceCostAlert, ServiceCostAlertUpdate } from './types.ts';
import { FlatObject } from '../../../shared/types/commonTypes.ts';

export const validateRecipientsEmails = (emails: string) => {
  const recipientsEmailsArr = emails.replace(/ /g, '').split(',');
  const result = emails && recipientsEmailsArr.every((mail) => !mail || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail));
  return !!result;
};

export const createOptions = (typesToDisplayObj: FlatObject, descriptionsObj?: FlatObject) => {
  let options = [] as Option[];
  try {
    options = Object.entries(typesToDisplayObj).map(
      ([k, v]) => ({ value: k, label: v, description: descriptionsObj?.[k] } as Option),
    );
  } catch (error) {
    return options;
  }
  return options;
};

export const prepareFiltersForSaving = (filters: ServiceCostAlert['filters']): ServiceCostAlertUpdate['filters'] =>
  Object.fromEntries(
    Object.entries(filters).map(([key, filterConfig]) => [
      key,
      {
        ...filterConfig,
        value: Array.isArray(filterConfig.value) ? filterConfig.value.map((d) => d.value) : filterConfig.value,
      },
    ]),
  );
