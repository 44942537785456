import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import CodeBlock from 'shared/components/andtComponents/CodeBlock';
import { objAwsRegionNames } from 'shared/constants/awsConstants';
import SimpleSelect from 'shared/components/andtComponents/SimpleSelect';
import { hashText } from 'shared/utils/strUtil';
import ListWithHelp from 'shared/components/andtComponents/ListWithHelp/ListWithHelp';
import { AWS_ONBOARDING_FIELDS } from 'app/containers/Onboarding/AwsOnboarding/AwsOnboardingConstants';
import { ONBOARDING_TYPES } from 'app/containers/Onboarding/utils/OnboardingConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { getZeroPadAccountId } from 'shared/utils/awsUtils';
import commonStyles from '../Pages.module.scss';
import { trimAccId } from '../../AwsOnboardingUtils';
import { getTenantParams } from '../../../utils/OnboardingUtils';
import DetailsPageHelp from './DetailsPageHelp';
import styles from './DetailsPage.module.scss';

const DetailsPage = () => {
  const {
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const { usersStore } = useRootStore();
  const onboardingType = getValues(AWS_ONBOARDING_FIELDS.ONBOARDING_TYPE);
  const accountId = getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_ID);
  const accountName = getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_NAME);
  useEffect(() => {
    if (onboardingType === ONBOARDING_TYPES.AUTOMATIC) {
      setValue(AWS_ONBOARDING_FIELDS.BUCKET_NAME, `cur-${getZeroPadAccountId(accountId)}`);
      setValue(AWS_ONBOARDING_FIELDS.BUCKET_REGION, 'us-east-1');
      setValue(AWS_ONBOARDING_FIELDS.ROLE_ARN, `arn:aws:iam::${getZeroPadAccountId(accountId)}:role/PileusRole`);
    }
  }, [accountId]);
  useEffect(() => {
    const { tenantName, tenantSchemaName } = getTenantParams(usersStore.currentDisplaydUser, accountName);
    setValue(AWS_ONBOARDING_FIELDS.TENANT_NAME, tenantName);
    setValue(AWS_ONBOARDING_FIELDS.TENANT_SCHEMA_NAME, tenantSchemaName);
  }, [accountName]);
  const renderInput = ({ fieldName, name, label, icon, disabled, help, onChange, before }) => (
    <div className={commonStyles.inputField}>
      {before || null}
      <label htmlFor={name}>
        <span>
          <GenerateIcon iconName={ICONS[icon].name} />
          {label}
        </span>
        {help || null}
      </label>
      <div className={commonStyles.inputBox}>
        <div>
          <Input
            disabled={disabled}
            value={getValues(fieldName)}
            isInvalid={!!errors[fieldName]}
            invalidComponent={errors[fieldName]?.message || errors[fieldName]}
            onChange={
              onChange ||
              ((e) => {
                setValue(fieldName, e.target.value);
                clearErrors(fieldName);
              })
            }
            placeholder=""
            name={name}
            type="text"
          />
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <ListWithHelp
        styles={{
          listGap: getValues(AWS_ONBOARDING_FIELDS.EXTERNAL_ID) ? 16 : 34,
          helpWidth: 460,
          controlStyles: {
            marginLeft: '-4px',
          },
        }}
        list={[
          {
            render: () =>
              renderInput({
                fieldName: AWS_ONBOARDING_FIELDS.ACCOUNT_ID,
                name: 'onboarding-account-id',
                label: 'AWS Payer Account ID',
                icon: 'cloud',
                onChange: async (e) => {
                  const trim = trimAccId(e.target.value);
                  setValue(AWS_ONBOARDING_FIELDS.ACCOUNT_ID, trim, { shouldValidate: true });
                  const externalId = trim ? await hashText(trim, 8) : '';
                  setValue(AWS_ONBOARDING_FIELDS.EXTERNAL_ID, externalId);
                },
                help: (
                  <span>
                    <Button
                      isTextButton
                      icon={() => <GenerateIcon iconName={ICONS.search.name} />}
                      text="Find my ID"
                      onClick={() => {
                        window.open('https://console.aws.amazon.com/billing/home?#/account', '_blank');
                      }}
                    />
                  </span>
                ),
              }),
            key: 'onboarding-account-id',
          },
          ...(getValues(AWS_ONBOARDING_FIELDS.EXTERNAL_ID)
            ? [
                {
                  render: () => (
                    <div className={styles.externalIdBlock}>
                      <CodeBlock code={getValues(AWS_ONBOARDING_FIELDS.EXTERNAL_ID)} icon="External ID:" />
                    </div>
                  ),
                  key: 'external-id',
                },
              ]
            : []),
          {
            render: () =>
              renderInput({
                fieldName: AWS_ONBOARDING_FIELDS.ACCOUNT_NAME,
                name: 'onboarding-account-name',
                label: 'Display Account Name',
                icon: 'cloud',
              }),
            key: 'onboarding-account-name',
          },
          {
            render: () =>
              renderInput({
                fieldName: AWS_ONBOARDING_FIELDS.BUCKET_NAME,
                name: 'onboarding-bucket-name',
                label: 'Invoice Bucket Name',
                disabled: onboardingType === ONBOARDING_TYPES.AUTOMATIC,
                icon: 'bucket',
              }),
            key: 'onboarding-bucket-name',
          },
          {
            render: () => (
              <div className={commonStyles.inputField}>
                <label htmlFor="onboarding-bucket-region">
                  <span>
                    <GenerateIcon iconName={ICONS.earth.name} />
                    Bucket Region
                  </span>
                </label>
                <div className={commonStyles.inputBox}>
                  <SimpleSelect
                    isClearable={false}
                    disabled={onboardingType === ONBOARDING_TYPES.AUTOMATIC}
                    className={commonStyles.select}
                    value={getValues(AWS_ONBOARDING_FIELDS.BUCKET_REGION)}
                    options={Object.values(objAwsRegionNames).map((a) => ({ label: a, value: a }))}
                    onChange={(val) => setValue(AWS_ONBOARDING_FIELDS.BUCKET_REGION, val, { shouldValidate: true })}
                    isInvalid={!!errors[AWS_ONBOARDING_FIELDS.BUCKET_REGION]}
                    invalidComponent={errors[AWS_ONBOARDING_FIELDS.BUCKET_REGION]}
                  />
                </div>
              </div>
            ),
            key: 'onboarding-bucket-region',
          },
        ]}
        help={{
          'onboarding-account-name': DetailsPageHelp.accountName,
          'onboarding-account-id': DetailsPageHelp.accountId,
          'onboarding-bucket-name':
            onboardingType === ONBOARDING_TYPES.AUTOMATIC ? undefined : DetailsPageHelp.bucketName,
          'onboarding-bucket-region':
            onboardingType === ONBOARDING_TYPES.AUTOMATIC ? undefined : DetailsPageHelp.bucketRegion,
        }}
      />
    </div>
  );
};

export default DetailsPage;
