import React, { useCallback, useMemo } from 'react';
import { OPERATORS_KEYS, PageNames } from 'shared/constants/appConstants';
import FilterContentList from 'shared/components/FilterSidebar/FilterContentList';
import { Option, ServiceCostAlert } from '../types.ts';
import { useInvoiceFilters } from '../../../../invoices/contexts/InvoiceFiltersContext';
import { useRootStore } from '../../../../app/contexts/RootStoreContext';

export interface FiltersServiceCostProps {
  alert: ServiceCostAlert;
  disabled?: boolean;

  setAlert?(field: keyof ServiceCostAlert | string, value: string | number | Option[]): void;
}

const FiltersServiceCost: React.FC<FiltersServiceCostProps> = ({ setAlert, disabled, alert }) => {
  const { usersStore } = useRootStore();
  const invoiceContext = useInvoiceFilters();
  const fieldToFieldDistinctValuesMap = invoiceContext.getPageFilters(
    PageNames.CUE_ALERTS,
    usersStore.currDispUserCloudAccountType,
  );
  const excludeMap = useMemo(
    () =>
      new Map(
        Object.entries(alert.filters).map(([key, value]) => [key, value.operator === OPERATORS_KEYS.IS_NOT ? 1 : 0]),
      ),
    [JSON.stringify(alert.filters)],
  );

  const selectedOptionsMap = useMemo(
    () =>
      new Map(
        Object.entries(alert.filters).map(([key, value]) => [
          key,
          Array.isArray(value.value) ? value.value?.filter((option) => option.value !== 'all') : value.value,
        ]),
      ),
    [alert.filters],
  );

  const handleOnExcludeClick = useCallback(
    (field: string, _, isExcluded: boolean) => {
      setAlert?.(`filters.${field}.operator`, isExcluded ? OPERATORS_KEYS.IS_NOT : OPERATORS_KEYS.IS);
    },
    [setAlert],
  );

  const handleSelectOptions = useCallback(
    (field: string, selected = [] as Option[]) => {
      let optionsToSave = [...selected];
      if (selected.length > 1) {
        optionsToSave = selected.filter((d) => d.value !== 'all');
      }
      if (selected.length === 0) {
        optionsToSave = [{ value: 'all', label: 'Any Type' }];
      }
      setAlert?.(`filters.${field}.value`, optionsToSave);
    },
    [setAlert, fieldToFieldDistinctValuesMap],
  );

  return (
    <FilterContentList
      fieldToFieldDistincValuesMap={fieldToFieldDistinctValuesMap}
      selectedOptionsMap={selectedOptionsMap}
      generalProps={{
        ...invoiceContext,
        isDisabled: disabled,
        handleFilterChange: handleSelectOptions,
        likeFiltersStatus: {},
        handleChangeFilterType: handleOnExcludeClick,
        excludedFiltersStatusMap: excludeMap,
        likeOperator: false,
        fieldsWidth: 440,
      }}
    />
  );
};

export default FiltersServiceCost;
