import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import toast from 'shared/components/andtComponents/Toast';
import { withRolesContextConsumer } from 'users/contexts/RolesProvider';
import { isRoleIdOfAdminType } from 'users/utils/rolesUtil';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import { mapRoleUserTypeToDisplay, RoleUsersType } from 'users/constants/usersConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { getLinkedAccountsInAccount } from 'users/containers/RolesAndUsers/components/AccountsChooser/utils';
import { ROLE_TYPES } from 'users/containers/RolesAndUsers/constants/rolesAndUsersConstants';
import RolesTable from 'users/containers/RolesAndUsers/components/RolesTable';
import Spinner from 'shared/components/andtComponents/Spinner';
import { ReactComponent as UserLinkedAccountIcon } from 'shared/img/icons/user-linked-account.svg';
import Button from 'shared/components/andtComponents/Button';
import AddOrEditUserModal from 'users/containers/RolesAndUsers/components/AddOrEditUserModal';
import TableHeader from 'shared/components/tableHeader/TableHeader';
import { useTableHeaderContext, withTableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import { withLinkedAccountsProvider } from 'users/contexts/LinkedAccountsProvider';
// eslint-disable-next-line max-len
import CreateOrUpdateRoleModal from 'users/containers/RolesAndUsers/components/CreateOrUpdateRoleModal/CreateOrUpdateRoleModal';
import styles from './RolesAndUsers.module.scss';
import { withLinkedAccountsTableContextProvider, withUsersTableContextProvider } from './contexts/rolesAndUsersContext';
import Filters from './components/Filters/Filters';
// eslint-disable-next-line max-len
import MultipleAssignLinkedAccountsModal from './components/MultipleAssignLinkedAccountsModal/MultipleAssignLinkedAccountsModal';

const RolesAndUsersPage = ({ roles, rolesLoading, cloudAccountsLinkedAccount }) => {
  const { usersStore } = useRootStore();
  const [createRoleModalOpen, setCreateRoleModalOpen] = useState(false);
  const [multiAssignModalOpen, setMultiAssignModalOpen] = useState(false);
  const [duplicatedRole, setDuplicatedRole] = useState(null);
  const { expandedRowIds, setExpandedRowIds } = useTableHeaderContext();
  const [filters, setFilters] = useState({
    roleName: [],
    userName: [],
    createdBy: [],
    accountName: [],
    linkedAccount: [],
  });
  const [editedRole, setEditedRole] = useState(null);
  const [editedLinkedAccountsRole, setEditedLinkedAccountsRole] = useState(null);
  const [addUserModal, setAddUserModal] = useState(null);
  const [roleTypeFilters, setRoleTypeFilters] = useState({
    [RoleUsersType.EDITOR]: 1,
    [RoleUsersType.VIEWER]: 1,
    [RoleUsersType.ADMIN]: 1,
  });
  const rolesFiltered = useMemo(
    () =>
      roles
        .filter((role) => {
          if (role.roleType === ROLE_TYPES.DEFAULT_ADMIN) {
            return !!roleTypeFilters[RoleUsersType.ADMIN];
          }
          return !!roleTypeFilters[+role.roleUserType];
        })
        .filter((role) => {
          if (filters.roleName.length > 0) {
            if (!filters.roleName.some((r) => r.value === role.roleName)) {
              return false;
            }
          }
          if (filters.createdBy.length > 0) {
            if (!filters.createdBy.some((c) => c.value === role.createdBy)) {
              return false;
            }
          }
          if (filters.linkedAccount.length > 0) {
            const linkedAccounts = role.accounts
              .map((acc) => getLinkedAccountsInAccount(acc, cloudAccountsLinkedAccount.get(acc?.accountId)))
              .flat();
            if (!linkedAccounts?.some((ln) => filters.linkedAccount.some((l) => l.value === ln.linkedAccountId))) {
              return false;
            }
          }
          if (filters.accountName.length > 0) {
            if (!role.accounts.some((acc) => filters.accountName.some((a) => a.value === acc.accountId))) {
              return false;
            }
          }
          if (filters.userName.length > 0) {
            const users = usersStore.usersModel.getSubUsers.filter((u) => u.userRole?.uuid === role.uuid);
            if (!users.some((user) => filters.userName.some((u) => u.value === user.userName))) {
              return false;
            }
          }
          return true;
        }),
    [roles, roleTypeFilters, filters],
  );
  useEffect(() => {
    setExpandedRowIds([]);
  }, [rolesFiltered.map((role) => role.uuid).join()]);

  const fetchCSVData = () => {
    const rolesCSVData = rolesFiltered
      .map((role) =>
        role.accounts
          .map((account) => {
            const acc = usersStore.getAllCurrDisplayedUserAccounts().find((acc) => acc.accountId === account.accountId);
            if (!acc) {
              return null;
            }
            const linkedAccounts = getLinkedAccountsInAccount(
              account,
              cloudAccountsLinkedAccount.get(account.accountId),
            );
            return linkedAccounts.map((ln) => ({
              linkedAccountId: ln.linkedAccountId,
              linkedAccountName: ln.linkedAccountName,
              accountName: acc.accountName,
              accountId: acc.accountId,
              roleId: role.uuid,
              roleName: role.roleName,
              rolePermissions: mapRoleUserTypeToDisplay.get(role.roleUserType),
            }));
          })
          .filter(Boolean),
      )
      .flat(4);
    const usersCSVData = usersStore.usersModel.getSubUsers.map((row) => {
      const { userRole, creationDate, awsAccountId, userName, isReadOnly } = row;
      return {
        roleName: userRole && userRole.roleName,
        roleId: userRole && userRole.uuid,
        rolePermissions: userRole && mapRoleUserTypeToDisplay.get(userRole.roleUserType),
        creationDate,
        awsAccountId,
        userName,
        isReadOnly,
      };
    });
    return [
      { data: rolesCSVData, filename: 'roles.csv' },
      { data: usersCSVData, filename: 'users.csv' },
    ];
  };

  const createNewUsersInRole = async (userEmails, role, overrideReadOnly) => {
    const isRoleAdmin = isRoleIdOfAdminType(roles, role.value);
    try {
      const result = await usersStore.createNewUsersInRole(userEmails, role.value, isRoleAdmin, overrideReadOnly);
      if (result.result) {
        await usersStore.fetchSubUsers();
        setEditedRole(null);
        toast.success(result?.message || 'An invitation mail was sent to the user(s)', {
          autoClose: !result?.message,
          onClose: () => {},
        });
        return true;
      }
      toast.error(
        'Failed to create user(s) - please check the input and connect with your user administrator or contact support',
        {
          onClose: () => {},
        },
      );
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Something went wrong, please try again later.', {
        onClose: () => {},
        autoClose: !error?.response?.data?.message,
      });
      return false;
    }
    return false;
  };

  const renderRoleTypeFilter = (roleType) => (
    <Checkbox
      isChecked={!!roleTypeFilters[roleType]}
      primary
      onChange={() => {
        setRoleTypeFilters({ ...roleTypeFilters, [roleType]: !roleTypeFilters[roleType] });
      }}
      text={mapRoleUserTypeToDisplay.get(roleType)}
    />
  );
  const expanded = expandedRowIds.length > 0;
  return (
    <Container>
      <TableHeader
        includeDetails
        tableName="Role"
        hideSearch
        titleComponent={
          <>
            <p className={styles.headerTitle}>Roles</p>
            <div className={styles.roleTypeFilters}>
              {renderRoleTypeFilter(RoleUsersType.ADMIN)}
              {renderRoleTypeFilter(RoleUsersType.EDITOR)}
              {renderRoleTypeFilter(RoleUsersType.VIEWER)}
            </div>
          </>
        }
        actionButton={
          <div className={styles.actionMenu}>
            <div className={styles.usersCounter}>
              <GenerateIcon iconName={ICONS.users.name} />
              Users: {usersStore.usersModel.getSubUsers.length}
            </div>
            <Button
              isSecondary
              disabled={usersStore.currentUserReadOnly}
              onClick={() => setMultiAssignModalOpen(true)}
              text="Multi Assignment"
              icon={() => <UserLinkedAccountIcon />}
            />
          </div>
        }
        fetchCsvData={fetchCSVData}
        expanded={expandedRowIds.length > 0}
        onCreate={() => setCreateRoleModalOpen(true)}
        isCreateLast
        isInline
        setExpanded={() => setExpandedRowIds(expanded ? [] : new Array(rolesFiltered.length).fill(0).map((_, i) => i))}
      />
      <Filters roles={roles} users={usersStore.usersModel.getSubUsers} filters={filters} setFilters={setFilters} />
      <div className="pt-4 pb-2" />
      {rolesLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <RolesTable
          roles={rolesFiltered}
          usersStore={usersStore}
          editRoleHandler={(role) => setEditedRole(role)}
          editedLinkedAccountsRoleHandler={(role) => setEditedLinkedAccountsRole(role)}
          duplicateRoleHandler={(role) => setDuplicatedRole(role)}
          addUserHandler={(role) => setAddUserModal(role)}
          expandedRowIds={expandedRowIds}
          setExpandedRowIds={setExpandedRowIds}
          filters={filters}
          renderCSVDownload={(role) => (
            <CustomCSVDownload
              fetchData={() => {
                const [rolesCSVData, usersCSVData] = fetchCSVData();
                return [
                  {
                    data: rolesCSVData.data.filter((r) => r.roleId === role.uuid),
                    filename: `${role.roleName}_linked_accounts.csv`,
                  },
                  {
                    data: usersCSVData.data.filter((user) => user.roleId === role.uuid),
                    filename: `${role.roleName}_users.csv`,
                  },
                ];
              }}
              showDownloadIcon
              isLoading={false}
              filesNumber={2}
              hideText
              style={{
                height: 36,
              }}
            >
              CSV
            </CustomCSVDownload>
          )}
        />
      )}
      {addUserModal ? (
        <AddOrEditUserModal
          open={!!addUserModal}
          onClose={() => {
            setAddUserModal(false);
          }}
          readOnly={addUserModal.roleUserType === RoleUsersType.VIEWER}
          role={addUserModal}
          onSave={async ({ userEmail, role, overrideReadOnly }) =>
            createNewUsersInRole(
              Array.isArray(userEmail) ? userEmail.map((e) => e.toLowerCase()) : [userEmail.toLowerCase()],
              role,
              overrideReadOnly,
            )
          }
          roles={roles}
        />
      ) : null}
      {createRoleModalOpen && (
        <CreateOrUpdateRoleModal
          linkedAccountsMode={false}
          onClose={() => setCreateRoleModalOpen(false)}
          usersStore={usersStore}
        />
      )}
      {multiAssignModalOpen && <MultipleAssignLinkedAccountsModal onClose={() => setMultiAssignModalOpen(false)} />}
      {!!editedRole && (
        <CreateOrUpdateRoleModal
          linkedAccountsMode={false}
          editedRole={editedRole}
          onClose={() => {
            setEditedRole(null);
          }}
          usersStore={usersStore}
        />
      )}
      {!!editedLinkedAccountsRole && (
        <CreateOrUpdateRoleModal
          linkedAccountsMode
          editedRole={editedLinkedAccountsRole}
          onClose={() => {
            setEditedLinkedAccountsRole(null);
          }}
          usersStore={usersStore}
        />
      )}
      {!!duplicatedRole && (
        <CreateOrUpdateRoleModal
          linkedAccountsMode={false}
          duplicatedRole={duplicatedRole}
          onClose={() => setDuplicatedRole(null)}
          usersStore={usersStore}
        />
      )}
    </Container>
  );
};

RolesAndUsersPage.propTypes = {
  roles: PropTypes.array.isRequired,
  rolesLoading: PropTypes.bool.isRequired,
  cloudAccountsLinkedAccount: PropTypes.object.isRequired,
};

export default withLinkedAccountsProvider(
  withTableHeaderProvider(
    withUsersTableContextProvider(withLinkedAccountsTableContextProvider(withRolesContextConsumer(RolesAndUsersPage))),
  ),
);
