import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { withRolesContextConsumer } from 'users/contexts/RolesProvider';
import toast from 'shared/components/andtComponents/Toast';
import { useRootStore } from 'app/contexts/RootStoreContext';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import TableWrapper from 'shared/components/tables/TableWrapper';
import Button from 'shared/components/andtComponents/Button';
import useTable from 'shared/hooks/customHooks/useTable';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import {
  useLinkedAccountsTableState,
  useUsersTableState,
} from 'divisions/containers/ManageCustomers/contexts/customersContext';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import { getLinkedAccountName } from 'shared/utils/cloudUtils';
import styles from './LinkedAccountsTable.module.scss';

const LinkedAccountsTable = ({ customer, handleUpdateLinkedAccounts }) => {
  const [deletedLinkedAccounts, setDeletedLinkedAccounts] = React.useState([]);
  const [deleteLinkdeAccountsLoading, setDeleteLinkdeAccountsLoading] = React.useState(false);
  const { NewTableSelectionBar } = useTable();
  const { usersStore } = useRootStore();
  const { anySelected: usersChecked } = useUsersTableState(customer.divisionId);
  const { selectedRows, setSelectedRows } = useLinkedAccountsTableState(customer.divisionId);

  const handleRemoveLinkedAccounts = async (linkedAccounts) => {
    setDeleteLinkdeAccountsLoading(true);
    try {
      await handleUpdateLinkedAccounts(
        customer,
        customer.linkedAccounts.filter((ln) => !linkedAccounts.includes(ln.linkedAccountId)),
      );
    } catch (e) {
      toast.error('Something went wrong, please try again later');
    }
    setDeleteLinkdeAccountsLoading(false);
  };
  const totalLinkedAccounts = usersStore.usersModel.userLinkedAccounts?.length;

  const { linkedAccounts } = customer;
  const selectedLinkedAccounts = linkedAccounts?.length;

  const actionsFormatter = (data) =>
    !handleUpdateLinkedAccounts ? null : (
      <Button
        text=""
        disabled={deleteLinkdeAccountsLoading}
        icon={() => <GenerateIcon iconName={ICONS.deleteRegular.name} />}
        onClick={() => {
          if (linkedAccounts.length < 2) {
            toast.error('Customer must have at least one linked account');
            return;
          }
          // eslint-disable-next-line react/destructuring-assignment
          const { linkedAccountId } = data.row;
          setDeletedLinkedAccounts([linkedAccountId]);
        }}
        isTextButton
      />
    );

  return (
    <div className={styles.container} data-disabled={!!usersChecked}>
      <NewTableSelectionBar selectedRows={selectedRows}>
        <div className={styles.selectedLns}>
          <Button
            isTextButton
            text="Delete"
            isLoading={deleteLinkdeAccountsLoading}
            icon={() => <GenerateIcon iconName={ICONS.delete.name} />}
            onClick={() => {
              if (selectedRows.length === customer.linkedAccounts.length) {
                toast.error('Customer must have at least one linked account');
                return;
              }
              setDeletedLinkedAccounts(selectedRows.map((ind) => linkedAccounts[ind].linkedAccountId));
            }}
          />
        </div>
      </NewTableSelectionBar>
      <div className={styles.title}>
        {getLinkedAccountName(usersStore.currDispUserCloudAccountType)}s{' '}
        <span>
          ({selectedLinkedAccounts}/{totalLinkedAccounts})
        </span>
        <InfoPopover isSimple>
          The account count is as follows:
          <br />
          count of associated {getLinkedAccountName(usersStore.currDispUserCloudAccountType).toLowerCase()}s / count of
          active {getLinkedAccountName(usersStore.currDispUserCloudAccountType).toLowerCase()}s
        </InfoPopover>
      </div>
      <div className={`sub-table ${styles.tableWrapper}`}>
        <Grid
          rows={linkedAccounts}
          columns={[
            {
              title: `${getLinkedAccountName(usersStore.currDispUserCloudAccountType)} Name & ID`,
              name: 'linkedAccountName',
              getCellValue: (row) => `${row.linkedAccountName} (${row.linkedAccountId})`,
            },
            {
              title: ' ',
              name: 'actions',
            },
          ]}
        >
          <FilteringState defaultFilters={[]} />
          <IntegratedFiltering />
          <SortingState defaultSorting={[{ columnName: 'linkedAccountName', direction: 'asc' }]} />
          <IntegratedSorting />
          <SelectionState selection={selectedRows} onSelectionChange={setSelectedRows} />
          <IntegratedSelection />
          <PagingState defaultCurrentPage={0} defaultPageSize={10} />
          <IntegratedPaging />
          <DataTypeProvider for={['actions']} formatterComponent={actionsFormatter} />
          <TableWrapper
            columnExtensions={[
              { columnName: 'linkedAccountName', width: '70%' },
              { columnName: 'actions', width: 'auto', align: 'right' },
            ]}
          />
          <TableHeaderRow showSortingControls />
          {handleUpdateLinkedAccounts && <TableSelection showSelectAll />}
          <PagingPanel pageSizes={[5, 10, 20]} />
        </Grid>
      </div>
      <DeleteWarningModal
        isOpen={deletedLinkedAccounts.length > 0}
        handleDelete={async (action) => {
          if (action === 'delete') {
            await handleRemoveLinkedAccounts(deletedLinkedAccounts);
          }
          setDeletedLinkedAccounts([]);
          setSelectedRows([]);
        }}
        modalTitle="Remove Linked Accounts"
        warningMessage={`Be advised, You are about to remove linked accounts from ${customer.divisionName}.`}
      />
    </div>
  );
};

LinkedAccountsTable.propTypes = {
  customer: PropTypes.object.isRequired,
  handleUpdateLinkedAccounts: PropTypes.func.isRequired,
};

export default withRolesContextConsumer(LinkedAccountsTable);
