import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';
import { palette } from 'shared/constants/colorsConstants';
import classNames from 'classnames';
import { titleByChannelType, iconByChannelType } from 'users/containers/Channels/channelsHelpers.js';
import styles from './RecipientsSelect.module.scss';

const selectStyles = ({ height }) => ({
  container: (defaultStyles) => ({ ...defaultStyles, height, borderRadius: 5 }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? palette.blue[400] : 'transparent',
    backgroundColor: state.isDisabled ? palette.gray[100] : palette.gray[200],
    borderWidth: '2px',
    borderRadius: '6px',
    boxShadow: 'unset',
    minHeight: '0',
    height,
  }),
  valueContainer: (baseStyle) => ({
    ...baseStyle,
    padding: '2px 4px',
    height,
  }),
  clearIndicator: (baseStyle) => ({
    ...baseStyle,
    padding: '0',
    margin: '6px',
    height,
  }),
  dropdownIndicator: (baseStyle) => ({
    ...baseStyle,
    padding: '0',
    margin: '6px',
  }),
  indicatorsContainer: (baseStyle) => ({
    ...baseStyle,
    height,
  }),
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    color: state.isDisabled ? palette.gray[400] : palette.gray[450],
  }),
  multiValue: (baseStyle) => ({
    ...baseStyle,
    backgroundColor: 'white',
    alignItems: 'center',
    border: `1px solid ${palette.gray[300]}`,
    borderRadius: '6px',
  }),
  multiValueLabel: (baseStyle) => ({
    ...baseStyle,
    color: palette.gray[450],
    backgroundColor: 'white',
    borderRadius: '6px',
    fontSize: '13px',
    fontWeight: 500,
    paddingLeft: '4px',
    padding: '0 4px',
  }),
  multiValueRemove: (baseStyle, state) => ({
    ...baseStyle,
    color: palette.gray[300],
    padding: 0,
    marginRight: '2px',
    borderRadius: '50%',
    display: state.isDisabled ? 'none' : 'flex',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
});

const RecipientsSelect = ({
  channels,
  isLoading,
  savedRecipients = [],
  onChangeHandler,
  isDisabled,
  menuPosition = 'absolute',
  className = '',
  height,
  selectStylesConfig,
  isMulti = true,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const processOptions = () => {
    if (!channels || channels.length === 0) {
      return;
    }

    setOptions(
      channels.map((channel) => ({
        label: `${titleByChannelType[channel.channelType] || channel.channelType}: ${channel.name}`,
        options: channel.recipients
          .filter(({ id }) => !savedRecipients.some((r) => id === r.id)) // Filter out already saved recipients
          .map((recipient) => ({
            ...recipient, // raw recipient data for the onChangeHandler
            value: recipient.id,
            label: (
              <>
                {iconByChannelType[channel.channelType]}{' '}
                {recipient.anodotName || recipient.recipientData.name || recipient.dstId}
              </>
            ),
          })),
      })),
    );
    if (!savedRecipients) {
      return;
    }

    setSelectedOptions(
      savedRecipients.reduce((acc, recipient) => {
        const { type, name, dstId } = recipient.recipientData;
        if (type === 'SENDGRID') {
          return acc;
        }
        const label = (
          <>
            {iconByChannelType[type]} {recipient.anodotName || name || dstId}
          </>
        );
        return [...acc, { ...recipient, value: recipient.id, label }];
      }, []),
    );
  };

  useEffect(() => {
    processOptions();
  }, [channels, savedRecipients]);

  return (
    <Select
      isLoading={isLoading}
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      options={options}
      value={selectedOptions}
      onChange={(selected) => {
        const getSelectedRawData = ({ value, lable, ...rawData }) => rawData;
        onChangeHandler(isMulti ? selected?.map(getSelectedRawData) || [] : getSelectedRawData(selected));
      }}
      filterOption={createFilter({
        stringify: ({
          data: {
            recipientData: { name, dstId },
          },
        }) => name || dstId || '',
      })}
      className={classNames(styles.select, className)}
      menuPosition={menuPosition}
      classNamePrefix="select"
      styles={selectStylesConfig || selectStyles({ height })}
      placeholder={`Select Channel${isMulti ? 's' : ''}`}
      isDisabled={isDisabled}
      components={{ IndicatorSeparator: () => null }}
      openMenuOnFocus
      inputId="recipients-select"
    />
  );
};
RecipientsSelect.propTypes = {
  savedRecipients: PropTypes.array.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  height: PropTypes.number,
  selectStylesConfig: PropTypes.object,
};
RecipientsSelect.defaultProps = {
  height: undefined,
  selectStylesConfig: undefined,
};
export default RecipientsSelect;
