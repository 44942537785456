import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { CLOUD_TYPE_IDS, mapCloudTypeIdToDescription } from 'users/constants/usersConstants';
import {
  awsServiceNameToIconMapping,
  azureServiceNameToIconMapping,
  gcpServiceNameToIconMapping,
} from '../servicesIcons/serviceNameToIconMapping';

import classes from './SelectIconsMultiMenu.module.scss';

export const ServicesImageTag = ({ label }) => {
  const iconImageRef = useRef(null);

  const { usersStore } = useRootStore();
  const cloudType = usersStore?.currDispUserCloudAccountType;
  const cloudTypeString = mapCloudTypeIdToDescription.get(cloudType).toLowerCase();

  const cloudMapper = useMemo(() => {
    if (cloudType === CLOUD_TYPE_IDS.AWS) {
      return awsServiceNameToIconMapping;
    }
    if (cloudType === CLOUD_TYPE_IDS.AZURE) {
      return azureServiceNameToIconMapping;
    }
    if (cloudType === CLOUD_TYPE_IDS.GCP) {
      return gcpServiceNameToIconMapping;
    }
    return null;
  }, [cloudType]);

  const defaultCloudIcon = `${cloudTypeString?.toUpperCase()}Generic`;

  useEffect(() => {
    const iconImage = iconImageRef.current;

    let iconSrc;
    try {
      /* eslint-disable-next-line global-require,import/no-dynamic-require */
      iconSrc = require(`../servicesIcons/${cloudTypeString}/${cloudMapper[label]}.svg`);
    } catch (error) {
      /* eslint-disable-next-line global-require,import/no-dynamic-require,max-len */
      iconSrc = require(`../servicesIcons/${cloudTypeString}/${defaultCloudIcon}.svg`);
    }
    iconImage.src = iconSrc;
  }, []);

  return <img ref={iconImageRef} alt="iconService" className={classes.serviceIcon} />;
};

ServicesImageTag.propTypes = {
  label: PropTypes.string,
};

ServicesImageTag.defaultProps = {
  label: '',
};
