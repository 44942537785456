import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { GenerateIcon, ICONS, Progress } from '@pileus-cloud/anodot-frontend-common/dist';
import { DataTypeProvider, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { Col } from 'reactstrap';
import moment from 'moment';
import { CLOUD_TYPE_IDS, mapCloudTypeIdToDescription, UsersType } from 'users/constants/usersConstants';
import AllowedUsersDisplayWrapper from 'shared/components/AllowedUsersDisplayWrapper';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import TableWrapper from 'shared/components/tables/TableWrapper';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import toast from 'shared/components/andtComponents/Toast';
import Button from 'shared/components/andtComponents/Button';
import { Routes } from 'shared/constants/routes';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import { cloudTypeToIcon } from 'shared/constants/appConstants';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { getRoleNamesContainingAccountId } from 'users/utils/rolesUtil';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import {
  getPageForPausedOnboarding,
  getPageTitleForPausedOnboarding,
} from 'app/containers/Onboarding/utils/OnboardingUtils';
import { withRolesContextConsumer } from 'users/contexts/RolesProvider';
import { AWS_ONBOARDING_STEPS } from 'app/containers/Onboarding/AwsOnboarding/AwsOnboardingConstants';
import EditAccountModal from './EditAccountModal';
import * as AccountTableConstants from '../constants/cloudAccountsConstants';
import styles from './AccountsTable.module.scss';

const AccountsTable = ({ data, usersStore, history, currentUserReadOnly, roles }) => {
  const [deleteAccountModalIsOpen, setDeleteAccountModalIsOpen] = React.useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(null);
  const { cancelOnboarding } = useOnboarding();
  const handeCancelAccountOnboarding = cancelOnboarding();
  const getFilterLabel = (field) => {
    const label = LabelCoordinator.getFieldLabel(field);
    return label;
  };
  const checkIfAccountConnectedToDivisions = (accountId) => {
    const result = usersStore.isAccountConnectedToDivisions(accountId);
    return result;
  };
  const selectAccountForDeleteHandler = (data) => {
    const account = data.row || {};
    if (checkIfAccountConnectedToDivisions(account.accountId)) {
      toast.error(`This Account has connected ${getFilterLabel('Cost Center')} and can't be deleted`);
      return;
    }
    const rolesWithAccount = getRoleNamesContainingAccountId(roles, account.accountId);
    if (rolesWithAccount.length) {
      const rolesText = `${rolesWithAccount.slice(0, 5).join(', ')}
        ${rolesWithAccount.length > 5 ? ` and ${rolesWithAccount.length} more` : ''}`;
      toast.error(`Before deleting account, remove account id from the following roles: ${rolesText}`);
      return;
    }
    setDeleteAccountModalIsOpen(account);
  };

  const updateDefaultAccountId = async (accountId) => {
    usersStore.usersModel.updateDefaultAccountId(accountId, usersStore.currentDisplayedUserKey);
  };
  const updateFilterOutMicrosoft365 = async (accountId, value) => {
    usersStore.updateFilterOutMicrosoft365AccountProperty(accountId, value);
  };

  const deleteAccountHandler = async (action) => {
    if (action === 'delete') {
      if (deleteAccountModalIsOpen.onboarding) {
        await handeCancelAccountOnboarding.mutateAsync({
          accountId: deleteAccountModalIsOpen.accountId,
        });
      } else {
        await usersStore.deleteAccount(deleteAccountModalIsOpen);
      }
    }
    setDeleteAccountModalIsOpen(null);
  };
  const editAccountHandler = async ({ name, roleARN }) => {
    const { accountId, accountKey } = isEditModalOpen;
    try {
      await usersStore.updateCurrentDisplayedUserAccount(accountId, accountKey, name, roleARN);
    } catch (error) {
      console.log(error);
    }
    setIsEditModalOpen(null);
  };
  // eslint-disable-next-line react/prop-types
  const TableRow = ({ row, children, ...restProps }) => {
    // eslint-disable-next-line react/prop-types
    const migrationRequired = usersStore.getIsAzureAccountMigrationRequired(row.accountId);
    const getRowColor = () => {
      // eslint-disable-next-line react/prop-types
      if (row.onboarding) {
        return 'FAFCFF';
      }
      if (migrationRequired) {
        return 'rgba(255, 119, 119, 0.06)';
      }
      return null;
    };
    return (
      <Table.Row
        {...restProps}
        style={{
          background: getRowColor(),
        }}
      >
        {children || null}
      </Table.Row>
    );
  };
  const detailsFormatter = (data) => {
    const { accountInfo, isMCAMigrationNeeded, accountId, onboarding } = data.row;
    if (onboarding) {
      return null;
    }
    const {
      pileus_role_arn: roleARN,
      s3_invoice_bucket: bucket,
      external_id: externalId,
      azure_interface_type: interfaceType,
    } = accountInfo || {};
    return (
      <div className={styles.detailsCell}>
        {bucket && (
          <p>
            <b>Bucket:</b> {bucket}
          </p>
        )}
        {externalId && (
          <p>
            <b>External ID:</b> {externalId}
          </p>
        )}
        {roleARN && (
          <p>
            <b>Role ARN:</b> {roleARN}
          </p>
        )}
        {interfaceType && (
          <p>
            <b>Interface Type:</b> {interfaceType}
          </p>
        )}
        {!bucket && !roleARN && !interfaceType && <p>-</p>}
        {isMCAMigrationNeeded && (
          <Col>
            <Button
              isErrorOutlined
              text="Migrate"
              onClick={() => {
                history.push(`${Routes.NEW_AZURE_USER}?migrationAccountId=${accountId}`);
              }}
            />
          </Col>
        )}
      </div>
    );
  };
  const accountNameFormatter = (data) => (
    <Tooltip title={data.row.accountName}>
      <div className={styles.accountNameCell}>{data.row.accountName}</div>
    </Tooltip>
  );
  const cloudTypeFormatter = (data) => {
    const CloudTypeIcon = cloudTypeToIcon[data.row.cloudTypeId];
    return CloudTypeIcon ? <CloudTypeIcon /> : null;
  };
  const lastProcessedFormatter = (data) => {
    const { lastProcessTime, onboarding, ...rest } = data.row;
    const getTitle = () => {
      const page = getPageForPausedOnboarding(rest.cloudTypeId, rest);
      const { validationStatus, accountSetupStatus, userManagementStatus } = rest;
      const isRunning =
        validationStatus?.status === 'RUNNING' ||
        accountSetupStatus?.status === 'RUNNING' ||
        userManagementStatus?.status === 'RUNNING';
      if (isRunning) {
        return 'Account setup & validation';
      }
      if (page === AWS_ONBOARDING_STEPS.PROCESS_DATA) {
        return 'Invoice processing';
      }
      return `Paused at ${getPageTitleForPausedOnboarding(rest.cloudTypeId, rest)}`;
    };
    if (onboarding) {
      return (
        <div className={styles.onboardingAccountProgress}>
          <Progress value={rest.invoiceProgress || 0} classes={{ root: styles.progress }} />
          <span>{getTitle()}</span>
          <Button
            onClick={() => {
              history.push(
                `/onboarding/${mapCloudTypeIdToDescription.get(rest.cloudTypeId)?.toLowerCase()}?accountId=${
                  data.row.accountId
                }`,
              );
            }}
            isTextButton
            overrideStyles={{ height: 20 }}
            text="Open"
          />
        </div>
      );
    }
    return lastProcessTime ? moment(lastProcessTime).format('MMMM DD YYYY') : '';
  };
  const isDefaultFormatter = (data) => {
    const { isDefault, accountId, onboarding } = data.row;
    if (onboarding) {
      return null;
    }
    return (
      <Checkbox
        isDisabled={currentUserReadOnly}
        primary={isDefault}
        onChange={() => updateDefaultAccountId(isDefault ? null : accountId)}
        text=""
        isChecked={isDefault}
      />
    );
  };
  const filterOutMicrosoft365Formatter = (data) => {
    const { filterOutMicrosoft365, accountId, onboarding, cloudTypeId } = data.row;
    if (onboarding || cloudTypeId !== CLOUD_TYPE_IDS.AZURE) {
      return null;
    }
    return (
      <Checkbox
        isDisabled={currentUserReadOnly}
        primary={filterOutMicrosoft365}
        onChange={() => updateFilterOutMicrosoft365(accountId, !filterOutMicrosoft365)}
        text=""
        isChecked={filterOutMicrosoft365}
      />
    );
  };
  const deleteFormatter = (data) => (
    <>
      <AllowedUsersDisplayWrapper
        isHide={false}
        viewingUserType={usersStore.currentDisplayedUserType}
        allowedUserTypes={[UsersType.USER, UsersType.RESELLER, UsersType.BETA_USER]}
      >
        <Button
          text=""
          disabled={currentUserReadOnly}
          isTextButton
          overrideStyles={{ paddingLeft: 0 }}
          onClick={() => selectAccountForDeleteHandler(data)}
          icon={() => <GenerateIcon iconName={ICONS.delete.name} />}
        />
      </AllowedUsersDisplayWrapper>
    </>
  );
  const editFormatter = (data) => {
    if (data.row.onboarding) {
      return null;
    }
    return (
      <AllowedUsersDisplayWrapper
        isHide={false}
        viewingUserType={usersStore.currentDisplayedUserType}
        allowedUserTypes={[UsersType.USER, UsersType.RESELLER, UsersType.BETA_USER]}
      >
        <Button
          text=""
          disabled={currentUserReadOnly}
          isTextButton
          overrideStyles={{ paddingLeft: 0 }}
          onClick={() => {
            setIsEditModalOpen(data.row);
          }}
          icon={() => <GenerateIcon iconName={ICONS.edit.name} />}
        />
      </AllowedUsersDisplayWrapper>
    );
  };
  const { defaultAccountId } = usersStore.usersModel.usersNotifications;
  const preparedRows = data.map((r) => ({
    ...r,
    isDefault: r.accountId === defaultAccountId,
    isMCAMigrationNeeded: usersStore.getIsAzureAccountMigrationRequired(r.accountId),
  }));
  return (
    <>
      <div className={styles.container}>
        <Grid
          rows={preparedRows}
          columns={AccountTableConstants.CLOUD_ACCOUNTS_TABLE_COLUMNS.filter(
            (c) => !c.cloudTypeIds || c.cloudTypeIds.some((ct) => preparedRows.some((r) => r.cloudTypeId === ct)),
          )}
        >
          <SortingState
            defaultSorting={AccountTableConstants.CLOUD_ACCOUNTS_DEFAULT_SORTING}
            columnExtensions={AccountTableConstants.CLOUD_ACCOUNTS_TABLE_COLUMN_WIDTHS}
          />
          <IntegratedSorting />
          <DataTypeProvider for={['accountName']} formatterComponent={accountNameFormatter} />
          <DataTypeProvider for={['cloudTypeId']} formatterComponent={cloudTypeFormatter} />
          <DataTypeProvider for={['details']} formatterComponent={detailsFormatter} />
          <DataTypeProvider for={['delete']} formatterComponent={deleteFormatter} />
          <DataTypeProvider for={['edit']} formatterComponent={editFormatter} />
          <DataTypeProvider for={['isFilterOutMicrosoft365']} formatterComponent={filterOutMicrosoft365Formatter} />
          <DataTypeProvider for={['isDefaultAcc']} formatterComponent={isDefaultFormatter} />
          <DataTypeProvider for={['lastProcessTime']} formatterComponent={lastProcessedFormatter} />
          <TableWrapper
            rowComponent={TableRow}
            columnExtensions={AccountTableConstants.CLOUD_ACCOUNTS_TABLE_COLUMN_WIDTHS}
          />
          <TableHeaderRow showSortingControls />
        </Grid>
      </div>
      <DeleteWarningModal
        modalTitle={deleteAccountModalIsOpen?.onboarding ? 'Delete Onboarding' : 'Delete Account'}
        handleDelete={deleteAccountHandler}
        isOpen={!!deleteAccountModalIsOpen}
        deletedItemName={deleteAccountModalIsOpen?.accountName}
      />
      {isEditModalOpen && (
        <EditAccountModal
          accountData={isEditModalOpen}
          onClose={() => setIsEditModalOpen(null)}
          isOpen
          editAccountHandler={editAccountHandler}
        />
      )}
    </>
  );
};

AccountsTable.propTypes = {
  data: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
  currentUserReadOnly: PropTypes.bool.isRequired,
};

const ObserverAccountsTable = withRouter(withRolesContextConsumer(observer(AccountsTable)));
export default ObserverAccountsTable;
