import React from 'react';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import styles from './toggleSwitchButton.module.scss';

const DailyHourlySwitchButton = ({ isDailyMode, setIsDailyMode, automationId }) => (
  <div className={styles.switchButtonWrapper}>
    <div
      className={`${styles.switchButton} ${!isDailyMode && styles.selectedOption}`}
      onClick={() => setIsDailyMode(!isDailyMode)}
      automation-id={`${automationId}Hourly`}
    >
      <GenerateIcon iconName={ICONS.clock.name} />
      <span>Hourly</span>
    </div>
    <div
      className={`${styles.switchButton} ${isDailyMode && styles.selectedOption}`}
      onClick={() => setIsDailyMode(!isDailyMode)}
      automation-id={`${automationId}Daily`}
    >
      <GenerateIcon iconName={ICONS.calendarDay.name} />
      <span>Daily</span>
    </div>
  </div>
);

export default DailyHourlySwitchButton;
