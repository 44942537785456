import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { Progress } from '@pileus-cloud/anodot-frontend-common/dist';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { palette } from 'shared/constants/colorsConstants';

import classes from './heatMapCardGroupedByItem.module.scss';

const COLORS_MAPPING = {
  0: palette.blue['500'],
  1: palette.blue['400'],
  2: palette.blue['300'],
  3: palette.blue['200'],
};

const HeatMapCardGroupedByItem = ({ item, itemIndex, onGroupedByItemClick, progressPart }) => {
  const { currencySymbol } = useUserSettingsContext();

  const cost = item?.savings || 0;
  const count = item?.recsCount || 0;
  const name = item?.label;

  return (
    <div className={classes.heatMapCardGroupedByItem} onClick={() => onGroupedByItemClick(item)}>
      <div className={classes.info}>
        <div className={classes.numbers}>
          <div>{currencySymbol}</div>
          <div>{cost?.toLocaleString()}</div>
          <div className={classes.count}>({count?.toLocaleString()})</div>
        </div>
        <Tooltip placement="top" title={name} arrow>
          <div className={classes.name}>{name}</div>
        </Tooltip>
      </div>
      <Progress
        value={progressPart}
        height={5}
        color={itemIndex <= 2 ? COLORS_MAPPING[itemIndex] : COLORS_MAPPING[3]}
      />
    </div>
  );
};

HeatMapCardGroupedByItem.propTypes = {
  item: PropTypes.object.isRequired,
  itemIndex: PropTypes.number,
  onGroupedByItemClick: PropTypes.func.isRequired,
  progressPart: PropTypes.number.isRequired,
};
HeatMapCardGroupedByItem.defaultProps = {
  itemIndex: 0,
};

export default HeatMapCardGroupedByItem;
