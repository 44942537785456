export const graphColors = [
  '#4fb5f5',
  '#f3a391',
  '#efdb4a',
  '#449fa9',
  '#bbdce2',
  '#924e7a',
  '#d7e643',
  '#6c5c84',
  '#86b582',
  '#bc426d',
  '#99d8d0',
  '#7189bf',
  '#146c71',
  '#e3b8b4',
  '#89b3cb',
  '#f6c523',
  '#1f5189',
  '#f77a55',
  '#733c5a',
  '#b8badf',
  '#837da9',
  '#eabfc9',
  '#758e3e',
  '#478ba1',
  '#d27575',
  '#295ba7',
  '#683774',
  '#ffc878',
  '#64d2df',
  '#9c829e',
  '#6c8a93',
  '#d7569f',
  '#194268',
  '#bdb4a2',
  '#546db9',
  '#d3cde1',
  '#f99f38',
  '#8d6976',
  '#759610',
  '#7988a0',
  '#e094b9',
  '#986bb0',
  '#8ed8da',
  '#07628a',
  '#80005d',
  '#5b7863',
  '#484c7f',
  '#12c9dd',
  '#ffba92',
  '#a4d7e1',
  '#891fa1',
  '#Cdffeb',
  '#90aeff',
  '#ff6b64',
  '#fcfa70',
  '#ffb1c7',
  '#38817a',
  '#ccb6c6',
  '#a94444',
  '#efc7b0',
  '#00bda4',
  '#b3c78f',
  '#a0709e',
  '#c9ac68',
  '#467082',
  '#4899c3',
  '#e0504c',
  '#fdc0a4',
  '#a02d59',
  '#975bea',
  '#5c7782',
  '#ffc5c5',
  '#ffe28e',
  '#afe57b',
  '#a077d9',
  '#fff0e6',
  '#90cc70',
  '#BDECFF',
  '#f2e085',
  '#d99a8d',
  '#8aa8e6',
  '#91f2ea',
  '#abf285',
  '#c877d9',
  '#74a3b3',
  '#d7f285',
  '#b08ae6',
  '#85f2bc',
  '#f2b385',
  '#b5cc70',
  '#8ac8e6',
  '#62b369',
  '#e6d895',
  '#14C1CA',
  '#c5c5ff',
  '#18A5F8',
  '#6ABE7C',
  '#a2798f',
  '#C3EA52',
  '#4281F3',
  '#FFC73C',
  '#99BCF5',
  '#E56FFE',
  '#6bb39b',
  '#BDD393',
  '#9E008E',
  '#00B917',
  '#FF74A3',
  '#01D0FF',
  '#004754',
  '#2FCF7D',
  '#FFE502',
  '#620E00',
  '#0E4CA1',
  '#91D0CB',
  '#968AE8',
  '#BB8800',
  '#008F9C',
  '#98FF52',
  '#B500FF',
  '#FF6E41',
  '#005F39',
  '#7544B1',
  '#5FAD4E',
  '#A75740',
  '#6B6882',
  '#A5FFD2',
  '#EA0072',
  '#FFB167',
  '#E85EBE',
  '#009BFF',
  '#C4A8CB',
  '#55C899',
  '#E78FCF',
  '#004547',
  '#F6E2E3',
  '#966716',
  '#378FDB',
  '#435E6A',
  '#5B9C8F',
  '#6E2B52',
  '#E3E8C4',
  '#AE3B85',
  '#FF9E6B',
  '#457D8B',
  '#96C57F',
  '#92678B',
  '#00CDBB',
  '#EA1CA9',
  '#9CCC04',
  '#766E52',
  '#E3D19F',
  '#B2EACE',
  '#F3BDA4',
  '#A24E3D',
  '#976FD9',
  '#CFF6B4',
  '#8C9FA8',
  '#7C2B73',
  '#4E5F37',
  '#5D5462',
  '#90956F',
  '#6AA776',
  '#DA71FF',
  '#987C95',
  '#4FC15F',
  '#52323C',
  '#BB3C42',
  '#584D39',
  '#A2B9C1',
  '#79DB21',
  '#1D5958',
  '#BD744E',
  '#6B8295',
  '#00E0E4',
  '#1B782A',
  '#DAA9B5',
  '#B0415D',
  '#97A094',
  '#06E3C4',
  '#47688C',
  '#7C6755',
  '#075C00',
  '#7560D5',
  '#7D9F00',
  '#C36D96',
  '#4D913E',
  '#5F4276',
  '#FCE4C8',
  '#303052',
  '#E5A532',
  '#706690',
  '#AA9A92',
  '#237363',
  '#73013E',
  '#FF9079',
  '#A79A74',
  '#029BDB',
  '#FF0169',
  '#C7D2E7',
  '#6A9573',
  '#CA8869',
  '#BB1F69',
  '#5D3033',
  '#7D74A9',
  '#90B0AB',
  '#FCC7DB',
  '#99375B',
  '#00AB4D',
  '#ABAED1',
  '#BE9D91',
  '#DD587B',
  '#E6E5A7',
  '#A8ABB4',
  '#C535A9',
  '#6A8781',
  '#D45262',
  '#794B61',
  '#4621B2',
  '#8DA4DB',
  '#4CA43B',
  '#A243A7',
  '#B2B081',
  '#286154',
  '#A8441D',
  '#5C727B',
  '#D0CFCB',
  '#897B77',
  '#1F3F22',
  '#4145A7',
  '#DA9894',
  '#738671',
  '#A1757A',
  '#63243C',
  '#ADAAFF',
  '#DDBC62',
  '#698EB1',
  '#208462',
  '#00B7E0',
  '#614A44',
  '#9BBB57',
  '#766B7E',
  '#FF8347',
  '#7A8EBA',
  '#274740',
  '#946444',
  '#EBD8E6',
  '#646241',
  '#6AD450',
  '#81817B',
  '#D499E3',
  '#526554',
  '#B5885C',
  '#A499A5',
  '#03AD89',
  '#B3008B',
  '#E3C4B5',
  '#96531F',
  '#867175',
  '#74569E',
  '#E70452',
  '#067EAF',
  '#A697B6',
  '#617D9F',
  '#3A9459',
  '#6E746E',
  '#B0C5AE',
  '#84EDF7',
  '#ED3488',
  '#754C78',
  '#384644',
  '#C7847B',
  '#00B6C5',
  '#7FA670',
  '#C1AF9E',
  '#2A7FFF',
  '#72A58C',
  '#FFC07F',
  '#9DEBDD',
  '#D97C8E',
  '#7E7C93',
  '#62E674',
  '#B5639E',
  '#FFA861',
  '#8D9C83',
  '#B70546',
  '#0098FF',
  '#985975',
  '#20204C',
  '#FF6C60',
  '#445083',
  '#8502AA',
  '#72361F',
  '#9676A3',
  '#CDE6DC',
  '#484449',
  '#3B164A',
  '#CCA763',
  '#2C7F77',
  '#02227B',
  '#A37E6F',
  '#CDFFFB',
  '#BE811A',
  '#CDC6B4',
  '#F77183',
  '#EDE6E2',
  '#FFE09E',
  '#3A7271',
  '#FF7B59',
  '#4E4E01',
  '#4AC684',
  '#BC8A96',
  '#CF6353',
  '#DCDE5C',
  '#5EAADD',
  '#F6A0AD',
  '#436E83',
  '#E269AA',
  '#A3DAE4',
  '#ECFBFF',
  '#A1C2B6',
  '#50003F',
  '#71695B',
  '#67C4BB',
  '#536EFF',
  '#5D5A48',
  '#890039',
  '#8D6F81',
  '#5E4665',
  '#AA62C3',
  '#2C6135',
  '#E3A68B',
  '#564620',
  '#E69034',
  '#6DA6BD',
  '#48B176',
  '#D27D67',
  '#FF84E6',
  '#4B6BA5',
  '#EAE408',
  '#ADCEFF',
  '#435740',
  '#F4F5FF',
  '#325800',
  '#9B8ACC',
  '#885138',
  '#5875C1',
  '#7E7311',
  '#FEA5CA',
  '#A55B54',
  '#89006A',
  '#AF756F',
  '#576E4A',
  '#7F9EFF',
  '#7499A1',
  '#FFB550',
  '#D1511C',
  '#688151',
  '#BC908A',
  '#78C8EB',
  '#8502FF',
  '#483D30',
  '#5EA7FF',
  '#B3AF9D',
  '#3E3D52',
  '#9C2F90',
  '#00768B',
  '#8D5700',
  '#5A9BC2',
  '#ADD79C',
  '#337D00',
  '#3156DC',
  '#944575',
  '#ECFFDC',
  '#D24CB2',
  '#88FFEC',
  '#56735F',
  '#9BB195',
  '#A9795C',
  '#E4C5D3',
  '#9F4F67',
  '#396D2B',
  '#664327',
  '#AFCE78',
  '#322EDF',
  '#86B487',
  '#96656D',
  '#CAEFFF',
  '#323F61',
  '#0080CF',
  '#6A9D3B',
  '#736060',
  '#FFBAAD',
  '#D369B4',
  '#FFDED6',
  '#6C6D74',
  '#927D5E',
  '#845D70',
  '#5B62C1',
  '#2F4A36',
  '#E45F35',
  '#AC84DD',
  '#762988',
  '#408543',
  '#9B9EE2',
  '#58AFAD',
  '#5C424D',
  '#7AC5A6',
  '#685D75',
  '#B9BCBD',
  '#834357',
  '#6A004D',
  '#2E57AA',
  '#E55199',
  '#316E47',
  '#CD00C5',
  '#7FBBEC',
  '#F35691',
  '#D7C54A',
  '#62ACB7',
  '#CBA1BC',
  '#A28A9A',
  '#6C3F3B',
  '#FFE47D',
  '#DCBAE3',
  '#5F816D',
  '#3A404A',
  '#7DBF32',
  '#E6ECDC',
  '#852C19',
  '#285366',
  '#B8CB9C',
  '#4B5D56',
  '#E27172',
  '#0568EC',
  '#2EB500',
  '#D21656',
  '#EFAFFF',
  '#682021',
  '#DA4CFF',
  '#E8C282',
  '#70968E',
  '#FF7B7D',
  '#E7DBBC',
  '#36574E',
  '#ADAAA9',
  '#8A9F45',
  '#6542D2',
  '#5D697B',
  '#CCD27F',
  '#94A5A1',
  '#790229',
  '#E383E6',
  '#7EA4C1',
  '#4E4452',
  '#620B70',
  '#314C1E',
  '#874AA6',
  '#EB9A8B',
];

export const colors = [
  '#70bbfd',
  '#f6da6e',
  '#8961C5',
  '#4ce1b6',
  '#3E6AB4',
  '#EB9C45',
  '#1698AF',
  '#3E6AB4',
  '#3E6AB4',
  '#2d4872',
  '#62c4a3',
  '#b21025',
  '#FF4C1F',
  '#6E4B3F',
  '#949394',
  '#557280',
  '#FF8D05',
  '#FFB90C',
  '#FFE834',
  '#FFE834',
  '#C6D732',
  '#80BB41',
  '#1D8BF1',
  '#009FF2',
  '#00B4CE',
  '#008B7D',
  '#43A547',
  '#3748AC',
  '#5C33AE',
  '#9123A7',
  '#E61B58',
  '#F23A30',
  '#1A3953',
  '#BDECFF',
  '#FEF79C',
  '#14C1CA',
  '#393D3E',
  '#18A5F8',
  '#E7EEF3',
  '#2FCF7D',
  '#C3EA52',
  '#6ABE7C',
  '#4281F3',
  '#FFC73C',
  '#99BCF5',
  '#FD552A',
];

export const palette = {
  blue: {
    600: '#1f2c9c',
    500: '#2671ff',
    400: '#a1c0fd',
    450: '#518dff',
    300: '#c9dbff',
    250: '#ebf2fc',
    200: '#E9F0FF',
    100: '#e9f0ff',
  },
  black: {
    500: '#1f292e',
  },
  white: {
    500: '#ffffff',
  },
  offWhite: {
    100: '#ececf8',
    200: '#f9f9fd',
  },
  tomato: {
    500: '#FF5653',
    400: '#FF7777',
  },
  red: {
    100: '#ED9FAF',
    200: '#FACBD5',
    300: '#ED9FAF',
    400: '#DE5773',
    500: '#D10F37',
    600: '#aa0000',
  },
  rose: {
    400: '#F06292',
    500: '#E91E63',
  },
  orange: {
    500: '#eb6f07',
    400: '#FF9F30',
    350: '#fcd176',
    300: '#FFD0A5',
    200: '#FFEEDE',
    100: '#FFEAD8',
  },
  mango: {
    500: '#FF8F24',
  },
  eucaliptus: {
    500: '#00934E',
    400: '#00875A',
  },
  lime: {
    500: '#7cb342',
  },
  yellow: {
    100: '#F5FFDD',
    200: '#FFE8B2',
    300: '#FFE400',
    400: '#FFC700',
    500: '#F4B92D',
  },
  green: {
    50: '#dff7e4',
    100: '#c7e8ce',
    400: '#64E17B',
    450: '#94D85F',
    500: '#41ca5a',
    600: '#0fc330',
    650: '#009263',
    700: '#0eab2b',
  },
  gray: {
    600: '#27363E',
    500: '#3D4C59',
    450: '#616f7e',
    400: '#8995A0',
    300: '#D4D9E0',
    350: '#B5BBC3',
    200: '#E9E9F0',
    150: '#F0F0F4',
    100: '#F4F7FB',
  },
  purple: {
    600: '#6826AB',
    500: '#8947CD',
    200: '#E1D1F2',
  },
  teal: {
    600: '#017DA5',
    550: '#0e98c4',
    500: '#00BBD4',
    400: '#80DEEA',
  },
  lilach: {
    600: '#8C0DAF',
    500: '#C83CEE',
    400: '#D083E8',
  },
};
