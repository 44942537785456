import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'reactstrap';
import IconInputField from 'shared/components/IconInputField';
import { CurrenciesSelect } from 'users/containers/Onboarding/shared/components/UserCurrency/CurrencySelect';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';

const nameValidation = {
  marginTop: '5px',
  color: 'red',
  fontSize: '10px',
};

class AzureAppRegistration extends PureComponent {
  static propTypes = {
    eaToMCAMigration: PropTypes.bool.isRequired,
    accountStorage: PropTypes.string.isRequired,
    containerName: PropTypes.string.isRequired,
    accountName: PropTypes.string.isRequired,
    isMultipleSources: PropTypes.bool.isRequired,
    isMultipleFiles: PropTypes.bool.isRequired,
    directoryPrefix: PropTypes.string.isRequired,
    handleChangeStateValuesByName: PropTypes.func.isRequired,
    currencySymbol: PropTypes.object,
    handleSelectCurrency: PropTypes.func.isRequired,
    usersStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      nameIsValid: true,
    };
  }

  getValidInput = (inputValue, eventTarget) => {
    const { type, checked, name } = eventTarget;
    if (type === 'checkbox') {
      return checked;
    }
    if (name === 'accountName') {
      return inputValue;
    }
    return inputValue.replace(/["\s]+/g, '');
  };

  handleInputsChange = (newInput, e) => {
    if (!e) {
      return;
    }
    const { handleChangeStateValuesByName, accountName } = this.props;
    const { name, value } = e.target;
    if (name === 'accountName' && value.length > 15) {
      this.setState({ nameIsValid: false });
      return;
    }
    if (accountName.length >= 2 && accountName.length <= 15) {
      this.setState({ nameIsValid: true });
    }
    const validInput = this.getValidInput(newInput, e.target);
    handleChangeStateValuesByName(name, validInput);
  };
  disableEnterKeySub = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  render() {
    const {
      containerName,
      accountStorage,
      eaToMCAMigration,
      isMultipleSources,
      isMultipleFiles,
      directoryPrefix,
      accountName,
      currencySymbol,
      handleSelectCurrency,
      usersStore,
      handleChangeStateValuesByName,
    } = this.props;

    const { nameIsValid } = this.state;
    return (
      <Row>
        <Col xs={12} md={12} lg={12} xl={12}>
          <p>
            You can find more information in the link below <br />
            <a
              // id="EaConnectGuide"
              rel="noopener noreferrer"
              target="_blank"
              href="https://cloudcost.zendesk.com/hc/en-us/articles/6041382945692"
            >
              Grant access to Azure application
            </a>
          </p>
          <br />
        </Col>
        <Col xs={12} md={12} lg={8} xl={8}>
          <Form onKeyPress={this.disableEnterKeySub} className="form">
            {!eaToMCAMigration && (
              <>
                <div className="form__form-group">
                  <IconInputField
                    icon="lnr lnr-database"
                    handler={this.handleInputsChange}
                    label="Display Account Name"
                    name="accountName"
                    value={accountName}
                    type="text"
                    placeHolder="Enter a display name for your account"
                  />
                </div>
                {!nameIsValid && (
                  <div style={nameValidation}>Please use alphanumeric values only and length between 2-15</div>
                )}
              </>
            )}
            <div className="form__form-group">
              <IconInputField
                icon="lnr lnr-database"
                handler={this.handleInputsChange}
                label="Storage Account"
                name="accountStorage"
                value={accountStorage}
                type="text"
                placeHolder="Enter your Storage Account"
              />
            </div>
            <div className="form__form-group">
              <IconInputField
                icon="lnr lnr-cloud"
                handler={this.handleInputsChange}
                label="Container Name"
                name="containerName"
                value={containerName}
                type="text"
                placeHolder="Enter your Container Name"
              />
            </div>
            <CurrenciesSelect
              usersStore={usersStore}
              currencySymbol={currencySymbol}
              handleSelectCurrency={handleSelectCurrency}
            />
            <div className="form__form-group mt-3">
              <Checkbox
                isChecked={isMultipleSources}
                primary
                text={
                  <span>
                    Shared Container{' '}
                    <InfoPopover>
                      Applicable when multiple exports have been configured on the same container (either using
                      different profiles, scopes, or export configurations). You can’t use file partitioning if you
                      select this option.
                    </InfoPopover>
                  </span>
                }
                onChange={(value) => {
                  handleChangeStateValuesByName('isMultipleSources', value);
                  handleChangeStateValuesByName('isMultipleFiles', false);
                }}
              />
            </div>
            <div className="form__form-group">
              <Checkbox
                isChecked={isMultipleFiles}
                primary
                text="File partitioning"
                onChange={(value) => {
                  handleChangeStateValuesByName('isMultipleFiles', value);
                  handleChangeStateValuesByName('isMultipleSources', false);
                }}
              />
            </div>
            <div className="form__form-group">
              <IconInputField
                handler={this.handleInputsChange}
                label="Directory field (not mandatory)"
                name="directoryPrefix"
                value={directoryPrefix}
                type="text"
                placeHolder="Directory field"
              />
            </div>
          </Form>
        </Col>
      </Row>
    );
  }
}

AzureAppRegistration.defaultProps = {
  currencySymbol: null,
};

export default AzureAppRegistration;
