import React from 'react';
import { Form, Input } from 'reactstrap';
import RecipientsSelect from 'shared/components/RecipientsSelect.jsx';
import useChannels from 'shared/components/slackIntegration/hooks/useChannels.js';
import classNames from 'classnames';
import { getAnodotReactSelectStyles } from 'shared/components/AnodotReactSelect';
import t from '../texts.ts';
import styles from '../alerts.module.scss';

export interface FormCommitmentExpirationProps {
  className?: string;
  daysBeforeExpiry: number;
  recipientsEmails: string;
  recipients: object[];
  isSavingPlan?: boolean;
}

export const FormCommitmentExpiration: React.FC<FormCommitmentExpirationProps> = ({
  daysBeforeExpiry,
  recipientsEmails,
  recipients,
  className,
  isSavingPlan = false,
}) => {
  const { getChannels } = useChannels();
  const { data: channels, isLoading: isLoadingChannels } = getChannels();

  return (
    <Form className={className}>
      <div>
        <h5>{t(isSavingPlan ? 'DAYS_BEFORE_SAVING_PLAN_EXPIRES' : 'DAYS_BEFORE_RESERVATION_EXPIRES')}</h5>
        <Input
          automation-id="days-before-field"
          value={daysBeforeExpiry}
          margin="normal"
          variant="outlined"
          placeholder="Name"
          className="name-input"
          disabled
        />
      </div>
      <div>
        <h5>{t('RECIPIENTS')}</h5>
        <div>
          <Input
            automation-id="recipient-field"
            value={recipientsEmails}
            margin="normal"
            variant="outlined"
            placeholder="Name"
            className="name-input"
            disabled
          />
        </div>
      </div>
      <div>
        <RecipientsSelect
          className={classNames(styles.recipientsSelect, styles.select)}
          channels={channels}
          isLoading={isLoadingChannels}
          savedRecipients={recipients}
          onChangeHandler={() => {}}
          selectStylesConfig={getAnodotReactSelectStyles({ disabled: true, isEmpty: !recipients, height: 35 })}
          isDisabled
        />
      </div>
    </Form>
  );
};

export default FormCommitmentExpiration;
