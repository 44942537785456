import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TableHeaderRow, PagingPanel, TableColumnResizing } from '@devexpress/dx-react-grid-material-ui';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common/dist';
import {
  DataTypeProvider,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
  SummaryState,
  IntegratedSummary,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { percentStr } from 'shared/utils/strUtil';
import { withStyles } from '@mui/styles';
import { getBudgetColor } from 'usage/containers/Budget/budgetUtil';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import Button from 'shared/components/andtComponents/Button';
import moment from 'moment';
import classes from './budgets.module.scss';

const detailsRowColumns = (isPeriod) => {
  const { currencySymbol, getCurrencyNumber } = useUserSettingsContext();
  return [
    {
      name: 'date',
      title: 'Date',
    },
    {
      name: 'cost',
      title: isPeriod ? 'Accumulated Spend / Accumulated Forecast' : 'Accumulated Spend / Forecast',
      getCellValue: (row) => {
        if (!row.accValue) {
          return getCurrencyNumber(row.cost || row.forecast);
        }
        return getCurrencyNumber(row.accValue);
      },
    },
    { name: 'budget', title: 'Budgeted', getCellValue: (row) => getCurrencyNumber(+row.budget) },
    {
      name: 'remaining',
      title: `Remaining Budget (${currencySymbol})`,
      getCellValue: (row) => {
        if (!row.cost && !row.forecast) {
          return '';
        }
        if (!row.accValue || (row.cost && row.forecast)) {
          return getCurrencyNumber(row.budget - (row.cost || row.forecast));
        }
        return getCurrencyNumber(row.budget - row.accValue);
      },
    },
    {
      name: 'budgetUtil',
      title: 'Budget Utilization (%)',
      getCellValue: (row) => {
        if (!row.cost && !row.forecast) {
          return '';
        }
        if (!row.accValue || (row.cost && row.forecast)) {
          return percentStr(((row.cost || row.forecast) * 100) / row.budget);
        }
        return percentStr((row.accValue * 100) / row.budget);
      },
    },
    {
      name: 'actualDailyCost',
      title: 'Actual Daily Spend',
      getCellValue: (row) => (row.actualDailyCost ? getCurrencyNumber(Number(row.actualDailyCost)) : 'N/A'),
    },
    {
      name: 'previousDayDelta',
      title: 'Previous Day Delta',
      getCellValue: (row) =>
        row.previousDayDelta
          ? `${getCurrencyNumber(Number(row.previousDayDelta))} (${percentStr(row.previousDayDeltaPercent)})`
          : 'N/A',
    },
  ];
};

const budgetColumnsWidths = [
  { columnName: 'date', width: '200' },
  { columnName: 'cost', width: '420' },
  { columnName: 'budget', width: '195' },
  { columnName: 'remaining', width: '225' },
  { columnName: 'budgetUtil', width: '235' },
  { columnName: 'actualDailyCost', width: '170' },
  { columnName: 'previousDayDelta', width: '175' },
];

const budgetUtilFormatCell = (data) => {
  withStyles(data);
  return (
    <div className={classes.tdWrapper}>
      {(!data.row?.cost && data.row?.forecast) || data.row?.isForecast ? (
        <span className={classes.forecastColor}>{data.value}</span>
      ) : (
        <span style={{ color: getBudgetColor((data.row?.cost * 100) / data.row?.budget) }}>{data.value}</span>
      )}
      {data.row?.accValue && data.row?.cost && data.row?.forecast && (
        <span className={classes.forecastColor}>
          {' '}
          | {percentStr(((data.row?.accValue || data.row?.cost + data.row?.forecast) * 100) / data.row?.budget)}
        </span>
      )}
    </div>
  );
};
const remainingFormatCell = (data) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  withStyles(data);
  return (
    <div className={classes.tdWrapper}>
      {(!data.row?.cost && data.row?.forecast) || data.row?.isForecast ? (
        <span className={classes.forecastColor}>{data.value}</span>
      ) : (
        <span style={{ color: getBudgetColor((data.row?.cost * 100) / data.row?.budget) }}>{data.value}</span>
      )}
      {data.row?.accValue && data.row?.cost && data.row?.forecast && (
        <span className={classes.forecastColor}>
          {' '}
          | {getCurrencyNumber(data.row?.budget - (data.row?.accValue || data.row?.cost + data.row?.forecast))}
        </span>
      )}
    </div>
  );
};

const costFormatCell = (data) => {
  const { getCurrencyNumber } = useUserSettingsContext();

  withStyles(data);
  if (data.row?.accValue && data.row?.cost && data.row?.forecast) {
    return (
      <div className={classes.tdWrapper}>
        <span
          style={{
            color: getBudgetColor(
              (((data.row?.accValue || data.row?.cost) - data.row?.forecast) * 100) / data.row?.budget,
            ),
          }}
        >
          {getCurrencyNumber(data.row?.accValue - data.row?.forecast)} |{' '}
          {getCurrencyNumber(data.row?.accValue - data.row?.forecast)} +{' '}
          <span className={classes.forecastColor}>
            {getCurrencyNumber(data.row?.forecast)} = {data.value}
          </span>
        </span>
      </div>
    );
  }
  return (
    <div className={classes.tdWrapper}>
      <span className={(!data.row?.cost && data.row?.forecast) || data.row?.isForecast ? classes.forecastColor : ''}>
        {data.value}
      </span>
    </div>
  );
};

const dateFormatCell = (data) => {
  withStyles(data);
  return data.row?.isDailyMode
    ? moment(new Date(data.row?.date)).format('DD MMM YYYY')
    : moment(new Date(data.row?.date)).format('MMM YYYY');
};
const summaryCalculator = (type, rows, getValue, getCurrencyNumber) => {
  if (type === 'sumCost') {
    if (!rows?.length) {
      return 0;
    }
    const sums = rows.map((row) => row?.cost || row?.forecast || 0);
    const sumCost = sums.reduce((a, b) => a + b);
    return getCurrencyNumber(sumCost);
  }
  if (type === 'sumRemaining') {
    if (!rows?.length) {
      return 0;
    }
    const sumRemaining = rows.map((row) => (row?.cost ? row?.budget - row?.cost : 0)).reduce((a, b) => a + b);
    return getCurrencyNumber(sumRemaining);
  }
  if (type === 'sumBudget') {
    if (!rows?.length) {
      return 0;
    }
    const sumBudget = rows.map((row) => +row?.budget).reduce((a, b) => a + b);
    return getCurrencyNumber(sumBudget);
  }
  if (type === 'total') {
    return 'Total';
  }
  return IntegratedSummary.defaultCalculator(type, rows, getValue);
};

const BudgetDetailsTable = ({ rows, viewType }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const [csvData, setCsvData] = useState([]);
  const [showTableData, setShowTableData] = useState(true);
  const [sorting, setSorting] = useState([{ columnName: 'date', direction: 'asc' }]);
  const [totalSummaryItems] = useState([
    { columnName: 'date', type: 'total' },
    { columnName: 'cost', type: 'sumCost' },
    { columnName: 'budget', type: 'sumBudget' },
    { columnName: 'remaining', type: 'sumRemaining' },
  ]);

  const tableData = useMemo(() => {
    if (rows?.length) {
      const data = rows?.map((row) => ({
        date:
          viewType === 'daily'
            ? moment(new Date(row?.date)).format('DD MMM YYYY')
            : moment(new Date(row?.date)).format('MMM YYYY'),
        cost: getCurrencyNumber(row.cost || row.forecast),
        budget: getCurrencyNumber(+row.budget),
        remaining: row.cost || row.forecast ? getCurrencyNumber(row.budget - (row.cost || row.forecast)) : '',
        budgetUtil: row.cost || row.forecast ? percentStr(((row.cost || row.forecast) * 100) / row.budget) : '',
        actualDailyCost: row.actualDailyCost ? getCurrencyNumber(Number(row.actualDailyCost)) : 'N/A',
        previousDelta: row.previousDayDelta
          ? `${getCurrencyNumber(row.previousDayDelta)} (${percentStr(row.previousDayDeltaPercent)})`
          : 'N/A',
      }));
      if (viewType === 'monthly') {
        setCsvData(
          data.map((d) => ({
            date: d.date,
            cost: d.cost,
            budget: d.budget,
            remaining: d.remaining,
            budgetUtil: d.budgetUtil,
          })),
        );
      } else {
        setCsvData(data);
      }
    }
    return rows?.map((row) => ({
      ...row,
      date: new Date(row.date),
    }));
  }, [rows]);

  return (
    <div className={`sub-table ${classes.gridWrapper}`}>
      <div className={classes.customHeader}>
        <div className={classes.toolbarWrapper}>
          <Button
            onClick={() => setShowTableData(!showTableData)}
            icon={() => <GenerateIcon iconName={showTableData ? ICONS.eyeSlash.name : ICONS.eye.name} />}
            overrideStyles={{
              marginTop: -5,
              fontWeight: 400,
            }}
            isTextButton
            text={showTableData ? 'Hide Table' : 'Show Table'}
          />
          <CustomCSVDownload
            filesNumber={1}
            showDownloadIcon
            hideText
            isLoading={false}
            data={[{ data: csvData, filename: 'budgetsDetails.csv' }]}
            classNames="btn link"
          >
            <a role="button" tabIndex={0} automation-id="export-csv">
              Download
            </a>
          </CustomCSVDownload>
        </div>
      </div>
      {showTableData && (
        <Grid rows={tableData} columns={detailsRowColumns(rows[0]?.budgetType === 'expiringFixed')}>
          <SortingState
            sorting={sorting}
            onSortingChange={(sortingColumAndDirection) => setSorting(sortingColumAndDirection)}
          />
          <IntegratedSorting />

          <DataTypeProvider for={['date']} formatterComponent={dateFormatCell} />
          <DataTypeProvider for={['budgetUtil']} formatterComponent={budgetUtilFormatCell} />
          <DataTypeProvider for={['remaining']} formatterComponent={remainingFormatCell} />
          <DataTypeProvider for={['cost']} formatterComponent={costFormatCell} />
          <SummaryState totalItems={totalSummaryItems} />
          <IntegratedSummary
            calculator={(type, rows, getValue) => summaryCalculator(type, rows, getValue, getCurrencyNumber)}
          />
          <PagingState defaultCurrentPage={0} pageSize={5} />
          <IntegratedPaging />
          <TableWrapper />
          <TableColumnVisibility
            hiddenColumnNames={viewType === 'monthly' ? ['previousDayDelta', 'actualDailyCost'] : []}
          />
          <TableColumnResizing columnWidths={budgetColumnsWidths} />
          <TableHeaderRow showSortingControls />

          <PagingPanel />
        </Grid>
      )}
    </div>
  );
};

export default BudgetDetailsTable;

BudgetDetailsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  viewType: PropTypes.string.isRequired,
};
