import React from 'react';
import Spinner from 'shared/components/andtComponents/Spinner';
import useTable from 'shared/hooks/customHooks/useTable';
import useRoles from '../../../hooks/react-query/useRoles';
import { RolesProvider } from './contexts/rolesContext';
import RolesHeader from './RolesHeader';
import RolesTable from './RolesTable';

import styles from './Roles.module.scss';

const Roles = () => {
  const rolesHook = useRoles();
  const { data, isLoading } = rolesHook.fetchRoles();

  const { NewTableWrapper } = useTable();

  return isLoading ? (
    <Spinner />
  ) : (
    <div className={styles.roles}>
      <RolesProvider>
        <NewTableWrapper>
          <RolesHeader totalRoles={data?.total} />
          <RolesTable isLoading={isLoading} roles={data?.roles} />
        </NewTableWrapper>
      </RolesProvider>
    </div>
  );
};

export default Roles;
