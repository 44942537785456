import { ICONS } from '@pileus-cloud/anodot-frontend-common/dist';

export const STALE_TIME = 60 * 60 * 1000;

export const USER_STATUS_OPTIONS = { ENABLED: 'Enabled', DISABLED: 'Disabled' };

export const USERS_COLUMNS = {
  USER_NAME: {
    columnName: 'username',
    title: 'User Name',
    width: 250,
    sortingEnabled: false, // Sorting is not supported by KeyCloak. The results will be sorted by username ascending.
    direction: 'asc',
  },
  FIRST_NAME: {
    columnName: 'firstName',
    title: 'First Name',
    width: 150,
    sortingEnabled: false,
  },
  LAST_NAME: {
    columnName: 'lastName',
    title: 'Last Name',
    width: 150,
    sortingEnabled: false,
  },
  CUSTOMER: {
    columnName: 'customer',
    title: 'Customer',
    width: 170,
    sortingEnabled: false,
  },
  ROLES: {
    columnName: 'roles',
    title: 'Roles',
    width: 150,
    sortingEnabled: false,
  },
  CREATED_AT: {
    columnName: 'createdAt',
    title: 'Created At',
    width: 170,
    sortingEnabled: false,
  },
  LAST_LOGIN: {
    columnName: 'lastLogin',
    title: 'Last Login',
    width: 170,
    sortingEnabled: false,
  },
  USER_STATUS: {
    columnName: 'userStatus',
    title: '',
    width: 170,
    sortingEnabled: false,
    align: 'right',
  },
  IMPERSONATE: { columnName: 'impersonate', title: '', width: 30, sortingEnabled: false, align: 'right' },
  ACTIONS: { columnName: 'actions', title: '', width: 40, sortingEnabled: false, align: 'right' },
};

export const USER_ROLES_COLUMNS = {
  ROLE_NAME: {
    columnName: 'displayName',
    title: 'Role Name',
    width: 100,
    direction: 'desc',
    sortingEnabled: true,
  },
  ASSIGNED_AT: {
    columnName: 'assignedAt',
    title: 'Assigned At',
    width: 100,
    sortingEnabled: false,
    getCellValue: () => '2024-08-01T09:05:06.311Z',
  },
  ASSIGNED_BY: {
    columnName: 'assignedBy',
    title: 'Assigned By',
    width: 100,
    sortingEnabled: false,
    getCellValue: () => 'Display Name',
  },
  REMOVE_USER_ROLE: {
    columnName: 'removeUserRole',
    title: '',
    width: 200,
    align: 'right',
    sortingEnabled: false,
  },
};

export const USER_ACTIONS = {
  ASSIGN_ROLES: { label: 'Assign Roles', icon: ICONS.userGear.name, automationId: 'userMenuActions_AssignRole' },
  EDIT: { label: 'Edit', icon: ICONS.edit.name, automationId: 'userMenuActions_Edit' },
  RESET_PASSWORD: { label: 'Reset Password', icon: ICONS.refresh.name, automationId: 'userMenuActions_ResetPassword' },
  DELETE: { label: 'Delete', icon: ICONS.deleteRegular.name, automationId: 'userMenuActions_Delete' },
};

export const ASSIGN_ROLES_MODAL_COLUMNS = {
  ROLE_NAME: {
    columnName: 'roleName',
    title: 'Role Name',
    width: 100,
    direction: 'desc',
    sortingEnabled: true,
  },
  ROLE_ID: {
    columnName: 'roleId',
    title: 'ID',
    width: 100,
    sortingEnabled: false,
  },
};

export const ROLES_COLUMNS = {
  ROLE_NAME: {
    columnName: 'identifier',
    title: 'User Name',
    width: 250,
    sortingEnabled: false,
    getCellValue: (cell) => cell?.displayName,
    direction: 'asc',
  },
  ROLE_ID: {
    columnName: 'identifier',
    title: 'User Name',
    width: 250,
    sortingEnabled: false,
    getCellValue: (cell) => cell?.id,
  },
  USERS_COUNT: {
    columnName: 'usersCount',
    title: 'Users',
    width: 170,
    sortingEnabled: false,
  },
  CREATED_AT: {
    columnName: 'createdAt',
    title: 'Created At',
    width: 170,
    sortingEnabled: false,
  },
  CREATED_BY: {
    columnName: 'createdBy',
    title: 'Created By',
    width: 170,
    sortingEnabled: false,
  },
  UPDATED_AT: {
    columnName: 'updatedAt',
    title: 'Updated At',
    width: 170,
    sortingEnabled: false,
  },
  UPDATED_BY: {
    columnName: 'updatedBy',
    title: 'Updated By',
    width: 170,
    sortingEnabled: false,
  },
  ADD_USERS: {
    columnName: 'addUsers',
    title: '',
    width: 170,
    sortingEnabled: false,
    align: 'right',
  },
  ACTIONS: { columnName: 'actions', title: '', width: 40, sortingEnabled: false, align: 'right' },
};

export const getColumns = (columns, isMsp = undefined) => {
  if (!columns) {
    return null;
  }
  let tmpColumns = Object.values(columns);
  if (tmpColumns.find((c) => c.columnName === 'customer') && isMsp === false) {
    tmpColumns = columns.filter((c) => c.columnName !== 'customer');
  }
  return Object.values(tmpColumns).map((c) => ({ name: c.columnName, title: c.title }));
};

export const getSortingColumns = (columns) =>
  Object.values(columns).map((c) => ({ columnName: c.columnName, sortingEnabled: c.sortingEnabled }));

export const getDefaultSorting = (columns) =>
  Object.values(columns)
    .filter((c) => c.direction)
    .map((c) => ({
      columnName: c.columnName,
      direction: c.direction,
    }));

export const EDIT_MODAL_FIELDS = {
  USER_NAME: { id: 'userName', label: 'User Name', placeholder: 'User Name' },
  FIRST_NAME: { id: 'firstName', label: 'First Name', placeholder: 'First Name' },
  LAST_NAME: { id: 'lastName', label: 'Last Name', placeholder: 'Last Name' },
};
