import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import { ReactComponent as InfoBubble } from 'shared/img/images/info_bubble.svg';
import { numberWithCommas, percentStr } from 'shared/utils/strUtil';
import moment from 'moment';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { GptLoader } from './GptLoader';
import { ReactComponent as UserAvatar } from '../assets/user-avatar.svg';
import { ReactComponent as AnobotAvatar } from '../assets/anobot-avatar.svg';
import { gptFormattedKeys } from '../costGptConstants';
import styles from './answer.module.scss';

const getFormattedValue = ({ value, format }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  if (value == null) {
    return value;
  }

  switch (format) {
    case 'month':
      return moment(value).format('MMM YYYY');
    case 'money':
      return `${getCurrencyNumber(value, 2)}`;
    case 'numeric':
      return numberWithCommas(value, 2);
    case 'percentage':
      return percentStr(value, 2);
    default:
      return value;
  }
};

const renderAnswer = ({ answer, refreshResult, sendFeedback, isLoading, setIsLoading }) => {
  if (!answer || isLoading) {
    return <GptLoader answerReceived={!!answer} setIsLoading={setIsLoading} />;
  }
  if (!answer.data?.length && !answer.images?.length && !answer.files?.length && !answer.text) {
    // No results or API error
    return (
      <>
        <figure>
          <InfoBubble />
        </figure>
        <figcaption>
          {answer?.description || (
            <>
              Sorry, I don&apos;t have any results.
              <br />
              Please try again.
            </>
          )}
        </figcaption>
      </>
    );
  }
  return (
    <>
      <div className={styles.answerContent}>
        <div className={styles.historyIndication} title={new Date(answer?.timestamp * 1000).toString()}>
          <GenerateIcon iconName={ICONS.clockRotateLeft.name} />
          <span>{answer?.timestamp && moment.unix(answer.timestamp).format('MMM DD YYYY \xa0|\xa0 HH:mm')}</span>
        </div>
        <button onClick={() => refreshResult(answer.question)} className={styles.refreshResultButton} type="button">
          <GenerateIcon iconName={ICONS.refresh.name} />
          Refresh
        </button>
        {!!answer?.text && <p className={styles.rawText}>{answer.text}</p>}
        {!!answer.data?.length && (
          <div className={styles.tableContainer}>
            <table>
              <thead>
                <tr>
                  {Object.keys(answer.data[0]).map((key) => (
                    <th>
                      {key
                        ?.split('_')
                        .map((word) => gptFormattedKeys[word] || word)
                        .join(' ')}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {answer.data.map((row) => (
                  <tr>
                    {Object.keys(row).map((key) => (
                      <td title={row[key] ?? row[key]}>
                        {getFormattedValue({ value: row[key], format: answer.schema[key] })}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {!!answer.images.length &&
          answer.images.map((img) => <img src={img} className={styles.answerImage} alt="cost-gpt-result" />)}
      </div>
      <footer>
        {!!answer.files?.length && (
          <a href={answer.files[0]}>
            <GenerateIcon iconName={ICONS.download.name} />
            Download Results
          </a>
        )}
        <div className={styles.feedback}>
          <div>
            <button
              onClick={() => sendFeedback({ requestId: answer.requestId, feedback: true })}
              className={answer.feedback && styles.activeFeedback}
              type="button"
            >
              <GenerateIcon iconName={ICONS.thumbUp.name} />
            </button>
          </div>
          <div>
            <button
              onClick={() => sendFeedback({ requestId: answer.requestId, feedback: false })}
              className={answer.feedback === false && styles.activeFeedback}
              type="button"
            >
              <GenerateIcon iconName={ICONS.thumbDown.name} />
            </button>
          </div>
        </div>
      </footer>
    </>
  );
};

export const Answer = ({ question, answer, refreshResult, sendFeedback }) => {
  const [isLoading, setIsLoading] = useState(false); // Required for realistic loader completion on answer received
  return (
    <section className={styles.answer} automation-id="gptAnswer">
      <div className={styles.chatContainer}>
        <div className={styles.avatar}>
          <UserAvatar />
        </div>
        <div className={styles.chatBubble} automation-id="gptAskedQuestionQuote">
          <blockquote>{question}</blockquote>
        </div>
      </div>
      <div className={styles.chatContainer}>
        <div className={styles.avatar}>
          <AnobotAvatar />
        </div>
        <div className={styles.chatBubble} automation-id="gptAnswerResult">
          {renderAnswer({ answer, refreshResult, sendFeedback, isLoading, setIsLoading })}
        </div>
      </div>
    </section>
  );
};

Answer.defaultProps = {
  answer: null,
};

Answer.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.shape({
    data: PropTypes.array.isRequired,
    images: PropTypes.array.isRequired,
    files: PropTypes.array.isRequired,
    schema: PropTypes.object.isRequired,
  }),
  refreshResult: PropTypes.func.isRequired,
  sendFeedback: PropTypes.func.isRequired,
};
