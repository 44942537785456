import React, { useState } from 'react';
import { Grid, TableColumnResizing, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSelection, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import {
  CustomFormatterWithTooltip,
  CustomHeaderCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { palette } from 'shared/constants/colorsConstants';
import { getColumns, getDefaultSorting, getSortingColumns, USER_ROLES_COLUMNS } from '../../consts';
import RemoveUserRole from './RemoveUserRole';
import { ReactComponent as NoResults } from '../img/no-results.svg';

import styles from '../UsersTable.module.scss';

export const UserRolesNoData = (userRoles) => {
  if (!userRoles) {
    return null;
  }
  return (
    <div className={styles.noResultsWrapper}>
      <NoResults />
      <span className={styles.mainText}>No Roles for this user</span>
    </div>
  );
};

const UserRolesTabTable = ({ userRoles, selectedUserRoles, setSelectedUserRoles }) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(USER_ROLES_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );

  return (
    userRoles && (
      <Grid rows={userRoles} columns={getColumns(USER_ROLES_COLUMNS)} automationId="users-list">
        <SortingState
          columnExtensions={getSortingColumns(USER_ROLES_COLUMNS)}
          defaultSorting={getDefaultSorting(USER_ROLES_COLUMNS)}
        />
        <SelectionState selection={selectedUserRoles} onSelectionChange={setSelectedUserRoles} />
        <IntegratedSelection />
        <DataTypeProvider
          for={[USER_ROLES_COLUMNS.ROLE_NAME.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltip overrideStyles={{ color: palette.blue[500], fontWeight: 500 }} {...props} />
          )}
        />
        <DataTypeProvider
          for={[USER_ROLES_COLUMNS.ASSIGNED_AT.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltip
              value={moment(props.assignedAt).format('MMM DD YYYY, hh:mm:ss A')}
              isSmallText
            />
          )}
        />
        <DataTypeProvider
          for={[USER_ROLES_COLUMNS.ASSIGNED_BY.columnName]}
          formatterComponent={(props) => <CustomFormatterWithTooltip isSmallText {...props} />}
        />
        <DataTypeProvider
          for={[USER_ROLES_COLUMNS.REMOVE_USER_ROLE.columnName]}
          formatterComponent={(props) => <RemoveUserRole props={props} />}
        />
        <TableWrapper virtual noDataCellComponent={UserRolesNoData} height="auto" />
        <TableSelection showSelectAll cellComponent={CustomSelectionCell} />
        <TableColumnResizing
          resizingMode="nextColumn"
          columnWidths={localColumnsWidth}
          onColumnWidthsChange={setLocalColumnsWidth}
        />
        <TableHeaderRow showSortingControls cellComponent={CustomHeaderCell} />
      </Grid>
    )
  );
};

UserRolesTabTable.propTypes = {
  userRoles: PropTypes.array.isRequired,
};

export default UserRolesTabTable;
