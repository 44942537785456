import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Spinner, { SIZES } from 'shared/components/andtComponents/Spinner';
import { ReactComponent as NoData } from 'recommendationsNew/img/noData.svg';
import { SearchMultiIcon } from './SearchMultiIcon';
import { ServicesImageTag } from './ServicesImageTag';

import classes from './SelectIconsMultiMenu.module.scss';

const SelectIconsMultiMenu = ({
  options,
  selectedOptions,
  onSelectionChanged,
  menuWidth,
  searchValue,
  onChangeSearchInputValue,
  onBlur,
  containerProps,
  isLoading,
}) => {
  const SELECT_ALL = 'Select All';

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(
    options?.length === selectedOptions?.length && options?.length > 0,
  );

  useEffect(() => {
    setIsSelectAllChecked(options?.length === selectedOptions?.length && options?.length > 0);
  }, [options, selectedOptions]);

  const onServiceClick = (option) => {
    if (selectedOptions.includes(option)) {
      onSelectionChanged(selectedOptions.filter((o) => o !== option));
    } else {
      onSelectionChanged([...selectedOptions, option]);
    }
  };

  const selectAllClicked = () => {
    if (isSelectAllChecked) {
      onSelectionChanged([]);
    } else {
      onSelectionChanged(options);
    }
    setIsSelectAllChecked(!isSelectAllChecked);
  };

  const displayedOptions = useMemo(() => {
    if (searchValue) {
      return options.filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase()));
    }
    return options;
  }, [options, searchValue]);

  return (
    <div style={{ width: menuWidth }}>
      <SearchMultiIcon
        searchValue={searchValue}
        onChangeSearch={onChangeSearchInputValue}
        onBlur={onBlur}
        containerProps={containerProps}
      />
      {isLoading ? (
        <Spinner size={SIZES.SMALL_30} />
      ) : (
        <div className={classes.selectIconsMultiMenuContainer}>
          {displayedOptions && displayedOptions?.length ? (
            <>
              <div className={classes.selectAllContainer} onClick={selectAllClicked}>
                <input className={classes.selectAllCheckbox} type="checkbox" checked={isSelectAllChecked} />
                <div className={classes.selectAllLabel}>{SELECT_ALL}</div>
              </div>
              <div className={classes.optionsIconMultiContainer}>
                {displayedOptions.map((option) => (
                  <div
                    key={option.label}
                    className={`${classes.optionIconMulti} ${
                      selectedOptions?.includes(option) ? '' : classes.notSelected
                    }`}
                    onClick={() => onServiceClick(option)}
                  >
                    <ServicesImageTag label={option.label} />
                    <Tooltip placement="bottom" title={option.label} arrow>
                      <div className={classes.serviceLabel}>{option.label}</div>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="m-xl-auto">
              <NoData />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

SelectIconsMultiMenu.propTypes = {
  options: PropTypes.array.isRequired,
  menuWidth: PropTypes.string,
  onChangeSearchInputValue: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  onSelectionChanged: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  containerProps: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

SelectIconsMultiMenu.defaultProps = {
  menuWidth: '100%',
  isLoading: false,
};

export default SelectIconsMultiMenu;
