import React from 'react';
import Spinner from 'shared/components/andtComponents/Spinner';
import useTable from 'shared/hooks/customHooks/useTable';
import UsersHeader from './UsersHeader';
import UsersTable from './UsersTable';
import useUsers from '../../../new-user-management/hooks/reactQuery/useUsers';
import { UsersProvider } from './contexts/usersContext';

import styles from './Users.module.scss';

const Users = () => {
  const usersHook = useUsers();
  const { data, isLoading } = usersHook.fetchUsers();

  const { NewTableWrapper } = useTable();

  return isLoading ? (
    <Spinner />
  ) : (
    <div className={styles.users}>
      <UsersProvider>
        <NewTableWrapper>
          <UsersHeader totalUsers={data?.total} />
          <UsersTable isLoading={isLoading} users={data?.users} />
        </NewTableWrapper>
      </UsersProvider>
    </div>
  );
};

export default Users;
