import React from 'react';
import PropTypes from 'prop-types';
import NoDataFoundComponent from 'shared/components/NoDataFoundComponent';
import NewBudgetChart from './NewBudgetChart';

const BudgetDisplay = ({ data }) => {
  if (!data || !data[0]) {
    return <NoDataFoundComponent bodyText=" " />;
  }
  return (
    <div>
      <NewBudgetChart budget={data[0]} />
    </div>
  );
};

BudgetDisplay.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BudgetDisplay;
