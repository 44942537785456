import { AlertLevelsEnum, ServiceCostAlert } from '../types.ts';

export const defaultServiceCostAlert: ServiceCostAlert = {
  uuid: '',
  alertLevel: AlertLevelsEnum.SERVICE,
  changePercent: '',
  changeType: { label: 'Change', value: 'change' },
  changeValue: '',
  dayInWeek: null,
  filters: {
    usagetype: {
      operator: 'IS',
      value: [
        {
          value: 'all',
          label: 'Any Usage Type',
        },
      ],
    },
    linkedaccname: {
      operator: 'IS',
      value: [
        {
          value: 'all',
          label: 'Any Linked Account',
        },
      ],
    },
    operation: {
      operator: 'IS',
      value: [
        {
          value: 'all',
          label: 'all',
        },
      ],
    },
    service: {
      operator: 'IS',
      value: [
        {
          value: 'all',
          label: 'Any Service',
        },
      ],
    },
  },
  granularity: { label: 'Today vs. Yesterday', value: '0' },
  operatorType: { label: 'And', value: 'and' },
  recipientsEmails: '',
  recipients: [],
};

export const isDefaultExpanded = false;
