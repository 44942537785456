import React, { useEffect, useMemo, useState } from 'react';
import Input from 'shared/components/andtComponents/Input';
import ErrorMessage from 'shared/components/andtComponents/ErrorMessage';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import PropTypes from 'prop-types';
import { AMORTIZE_COST_TYPES_ITEMS } from 'usage/constants/costAndUsageConstants';
import { FilterTypes } from 'usage/constants/usageConstants';
import { formatExcludeFilterMap } from 'shared/utils/filtersUtils';
// eslint-disable-next-line max-len
import { formatFilterOptionValueLabel } from 'usage/containers/CostAndUsageExplorer/helpers/customDashboardHelperMethods';
import FiltersSidebarContainer from 'shared/components/FilterSidebar/FiltersSidebarContainer';
import { convertObjToMap } from 'shared/utils/apiUtil';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { OPERATORS_KEYS } from 'shared/constants/appConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { CLOUD_TYPE_IDS, UsersType } from 'users/constants/usersConstants';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import classes from './budgetModal.module.scss';

const getFiltersConfig = () => ({
  [AwsCommonFields.CUSTOM_TAGS]: {
    showNotAllocated: true,
  },
  [AwsCommonFields.CATEGORIES]: {
    disableLike: true,
  },
  [AwsCommonFields.REGION]: {
    disableLike: true,
  },
  [AwsCommonFields.PURCHASE_OPTION]: {
    disableLike: true,
  },
  [AwsCommonFields.QUANTITY_TYPE]: {
    disableLike: true,
  },
  [AwsCommonFields.DIVISION]: {
    disableLike: true,
  },
  [AwsCommonFields.CLOUD_PROVIDER]: {
    disableLike: true,
  },
  [AwsCommonFields.VIRTUAL_CUSTOM_TAGS]: {
    disableLike: true,
  },
  [AwsCommonFields.SUB_VIEWS_CUSTOM_TAGS]: {
    disableLike: true,
  },
  [AwsCommonFields.BUSINESS_MAPPING]: {
    isK8S: false,
  },
});

const CBDetailsAndFilters = ({
  budget,
  setBudget,
  budgetValidationErrors,
  saveClicked,
  fieldToFieldDistincValuesMap,
  filterMapTypes,
  setFilterMapTypes,
  likeFiltersStatus,
  setLikeFiltersStatus,
  budgetsNames,
  isEditMode,
  cloudType,
}) => {
  const [selectedOptionsMap, setSelectedOptionsMap] = useState(new Map([]));
  const { usersStore, appStore } = useRootStore();
  const { currentDisplayedUserType } = usersStore;
  const { isPpApplied } = appStore;
  const showCostBasis = cloudType === CLOUD_TYPE_IDS.AWS || cloudType === CLOUD_TYPE_IDS.MULTI;
  const getSelectValues = (values, filterName) => {
    if (!values) {
      return [];
    }
    const selectedValues = values.map((value) => {
      if (typeof value === 'object') {
        return value;
      }
      const fieldValue = fieldToFieldDistincValuesMap
        .get(filterName)
        ?.find(
          (v) =>
            v === value ||
            Object.keys(v).find((key) => (filterName !== 'linkedaccid' || key !== 'accountId') && v[key] === value),
        );
      return fieldValue ? formatFilterOptionValueLabel(fieldValue, filterName) : { value, label: value };
    });
    return selectedValues;
  };
  useEffect(() => {
    const selectedMap = new Map([]);
    if (!budget.includeFilters && !budget.excludeFilters && !budget.likeFilters) {
      return;
    }
    if (budget.includeFilters) {
      Object.keys(budget.includeFilters)?.forEach((filterName) => {
        const values = getSelectValues(budget.includeFilters[filterName], filterName);
        selectedMap.set(filterName, values);
      });
    }
    if (budget.excludeFilters) {
      Object.keys(budget.excludeFilters)?.forEach((filterName) => {
        const values = getSelectValues(budget.excludeFilters[filterName], filterName);
        const includeSelectedOptions = selectedMap.get(filterName);
        selectedMap.set(filterName, includeSelectedOptions ? values.concat(...includeSelectedOptions) : values);
      });
    }
    if (budget.likeFilters) {
      Object.keys(budget.likeFilters)?.forEach((filterName) => {
        const values = getSelectValues(budget.likeFilters[filterName], filterName);
        selectedMap.set(filterName, values);
      });
    }
    setSelectedOptionsMap(selectedMap);
  }, []);

  const handleUpdateField = (field, val) => {
    setBudget({
      ...budget,
      [field]: val,
    });
  };

  const saveBudgetFilters = () => {
    const includeFilters = {};
    const excludeFilters = {};
    const likeFilters = {};
    selectedOptionsMap.forEach((values, filterName) => {
      const filterType = filterMapTypes.get(filterName);
      if (typeof filterType === 'object') {
        includeFilters[filterName] = [];
        excludeFilters[filterName] = [];
        values.forEach((subValue) => {
          const parentField = subValue.value.split(':')[0];
          if (!filterType[parentField] || filterType[parentField] === FilterTypes.INCLUDE) {
            includeFilters[filterName] = [...includeFilters[filterName], subValue];
          } else {
            excludeFilters[filterName] = [...excludeFilters[filterName], subValue];
          }
        });
      } else if (filterType === FilterTypes.INCLUDE) {
        if (likeFiltersStatus[filterName] === 1) {
          likeFilters[filterName] = [...values];
        } else {
          includeFilters[filterName] = [...values];
        }
      } else {
        excludeFilters[filterName] = [...values];
      }
    });
    setBudget({
      ...budget,
      includeFilters,
      excludeFilters,
      likeFilters,
    });
  };

  useEffect(() => {
    saveBudgetFilters();
  }, [filterMapTypes, selectedOptionsMap]);

  const updateFilter = (filterName, val) => {
    setSelectedOptionsMap(new Map([...selectedOptionsMap, [filterName, val]]));
  };
  const handleChangeFilterType = (field, subField, type) => {
    if (!subField) {
      const filterType =
        type !== OPERATORS_KEYS.LIKE && filterMapTypes.get(field) === FilterTypes.INCLUDE
          ? FilterTypes.EXCLUDE
          : FilterTypes.INCLUDE;
      setFilterMapTypes(new Map([...filterMapTypes, [field, filterType]]));
    } else if (subField) {
      setFilterMapTypes(new Map([...formatExcludeFilterMap(convertObjToMap(filterMapTypes), field, subField)]));
    }
    setLikeFiltersStatus(
      type === OPERATORS_KEYS.LIKE
        ? {
            ...likeFiltersStatus,
            [field]: +!likeFiltersStatus[field],
          }
        : {},
    );
  };

  const pricingMode = () => {
    const resellerFlag = isEditMode ? budget.isPpApplied : isPpApplied;
    if (resellerFlag === undefined) {
      return 'N/A';
    }
    return resellerFlag ? 'Customer' : 'Partner';
  };

  const costBasisOptions = useMemo(() => {
    if (cloudType === CLOUD_TYPE_IDS.MULTI) {
      return [
        {
          ...AMORTIZE_COST_TYPES_ITEMS.AMORTIZED,
          primary: true,
          automationId: 'amortized-cost',
          disabled: isEditMode && new Date(budget.endDate) < new Date(),
        },
        {
          ...AMORTIZE_COST_TYPES_ITEMS.UNBLENDED,
          primary: true,
          disabled: isEditMode && new Date(budget.endDate) < new Date(),
          optionClassName: classes.marginOption,
          automationId: 'unblended-cost',
        },
      ];
    }
    if (currentDisplayedUserType === UsersType.RESELLER_CUSTOMER) {
      return [
        {
          ...AMORTIZE_COST_TYPES_ITEMS.UNBLENDED,
          primary: true,
          automationId: `${isEditMode ? 'edit' : 'create'}-unblended-cost`,
        },
      ];
    }
    return [
      {
        ...AMORTIZE_COST_TYPES_ITEMS.AMORTIZED,
        primary: true,
        automationId: `${isEditMode ? 'edit' : 'create'}-amortized-cost`,
        disabled: isEditMode && new Date(budget.endDate) < new Date(),
      },
      {
        ...AMORTIZE_COST_TYPES_ITEMS.NET_AMORTIZED,
        value: 'net_amortized',
        primary: true,
        disabled: isEditMode && new Date(budget.endDate) < new Date(),
        optionClassName: classes.option2,
        automationId: `${isEditMode ? 'edit' : 'create'}-amortized-cost`,
      },
      {
        ...AMORTIZE_COST_TYPES_ITEMS.UNBLENDED,
        primary: true,
        disabled: isEditMode && new Date(budget.endDate) < new Date(),
        automationId: `${isEditMode ? 'edit' : 'create'}-unblended-cost`,
      },
    ];
  }, [cloudType, currentDisplayedUserType]);

  return (
    <div className={classes.container}>
      <div className={classes.detailsWrapper}>
        <span className={classes.subTitle}>Budget Details</span>
        <div className={classes.fullWidth}>
          <h5 className={classes.header}>Budget Name</h5>
          <Input
            className={classes.fieldInput}
            value={budget.budgetName}
            onChange={(e) => {
              handleUpdateField('budgetName', e.target.value);
            }}
            isInvalid={
              saveClicked &&
              ((!!budgetValidationErrors?.budgetName && !budget.budgetName) ||
                (!!budgetValidationErrors?.budgetNameUnique && budgetsNames?.includes(budget.budgetName)))
            }
            automationId={`${isEditMode ? 'edit' : 'create'}-budget-name-input`}
            invalidComponent={
              <ErrorMessage
                displayError={
                  saveClicked && (!!budgetValidationErrors?.budgetName || !!budgetValidationErrors?.budgetNameUnique)
                }
                errMsg={budgetValidationErrors?.budgetName || budgetValidationErrors?.budgetNameUnique}
                automationId={`${isEditMode ? 'edit' : 'create'}-budget-name-input-error`}
              />
            }
            disabled={isEditMode && new Date(budget.endDate) < new Date()}
            placeholder="Enter Name"
            type="text"
          />
        </div>
        <div className={classes.fullWidth}>
          <h5 className={classes.header}>Description</h5>
          <textarea
            type="text"
            value={budget.description}
            rows={5}
            onChange={(e) => {
              handleUpdateField('description', e.target.value);
            }}
            disabled={isEditMode && new Date(budget.endDate) < new Date()}
            placeholder="Enter Description"
            automation-id={`${isEditMode ? 'edit' : 'create'}-descriptionTextArea`}
          />
        </div>
        {/* {isEditMode && ( */}
        {/*  <div className={classes.fullWidth}> */}
        {/*    <h5 className={classes.header}>Budget Owner</h5> */}
        {/*    <Input */}
        {/*      className={classes.fieldInput} */}
        {/*      value={budget.budgetOwner} */}
        {/*      onChange={(e) => handleUpdateField('owner', e.target.value)} */}
        {/*      automationId="budget-owner-input" */}
        {/*      placeholder="Enter Owner" */}
        {/*      type="text" */}
        {/*    /> */}
        {/*  </div> */}
        {/* )} */}
        {showCostBasis && (
          <div className={classes.fullWidth}>
            <div className={classes.tooltipWrapper}>
              <h5 className={classes.header}>Select AWS Cost Basis:</h5>
              <Tooltip
                title={`Amortize cost selection is relevant only for AWS, to select
                Azure Amortize cost please choose the relevant Amortize payer account.`}
                arrow
                className="toolTip"
              >
                <span className={classes.infoIcon}>
                  <InfoIcon />
                </span>
              </Tooltip>
            </div>
            <div className={classes.radioWrapper}>
              <RadioButtonList
                className={classes.radioList}
                optionClassName={classes.option}
                options={costBasisOptions}
                value={budget.costType}
                onChange={(val) => {
                  handleUpdateField('costType', val);
                }}
                automation-id={`${isEditMode ? 'edit' : 'create'}-cost-type`}
              />
              <ErrorMessage
                displayError={saveClicked && !!budgetValidationErrors?.costType && !budget.costType}
                errMsg={budgetValidationErrors?.costType}
                automationId={`${isEditMode ? 'edit' : 'create'}-alert-granularity-error`}
              />
            </div>
          </div>
        )}
        {currentDisplayedUserType === UsersType.RESELLER ? (
          <div className={classes.fullWidth}>
            <h5 className={classes.header}>
              Pricing Mode{' '}
              <Tooltip
                title="The value is taken from pricing mode viewed at the budget creation
                 (Partner/Customer). The field isn’t updatable."
                arrow
                className="toolTip"
              >
                <span>
                  <InfoIcon />
                </span>
              </Tooltip>
            </h5>
            <Input
              className={classes.fieldInput}
              value={pricingMode()}
              automationId={`${isEditMode ? 'edit' : 'create'}-pricing-mode-input`}
              disabled
              type="text"
            />
          </div>
        ) : null}
      </div>
      <div className={classes.filterWrapper}>
        <div />

        <FiltersSidebarContainer
          likeOperator
          selectedOptionsMap={selectedOptionsMap}
          fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
          currDispUserCloudAccountType={usersStore.currDispUserCloudAccountType}
          fieldToFieldDistincValuesProps={getFiltersConfig()}
          excludedFiltersStatusMap={filterMapTypes}
          likeFiltersStatus={likeFiltersStatus}
          isDisabled={isEditMode && new Date(budget.endDate) < new Date()}
          fieldsWidth={440}
          handleChangeFilterType={handleChangeFilterType}
          handleFilterChange={updateFilter}
          className={classes.noScroll}
        />
      </div>
    </div>
  );
};

export default CBDetailsAndFilters;
CBDetailsAndFilters.propTypes = {
  budget: PropTypes.object.isRequired,
  setBudget: PropTypes.func.isRequired,
  budgetValidationErrors: PropTypes.object.isRequired,
  fieldToFieldDistincValuesMap: PropTypes.object.isRequired,
  saveClicked: PropTypes.bool,
  filterMapTypes: PropTypes.object.isRequired,
  setFilterMapTypes: PropTypes.func.isRequired,
  likeFiltersStatus: PropTypes.object.isRequired,
  setLikeFiltersStatus: PropTypes.func.isRequired,
  budgetsNames: PropTypes.array.isRequired,
  isEditMode: PropTypes.bool,
  cloudType: PropTypes.string.isRequired,
};

CBDetailsAndFilters.defaultProps = {
  saveClicked: false,
  isEditMode: false,
};
