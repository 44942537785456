import React from 'react';

import styles from './UserRoles.module.scss';

const UserRoles = ({ row }) => {
  const { roles } = row;
  return (
    <div className={styles.userRoles}>
      {roles[0]?.displayName}
      {roles.length > 1 && <span className={styles.moreRoles}>+{roles.length - 1}</span>}
    </div>
  );
};

export default UserRoles;
