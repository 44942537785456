import React from 'react';
import { Grid, TableHeaderRow, Table } from '@devexpress/dx-react-grid-material-ui';
import TableCell from '@mui/material/TableCell';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import useTable from 'shared/hooks/customHooks/useTable';

import styles from './discountTable.module.scss';

const columns = [
  {
    name: 'instanceType',
    title: 'Instance Type',
  },
  {
    name: 'region',
    title: 'Region',
  },
  {
    name: 'os',
    title: 'Operation System',
  },
  {
    name: 'discount',
    title: 'Saving over On-Demand',
    getCellValue: (row) => `${Number(row.discount * 100).toFixed(2)}%`,
  },
  {
    name: 'totalUsage',
    title: 'Total Usage Hour',
  },
];

const DiscountTable = ({ discountData }) => {
  const { NewTableWrapper } = useTable();
  return (
    <div className={styles.tableWrapper}>
      <span className={styles.header}>Instance Type Savings- Daily Breakdown</span>

      <div className={styles.flex}>
        <NewTableWrapper isCompact className={styles.relativeWrapper}>
          <Grid rows={discountData.slice(0, 3)} columns={columns} automationId="discount-list">
            <Table
              cellComponent={({ children, ...props }) => (
                <TableCell {...props}>
                  <div className={styles.emptyCell} />
                </TableCell>
              )}
            />
            <TableHeaderRow />
          </Grid>
          <CustomCSVDownload
            fetchData={() => {
              const exportColumns = columns.map((column) => ({
                key: column.name,
                label: column.title,
              }));
              return [
                {
                  headers: exportColumns,
                  data: discountData,
                  filename: 'Instance Type Savings',
                },
              ];
            }}
            showDownloadIcon
            hideText
            isPrimary
            style={{
              height: '36px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
            className={styles.absoluteButton}
            isLoading={false}
            filesNumber={1}
          >
            Download CSV
          </CustomCSVDownload>
        </NewTableWrapper>
      </div>
    </div>
  );
};

export default DiscountTable;
