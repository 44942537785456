import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverContent, PopoverTrigger } from '@pileus-cloud/anodot-frontend-common/dist';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from '../Tooltip';

import classes from './InfoPopover.module.scss';

const InfoPopover = ({ children, isSimple, placement, mode }) => {
  const [isOpen, setIsOpen] = useState(false);

  if (isSimple) {
    return (
      <Tooltip title={children} placement={placement}>
        <span className={classes.infoIcon} onClick={() => setIsOpen(!isOpen)} data-simple data-info-popover="true">
          {mode === 'outline' ? <GenerateIcon iconName={ICONS.circleInfo.name} /> : <InfoIcon />}
        </span>
      </Tooltip>
    );
  }

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <Popover open={isOpen}>
      <PopoverTrigger as="button" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <InfoIcon />
      </PopoverTrigger>
      <div className={classes.wrapper}>
        <PopoverContent
          className={classes.popoverContent}
          side={placement || 'bottom'}
          sideOffset={1}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          {children}
        </PopoverContent>
      </div>
    </Popover>
  );
};

InfoPopover.propTypes = {
  children: PropTypes.node.isRequired,
  isSimple: PropTypes.bool,
  placement: PropTypes.string,
  mode: PropTypes.oneOf(['outline', 'filled']),
};

InfoPopover.defaultProps = {
  isSimple: true,
  placement: undefined,
  mode: 'filled',
};

export default InfoPopover;
