import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Button from 'shared/components/andtComponents/Button';
import { createDateDisplayStr, dateToStr } from 'shared/utils/dateUtil';
import SimpleSelect from 'shared/components/andtComponents/SimpleSelect';
import moment from 'moment';
import {
  FORECAST_COST_COLOR,
  getBudgetAmount,
  getPreparedAccumCostsData,
  HIGH_COST_COLOR,
  LIMIT_COST_COLOR,
  methodOptions,
  PROPER_COST_COLOR,
} from 'usage/containers/Budget/budgetUtil';
import NewCustomDashboardPanelModal from 'shared/components/NewCustomDashboardPanelModal';
import { useRootStore } from 'app/contexts/RootStoreContext';
import * as custDabrdHelpers from 'usage/containers/CostAndUsageExplorer/helpers/customDashboardHelperMethods';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { withStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { PageNames } from 'shared/constants/appConstants';
import { useInvoiceFilters } from 'invoices/contexts/InvoiceFiltersContext';
import DateFilter from 'shared/modules/dateFilter';
import classes from './budgets.module.scss';
import MonthlyCostBarChart from './MonthlyCostBarChart';
import DailyCostAreaChart from './DailyCostAreaChart';
import BudgetDetailsTable from './BudgetDetailsTable';
import CurrentBudgetProgress from './CurrentBudgetProgress';

const RenderLegend = () => {
  const legend = [
    { value: 'Cost: 0-80%', color: PROPER_COST_COLOR },
    { value: 'Cost: 80% - 100%', color: LIMIT_COST_COLOR },
    { value: 'Current Cost Overuse', color: HIGH_COST_COLOR },
    { value: 'Forecast Cost', color: FORECAST_COST_COLOR },
  ];
  return (
    <div className={classes.legendWrapper}>
      {legend.map((entry) => (
        <>
          <svg width="20" height="20">
            <circle cx="8" cy="8" r="7" fill={entry.color} />
          </svg>
          <span className={classes.legend}>{entry.value}</span>
        </>
      ))}
    </div>
  );
};

const WhiteTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'white !important',
    borderRadius: '6px',
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 36, 0.25);',
    zIndex: 100,
  },
}))(Tooltip);

const filterLabel = (field) => LabelCoordinator.getFieldLabel(field);
const filterChip = (field, filter, isExclude, fieldToFieldDistincValuesMap) => {
  const getFilterName = (filterValue) => {
    if (!filterValue) {
      return '';
    }
    const [key, value] = filterValue.split(':');
    if (!value) {
      if (field === AwsCommonFields.LINKED_ACCOUNT_ID) {
        const linkAccountValues = fieldToFieldDistincValuesMap.get(AwsCommonFields.LINKED_ACCOUNT_ID);
        const linkAccount = linkAccountValues?.find((aa) => aa.linkedAccountId === key);
        return linkAccount?.displayLabel || key;
      }
      const keyValue = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', filterValue.trim());
      return keyValue === filterValue ? filterValue : `${filterValue} (${keyValue})`;
    }
    const keyName = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', key);
    const displayValue =
      field === AwsCommonFields.ACCOUNT_TAGS
        ? value
        : LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', value.trim());
    return `${keyName}: ${displayValue}`;
  };
  return (
    <>
      {' '}
      <span className={classes.filterName}>{filterLabel(field)}:</span>
      <WhiteTooltip
        placement="bottom"
        title={
          <div className={classes.filterTooltipWrap}>
            <span className={classes.filterName}>
              {isExclude ? 'Excluded ' : ''}
              {filterLabel(field)} <span className={classes.filterCount}> ({filter?.length})</span>
            </span>
            {filter.map((f) => (
              <div className={classes.tooltipRow}>
                <span className={classes.value}>{getFilterName(f)}</span>
                <br />
              </div>
            ))}
          </div>
        }
      >
        <span className={`${classes.filterValue} ${classes.marginTop}`}>{getFilterName(filter[0])}</span>
      </WhiteTooltip>
      {filter?.length > 1 && <span className={classes.itemCount}> +{filter?.length - 1}</span>}
    </>
  );
};

const BudgetDetails = ({ budget, isDashboardPanel }) => {
  const { getPageFilters } = useInvoiceFilters();
  const { usersStore } = useRootStore();
  const { getCurrencyNumber } = useUserSettingsContext();
  const [viewType, setViewType] = useState('daily');
  const [forecastMode, setForecastMode] = useState(1);
  const [currentBudget, setCurrentBudget] = useState({ ...budget });
  const [dashboardModalIsOpen, setDashboardModalIsOpen] = useState(false);
  const [customPanelType, setCustomPanelType] = useState('budget-chart');
  const [scrollToMonth, setScrollToMonth] = useState();
  const { usageStore } = useRootStore();
  const { existingDashboardsNamesAndIds, modelIsLoading } = usageStore.customDbSubStore.customDashboardModel;
  const fieldToFieldDistincValuesMap = getPageFilters(PageNames.BUDGET, usersStore.currDispUserCloudAccountType);

  const getBudgetAmountPerMonth = (date) => {
    const currentMonth = new Date(date).getMonth();
    const currentYear = new Date(date).getFullYear();
    const currentMonthAmount = currentBudget?.budgetAmounts.find((b) => {
      const amountDate = new Date(`${b.date}-01`);
      return amountDate.getMonth() === currentMonth && amountDate.getFullYear() === currentYear;
    });
    return currentMonthAmount?.amount;
  };

  const displayPreparedAccumCostsData = currentBudget.preparedAccumCostsData?.filter(
    (row) => !row.monthNum || forecastMode >= row.monthNum,
  );

  const getMonthlyChartData = () => {
    const currentBudgetMonthData = {
      date: budget.monthlyStartDate,
      cost: Number(budget.monthlyTotalCost),
      forecast:
        budget.monthlyBudgetAmount - +budget.monthlyTotalCost >= 0
          ? Number(budget.monthlyTotalForecastedCost - budget.monthlyTotalCost)
          : undefined,
      budgetTotal:
        budget.monthlyBudgetAmount - +budget.monthlyTotalForecastedCost >= 0 &&
        budget.monthlyBudgetAmount - +(budget.monthlyTotalForecastedCost - budget.monthlyTotalCost) >= 0
          ? budget.monthlyBudgetAmount -
            +budget.monthlyTotalCost -
            +(budget.monthlyTotalForecastedCost - budget.monthlyTotalCost)
          : 0,
      budget: +budget.monthlyBudgetAmount,
      percent: budget.budgetAmounts?.length
        ? (budget.totalCost / +budget.monthlyBudgetAmount) * 100
        : (budget.totalCost / budget.budgetAmount) * 100,
      budgetType: budget.budgetType,
    };
    const data = budget.previousBudgets
      ? budget.previousBudgets.map((b) => ({
          date: b.monthlyStartDate,
          cost: +b.monthlyTotalCost,
          budgetTotal:
            b.monthlyBudgetAmount - +b.monthlyTotalCost >= 0 ? b.monthlyBudgetAmount - +b.monthlyTotalCost : 0,
          budget: b.monthlyBudgetAmount,
          percent: b.budgetAmounts?.length
            ? (b.totalCost / +b.monthlyBudgetAmount) * 100
            : (b.totalCost / b.budgetAmount) * 100,
          budgetType: budget.budgetType,
        }))
      : [];
    const forecasting = budget.preparedMonthForecastingData?.filter(
      (forecast) => forecast.monthNum === undefined || (forecast.monthNum > 0 && forecastMode >= forecast.monthNum),
    );
    const forecastingData = forecasting.map((fRow) => ({
      date: fRow.date,
      budgetTotal:
        budget.monthlyBudgetAmount - +fRow.forecastTotalCost >= 0
          ? budget.monthlyBudgetAmount - +fRow.forecastTotalCost
          : 0,
      budget: currentBudget.budgetAmounts?.length ? getBudgetAmountPerMonth(fRow.date) : budget.monthlyBudgetAmount,
      forecast: +fRow.forecastTotalCost,
      percent:
        (fRow.totalCost / currentBudget.budgetAmounts?.length
          ? getBudgetAmountPerMonth(fRow.date)
          : budget.budgetAmount) * 100,
      budgetType: budget.budgetType,
    }));
    let accumulator = currentBudgetMonthData.cost;
    if (budget.budgetType === 'expiringFixed') {
      return [...data, currentBudgetMonthData, ...forecastingData].map((item) => {
        accumulator += item.forecast || 0;
        return !item.forecast
          ? { ...item, budgetTotal: item.budget - accumulator >= 0 ? item.budget - accumulator : 0 }
          : {
              ...item,
              accValue: accumulator,
              accForecast: item.cost && item.forecast ? item.forecast : accumulator,
              budgetTotal: item.budget - accumulator >= 0 ? item.budget - accumulator : 0,
            };
      }, 0);
    }
    return [...data, currentBudgetMonthData, ...forecastingData];
  };

  const calculatePreviousDayDelta = (row, actualDate, prevDate) => ({
    ...row,
    actualDailyCost: actualDate,
    previousDayDelta: actualDate && prevDate ? actualDate - prevDate : 0,
    previousDayDeltaPercent: actualDate && prevDate ? ((actualDate - prevDate) / actualDate) * 100 : 0,
  });

  const getDailyChartData = () => {
    if (!currentBudget.preparedAccumCostsData?.length) {
      return [];
    }
    const displayCostData = displayPreparedAccumCostsData;
    const getWarnCostValue = (row, index) => {
      if (row.isWarnValue && row.cost) {
        return row.cost;
      }
      if (!row.isWarnValue && displayCostData[index - 1]?.isWarnValue) {
        return row.overuseCost;
      }
      return null;
    };
    const data = displayCostData.map((row, index) => ({
      date: row.date,
      cost: (row.cost && !row.isWarnValue) || (row.cost && !displayCostData[index - 1]?.isWarnValue) ? row.cost : null,
      warnCost: getWarnCostValue(row, index),
      overuseCost: row.overuseCost || null,
      forecast: row.forecastedCost >= 0 ? row.forecastedCost : null,
      actualData: row.actualData,
      budgetForThisDay: getBudgetAmount(budget, new Date(row.date)),
    }));

    return data?.map((row, index) =>
      calculatePreviousDayDelta(row, row.actualData, index > 0 ? data[index - 1].actualData : row.actualData),
    );
  };
  const getDetailsTableData = () => {
    if (viewType === 'monthly') {
      return getMonthlyChartData();
    }
    const data = displayPreparedAccumCostsData?.map((row) => ({
      date: row.date,
      cost: row.cost || row.overuseCost || null,
      forecast: row.forecastedCost || null,
      budget: currentBudget.budgetAmounts?.length ? getBudgetAmountPerMonth(row.date) : currentBudget.budgetAmount,
      actualData: row.actualData,
      isDailyMode: true,
    }));
    return data?.map((row, index) =>
      calculatePreviousDayDelta(row, row.actualData, index > 0 ? data[index - 1].actualData : row.actualData),
    );
  };

  const changeMonth = (isPrev) => {
    const currentDate = new Date(currentBudget.monthlyStartDate);

    if (budget.budgetType === 'expiringFixed') {
      const currentMonth = new Date(scrollToMonth || currentBudget.monthlyStartDate);
      const displayData = displayPreparedAccumCostsData;
      if (isPrev) {
        const prevMonth = moment(currentMonth).add(-1, 'months');
        const budgetStartDate = moment(displayData[0].date).set('date', 1);
        if (prevMonth.month() === budgetStartDate.month() && prevMonth.year() === budgetStartDate.year()) {
          setScrollToMonth(budgetStartDate.format('YYYY-MM-DD'));
        } else if (prevMonth.isAfter(budgetStartDate)) {
          setScrollToMonth(prevMonth.format('YYYY-MM-DD'));
        }
      } else {
        const lastMonth = moment(displayData[displayData.length - 1].date).set('date', 1);
        const nextMonth = moment(currentMonth).add(1, 'months').set('date', 1);
        if (
          nextMonth.isBefore(lastMonth) ||
          (nextMonth.month() === lastMonth.month() && nextMonth.year() === lastMonth.year())
        ) {
          setScrollToMonth(nextMonth.format('YYYY-MM-DD'));
        }
      }
      return;
    }
    const updatedDisplayedBudgetDate = isPrev
      ? moment(currentDate).add(-1, 'months').format('yyyy-MM')
      : moment(currentDate).add(1, 'months').format('yyyy-MM');
    const index = budget.previousBudgets?.findIndex(
      (b) => dateToStr(new Date(b.monthlyStartDate), 'yyyy-mm') === updatedDisplayedBudgetDate,
    );
    if (index > -1) {
      setCurrentBudget(budget.previousBudgets[index]);
    } else if (updatedDisplayedBudgetDate === dateToStr(new Date(budget.monthlyStartDate), 'yyyy-mm')) {
      setCurrentBudget(budget);
    }
  };

  const getParamsForCustomDashboard = () => ({ id: budget.uuid });

  const getLastMonthDisplay = () => {
    const displayMonthChartData = currentBudget.preparedAccumCostsData?.filter(
      (row) => !row.monthNum || forecastMode >= row.monthNum,
    );
    return displayMonthChartData[displayMonthChartData.length - 1].date;
  };

  const hasHistoricalUpdated = () => {
    const firstMonth = createDateDisplayStr(budget.startDate);
    if (
      budget.previousBudgets?.length &&
      createDateDisplayStr(budget.previousBudgets[0]?.monthlyStartDate) > firstMonth
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (currentBudget.monthlyStartDate < DateFilter.getFirstDayOfCurrMonthDate()) {
      setForecastMode(0);
    } else {
      setForecastMode(1);
    }
  }, [currentBudget]);

  if (!currentBudget.preparedAccumCostsData) {
    setCurrentBudget({ ...currentBudget, preparedAccumCostsData: getPreparedAccumCostsData(currentBudget) });
  }
  return (
    <div className={classes.rowDetails}>
      <div className={classes.container}>
        <div className={classes.rightSide}>
          <div className={classes.header}>
            <div className={classes.rightWrapper}>
              <span className={classes.title}>Accumulated cost view</span>
              <div className={classes.margin}>
                <RadioButtonList
                  className={classes.radioList}
                  optionClassName={classes.option}
                  options={[
                    { label: 'Daily View', name: 'Daily', value: 'daily', primary: true },
                    { label: 'Monthly View', name: 'Monthly', value: 'monthly', primary: true },
                  ]}
                  value={viewType}
                  onChange={(val) => setViewType(val)}
                />
              </div>
              <div className={classes.verticalLine} />
              <div className={classes.margin}>
                {forecastMode === 0 ? (
                  <div className={classes.noForecast}>
                    <Tooltip title="Forecast is not available for historical months">
                      <span>Forecast N/A</span>
                    </Tooltip>
                  </div>
                ) : (
                  <SimpleSelect
                    value={forecastMode}
                    onChange={(value) => {
                      setForecastMode(value);
                    }}
                    disabled={currentBudget.monthlyStartDate < DateFilter.getFirstDayOfCurrMonthDate()}
                    isClearable={false}
                    className={classes.select}
                    options={[
                      { value: 1, label: '1 Month Forecast' },
                      { value: 3, label: '3 Months Forecast' },
                      { value: 6, label: '6 Months Forecast' },
                    ]}
                  />
                )}
              </div>
              <div className={classes.verticalLine} />
              {viewType === 'monthly' ? (
                <div className={classes.text}>
                  <span>
                    {createDateDisplayStr(
                      budget.previousBudgets?.length
                        ? budget.previousBudgets[0]?.monthlyStartDate
                        : budget.monthlyStartDate,
                      'month',
                    )}{' '}
                    - {createDateDisplayStr(getLastMonthDisplay(), 'month')}
                  </span>
                </div>
              ) : (
                <div className={classes.arrows}>
                  <button className={classes.arrow} onClick={() => changeMonth(true)} type="button">
                    <GenerateIcon iconName={ICONS.chevronLeft.name} className={classes.icon} />
                  </button>
                  <div className={classes.month}>
                    {createDateDisplayStr(
                      currentBudget.budgetType === 'expiringFixed'
                        ? scrollToMonth || budget?.monthlyStartDate
                        : currentBudget?.monthlyStartDate,
                      'month',
                    )}
                  </div>
                  <button className={classes.arrow} onClick={() => changeMonth(false)} type="button">
                    <GenerateIcon iconName={ICONS.chevronRight.name} className={classes.icon} />
                  </button>
                </div>
              )}
              {hasHistoricalUpdated() && (
                <div className={classes.updateMsgWrapper}>
                  <GenerateIcon iconName={ICONS.infoStroke.name} className={classes.infoIcon} />
                  <span className={classes.updateMsg}>
                    Historical budget months are being updated and will be available in a few minutes
                  </span>
                </div>
              )}
            </div>
            <div className={classes.leftWrapper}>
              {!isDashboardPanel && (
                <Button
                  onClick={() => {
                    setDashboardModalIsOpen(true);
                    setCustomPanelType('budget-chart');
                  }}
                  isTextButton={false}
                  type="button"
                  icon={() => <GenerateIcon iconName={ICONS.dashboard.name} className={classes.icon} />}
                  automationId="save-budget-to-dashboard"
                />
              )}
            </div>
          </div>
          <div className={classes.descriptionWrapper}>{currentBudget.description}</div>
          <div className={`${classes.detailsWrapper} ${classes.noBorder}`}>
            <div className={classes.iconWrapper}>
              <GenerateIcon iconName={ICONS.moneyBills.name} className={classes.icon} />
              <span className={classes.text}>Left To spend</span>
              <span
                className={`${classes.spend} ${
                  getBudgetAmount(currentBudget, new Date(currentBudget.monthlyStartDate)) - currentBudget.totalCost < 0
                    ? classes.minus
                    : ''
                }`}
              >
                {getCurrencyNumber(
                  getBudgetAmount(currentBudget, new Date(currentBudget.monthlyStartDate)) - currentBudget.totalCost,
                )}
              </span>
            </div>
            <div className={classes.iconWrapper}>
              <GenerateIcon iconName={ICONS.gearRegular.name} className={classes.icon} />
              <span className={classes.text}>Budget Type</span>
              <span>
                {methodOptions.find((a) => a.value === currentBudget.budgetType)?.label || currentBudget.budgetType}
              </span>
            </div>
            {budget.budgetType !== 'expiringFixed' && (
              <div className={classes.iconWrapper}>
                <GenerateIcon iconName={ICONS.regularMoney.name} className={classes.icon} />
                <span className={classes.text}>Rolling Budget</span>
                <span>{currentBudget.flexible === true || currentBudget.flexible === '1' ? 'Yes' : 'No'}</span>
              </div>
            )}
            <div className={classes.iconWrapper}>
              <GenerateIcon iconName={ICONS.calendarDays.name} className={classes.icon} />
              <span className={classes.text}>Budget Date Range</span>
              <span>
                {createDateDisplayStr(budget.startDate, 'month')} - {createDateDisplayStr(budget.endDate, 'month')}
              </span>
            </div>
          </div>
          <div className={classes.detailsWrapper}>
            <>
              <div className={classes.iconWrapper}>
                <GenerateIcon iconName={ICONS.barsFilter.name} className={classes.icon} />
                <span className={classes.text}>Filters</span>
              </div>
              {(budget.includeFilters && Object.keys(budget.includeFilters).length > 0) ||
              (budget.excludeFilters && Object.keys(budget.excludeFilters).length > 0) ||
              (budget.likeFilters && Object.keys(budget.likeFilters).length > 0) ? (
                <div className={classes.filterChipWrapper}>
                  {Object.keys(budget.includeFilters).length > 0 && (
                    <span className={classes.filterType}>Included:</span>
                  )}
                  {Object.keys(budget.includeFilters).map((field) => (
                    <span className={classes.filterChip}>
                      {filterChip(field, budget.includeFilters[field], false, fieldToFieldDistincValuesMap)}
                    </span>
                  ))}
                  {Object.keys(budget.excludeFilters).length > 0 && (
                    <span className={`${classes.filterType} ${classes.margin}`}>Excluded:</span>
                  )}
                  {Object.keys(budget.excludeFilters).map((field) => (
                    <span className={`${classes.filterChip} ${classes.exclude}`}>
                      {filterChip(field, budget.excludeFilters[field], true, fieldToFieldDistincValuesMap)}
                    </span>
                  ))}
                  {budget.likeFilters && Object.keys(budget.likeFilters).length > 0 && (
                    <span className={`${classes.filterType} ${classes.margin}`}>Like:</span>
                  )}
                  {budget.likeFilters &&
                    Object.keys(budget.likeFilters).map((field) => (
                      <span className={classes.filterChip}>
                        {filterChip(field, budget.likeFilters[field], false, fieldToFieldDistincValuesMap)}
                      </span>
                    ))}
                </div>
              ) : (
                <span className={classes.filterChipWrapper}>No filters</span>
              )}
            </>
          </div>

          <div className={classes.chartWrapper}>
            <CurrentBudgetProgress budget={currentBudget} />
            <div>
              {viewType === 'monthly' ? (
                <MonthlyCostBarChart
                  data={getMonthlyChartData()}
                  customLegend={RenderLegend()}
                  isPeriod={budget.budgetType === 'expiringFixed'}
                />
              ) : (
                <DailyCostAreaChart
                  data={getDailyChartData()}
                  customLegend={RenderLegend()}
                  budget={currentBudget}
                  isDashboardPanel={isDashboardPanel}
                  scrollToMonth={scrollToMonth}
                />
              )}
            </div>
          </div>
          <div>
            <BudgetDetailsTable rows={getDetailsTableData()} viewType={viewType} />
          </div>
        </div>
        {/* TODO add alert details */}
        {/* <div className={classes.leftSide}> */}
        {/*  <div className={classes.header}> */}
        {/*    <span className={classes.title}>Alerts per Month</span> */}
        {/*  </div> */}
        {/* </div> */}
      </div>
      <NewCustomDashboardPanelModal
        modalIsOpen={dashboardModalIsOpen}
        getCurrentCauParams={getParamsForCustomDashboard}
        onClose={() => {
          setDashboardModalIsOpen(false);
          setCustomPanelType(null);
        }}
        customDashboardStore={usageStore.customDbSubStore}
        existingDashboardsNamesAndIds={existingDashboardsNamesAndIds}
        helpers={custDabrdHelpers}
        type={customPanelType}
        isCustomDbModelLoading={modelIsLoading}
        defaultPanelName={currentBudget.budgetName}
        state={{
          currCustomPanelType: customPanelType,
          currDisplayedBudgetDate: new Date(currentBudget.monthlyStartDate),
        }}
        isSelectTimePeriodEnabled={false}
        usageStore={usageStore}
      />
    </div>
  );
};

export default BudgetDetails;

BudgetDetails.propTypes = {
  budget: PropTypes.object.isRequired,
  isDashboardPanel: PropTypes.bool,
};

BudgetDetails.defaultProps = {
  isDashboardPanel: false,
};
