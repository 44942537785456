import React, { useEffect, useRef, useState } from 'react';
import { AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Area, ReferenceLine, Tooltip } from 'recharts';
import { BudgetTypes } from 'usage/constants/usageConstants';
import { palette } from 'shared/constants/colorsConstants';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import {
  FORECAST_COST_COLOR,
  getBudgetAmount,
  getNumberAbbreviationFormat,
  HIGH_COST_COLOR,
  LIGHT_RED_COLOR,
  LIMIT_COST_COLOR,
  PROPER_COST_COLOR,
} from 'usage/containers/Budget/budgetUtil';
import { percentStr } from 'shared/utils/strUtil';
import moment from 'moment';
import classes from './budgets.module.scss';

const getActualValueColor = (payload) => {
  if (payload.cost) {
    return PROPER_COST_COLOR;
  }
  if (payload.warnCost) {
    return LIMIT_COST_COLOR;
  }
  if (payload.overuseCost) {
    return HIGH_COST_COLOR;
  }
  if (payload.forecast || payload.overuseForecast) {
    return FORECAST_COST_COLOR;
  }
  return PROPER_COST_COLOR;
};
const CustomTooltip = ({ active, payload, budget }) => {
  const { getCurrencyNumber } = useUserSettingsContext();

  if (active && payload && payload[0]) {
    const { date, forecast, actualData, cost, overuseCost, warnCost, budgetForThisDay } = payload[0].payload;
    return (
      <div className={classes.customTooltip}>
        <p className={classes.date}>
          <GenerateIcon iconName={ICONS.calendarDays.name} />
          <span className={classes.dateValue}>{moment(new Date(date)).format('DD MMM')}</span>
        </p>
        <div className={classes.details}>
          <p className={classes.label}>
            {forecast && !cost && !overuseCost && !warnCost ? 'Forecast Accumulated Cost: ' : 'Accumulated Cost: '}
            <span className={classes.value} style={{ color: getActualValueColor(payload[0].payload) }}>
              {`${getNumberAbbreviationFormat(payload[0].value, getCurrencyNumber)} (${Math.round(
                (payload[0].value * 100) / (budgetForThisDay || budget),
              )}%)`}
            </span>
          </p>
          {actualData && (
            <p className={classes.label}>
              {forecast && !cost && !overuseCost && !warnCost ? 'Forecast' : 'Actual'} daily cost:{' '}
              <span className={classes.value} style={{ color: getActualValueColor(payload[0].payload) }}>
                {getNumberAbbreviationFormat(payload[0].payload.actualData, getCurrencyNumber)}
              </span>
            </p>
          )}
          <p className={classes.label}>
            Previous day delta:{' '}
            <span className={classes.value}>
              {getCurrencyNumber(payload[0].payload.previousDayDelta)} (
              {percentStr((payload[0].payload.previousDayDelta * 100) / (budgetForThisDay || budget))})
            </span>
          </p>
          <p className={classes.label}>
            Budget:{' '}
            <span className={classes.value}>
              {getNumberAbbreviationFormat(budgetForThisDay || budget, getCurrencyNumber)}
            </span>
          </p>
        </div>
      </div>
    );
  }

  return null;
};
CustomTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.array.isRequired,
  budget: PropTypes.number.isRequired,
};
const DailyCostAreaChart = ({ data, customLegend, budget, isDashboardPanel, scrollToMonth }) => {
  const { budgetType, budgetAmount, budgetAmounts } = budget;
  const [chartWidth, setChartWidth] = useState(window.innerWidth - 210);
  const monthlyStartDate = new Date(budget.monthlyStartDate);
  const { getCurrencyNumber } = useUserSettingsContext();
  const dateIndex = (budgetAmounts || []).findIndex((elem) => {
    const date = new Date(elem.date);
    return date.getMonth() === monthlyStartDate.getMonth() && date.getFullYear() === monthlyStartDate.getFullYear();
  });
  const referenceLineValue =
    dateIndex > -1 && budgetType === BudgetTypes.EXPIRING ? budgetAmounts[dateIndex].amount : budgetAmount;
  const maxCost = data.reduce((max, current) => {
    if (current.cost !== null && (max === null || current.cost > max)) {
      return current.cost;
    }
    if (current.forecast !== null && (max === null || current.forecast > max)) {
      return current.forecast;
    }
    if (current.overuseCost !== null && (max === null || current.overuseCost > max)) {
      return current.overuseCost;
    }
    return max;
  }, null);

  const chartRef = useRef(null);

  useEffect(() => {
    if (!scrollToMonth) {
      return;
    }
    if (chartRef.current) {
      const xValues = chartRef.current.state.xAxisMap[0].domain;
      const chartContainer = document.getElementById('chart-container');
      const jumpIndex = xValues.findIndex((value) => value === scrollToMonth);

      if (jumpIndex !== -1) {
        const chartWidth = chartRef.current.container.clientWidth;
        const newScrollX = jumpIndex * (chartWidth / (xValues.length - 1));
        chartContainer.scrollLeft = newScrollX;
      }
    }
  }, [scrollToMonth]);

  useEffect(() => {
    if (data.length > 40) {
      setChartWidth(window.innerWidth - 210 > data.length * 22 ? window.innerWidth - 210 : data.length * 22);
    } else {
      setChartWidth(window.innerWidth - 210);
    }
  }, [data]);

  const buildReferenceLine = () => {
    const lines = [];
    if (!budgetAmounts?.length) {
      return [];
    }
    data.forEach((d, index) => {
      if (data[index + 1] && new Date(d.date).getMonth() !== new Date(data[index + 1].date).getMonth()) {
        const bAmount = getBudgetAmount(budget, new Date(d.date));
        lines.push({
          startIndex: !lines.length ? 0 : lines[lines.length - 1].endIndex,
          endIndex: index,
          startVal: bAmount,
          endVal: bAmount,
        });
        if (index + 1 < data.length) {
          lines.push({
            startIndex: index,
            endIndex: index,
            startVal: bAmount,
            endVal: getBudgetAmount(budget, new Date(data[index + 1].date)),
          });
        }
      }
    });
    const dt = getBudgetAmount(budget, new Date(data[data.length - 1].date));
    lines.push({
      startIndex: !lines.length ? 0 : lines[lines.length - 1].endIndex,
      endIndex: data.length - 1,
      startVal: dt,
      endVal: dt,
    });
    return lines;
  };

  return (
    <>
      <div
        id="chart-container"
        style={{
          width: isDashboardPanel ? '98%' : `${window.innerWidth - 210}px`,
          overflowX: 'scroll',
          position: 'relative',
        }}
      >
        <div style={{ width: `${chartWidth}px` }}>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart
              data={data}
              width={data.length * 50}
              margin={{
                top: 10,
                right: 30,
                left: 20,
                bottom: 20,
              }}
              ref={chartRef}
              style={{ position: 'inherit' }}
            >
              <CartesianGrid vertical={false} stroke={palette.gray[200]} />
              <XAxis
                dataKey="date"
                tickFormatter={(value) => {
                  if (value.toLowerCase().indexOf('w') > -1) {
                    return value;
                  }
                  if (value.length > 0 && value.length < 9) {
                    return new Date(value).toLocaleString('en-US', { month: 'short', year: 'numeric' });
                  }
                  return new Date(value).toLocaleString('en-US', { month: 'short', day: 'numeric' });
                }}
                interval={1}
                stroke={palette.gray[300]}
                tick={{ fill: palette.gray[450], fontSize: 12 }}
              />
              <YAxis
                tickFormatter={(value) => getNumberAbbreviationFormat(Number(value.toFixed(0)), getCurrencyNumber)}
                stroke={palette.gray[300]}
                tick={{ fill: palette.gray[450], fontSize: 12 }}
              />
              <Tooltip content={<CustomTooltip budget={referenceLineValue} />} />
              {budgetType !== BudgetTypes.EXPIRING ? (
                <ReferenceLine
                  y={referenceLineValue}
                  position="top"
                  label={{
                    position: data?.length && maxCost > referenceLineValue ? 'insideBottomLeft' : 'insideTopLeft',
                    value: 'BUDGET',
                    fill: `${LIGHT_RED_COLOR}`,
                    fontSize: 14,
                  }}
                  stroke={LIGHT_RED_COLOR}
                  ifOverflow="extendDomain"
                />
              ) : (
                buildReferenceLine().map((t, index) => (
                  <ReferenceLine
                    segment={[
                      { x: data[t.startIndex].date, y: t.startVal },
                      { x: data[t.endIndex].date, y: t.endVal },
                    ]}
                    position="top"
                    label={{
                      position: data?.length && maxCost > referenceLineValue ? 'insideBottomLeft' : 'insideTopLeft',
                      value: index === 0 ? 'BUDGET' : '',
                      fill: `${LIGHT_RED_COLOR}`,
                      fontSize: 14,
                    }}
                    stroke={LIGHT_RED_COLOR}
                    ifOverflow="extendDomain"
                  />
                ))
              )}
              <defs>
                <linearGradient id="colorCost" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={PROPER_COST_COLOR} stopOpacity={0.4} />
                  <stop offset="100%" stopColor={PROPER_COST_COLOR} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorOveruse" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={HIGH_COST_COLOR} stopOpacity={0.4} />
                  <stop offset="95%" stopColor={HIGH_COST_COLOR} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorCostLimit" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={LIMIT_COST_COLOR} stopOpacity={0.4} />
                  <stop offset="95%" stopColor={LIMIT_COST_COLOR} stopOpacity={0} />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="cost"
                stroke={PROPER_COST_COLOR}
                fill="url(#colorCost)"
                fillOpacity="0.7"
              />
              <Area
                type="monotone"
                dataKey="warnCost"
                stroke={LIMIT_COST_COLOR}
                fill="url(#colorCostLimit)"
                fillOpacity="0.7"
              />
              <Area
                type="monotone"
                dataKey="forecast"
                stroke={FORECAST_COST_COLOR}
                fillOpacity="0"
                strokeDasharray="3 3"
              />
              <Area
                type="monotone"
                dataKey="overuseCost"
                stroke={HIGH_COST_COLOR}
                fill="url(#colorOveruse)"
                fillOpacity="0.7"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
      {customLegend}
    </>
  );
};

export default DailyCostAreaChart;
DailyCostAreaChart.propTypes = {
  data: PropTypes.array.isRequired,
  customLegend: PropTypes.element,
  budget: PropTypes.object.isRequired,
  isDashboardPanel: PropTypes.bool,
  scrollToMonth: PropTypes.string,
};

DailyCostAreaChart.defaultProps = {
  customLegend: null,
  isDashboardPanel: false,
  scrollToMonth: null,
};
