import { API } from 'shared/utils/apiMiddleware';
import { BILLINGS_API_NAME, USER_MANAGEMENT_USERS } from './helperFunctions';

export async function createUsers(users) {
  const payload = {
    body: {
      users,
    },
  };
  try {
    return API.post(BILLINGS_API_NAME, USER_MANAGEMENT_USERS, payload);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateUser(user) {
  const payload = {
    body: {
      user,
    },
  };
  try {
    return API.put(BILLINGS_API_NAME, `${USER_MANAGEMENT_USERS}/${user.id}`, payload);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteUsers(userIds) {
  const payload = {
    body: {
      userIds,
    },
  };
  try {
    return API.del(BILLINGS_API_NAME, `${USER_MANAGEMENT_USERS}`, payload);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function enableDisableUsers(userIds, enable) {
  try {
    return API.post(BILLINGS_API_NAME, `${USER_MANAGEMENT_USERS}/enable-disable?enable=${enable}`, {
      body: {
        userIds,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function resetUsersPassword(userIds) {
  try {
    return API.post(BILLINGS_API_NAME, `${USER_MANAGEMENT_USERS}/reset-password`, {
      body: {
        userIds,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchUsers() {
  try {
    return API.get(BILLINGS_API_NAME, USER_MANAGEMENT_USERS);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchAllUsers() {
  try {
    return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_USERS}/all`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function downloadUsers(usersToDownload) {
  try {
    return API.post(BILLINGS_API_NAME, `${USER_MANAGEMENT_USERS}/export`, {
      usersToDownload,
    });
  } catch (error) {
    return Promise.reject(error);
  }
}
