/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ClickAwayListener } from '@mui/material';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import Button from './Button';
import styles from './ButtonDropdown.module.scss';
import Tooltip from './Tooltip';

const ButtonDropdown = ({
  children,
  icon,
  text,
  tooltipText,
  closeOnSelect,
  disabled,
  onClose,
  arrow,
  isSecondary,
  isTextButton,
  overrideMenuStyles,
  overrideButtonStyles,
  menuOpen: controlledMenuOpen,
  setMenuOpen: controlledSetMenuOpen,
  isLoading,
  automationId,
  iconPlacement,
}) => {
  const [uncontrolledMenuOpen, uncontrolledSetMenuOpen] = useState(false);
  const menuOpen = controlledMenuOpen !== null ? controlledMenuOpen : uncontrolledMenuOpen;
  const setMenuOpen = controlledSetMenuOpen !== null ? controlledSetMenuOpen : uncontrolledSetMenuOpen;

  const handleSelect = (event) => {
    // since click event can be on any level of children, need to find nearest parent with id
    let currentElemet = event.target;
    let option = '';
    for (let i = 0; i < 10; i++) {
      // limit the search to 10 levels up
      if (currentElemet.id) {
        option = currentElemet.id;
        break;
      }
      currentElemet = currentElemet.parentElement;
    }
    // report to segment
    segmentEvent({
      type: 'select',
      target: automationId || text || 'ButtonDropdown',
      data: {
        option,
      },
    });
    setMenuOpen(false);
  };

  useEffect(() => {
    if (!menuOpen) {
      onClose();
    }
  }, [onClose, menuOpen]);
  const button = (
    <div className={classnames({ [styles.withArrow]: arrow })}>
      <Button
        text={text}
        disabled={disabled}
        icon={icon}
        iconPlacement={iconPlacement}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setMenuOpen(!menuOpen);
        }}
        overrideStyles={{ paddingLeft: 8, paddingRight: 8, ...overrideButtonStyles }}
        isSecondary={isSecondary}
        isTextButton={isTextButton}
        iconSize={16}
        isLoading={isLoading}
        automationId={automationId}
      />
    </div>
  );
  return (
    <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
      <div className={styles.container}>
        {tooltipText && !menuOpen && !disabled ? (
          <Tooltip title={tooltipText} arrow>
            {button}
          </Tooltip>
        ) : (
          button
        )}
        {menuOpen && (
          <div
            onClick={(e) => closeOnSelect && handleSelect(e)}
            style={overrideMenuStyles}
            className={styles.buttonDropDown}
          >
            {children}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

ButtonDropdown.propTypes = {
  text: PropTypes.string,
  tooltipText: PropTypes.string,
  closeOnSelect: PropTypes.bool,
  onClose: PropTypes.func,
  menuOpen: PropTypes.bool,
  overrideMenuStyles: PropTypes.object,
  overrideButtonStyles: PropTypes.object,
  disabled: PropTypes.bool,
  isTextButton: PropTypes.bool,
  arrow: PropTypes.bool,
  setMenuOpen: PropTypes.func,
  isSecondary: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.array.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  automationId: PropTypes.string,
  iconPlacement: PropTypes.string,
};

ButtonDropdown.defaultProps = {
  text: '',
  closeOnSelect: true,
  overrideMenuStyles: null,
  overrideButtonStyles: null,
  isSecondary: true,
  disabled: false,
  isLoading: false,
  isTextButton: false,
  arrow: false,
  tooltipText: null,
  onClose: () => {},
  icon: null,
  menuOpen: null,
  setMenuOpen: null,
  automationId: '',
  iconPlacement: 'left',
};

export default ButtonDropdown;
