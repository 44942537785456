import React from 'react';
import { Input } from 'reactstrap';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import classNames from 'classnames';
import styles from './preferences.module.scss';

interface PreferencesFiltersProps {
  className?: string;
  searchValue?: string;
  handleSearchValue(str: string): void;
  showActive: boolean;
  showInactive: boolean;
  setShowActive: any;
  setShowInactive: any;
}

const PreferencesFilters: React.FC<PreferencesFiltersProps> = ({
  className,
  searchValue,
  handleSearchValue,
  showActive,
  showInactive,
  setShowActive,
  setShowInactive,
}) => (
  <div className={classNames(className, 'd-flex align-items-center')}>
    <Input
      automationId="pref-search-input"
      type="text"
      value={searchValue}
      placeholder="Search by type..."
      onChange={(e) => handleSearchValue(e.target.value)}
      className={classNames(styles.newInput, 'me-3')}
      autoComplete="off"
      style={{ width: 'unset' }}
    />
    <Checkbox
      automationId="pref-active-checkbox"
      className="me-3"
      isChecked={showActive}
      primary
      onChange={() => {
        setShowActive((s) => !s);
      }}
      text="Active"
    />
    <Checkbox
      automationId="pref-inactive-checkbox"
      isChecked={showInactive}
      primary
      onChange={() => {
        setShowInactive((s) => !s);
      }}
      text="Inactive"
    />
  </div>
);

export default PreferencesFilters;
