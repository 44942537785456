import React from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';

import styles from './UserImpersonate.module.scss';

const UserImpersonate = ({ row }) => {
  const impersonateUser = () => {
    // console.log('Impersonate user', row);
  };

  return (
    <Tooltip title={`Impersonate ${row?.firstName} ${row?.lastName}`} placement="top">
      <span>
        <GenerateIcon iconName={ICONS.userSecret.name} onClick={impersonateUser} className={styles.impersonateIcon} />
      </span>
    </Tooltip>
  );
};

export default UserImpersonate;
