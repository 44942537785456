import React, { useEffect, useMemo, useState } from 'react';
import { spAnalyzerContext } from 'commitment/containers/spAnalyzerNew/contexts/spAnalyzerContext';

import {
  ACTUAL_CHART_LEGEND,
  ACTUAL_TP_MAP_DATA,
  ON_DEMAND_CHART_LEGEND,
  ON_DEMAND_TP_MAP_DATA,
} from 'commitment/containers/spAnalyzerNew/utils/consts';
import DiscountTable from './DiscountTable';
import RecommendedPlanDetails from './RecommendedPlanDetails';
import styles from './recommendedPlanData.module.scss';
import RecommendedChart from './recommendedCharts/RecommendedChart';

const RecommendedPlanData = () => {
  const { recommendationPlanData: recPlanData, planDataByKey } = spAnalyzerContext();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [recPerPlanData, setRecPerPlanData] = useState();

  useEffect(() => {
    if (selectedPlan) {
      setRecPerPlanData(planDataByKey?.[selectedPlan] || recPlanData?.recommendationPerPlan?.[selectedPlan]);
    }
  }, [planDataByKey, recPlanData, selectedPlan]);

  const onDemandChartData = useMemo(() => {
    if (!selectedPlan || !recPerPlanData) {
      return null;
    }
    const currentSpChartDaily = recPlanData?.currentSpCoverage?.daily;
    const onDemandChartDaily = recPlanData?.grossCosts?.daily;
    const recommendedSpChartDaily = recPerPlanData?.recommendedSpCoverage?.daily;

    const currentSpChartHourly = recPlanData?.currentSpCoverage?.hourly;
    const onDemandCharHourly = recPlanData?.grossCosts?.hourly;
    const recommendedSpChartHourly = recPerPlanData?.recommendedSpCoverage?.hourly;
    const dailyData = Object.entries(currentSpChartDaily?.spCoverage || {}).map(([date, currentSP]) => ({
      date,
      currentSP,
      onDemand: onDemandChartDaily.grossCost[date],
      recommendedSP: recommendedSpChartDaily?.spCoverage[date],
    }));
    const HourlyData = Object.entries(currentSpChartHourly?.spCoverage || {}).map(([date, currentSP]) => ({
      date,
      currentSP,
      onDemand: onDemandCharHourly.grossCost[date],
      recommendedSP: recommendedSpChartHourly?.spCoverage[date],
    }));
    return {
      daily: dailyData,
      hourly: HourlyData,
    };
  }, [recPlanData, recPerPlanData]);

  const actualChartData = useMemo(() => {
    if (!selectedPlan || !recPerPlanData) {
      return null;
    }
    const hourlyCommitmentDaily = recPlanData?.currentNetCosts?.daily?.hourlyCommitment;
    const netCostDataDaily = recPlanData?.currentNetCosts?.daily?.netCost;
    const hourlyCommitmentHourly = recPlanData?.currentNetCosts?.hourly?.hourlyCommitment;
    const netCostDataHourly = recPlanData?.currentNetCosts?.hourly?.netCost;

    const simulateHourlyCommitmentDaily = recPlanData?.simulatedCurrentNetCosts?.daily?.hourlyCommitment;
    const simulateNetCostDataDaily = recPlanData?.simulatedCurrentNetCosts?.daily?.netCost;
    const simulateHourlyCommitmentHourly = recPlanData?.simulatedCurrentNetCosts?.hourly?.hourlyCommitment;
    const simulateNetCostDataHourly = recPlanData?.simulatedCurrentNetCosts?.hourly?.netCost;

    const recommHourlyCommitmentDaily = recPerPlanData?.recommendedNetCosts?.daily.hourlyCommitment;
    const recommNetCostDaily = recPerPlanData?.recommendedNetCosts?.daily.netCost;
    const recommHourlyCommitmentHourly = recPerPlanData?.recommendedNetCosts?.hourly.hourlyCommitment;
    const recommNetCostHourly = recPerPlanData?.recommendedNetCosts?.hourly.netCost;

    const dailyData = Object.entries(hourlyCommitmentDaily || {}).map(([date, hourlyCommitment]) => ({
      date,
      hourlyCommitment,
      actualOnDemand: netCostDataDaily[date] - hourlyCommitment,
      netCost: netCostDataDaily[date],
      accHourlyCommitment: recommHourlyCommitmentDaily[date],
      actualAccOnDemand: recommNetCostDaily[date] - recommHourlyCommitmentDaily[date],
      accNetCost: recommNetCostDaily[date],
      actualSimulateOnDemand: simulateNetCostDataDaily[date] - simulateHourlyCommitmentDaily[date],
      simulateHourlyCommitment: simulateHourlyCommitmentDaily[date],
      simulateNetCost: simulateNetCostDataDaily[date],
    }));
    const hourlyData = Object.entries(hourlyCommitmentHourly || {}).map(([date, hourlyCommitment]) => ({
      date,
      hourlyCommitment,
      actualOnDemand: netCostDataHourly[date] - hourlyCommitment,
      netCost: netCostDataHourly[date],
      accHourlyCommitment: recommHourlyCommitmentHourly[date],
      accNetCost: recommNetCostHourly[date],
      actualAccOnDemand: recommNetCostHourly[date] - recommHourlyCommitmentHourly[date],
      actualSimulateOnDemand: simulateNetCostDataHourly[date] - simulateHourlyCommitmentHourly[date],
      simulateHourlyCommitment: simulateHourlyCommitmentHourly[date],
      simulateNetCost: simulateNetCostDataHourly[date],
    }));

    return { daily: dailyData, hourly: hourlyData };
  }, [recPerPlanData, recPlanData]);

  return (
    <div className={styles.planDataWrapper}>
      <RecommendedPlanDetails selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} />
      <div className={styles.container} automation-id="onDemandChart">
        {recPlanData && recPerPlanData && (
          <RecommendedChart
            legend={ON_DEMAND_CHART_LEGEND}
            data={onDemandChartData}
            isAnimationActive
            info="In this chart, you will see the cost and coverage by the on-demand
                  equivalent costs- meaning, the cost before discount."
            header="On-Demand Equivalent - Average Compute Costs"
            tooltipMapData={ON_DEMAND_TP_MAP_DATA}
            automationId="onDemandChart"
            chartType="monotone"
          />
        )}
      </div>
      <div className={styles.container} automation-id="actualChart">
        {recPlanData && recPerPlanData && (
          <RecommendedChart
            legend={ACTUAL_CHART_LEGEND}
            data={actualChartData}
            includeEstimatedOption
            info={
              <div className={styles.tooltipWrapper}>
                <span className={styles.bold}> Display your current costs in two different options: </span>
                <span className={styles.thinText}>
                  <span className={styles.bold}>Actual Costs:</span> The costs you are paying today.{' '}
                </span>
                <span className={styles.thinText}>
                  <span className={styles.bold}>Simulated Costs:</span>
                  {`Today's coverage segmentation and a simulation
                  of your past data based on the current coverage.`}
                </span>
                <span className={styles.thinText}>
                  Additionally, you can view a simulation of your estimated costs after purchasing the recommended
                  savings plans.
                </span>
                <span className={styles.thinText}>Please note that the costs are accumulated.</span>
              </div>
            }
            header="Actual Costs - Average Compute Costs"
            tooltipMapData={ACTUAL_TP_MAP_DATA}
            automationId="actualChart"
            isAnimationActive={false}
          />
        )}
      </div>
      <div className={styles.container}>
        {selectedPlan && <DiscountTable discountData={recPlanData?.discountTables?.[selectedPlan] || []} />}
      </div>
    </div>
  );
};

export default RecommendedPlanData;
