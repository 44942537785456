import React, { useMemo, useState } from 'react';
import Input from 'shared/components/andtComponents/Input';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from '@mui/material/Tooltip';
import Button from 'shared/components/andtComponents/Button';
import { PageNames } from 'shared/constants/appConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import FieldSidebarFilter from 'shared/components/FilterSidebar/FieldSidebarFilter';
import { FilterTypes } from 'usage/constants/usageConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { spAnalyzerContext } from 'commitment/containers/spAnalyzerNew/contexts/spAnalyzerContext';
import { useInvoiceFilters } from 'invoices/contexts/InvoiceFiltersContext';
import styles from './analyzerFilters.module.scss';

const coverageInfo =
  // eslint-disable-next-line max-len
  'The coverage percentage from your total EC2 compute costs. For example, a percentage of 80%, means that 80% of your EC2 compute costs will be covered and 20% will remain on demand';
const AnalyzerFilters = ({ analyze, disabled }) => {
  const [viewType, setViewType] = useState('linkedAccount');
  const {
    daysToCheck,
    setDaysToCheck,
    coverage,
    setCoverage,
    filterStatusType,
    setFilterStatusType,
    selectedOptions,
    setSelectedOptions,
  } = spAnalyzerContext();
  const { getPageFilters } = useInvoiceFilters();
  const { usersStore } = useRootStore();
  const spDistincValuesMap = getPageFilters(PageNames.SAVINGS_PLANS_ANALYZER, usersStore.currDispUserCloudAccountType);

  const linkedAccountNames = useMemo(() => {
    const linkedAccFilter = spDistincValuesMap?.get(AwsCommonFields.LINKED_ACCOUNT_ID) || [];
    const linkedAccounts = [
      ...linkedAccFilter?.map((ln) => ({
        label: ln.linkedAccountName,
        value: ln.linkedAccountId,
      })),
    ];
    return linkedAccounts;
  }, [spDistincValuesMap]);

  const handleFilterChange = (fieldName, selection) => {
    setSelectedOptions({ ...selectedOptions, [fieldName]: selection });
  };

  const handleFilterTypeChange = (field) => {
    const filterTypeValue = filterStatusType[field] === FilterTypes.INCLUDE ? FilterTypes.EXCLUDE : FilterTypes.INCLUDE;
    setFilterStatusType({ ...filterStatusType, [field]: filterTypeValue });
  };
  return (
    <div className={styles.filterContainer}>
      <div className={styles.fieldsWrapper}>
        <div className={styles.groupFields}>
          <div
            className={`${styles.fieldWrapper}  ${
              selectedOptions[AwsCommonFields.LINKED_ACCOUNT_ID]?.length && styles.includeChip
            }`}
          >
            <span className={styles.headerField}>Saving Plan Scope</span>
            <div className={styles.scopeWrapper}>
              <RadioButtonList
                className={styles.radioList}
                optionClassName={styles.option}
                options={[
                  { label: 'Payer Account', name: 'payer', value: 'payer', primary: true, automationId: 'payerRadio' },
                  {
                    label: 'Linked Account',
                    name: 'linkedAccount',
                    value: 'linkedAccount',
                    primary: true,
                    automationId: 'linkedAccountRadio',
                  },
                ]}
                value={viewType}
                onChange={(val) => {
                  setViewType(val);
                  if (val === 'payer') {
                    setSelectedOptions({});
                  }
                }}
              />
            </div>
          </div>
          <div
            className={`${styles.fieldWrapper} ${styles.linkedAccWrapper} ${
              selectedOptions[AwsCommonFields.LINKED_ACCOUNT_ID]?.length && styles.includeChip
            }`}
          >
            <span>Select Linked Accounts</span>
            <FieldSidebarFilter
              excludeMode={filterStatusType[AwsCommonFields.LINKED_ACCOUNT_ID] === FilterTypes.EXCLUDE}
              automation-id={`heatmap-${AwsCommonFields.LINKED_ACCOUNT_ID}-filter`}
              handleChange={handleFilterChange}
              handleChangeFilterType={handleFilterTypeChange}
              label="Linked Accounts"
              field={AwsCommonFields.LINKED_ACCOUNT_ID}
              isDisabled={viewType === 'payer'}
              className={{
                sideBarField: styles.sideBarField,
                fieldNameContainer: styles.fieldNameContainer,
                badgeContainer: styles.badgeContainer,
              }}
              selectedOptions={selectedOptions[AwsCommonFields.LINKED_ACCOUNT_ID]}
              options={linkedAccountNames}
              onOpen={() => {}}
              toFormatData={false}
            />
          </div>
        </div>
        <div className={styles.groupFields}>
          <div
            className={`${styles.fieldWrapper} ${
              selectedOptions[AwsCommonFields.LINKED_ACCOUNT_ID]?.length && styles.includeChip
            }`}
          >
            <span className={styles.headerField}>
              Days To Check <span className={styles.italicTxt}>(Max 45)</span>
            </span>
            <Input
              type="number"
              min={1}
              max={45}
              automationId="daysToCheck"
              className={styles.input}
              value={daysToCheck}
              onChange={(e) => {
                if (e.target.value === '' || (e.target.value >= 1 && e.target.value <= 45)) {
                  setDaysToCheck(e.target.value);
                }
              }}
            />
          </div>
          <div
            className={`${styles.fieldWrapper} ${
              selectedOptions[AwsCommonFields.LINKED_ACCOUNT_ID]?.length && styles.includeChip
            }`}
          >
            <div className={styles.coverageWrapper}>
              <span className={styles.headerField}>Coverage %</span>
              <Tooltip arrow title={coverageInfo}>
                <span className={styles.infoIcon}>
                  <GenerateIcon iconName={ICONS.circleInfo.name} />
                </span>
              </Tooltip>
            </div>
            <Input
              min={0}
              max={100}
              type="number"
              className={styles.input}
              automationId="coverage"
              value={coverage}
              onChange={(e) => {
                if (e.target.value >= 0 && e.target.value <= 100) {
                  setCoverage(e.target.value);
                }
              }}
            />
            <div className={styles.linkWrapper}>
              <span className={styles.linkText} automation-id="currentCoverage">
                My current coverage
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.calculateWrapper}>
        {/* <div> */}
        {/*  <Checkbox */}
        {/*    onChange={() => {}} */}
        {/*    text="Calculate based on savings plans that expire up to 30 days" */}
        {/*    className={styles.checkbox} */}
        {/*  /> */}
        {/*  <Tooltip arrow title={calculateInfo}> */}
        {/*    <span> */}
        {/*      <GenerateIcon iconName={ICONS.circleInfo.name} className={styles.infoIcon} /> */}
        {/*    </span> */}
        {/*  </Tooltip> */}
        {/* </div> */}
        <Button
          text="Analyze"
          disabled={disabled}
          className={{ button: styles.analyzeBtn }}
          icon={() => <GenerateIcon iconName={ICONS.calculator.name} />}
          onClick={analyze}
          automationId="analyzeBtn"
        />
      </div>
    </div>
  );
};

export default AnalyzerFilters;
