/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import Button from 'shared/components/andtComponents/Button';

import styles from './NewTable.module.scss';

export default function useTable() {
  const NewTableWrapper = useCallback(
    ({ children, className, isCompact = false }) => (
      <div className={`new-table${isCompact ? '-compact-view' : ''} ${className || ''}`}>{children}</div>
    ),
    [],
  );

  const NewTableRowDetail = ({ children, className }) => <Table.Row className={className || ''}>{children}</Table.Row>;

  const NewTableRow = ({ spacerHeight = 8, expandedRowIds, lastRowId, setExpandedRowIds, ...restProps }) => {
    const { rowId } = restProps.tableRow;
    const isExpand = expandedRowIds.includes(rowId);
    const isAfterExpand = expandedRowIds.includes(rowId - 1);
    const isLastRow = lastRowId && rowId === lastRowId;
    return (
      <>
        {isAfterExpand && <tr style={{ height: spacerHeight }} />}
        <Table.Row
          {...restProps}
          className={`tableRow ${expandedRowIds.includes(rowId) && 'table-row-expanded'} 
          ${restProps.row?.isExpired && 'table-row-expired'}`}
          onClick={() =>
            setExpandedRowIds &&
            setExpandedRowIds(
              // toggle rowId in expandedRowIds
              isExpand ? expandedRowIds.filter((i) => i !== rowId) : [...expandedRowIds, rowId],
            )
          }
        />
        {!isExpand && !isLastRow && <tr style={{ height: spacerHeight }} />}
      </>
    );
  };

  const NewTableColumnsFilterSwitch = ({ rowInd, expandedRowIds, showFilterIds, setShowFilterIds }) => {
    const filterOpen = showFilterIds.includes(rowInd);
    if (!expandedRowIds.includes(rowInd)) {
      return null;
    }
    return filterOpen ? (
      <div className={styles.filterButton}>
        <Button
          onClick={() => setShowFilterIds(showFilterIds.filter((id) => id !== rowInd))}
          isTextButton
          text="Columns Filter"
          icon={() => <GenerateIcon iconName={ICONS.xMark.name} />}
        />
      </div>
    ) : (
      <div className={styles.filterButton}>
        <Button
          onClick={() => setShowFilterIds([...showFilterIds, rowInd])}
          isTextButton
          text="Columns Filter"
          icon={() => <GenerateIcon iconName={ICONS.filterList.name} />}
        />
      </div>
    );
  };

  const NewTableSelectionBar = ({ selectedRows, children, counterClass }) => {
    if (!selectedRows.length) {
      return null;
    }
    return (
      <div className={styles.selectionBar}>
        <div className={styles.selectionBarCounter}>
          <div className={`${styles.counter} ${counterClass}`}>{selectedRows.length}</div>
          <p>Item{selectedRows.length > 1 ? 's' : ''} selected</p>
        </div>
        {children}
      </div>
    );
  };

  const NewNoDataCell = ({ children, ...props }) => <td {...props}>{children}</td>;

  return {
    NewTableWrapper,
    NewTableRow,
    NewTableRowDetail,
    NewTableColumnsFilterSwitch,
    NewTableSelectionBar,
    NewNoDataCell,
  };
}
