/* eslint-disable no-tabs */
/* eslint-disable indent */
/* eslint-disable no-unused-vars,arrow-body-style */

const apiReqHeaders = {
  createHeaders: () => {},
  setCreateHeadersFunc: (func) => {
    apiReqHeaders.createHeaders = func;
  },
};

const local = {
  env: 'localDev',
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'http://127.0.0.1:8080',
    LEGACY_URL: 'https://f78f-199-203-250-201.ngrok-free.app', // your ngrok url for slack development (https)
  },
  COGNITO_POOL_URL: 'https://dev-mypileus.auth.us-east-1.amazoncognito.com',
  // eslint-disable-next-line max-len
  COGNITO_REDIRECT_URI: 'https://dev.mypileus.io', // Change this to your local url if needed - check the url is set as valid in the sso configuration in cognito
};

const dev = {
  env: 'dev',
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://api.dev.mypileus.io',
  },
  KEYCLOAK_URL: 'https://keycloak.dev.mypileus.io',
  COGNITO_POOL_URL: 'https://dev-mypileus.auth.us-east-1.amazoncognito.com',
  COGNITO_REDIRECT_URI: 'https://dev.mypileus.io',
};

const staging = {
  env: 'staging',
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://api.staging.mypileus.io',
  },
  COGNITO_POOL_URL: 'https://dev-mypileus.auth.us-east-1.amazoncognito.com',
  COGNITO_REDIRECT_URI: 'https://staging.mypileus.io',
  // eslint-disable-next-line max-len
  KEYCLOAK_URL: 'https://keycloak.dev.mypileus.io', // TODO - change to staging keycloak url when keycloak staging setup is done
};

const devBigquery = {
  env: 'dev',
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://api.bigquery.mypileus.io',
  },
  COGNITO_POOL_URL: 'https://dev-mypileus.auth.us-east-1.amazoncognito.com',
  COGNITO_REDIRECT_URI: 'https://dev.mypileus.io',
  KEYCLOAK_URL: 'https://keycloak.dev.mypileus.io',
};

const redshiftClusters = {
  env: 'dev',
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://api-uicluster.dev.mypileus.io',
  },
  COGNITO_POOL_URL: 'https://mypileus.auth.us-east-1.amazoncognito.com',
  COGNITO_REDIRECT_URI: 'https://mypileus.io',
  KEYCLOAK_URL: 'https://keycloak.dev.mypileus.io',
};

const prod = {
  env: 'prod',
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://api-front.mypileus.io',
    LEGACY_URL: 'https://api.mypileus.io',
  },
  COGNITO_POOL_URL: 'https://mypileus.auth.us-east-1.amazoncognito.com',
  COGNITO_REDIRECT_URI: 'https://mypileus.io',
  // eslint-disable-next-line max-len
  KEYCLOAK_URL: 'https://keycloak.dev.mypileus.io', // TODO - change to prod keycloak url when keycloak prod setup is done
};

let config;
switch (process.env.REACT_APP_STAGE) {
  case 'local':
    // eslint-disable-next-line no-case-declarations
    let keycloakUrl = 'https://keycloak.dev.mypileus.io';
    if (process.env.dbEnv === 'prod') {
      // eslint-disable-next-line max-len
      keycloakUrl = 'https://keycloak.dev.mypileus.io'; // TODO - change to prod keycloak url when keycloak prod setup is done
    } else if (process.env.dbEnv === 'staging') {
      // eslint-disable-next-line max-len
      keycloakUrl = 'https://keycloak.dev.mypileus.io'; // TODO - change to staging keycloak url when keycloak staging setup is done
    }
    config = { ...local, KEYCLOAK_URL: keycloakUrl }; // in case of local set it by dbEnv
    break;
  case 'dev':
    config = dev;
    break;
  case 'staging':
    config = staging;
    break;
  case 'bigquery':
    config = devBigquery;
    break;
  case 'prod':
    config = prod;
    break;
  case 'redshift-clusters':
    config = redshiftClusters;
    break;
  default:
    config = dev; // ##default##
}

export default {
  // common config values here
  apiReqHeaders,
  mock: local,
  ...config,
};
