import React from 'react';
import { SWITCH_BUTTON_STATUSES } from 'recommendationsNew/consts';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';

import classes from './recommendationStatusSymbol.module.scss';

export const OpenStatus = ({ withBorder }) => (
  <div className={`${classes.openStatus} ${withBorder ? classes.withBorder : classes.statusContainer}`}>
    {SWITCH_BUTTON_STATUSES.OPEN}
  </div>
);

export const CompletedStatus = ({ withBorder }) => (
  <div className={`${classes.completedStatus} ${withBorder ? classes.withBorder : classes.statusContainer}`}>
    {SWITCH_BUTTON_STATUSES.COMPLETED}
  </div>
);

export const DoneStatus = ({ withBorder }) => (
  <div className={`${classes.doneStatus} ${withBorder ? classes.withBorder : classes.statusContainer}`}>
    <GenerateIcon iconName={ICONS.clipboardCheck.name} />
  </div>
);

export const DoneStatusWithLabel = () => (
  <div className={classes.filterContainer}>
    <div className={classes.filterIconContainer}>
      <GenerateIcon iconName={ICONS.clipboardCheck.name} className={classes.doneStatus} />
    </div>
    <div className={classes.filterLabel}>{SWITCH_BUTTON_STATUSES.DONE}</div>
  </div>
);

export const UndoneStatusWithLabel = () => (
  <div className={classes.filterContainer}>
    <div className={classes.filterIconContainer}>
      <GenerateIcon iconName={ICONS.clipboardCheck.name} />
    </div>
    <div className={classes.filterLabel}>{SWITCH_BUTTON_STATUSES.UN_DONE}</div>
  </div>
);

export const NotExcluded = () => (
  <div className={classes.filterContainer}>
    <div className={classes.filterIconContainer}>
      <GenerateIcon iconName={ICONS.excluded.name} />
    </div>
    <div className={classes.filterLabel}>{SWITCH_BUTTON_STATUSES.NOT_EXCLUDED}</div>
  </div>
);

export const Excluded = () => (
  <div className={classes.filterContainer}>
    <div className={classes.filterIconContainer}>
      <GenerateIcon iconName={ICONS.excluded.name} className={classes.excludedStatus} />
    </div>
    <div className={classes.filterLabel}>{SWITCH_BUTTON_STATUSES.EXCLUDED}</div>
  </div>
);

export const StatusOperator = ({ operator }) => <span className={classes.operator}>{operator}</span>;
