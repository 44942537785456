import { FilterTypes } from 'usage/constants/usageConstants';

export const prepareSPAnalyzerFilters = (selectedOptions, filterStatusType) => {
  let includeFilterParams = '';
  let excludeFilterParams = '';
  Object.entries(selectedOptions || {}).forEach(([filter, filterValues]) => {
    if (filterValues && filterValues.length) {
      filterValues.forEach((filterValue) => {
        if (filterStatusType[filter] === FilterTypes.INCLUDE) {
          includeFilterParams = includeFilterParams.concat(`&includeFilters[${filter}]=${filterValue.value}`);
        } else if (filterStatusType[filter] === FilterTypes.EXCLUDE) {
          excludeFilterParams = excludeFilterParams.concat(`&excludeFilters[${filter}]=${filterValue.value}`);
        }
      });
    }
  });

  return { includeFilterParams, excludeFilterParams };
};
