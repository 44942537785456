import React from 'react';
import Input from 'shared/components/andtComponents/Input';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';

import styles from './Search.module.scss';

export const Search = ({ searchText, setSearchText }) => (
  <div className={styles.search}>
    <Input
      className={styles.searchInput}
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      placeholder="Search"
      name="search-users"
      isSearch
      searchComponent={<GenerateIcon iconName={ICONS.search.name} />}
      type="text"
    />
  </div>
);
