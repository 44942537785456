import React, { HTMLProps } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common/dist';
import styles from '../alerts.module.scss';

export interface EditDeleteButtonsProps extends HTMLProps<HTMLDivElement> {
  className?: string;
  onEdit(): void;
  onDelete(): void;
  alertId: string;
}

export const EditDeleteButtons: React.FC<EditDeleteButtonsProps> = ({
  className,
  alertId,
  onDelete,
  onEdit,
  ...divProps
}) => (
  <div className={className} {...divProps}>
    <GenerateIcon
      onClick={(e) => {
        e.stopPropagation();
        onEdit();
      }}
      iconName={ICONS.edit.name}
      className={styles.actionIcon}
      automation-id={`${alertId}edit-alert-button`}
    />
    <GenerateIcon
      onClick={(e) => {
        e.stopPropagation();
        onDelete();
      }}
      iconName={ICONS.delete.name}
      className={styles.actionIcon}
      automation-id={`${alertId}delete-alert-button`}
    />
  </div>
);

export default EditDeleteButtons;
