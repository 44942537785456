import React, { useState } from 'react';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common/dist';
import WritePermissionWrapper, { WRITE_PERMISSION_ENTITIES } from 'shared/components/WritePermissionWrapper';
import Button from 'shared/components/andtComponents/Button';
import { USER_ACTIONS } from '../consts';
import AssignRolesModal from './userModals/AssignRolesModal/AssignRolesModal';
import EditUserModal from './userModals/EditUserModal';
import ResetPasswordModal from './userModals/ResetPasswordModal';
import DeleteUsersModal from './userModals/DeleteUsersModal';

import styles from './UserActions.module.scss';

const UserActions = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isAssignRolesModalOpen, setIsAssignRolesModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

  const toggleOpen = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.userActionsContainer} onClick={(e) => toggleOpen(e)}>
      <Popover open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
        <PopoverTrigger as="button" type="button">
          <GenerateIcon iconName={ICONS.verticalDots.name} className={styles.actionsIcon} />
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent sideOffset={10} side="bottom" className={styles.popoverContent}>
            <WritePermissionWrapper entity={WRITE_PERMISSION_ENTITIES.USER_MANAGEMENT}>
              <Button
                isTextButton
                text={USER_ACTIONS.ASSIGN_ROLES.label}
                automationId={USER_ACTIONS.ASSIGN_ROLES.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={USER_ACTIONS.ASSIGN_ROLES.icon} className={styles.itemIcon} />}
                onClick={() => setIsAssignRolesModalOpen(true)}
              />
            </WritePermissionWrapper>
            <WritePermissionWrapper entity={WRITE_PERMISSION_ENTITIES.USER_MANAGEMENT}>
              <Button
                isTextButton
                text={USER_ACTIONS.EDIT.label}
                automationId={USER_ACTIONS.EDIT.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={USER_ACTIONS.EDIT.icon} className={styles.itemIcon} />}
                onClick={() => setIsEditUserModalOpen(true)}
              />
            </WritePermissionWrapper>
            <WritePermissionWrapper entity={WRITE_PERMISSION_ENTITIES.USER_MANAGEMENT}>
              <Button
                isTextButton
                text={USER_ACTIONS.RESET_PASSWORD.label}
                automationId={USER_ACTIONS.RESET_PASSWORD.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={USER_ACTIONS.RESET_PASSWORD.icon} className={styles.itemIcon} />}
                onClick={() => setIsResetPasswordModalOpen(true)}
              />
            </WritePermissionWrapper>
            <WritePermissionWrapper entity={WRITE_PERMISSION_ENTITIES.USER_MANAGEMENT}>
              <Button
                isTextButton
                text={USER_ACTIONS.DELETE.label}
                automationId={USER_ACTIONS.DELETE.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={USER_ACTIONS.DELETE.icon} className={styles.itemIcon} />}
                onClick={() => setIsDeleteUserModalOpen(true)}
              />
            </WritePermissionWrapper>
          </PopoverContent>
        </PopoverPortal>
      </Popover>

      <AssignRolesModal setIsOpen={setIsAssignRolesModalOpen} isOpen={isAssignRolesModalOpen} user={row} />
      <EditUserModal setIsOpen={setIsEditUserModalOpen} isOpen={isEditUserModalOpen} user={row} />
      <ResetPasswordModal setIsOpen={setIsResetPasswordModalOpen} isOpen={isResetPasswordModalOpen} users={[row]} />
      <DeleteUsersModal setIsOpen={setIsDeleteUserModalOpen} isOpen={isDeleteUserModalOpen} users={[row]} />
    </div>
  );
};

export default UserActions;
